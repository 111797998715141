import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Menu, MenuItem, CircularProgress, Box } from '@mui/material';
import client from '../client';
import theme from '../theme';
import AddRoleModal from '../components/modals/roles/AddRoleModal';
import EditRoleModal from '../components/modals/roles/EditRoleModal';
import DeleteRoleModal from '../components/modals/roles/DeleteRoleModal';
import { ActionDotIcon } from '../assets/images/icons/ActionDotIcon/ActionDotIcon';

function ManageRolesPage() {
  const [configs, setConfigs] = useState([]);
  const [roles, setRoles] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    try {
      const configsResponse = await client.get("/v1/rails/configs");
      setConfigs(configsResponse.data);
      const rolesResponse = await client.get("/roles");
      setRoles(rolesResponse.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleMenuClick = (event, role) => {
    setAnchorEl(event.currentTarget);
    setSelectedRole(role);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    setIsEditModalOpen(true);
    handleMenuClose();
  };

  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true);
    handleMenuClose();
  };

  const handleRoleAdded = () => {
    fetchData();
  };

  const handleRoleUpdated = () => {
    fetchData();
  };

  const handleRoleDeleted = () => {
    fetchData();
  };

  return (
    <div className="flex h-full w-full">
      <div style={{ width: '100vw', padding: '6px', marginTop: '180px', margin: 'auto' }} className='h-full'>
        <div className="flex justify-between p-4 items-center mb-4">
          <div>
            <div className="text-2xl font-semibold" style={{ color: theme.tmryk_background_color }}>
              Role Policy
            </div>
            <div className="text-sm" style={{ color: theme.tmryk_black_text_color }}>
              Manage role policies
            </div>
          </div>
          <div className="flex">
            <Button
              variant="contained"
              style={{
                backgroundColor: theme.tmryk_background_color,
                color: 'white',
                width: '150px',
                height: '34px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '20px'
              }}
              onClick={() => setIsAddModalOpen(true)}
            >
              Add Role
            </Button>
          </div>
        </div>
        <div style={{ maxHeight: "700px", overflowY: "auto" }}>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <CircularProgress />
            </Box>
          ) : (
            <Table style={{ width: '100%' }}>
              <TableHead>
                <TableRow style={{ height: '42px', maxWidth: '1122px', background: theme.tmryk_background_color }}>
                  <TableCell style={{ color: 'white', padding: '6px 64px 6px 0', borderBottom: 'none' }}>
                    <Checkbox sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} />
                  </TableCell>
                  <TableCell style={{ color: 'white', padding: '6px 80px 6px 0', borderBottom: 'none' }}>Role ID</TableCell>
                  <TableCell style={{ color: 'white', padding: '6px 80px 6px 0', borderBottom: 'none' }}>Role Name</TableCell>
                  <TableCell style={{ color: 'white', padding: '6px 80px 6px 0', borderBottom: 'none' }}>Policy</TableCell>
                  <TableCell style={{ color: 'white', padding: '6px 32px 6px 0', borderBottom: 'none' }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {roles.map((role, index) => (
                  <TableRow key={index} style={{ height: '54px', maxWidth: '1122px', backgroundColor: "white" }}>
                    <TableCell style={{ color: 'white', padding: '6px 64px 6px 0', borderBottom: 'none' }}>
                      <Checkbox sx={{ color: theme.tmryk_background_color, '&.Mui-checked': { color: theme.tmryk_background_color } }} />
                    </TableCell>
                    <TableCell style={{ color: theme.tmryk_black_text_color, padding: '6px 80px 6px 0', borderBottom: 'none' }}>{role.role_id}</TableCell>
                    <TableCell style={{ color: theme.tmryk_black_text_color, padding: '6px 80px 6px 0', borderBottom: 'none', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '200px' }}>{role.role_name}</TableCell>
                    <TableCell style={{ color: theme.tmryk_black_text_color, padding: '6px 80px 6px 0', borderBottom: 'none' }}>{role.config_id}</TableCell>
                    <TableCell style={{ color: theme.tmryk_black_text_color, padding: '6px 32px 6px 0', borderBottom: 'none' }}>
                      <Button onClick={(event) => handleMenuClick(event, role)}>
                        <ActionDotIcon />
                      </Button>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        sx={{
                          '& .MuiPaper-root': {
                            boxShadow: `0px 2px 8px ${theme.tmryk_box_shadow}`,
                            borderRadius: '8px',
                            backgroundColor: 'white',
                          },
                        }}
                      >
                        <MenuItem onClick={handleEditClick}>Edit</MenuItem>
                        <MenuItem
                          onClick={handleDeleteClick}
                          sx={{
                            color: 'red',
                            '&:hover': {
                              backgroundColor: '#FDECEA',
                              color: 'red',
                            },
                          }}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </div>
      </div>

      {isAddModalOpen && <AddRoleModal isModalOpen={isAddModalOpen} setIsModalOpen={setIsAddModalOpen} configs={configs} onRoleAdded={handleRoleAdded} />}
      {isEditModalOpen && <EditRoleModal isModalOpen={isEditModalOpen} setIsModalOpen={setIsEditModalOpen} configs={configs} selectedRole={selectedRole} onRoleUpdated={handleRoleUpdated} />}
      {isDeleteModalOpen && <DeleteRoleModal isModalOpen={isDeleteModalOpen} setIsModalOpen={setIsDeleteModalOpen} selectedRole={selectedRole} onRoleDeleted={handleRoleDeleted} />}
    </div>
  );
}

export default ManageRolesPage;
