import React, { useState, useRef, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Button } from "@mui/material";
import client from "../../../client";
import theme from "../../../theme";
import { toast } from "react-toastify";

function AddFileConfigModal({ isModalOpen, setIsModalOpen, configName, onSuccess }) {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const fileInputRef = useRef(null);

    // Function for changing selected files from the dropdown menu
    const handleFileChange = (event) => {
        setSelectedFiles([...selectedFiles, ...Array.from(event.target.files)]);
    };

    //Function for removing the selected file once user clicks on the delete button
    const removeFile = (fileName) => {
        setSelectedFiles(selectedFiles.filter(file => file.name !== fileName));
    };

    // Function for uploading new files to a Policy
    const uploadFiles = async () => {
        const formData = new FormData();
        selectedFiles.forEach(file => formData.append("files", file));

        try {
            const response = await client.post(`/add_files_to_config?config_name=${configName}`, formData);
            toast.success("Files added successfully!");
            onSuccess(response.data.files_added);
            setIsModalOpen(false);
        } catch (error) {
            console.error("Error uploading files:", error);
            toast.error("Error uploading files");
        }
    };

    return (
        <Transition.Root show={isModalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={() => setIsModalOpen(false)}>
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="space-y-6 px-4 py-5 sm:p-6">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900" style={{ color: theme.tmryk_background_color}}>Add Files to Config</h3>
                                    <h3 className="text-lg leading-6 font-medium text-gray-900 mt-4">Selected Config: {configName}</h3>

                                    <input
                                        ref={fileInputRef}
                                        type="file"
                                        multiple
                                        className="hidden"
                                        onChange={handleFileChange}
                                    />
                                    <Button
                                        variant="contained"
                                        onClick={() => fileInputRef.current.click()}
                                        style={{ backgroundColor: theme.tmryk_background_color, color: "white" }}
                                    >
                                        Browse
                                    </Button>
                                    <div>
                                        {selectedFiles.map(file => (
                                            <div key={file.name} className="flex items-center justify-between mt-2">
                                                <span>{file.name}</span>
                                                <button
                                                    onClick={() => removeFile(file.name)}
                                                    className="bg-red-500 text-white rounded-full px-2 py-1 ml-2"
                                                >
                                                    -
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="flex justify-end space-x-3">
                                        <Button
                                            variant="contained"
                                            onClick={uploadFiles}
                                            style={{ backgroundColor: theme.tmryk_background_color, color: "white" }}
                                        >
                                            Upload
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            onClick={() => setIsModalOpen(false)}
                                            style={{ borderColor: theme.tmryk_background_color, color: theme.tmryk_background_color }}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export default AddFileConfigModal;
