// Component for uploading credentials files to the application

import React from 'react';
import { Button, Typography } from '@mui/material';
import theme from '../../theme';
import { toast } from 'react-toastify';

const FileUploadField = ({ 
  modelConfig, 
  file, 
  setFile, 
  onFileUpload 
}) => {
  // Early return if no requiresFile or it's not enabled
  if (!modelConfig?.requiresFile) return null;

  // Default values for file upload
  const fileType = modelConfig.requiresFile?.fileType || 'application/json';
  const label = modelConfig.requiresFile?.label || 'Upload Credentials File';

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Accept files even if the mime type doesn't match exactly
      if (fileType === 'application/json' && (
        selectedFile.type === 'application/json' || 
        selectedFile.name.endsWith('.json') ||
        selectedFile.type === 'text/plain'
      )) {
        setFile(selectedFile);
        if (onFileUpload) {
          onFileUpload(selectedFile);
        }
      } else if (selectedFile.type === fileType) {
        setFile(selectedFile);
        if (onFileUpload) {
          onFileUpload(selectedFile);
        }
      } else {
        toast.error(`Please upload a valid ${fileType} file.`);
      }
    }
  };

  return (
    <div className="mt-4">
      <input
        type="file"
        accept={fileType}
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="credentials-file-upload"
      />
      <label htmlFor="credentials-file-upload">
        <Button 
          variant="contained" 
          component="span" 
          style={{ 
            backgroundColor: theme.tmryk_background_color, 
            color: 'white', 
            marginBottom: '10px' 
          }}
        >
          {label}
        </Button>
      </label>
      {file && <Typography variant="body2">Selected File: {file.name}</Typography>}
    </div>
  );
};

export default FileUploadField; 