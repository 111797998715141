import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  CircularProgress,
  Divider,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import { toast } from "react-toastify";
import theme from "../../theme";

const ServiceUrlsPanel = ({ configId, parsedConfig, updateServiceUrls }) => {
  const [urls, setUrls] = useState({
    topic_guard_endpoint: "http://jailbreak.tmryk.com:8030/topic-check",
    jailbreak_detection_endpoint: "http://jailbreak.tmryk.com/heuristics",
    safety_check_endpoint: "http://moderation.tmryk.com:8020/safety-check",
    spell_check_endpoint: "http://moderation.tmryk.com:8004/spell-check",
    bias_check_endpoint: "http://moderation.tmryk.com:8004/bias-check",
    sensitive_data_protection_endpoint: "http://moderation.tmryk.com:8006",
    hallucination_check_endpoint: "http://alignscore.tmryk.com/alignscore_base",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Initialize from parsedConfig
    if (parsedConfig?.rails?.config) {
      const newUrls = { ...urls };
      
      if (parsedConfig.rails.config.topic_guard?.server_endpoint) {
        newUrls.topic_guard_endpoint = parsedConfig.rails.config.topic_guard.server_endpoint;
      }
      
      if (parsedConfig.rails.config.jailbreak_detection?.server_endpoint) {
        newUrls.jailbreak_detection_endpoint = parsedConfig.rails.config.jailbreak_detection.server_endpoint;
      }
      
      if (parsedConfig.rails.config.safety_check?.server_endpoint) {
        newUrls.safety_check_endpoint = parsedConfig.rails.config.safety_check.server_endpoint;
      }
      
      if (parsedConfig.rails.config.spell_check?.server_endpoint) {
        newUrls.spell_check_endpoint = parsedConfig.rails.config.spell_check.server_endpoint;
      }
      
      if (parsedConfig.rails.config.bias_check?.server_endpoint) {
        newUrls.bias_check_endpoint = parsedConfig.rails.config.bias_check.server_endpoint;
      }
      
      if (parsedConfig.rails.config.sensitive_data_detection?.sensitive_data_protection_endpoint) {
        newUrls.sensitive_data_protection_endpoint = parsedConfig.rails.config.sensitive_data_detection.sensitive_data_protection_endpoint;
      }
      
      if (parsedConfig.rails.config.fact_checking?.parameters?.endpoint) {
        newUrls.hallucination_check_endpoint = parsedConfig.rails.config.fact_checking.parameters.endpoint;
      }
      
      setUrls(newUrls);
    }
  }, [parsedConfig]);

  const handleUrlChange = (key, value) => {
    setUrls(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const success = await updateServiceUrls(configId, urls);
      if (success) {
        toast.success("Service URLs updated successfully");
      } else {
        toast.error("Failed to update service URLs");
      }
    } catch (error) {
      console.error("Error updating service URLs:", error);
      toast.error("An error occurred while updating service URLs");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card 
      sx={{ 
        marginY: 2, 
        boxShadow: `0px 2px 4px ${theme.tmryk_box_shadow}`,
        border: "1px solid #e0e0e0"
      }}
    >
      <CardContent>
        <Typography variant="h6" sx={{ color: theme.tmryk_black_text_color, fontWeight: "500", mb: 2 }}>
          Service Endpoints Configuration
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
          Configure the endpoints for various services used by the policy.
        </Typography>
        
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="Topic Guard Endpoint"
              variant="outlined"
              fullWidth
              value={urls.topic_guard_endpoint}
              onChange={(e) => handleUrlChange('topic_guard_endpoint', e.target.value)}
              helperText="The URL of the Topic Guard service"
            />
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              label="Jailbreak Detection Endpoint"
              variant="outlined"
              fullWidth
              value={urls.jailbreak_detection_endpoint}
              onChange={(e) => handleUrlChange('jailbreak_detection_endpoint', e.target.value)}
              helperText="The URL of the Jailbreak Detection service"
            />
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              label="Safety Check Endpoint"
              variant="outlined"
              fullWidth
              value={urls.safety_check_endpoint}
              onChange={(e) => handleUrlChange('safety_check_endpoint', e.target.value)}
              helperText="The URL of the Safety Check service"
            />
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              label="Spell Check Endpoint"
              variant="outlined"
              fullWidth
              value={urls.spell_check_endpoint}
              onChange={(e) => handleUrlChange('spell_check_endpoint', e.target.value)}
              helperText="The URL of the Spell Check service"
            />
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              label="Bias Check Endpoint"
              variant="outlined"
              fullWidth
              value={urls.bias_check_endpoint}
              onChange={(e) => handleUrlChange('bias_check_endpoint', e.target.value)}
              helperText="The URL of the Bias Check service"
            />
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              label="Sensitive Data Protection Endpoint"
              variant="outlined"
              fullWidth
              value={urls.sensitive_data_protection_endpoint}
              onChange={(e) => handleUrlChange('sensitive_data_protection_endpoint', e.target.value)}
              helperText="The URL of the Sensitive Data Protection service"
            />
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              label="Hallucination Check Endpoint"
              variant="outlined"
              fullWidth
              value={urls.hallucination_check_endpoint}
              onChange={(e) => handleUrlChange('hallucination_check_endpoint', e.target.value)}
              helperText="The URL of the Fact Checking/Hallucination Detection service"
            />
          </Grid>
        </Grid>
        
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={isLoading}
            startIcon={isLoading && <CircularProgress size={20} color="inherit" />}
            sx={{
              backgroundColor: theme.tmryk_background_color,
              "&:hover": { backgroundColor: "#172E6C" },
              color: "white",
            }}
          >
            Save URLs
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

ServiceUrlsPanel.propTypes = {
  configId: PropTypes.string.isRequired,
  parsedConfig: PropTypes.object,
  updateServiceUrls: PropTypes.func.isRequired,
};

export default ServiceUrlsPanel; 