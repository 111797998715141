import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  IconButton,
  Box,
  ListItemIcon,
  Paper,
  Chip,
  Typography,
  styled,
} from "@mui/material";
import yaml from "js-yaml";
import client from "../../client";
import { Delete, InsertDriveFile } from "@mui/icons-material";
import { toast } from "react-toastify";

const EllipsisText = styled("span")({
  whiteSpace: "nowrap", // Prevent text wrapping
  overflow: "hidden", // Hide overflowed text
  textOverflow: "ellipsis", // Add ellipsis for overflowed text
  display: "inline-block", // Make sure it behaves like an inline element
  width: "90%", // Set a fixed width
});

const RAGDocumentsTab = ({
  selectedConfigId,
  ragType,
  fileNames,
  filesContent,
  updateQdrantConfig,
  fetchFileContents,
}) => {
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [deletFile, setDeleteFile] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFiles = async () => {
      setLoading(true);
      if (ragType === "kb") {
        setFilteredFiles(
          fileNames
            .filter((file) => file.startsWith("kb/"))
            .map((file) => ({ id: file, name: file }))
        );
      } else if (ragType === "qdrant" && filesContent) {
        const qdrantFileContent =
          yaml.load(filesContent["qdrant_config.yml"]) || "";
        const qdrantFiles = qdrantFileContent?.ids?.flatMap((fileObj) =>
          Object.entries(fileObj).map(([id, fileData]) => ({
            id,
            name: fileData.file_name,
          }))
        );
        setFilteredFiles(qdrantFiles);
      }
      setLoading(false);
    };

    fetchFiles();
  }, [ragType, fileNames, filesContent]);

  // Function to delete a file
  const handleRAGDocumentDelete = async (fileId, fileName) => {
    setDeleteFile(fileId);
    if (ragType === "kb") {
      // Call delete_config API for kb files
      try {
        await client.post(
          `/delete_config_file?config_name=${selectedConfigId}&file_name=${fileName}`
        );
        setFilteredFiles((prev) =>
          prev.filter((file) => file.name !== fileName)
        );
        await fetchFileContents(selectedConfigId);
      } catch (error) {
        toast.error(`Failed to delete file: ${fileName}`);
      }
    } else if (ragType === "qdrant" && filesContent) {
      // Get the file ID from qdrant_config.yml
      try {
        const parsedConfig = yaml.load(filesContent["qdrant_config.yml"]) || "";
        const fileToDelete = parsedConfig?.ids.find(
          (fileObj) => fileObj[fileId]
        );

        if (fileToDelete) {
          try {
            await client.post(`/delete_from_qdrant?chat_id=${fileId}&config_name=${selectedConfigId}`);
            const updatedQdrantConfig = {
              ids: parsedConfig.ids.filter((fileObj) => !fileObj[fileId]),
            };
            if (updatedQdrantConfig?.ids.length === 0) {
              await client.post(
                `/delete_config_file?config_name=${selectedConfigId}&file_name=qdrant_config.yml`
              );

              setFilteredFiles([]);
              await fetchFileContents(selectedConfigId);
            } else {
              // Update the qdrant_config.yml and reload the file content for that policy
              updateQdrantConfig(selectedConfigId, updatedQdrantConfig, false); // Update parent component
              await fetchFileContents(selectedConfigId);
            }
          } catch (error) {
            toast.error(`Failed to delete file: ${fileName}`);
          }
        }
      } catch (error) {
        console.error("Error updating qdrant_config:", error);
      }
      setDeleteFile(null);
    }
  };

  return (
    <Box px={2}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {ragType && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 1,
                gap: 2,
              }}
            >
              <Typography variant="body1">RAG Type:</Typography>
              <Chip
                variant="outlined"
                color="secondary"
                label={
                  ragType === "kb"
                    ? "Knowledge Base"
                    : ragType === "qdrant"
                    ? "Qdrant"
                    : null
                }
              />
            </Box>
          )}
          <List>
            {filteredFiles.length > 0 && ragType ? (
              filteredFiles.map(({ id, name }) => (
                <Paper
                  key={id}
                  sx={{
                    mb: 1,
                    p: 1,
                    borderRadius: 2,
                    backgroundColor: "#f8f9fa",
                    "&:hover": { backgroundColor: "#e0e0e0" },
                    transition: "0.3s",
                  }}
                >
                  <ListItem
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    disablePadding
                    key={id}
                    secondaryAction={
                      deletFile && deletFile === id ? (
                        <CircularProgress size={20} color="error" />
                      ) : (
                        <IconButton
                          edge="end"
                          onClick={() => handleRAGDocumentDelete(id, name)}
                        >
                          <Delete />
                        </IconButton>
                      )
                    }
                  >
                    <ListItemIcon>
                      <InsertDriveFile />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <EllipsisText>{name.replace("kb/", "")}</EllipsisText>
                      }
                      secondary={ragType === "qdrant" ? id : null}
                    />
                  </ListItem>
                </Paper>
              ))
            ) : (
              <Typography align="center">
                {" "}
                No files found! Please upload file to activate RAG.
              </Typography>
            )}
          </List>
        </>
      )}
    </Box>
  );
};

export default RAGDocumentsTab;
