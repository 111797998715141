import { useState, useEffect } from 'react';
import client from '../client';

/**
 * React hook to fetch supported LLM models from the backend API
 */
const useSupportedModels = () => {
  const [models, setModels] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchModels = async () => {
      try {
        setLoading(true);
        const response = await client.get('/llm-models/supported-models');
        
        if (response.data && response.data.providers) {
          const transformedData = transformData(response.data);
          
          setModels(transformedData);
        }
        
        setError(null);
      } catch (err) {
        console.error('Error fetching supported models:', err);
        setError('Failed to fetch supported models');
        setModels({});
      } finally {
        setLoading(false);
      }
    };

    fetchModels();
  }, []);

  return { models, loading, error };
};

// Transform API response to match expected format
const transformData = (data) => {
  const models = {};
  if (data.providers) {
    data.providers.forEach((provider) => {
      models[provider.provider_name] = {
        keyName: provider.key_name,
        models: provider.models,
        requiredToAddKey: provider.requires_api_key,
        requiresEndpoint: provider.requires_endpoint,
        requiresFile: provider.requires_file,
        // Provide default file config if the provider requires a file
        fileConfig: provider.requires_file ? {
          fileType: 'application/json',
          fileName: 'credentials.json',
          label: 'Upload Credentials File'
        } : {}
      };
    });
  }
  return models;
};

export default useSupportedModels;