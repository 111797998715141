import React from 'react';
import { Link } from 'react-router-dom';
import client from "../../client";

function LogoutButtonComponent({classNames, text, link_to}) {
  const classes = `h-12 w-48 mx-auto text-center table font-bold ${classNames}`
  
  const logout = async (e) => {
    e.preventDefault();
    try {
      await client.post("/tmryk-logout")
      .then((response) => {window.location.href = "/";});
    } catch (error) {
      window.location.href = "/";
    }
  };

  return (
    <Link className={classes} to={link_to} onClick={logout}>
      <div className='table-cell align-middle'>
        <p>{text}</p>
      </div>
    </Link>
  );
}

export default LogoutButtonComponent;