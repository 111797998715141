import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Alert, CircularProgress } from '@mui/material';
import client from '../../../client';
import theme from '../../../theme';

export default function AddRoleModal({ isModalOpen, setIsModalOpen, configs, onRoleAdded }) {
  const formRef = useRef(null);
  const cancelButtonRef = useRef(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const addRole = async () => {
    if (formRef.current) {
      const formData = new FormData(formRef.current);
      const role_name = formData.get("role_name");
      const config_id = formData.get("config_id");

      if (!role_name || !config_id) {
        setError('Role name and AI policy selection are required.');
        return;
      }

      setLoading(true);
      const endpoint = `/create_role?role_name=${role_name}&config_id=${config_id}`;
      try {
        const response = await client.post(endpoint);
        onRoleAdded();
        setIsModalOpen(false);
      } catch (error) {
        console.error("Error creating role:", error);
        if (error.response && error.response.data && error.response.data.detail) {
          setError(error.response.data.detail);
        } else {
          setError("An unexpected error occurred while creating the role");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => setIsModalOpen(false)}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                <div className="flex h-5/6 flex-col overflow-y-scroll bg-white shadow-xl rounded-lg"
                  style={{ marginTop: '80px', marginRight: '15px' }}>
                  <div className="px-4 pt-6 sm:px-6 bg-white rounded-t-lg">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium" style={{ color: theme.tmryk_background_color }}>Add New Role</Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-white hover:bg-[#031A58] focus:outline-none focus:ring-2"
                          onClick={() => setIsModalOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative flex-1 px-4 py-6 sm:px-6">
                    <form ref={formRef} className="space-y-6" onSubmit={(e) => e.preventDefault()}>
                      {error && (
                        <Alert severity="error">
                          {error}
                        </Alert>
                      )}
                      <TextField
                        name="role_name"
                        label="New Role Name"
                        fullWidth
                        required
                        className="w-full bg-white border-2 border-green-700 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
                      />
                      <FormControl fullWidth required>
                        <InputLabel className="text-gray-700">Select AI Policy</InputLabel>
                        <Select
                          name="config_id"
                          label="Select AI Policy Id"
                          className="w-full bg-white rounded text-gray-900 focus:outline-none focus:ring-green-500"
                        >
                          {configs.map((config, index) => (
                            <MenuItem key={index} value={config.id}>{config.id}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <div className="mt-4 flex justify-end space-x-3 pl-4">
                        <Button
                          variant="contained"
                          onClick={addRole}
                          disabled={loading}
                          style={{
                            backgroundColor: theme.tmryk_background_color,
                            color: 'white',
                            width: '150px',
                            height: '48px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textTransform: 'none',
                            fontWeight: 'bold',
                            boxShadow: '0 3px 5px 2px rgba(84, 130, 78, .3)',
                          }}
                        >
                          {loading ? <CircularProgress size={24} color="inherit" /> : 'Create Role'}
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() => setIsModalOpen(false)}
                          ref={cancelButtonRef}
                          style={{
                            borderColor: theme.tmryk_background_color,
                            color: theme.tmryk_background_color,
                            width: '150px',
                            height: '48px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textTransform: 'none',
                            fontWeight: 'bold',
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
