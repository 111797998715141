import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  TextField,
  FormControlLabel,
  Button,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  InputLabel,
  Alert,
  Typography,
  Slider,
  Box,
  Checkbox,
  Tooltip,
  IconButton,
} from "@mui/material";
import {
  Close,
  ArrowBack,
  ArrowForward,
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon
} from "@mui/icons-material";
import { toast } from 'react-toastify';
import client from "../../../client";
import theme from "../../../theme";
import config from "../../../config";
import CreatePolicyScanModal from "./CreatePolicyScanModal";
import RunScanProbesModal from "./RunScanProbesModal";
import RunScanSummaryModal from "./RunScanSummaryModal";
import GenerateTrustScoreModal from "./GenerateTrustScoreModal";
import useSupportedModels from "../../../hooks/useSupportedModels";
import { LLM_TYPES as FALLBACK_LLM_TYPES } from "../../../constants/supportedModels";

import CreateOrUpdateTenantEndpointModal from "./CreateOrUpdateTenantEndpointModal";
import DeleteTenantEndpointModal from "./DeleteTenantEndpointModal";
import FileUploadField from '../../common/FileUploadField';

const AzureFields = ({
  azureEndpoint,
  setAzureEndpoint,
  azureApiVersion,
  setAzureApiVersion,
  azureDeploymentName,
  setAzureDeploymentName,
  apiKey,
  setApiKey,
}) => {
  const handleEndpointChange = (e) => {
    const value = e.target.value;
    setAzureEndpoint(value);
    try {
      if (value.includes('/openai/deployments/')) {
        const url = new URL(value);
        setAzureEndpoint(`${url.protocol}//${url.hostname}`);
        const pathParts = url.pathname.split('/');
        const deploymentIndex = pathParts.indexOf('deployments');
        if (deploymentIndex !== -1 && deploymentIndex + 1 < pathParts.length) {
          setAzureDeploymentName(pathParts[deploymentIndex + 1]);
        }
        const apiVersion = url.searchParams.get('api-version');
        if (apiVersion) {
          setAzureApiVersion(apiVersion);
        }
      }
    } catch (error) {
      console.error('Failed to parse Azure URL:', error);
    }
  };

  return (
    <>
      <TextField
        label="API Key"
        name="apiKey"
        value={apiKey}
        onChange={(e) => setApiKey(e.target.value)}
        fullWidth
        className="w-full bg-white border-2 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
      />
      <TextField
        label="Azure Endpoint"
        name="azureEndpoint"
        value={azureEndpoint}
        onChange={handleEndpointChange}
        fullWidth
        className="w-full bg-white border-2 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
        helperText="You can paste a full Azure OpenAI URL to auto-fill all fields"
      />
      <TextField
        label="API Version"
        name="azureApiVersion"
        value={azureApiVersion}
        onChange={(e) => setAzureApiVersion(e.target.value)}
        fullWidth
        className="w-full bg-white border-2 rounded p-3 text-gray-900"
      />
      <TextField
        label="Deployment Name"
        name="azureDeploymentName"
        value={azureDeploymentName}
        onChange={(e) => setAzureDeploymentName(e.target.value)}
        fullWidth
        className="w-full bg-white border-2 rounded p-3 text-gray-900"
      />
    </>
  );
};

export default function RunScanModal({
  isModalOpen,
  setIsModalOpen,
  setScans,
  tenantId,
  username,
  accessToken,
  publicTenantId,
  isActive,
  onRequestAccess
}) {
  const formRef = useRef(null);
  const cancelButtonRef = useRef(null);

  // Get models from the hook
  const { models: dynamicModels, loading: modelsLoading, error: modelsError } = useSupportedModels();
  
  // Use dynamic models if available, otherwise fall back to the static ones
  const modelTypes = Object.keys(dynamicModels).length > 0 ? dynamicModels : FALLBACK_LLM_TYPES;

  const [llmType, setLlmType] = useState("ai_policy");
  const [aiPolicyType, setAiPolicyType] = useState("");
  const [configs, setConfigs] = useState([]);
  const [llmEnvName, setLlmEnvName] = useState("");
  const [credentialsFile, setCredentialsFile] = useState(null);
  const [llmData, setLlmData] = useState({
    llmName: "",
    llmModel: "",
    apiKey: "",
    generations: "",
    aiPolicyType: "",
    // For private endpoint
    url: "",
    header_template: "",
    payload_template: ""
  });
  
  // Replace the rate limit state with:
  const [rateLimit, setRateLimit] = useState(10);
  const [isRateLimitEnabled, setIsRateLimitEnabled] = useState(false);
  
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");
  const [activeSection, setActiveSection] = useState(null);
  const [isCustom, setIsCustom] = useState(true);

  const [isProbesModalOpen, setIsProbesModalOpen] = useState(false);
  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState(false);
  const [isGenerateTrustScoreModalOpen, setIsGenerateTrustScoreModalOpen] = useState(false);
  const [probes, setProbes] = useState([]);
  const [probeType, setProbeType] = useState("owasp");
  const [isCreatePolicyModalOpen, setIsCreatePolicyModalOpen] = useState(false);

  const [successMessage, setSuccessMessage] = useState("");

  // Azure
  const [azureEndpoint, setAzureEndpoint] = useState("");
  const [azureApiVersion, setAzureApiVersion] = useState("");
  const [azureDeploymentName, setAzureDeploymentName] = useState("");

  // Private endpoints
  const [privateEndpoints, setPrivateEndpoints] = useState([]);
  const [selectedEndpointId, setSelectedEndpointId] = useState(null);

  // For create/update
  const [isCreateOrUpdateModalOpen, setIsCreateOrUpdateModalOpen] = useState(false);
  const [editEndpointId, setEditEndpointId] = useState(null);

  // For delete
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteEndpointId, setDeleteEndpointId] = useState(null);
  const [deleteEndpointName, setDeleteEndpointName] = useState("");

  const [isParentModalActive, setIsParentModalActive] = useState(true); 

  // Add a new state to track the currently expanded section
  const [expandedSection, setExpandedSection] = useState(null);

  // Load configs
  const fetchConfigs = async () => {
    try {
      const response = await client.get("/v1/rails/configs");
      setConfigs(response.data);
    } catch (error) {
      console.error("Error fetching configs:", error);
    }
  };

  useEffect(() => {
    fetchConfigs();
  }, []);

  // If user picks private => fetch endpoints
  useEffect(() => {
    if (llmType === "private") {
      fetchTenantEndpoints();
    }
  }, [llmType]);

  const fetchTenantEndpoints = async () => {
    try {
      const resp = await client.get(`/api/tenant_pvt_endpoints?tenant_id=${tenantId}`);
      setPrivateEndpoints(resp.data || []);
    } catch (err) {
      console.error("Error fetching tenant endpoints:", err);
    }
  };

  // Clear error when llmType changes
  useEffect(() => {
    setError("");
  }, [llmType]);

  const handleInputChange = (event) => {
    setLlmData({ ...llmData, [event.target.name]: event.target.value });
  };

  const handleTypeChange = (event) => {
    setLlmType(event.target.value);
    setError("");
  };

  const handleAiPolicyChange = (event) => {
    setAiPolicyType(event.target.value);
    setLlmData({ ...llmData, aiPolicyType: event.target.value });
    setError("");
  };

  const handleLlmModelChange = (e) => {
    const newModel = e.target.value;
    setLlmData({
      ...llmData,
      llmModel: newModel,
      llmName: "",
      apiKey: "",
    });
    setLlmEnvName(modelTypes[newModel]?.keyName?.toUpperCase() || "");
    setError("");
    
    // Reset file upload if the new model doesn't require it
    const modelConfig = modelTypes[newModel];
    if (!modelConfig?.requiresFile) {
      setCredentialsFile(null);
    }
    
    // Reset Azure-specific fields if not Azure
    if (newModel !== 'azure') {
      setAzureEndpoint("");
      setAzureApiVersion("");
      setAzureDeploymentName("");
    }
  };

  const handleSliderChange = (event, newValue) => {
    if (newValue <= 10) {
      setLlmData({ ...llmData, generations: newValue });
      setError("");
    }
  };

  // New handler for generations input change
  const handleGenerationsInputChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value)) {
      setLlmData({ ...llmData, generations: value });
      setError("");
    }
  };

  // Handle rate limit input change
  const handleRateLimitInputChange = (event) => {
    const newValue = event.target.value;
    
    // Allow empty string and valid numeric input (integers and decimals)
    if (newValue === '' || /^[0-9]*\.?[0-9]*$/.test(newValue)) {
      setRateLimit(newValue);
    }
  };

  const handleRateLimitBlur = () => {
    // Validate and enforce minimum on blur
    const value = parseFloat(rateLimit);
    if (isNaN(value) || value < 0.1) {
      setRateLimit(0.1);
    } else {
      // Ensure proper numeric format
      setRateLimit(value);
    }
  };

  // Handle rate limit toggle
  const handleRateLimitToggle = (event) => {
    const isChecked = event.target.checked;
    setIsRateLimitEnabled(isChecked);
    
    // If checked, set this as the expanded section, otherwise collapse
    if (isChecked) {
      setExpandedSection('rateLimit');
      // If generations customization is enabled, disable it
      if (isCustom) {
        setIsCustom(false);
        setLlmData({ ...llmData, generations: 10 });
      }
    } else {
      setExpandedSection(null);
    }
  };

  // Validate
  const validateFields = () => {
    const errorMessages = [];

    // Ensure model is selected only for public models
    if (llmType === 'public' && !llmData.llmModel) {
      errorMessages.push("Model is required");
    }

    // AI Policy type must be selected
    if (llmType === 'ai_policy' && !aiPolicyType) {
      errorMessages.push("AI Policy Type is required");
    }

    // LLM Name is required for public/private
    if ((llmType === 'public' || llmType === 'private') && !llmData.llmName) {
      errorMessages.push("LLM Name is required");
    }

    const modelConfig = modelTypes[llmData.llmModel] || {};

    // API key check
    if (modelConfig.requiredToAddKey && !llmData.apiKey) {
      errorMessages.push("API Key is required");
    }

    // Credentials file check
    if (modelConfig.requiresFile && !credentialsFile) {
      errorMessages.push("Credentials file is required");
    }

    // Azure-specific checks
    if (llmData.llmModel === 'azure') {
      if (!azureEndpoint) errorMessages.push("Azure Endpoint is required");
      if (!azureApiVersion) errorMessages.push("Azure API Version is required");
      if (!azureDeploymentName) errorMessages.push("Azure Deployment Name is required");
    }

    // Private endpoint checks
    if (llmType === 'private') {
      if (!llmData.url) errorMessages.push("Endpoint URL is required");

      try {
        JSON.parse(llmData.header_template);
      } catch {
        errorMessages.push("Header Template must be valid JSON");
      }

      try {
        JSON.parse(llmData.payload_template);
      } catch {
        errorMessages.push("Payload Template must be valid JSON");
      }
    }

    // Final check: display errors if any
    if (errorMessages.length > 0) {
      setError("Please fix the following: " + errorMessages.join(', '));
      return false;
    }

    setError(""); // Clear previous errors
    return true;
  };

  const handleNext = () => {
    if (!validateFields()) {
      return;  // validateFields already sets the error message
    }
    setError("");
    setIsModalOpen(false);
    setIsProbesModalOpen(true);
  };

  const handlePolicyCreationSuccess = async (newPolicyName) => {
    await fetchConfigs();
    setAiPolicyType(newPolicyName);
    setSuccessMessage(`Policy '${newPolicyName}' created successfully!`);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setError("");
  };

  const handleGenerateTrustScore = () => {
    const isValid = validateFields();
    if (!isValid) {
      // validateFields already sets the error message
      return;
    }
    setError('');
    setIsModalOpen(false);
    setIsGenerateTrustScoreModalOpen(true);
  };

  // Private Endpoint handlers
  const handleAddEndpoint = () => {
    setEditEndpointId(null);
    setIsParentModalActive(false);
    setIsCreateOrUpdateModalOpen(true);
  };

  const handleUpdateEndpoint = () => { 
    if (!selectedEndpointId) {
      toast.warn("No endpoint selected");
      return;
    }
    const selectedEndpoint = privateEndpoints.find((ep) => ep.id === selectedEndpointId);
    if (!selectedEndpoint) {
      toast.error("Endpoint not found");
      return;
    }
    setEditEndpointId(selectedEndpointId); // Pass ID to the modal
    setIsParentModalActive(false);
    setIsCreateOrUpdateModalOpen(true);
  };

  const handleDeleteEndpoint = () => { 
    if (!selectedEndpointId) {
      toast.warn("No endpoint selected");
      return;
    }
    const selectedEndpoint = privateEndpoints.find((ep) => ep.id === selectedEndpointId);
    if (!selectedEndpoint) {
      toast.error("Endpoint not found");
      return;
    }
    setDeleteEndpointId(selectedEndpointId); // Pass ID to the modal
    setDeleteEndpointName(selectedEndpoint.endpoint_name); // Pass name to the modal
    setIsParentModalActive(false);
    setIsDeleteModalOpen(true);
  };

  const handleSaveSuccess = async (newSelectedId) => {
    await refreshSelectedEndpoint(newSelectedId); 
    setIsParentModalActive(true);
    setIsCreateOrUpdateModalOpen(false);
  };

  const handleCloseChildModal = (shouldRefresh = false) => { 
    setIsParentModalActive(true);
    setIsCreateOrUpdateModalOpen(false);
    setIsDeleteModalOpen(false);
  
    if (shouldRefresh) {
      fetchTenantEndpoints(); // Refresh endpoint list
    }
  };

  const handleDeleteSuccess = async () => {
    await refreshSelectedEndpoint();
    setEditEndpointId(null);
    setIsParentModalActive(true);
    setIsDeleteModalOpen(false);
  };

  const refreshSelectedEndpoint = async (newSelectedId = null) => {
    try {
      const resp = await client.get(`/api/tenant_pvt_endpoints?tenant_id=${tenantId}`);
      setPrivateEndpoints(resp.data || []);
      if (newSelectedId) {
        const selected = resp.data.find((ep) => ep.id === newSelectedId);
        if (selected) {
          setSelectedEndpointId(newSelectedId);
          setLlmData((prev) => ({
            ...prev,
            llmName: selected.endpoint_name,
            url: selected.endpoint_url,
            header_template: selected.header_template,
            payload_template: selected.payload_template,
          }));
        }
      } else {
        // If no specific endpoint to select, clear the selection
        setSelectedEndpointId(null);
        setLlmData((prev) => ({
          ...prev,
          url: "",
          header_template: "",
          payload_template: "",
        }));
      }
    } catch (err) {
      console.error("Error refreshing private endpoints:", err);
    }
  };

  // On selecting new endpoint from the dropdown, we fill fields
  // If none selected, we show the "Please select or create new" message.

  return (
    <>
      <Transition.Root show={isModalOpen && isParentModalActive} as={Fragment}>
       <Dialog as="div" className="relative static z-40" onClose={handleCloseModal}>
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden flex justify-center items-center ml-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-y-[-100%]"
                enterTo="translate-y-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-y-0"
                leaveTo="translate-y-[-100%]"
              >
                <Dialog.Panel
                  className={`pointer-events-auto w-screen max-w-7xl ${
                    // Dynamically adjust height based on content state
                    (isRateLimitEnabled || isCustom) ? "max-h-[95vh]" : 
                    llmType === "ai_policy" ? "max-h-[85vh]" : 
                    llmType === "private" ? "max-h-[94vh]" : 
                    "max-h-[90vh]"
                  } flex flex-col bg-white shadow-xl rounded-lg overflow-y-auto`}
                >
                  <div className="px-4 pt-6 sm:px-6 bg-white rounded-t-lg">
                    <div className="flex items-start justify-between">
                      <Dialog.Title
                        className="text-xl font-semibold"
                        style={{ color: theme.tmryk_background_color }}
                      >
                        Run AI Scoring
                      </Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-white hover:bg-[#031A58] focus:outline-none"
                          onClick={handleCloseModal}
                        >
                          <span className="sr-only">Close panel</span>
                          <Close className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="relative flex-1 px-4 py-6 sm:px-6 overflow-y-auto">
                    {isCreatePolicyModalOpen ? (
                      <CreatePolicyScanModal
                        setIsCreatePolicyModalOpen={setIsCreatePolicyModalOpen}
                        setAiPolicyType={setAiPolicyType}
                        onSuccess={handlePolicyCreationSuccess}
                      />
                    ) : (
                      <form ref={formRef} className="space-y-6">
                        {successMessage && (
                          <Alert severity="success" className="mt-4">
                            {successMessage}
                          </Alert>
                        )}

                        {/* LLM Type */}
                        <FormControl component="fieldset">
                          <Typography variant="body2" style={{ color: "Black", marginBottom: "8px" }}>
                            Select AI Endpoint
                          </Typography>
                          <RadioGroup
                            aria-label="llmType"
                            name="llmType"
                            value={llmType}
                            onChange={handleTypeChange}
                            row
                          >
                            <FormControlLabel
                              value="ai_policy"
                              control={
                                <Radio
                                  sx={{ "&.Mui-checked": { color: theme.tmryk_background_color } }}
                                />
                              }
                              label="Policies"
                            />
                            <FormControlLabel
                              value="public"
                              control={
                                <Radio
                                  sx={{ "&.Mui-checked": { color: theme.tmryk_background_color } }}
                                />
                              }
                              label="Models"
                            />
                            <FormControlLabel
                              value="private"
                              control={
                                <Radio
                                  sx={{ "&.Mui-checked": { color: theme.tmryk_background_color } }}
                                />
                              }
                              label="Private Endpoints"
                            />
                          </RadioGroup>
                        </FormControl>

                        {/* Content */}
                        <div className="space-y-4">
                          {/* AI Policy */}
                          {llmType === "ai_policy" && (
                            <div className="flex items-center space-x-4">
                              <FormControl fullWidth>
                                <InputLabel className="text-gray-700">
                                  Select AI Policy
                                </InputLabel>
                                <Select
                                  name="aiPolicyType"
                                  label="Select AI Policy"
                                  value={aiPolicyType}
                                  onChange={handleAiPolicyChange}
                                  className="w-full bg-white rounded text-gray-900 focus:outline-none focus:ring-green-500"
                                >
                                  {configs.map((config, index) => (
                                    <MenuItem key={index} value={config.id}>
                                      {config.id}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setIsCreatePolicyModalOpen(true)}
                                style={{
                                  backgroundColor: theme.tmryk_background_color,
                                  color: "white",
                                  width: "180px",
                                  height: "48px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  textTransform: "none",
                                  fontWeight: "bold",
                                  boxShadow: "0 3px 5px 2px rgba(84, 130, 78, .3)",
                                }}
                              >
                                Create AI Policy
                              </Button>
                            </div>
                          )}

                          {/* Public */}
                          {llmType === "public" && (
                            <div className="space-y-4">
                              <FormControl fullWidth>
                                <InputLabel className="text-gray-700">
                                  Select Model
                                </InputLabel>
                                <Select
                                  name="llmModel"
                                  label="Select Model"
                                  value={llmData.llmModel}
                                  onChange={handleLlmModelChange}
                                  className="w-full bg-white rounded text-gray-900 focus:outline-none focus:ring-green-500"
                                >
                                  {Object.keys(modelTypes).map((key) => (
                                    <MenuItem key={key} value={key}>
                                      {key}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>

                              {llmData.llmModel && (
                                <FormControl fullWidth>
                                  <InputLabel className="text-gray-700">
                                    Select LLM
                                  </InputLabel>
                                  <Select
                                    name="llmName"
                                    label="Select LLM"
                                    value={llmData.llmName}
                                    onChange={handleInputChange}
                                    className="w-full bg-white rounded text-gray-900 focus:outline-none focus:ring-green-500"
                                  >
                                    {modelTypes[llmData.llmModel].models.map((model, index) => (
                                      <MenuItem key={index} value={model}>
                                        {model}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              )}

                              {llmData.llmModel === 'azure' ? (
                                <AzureFields
                                  azureEndpoint={azureEndpoint}
                                  setAzureEndpoint={setAzureEndpoint}
                                  azureApiVersion={azureApiVersion}
                                  setAzureApiVersion={setAzureApiVersion}
                                  azureDeploymentName={azureDeploymentName}
                                  setAzureDeploymentName={setAzureDeploymentName}
                                  apiKey={llmData.apiKey}
                                  setApiKey={(val) => setLlmData({ ...llmData, apiKey: val })}
                                />
                              ) : (
                                llmData.llmModel === 'vertexai' ? (
                                  <FileUploadField
                                    modelConfig={modelTypes[llmData.llmModel]}
                                    file={credentialsFile}
                                    setFile={setCredentialsFile}
                                  />
                                ) : (
                                  llmData.llmModel &&
                                  modelTypes[llmData.llmModel].requiredToAddKey && (
                                    <TextField
                                      label="API Key"
                                      name="apiKey"
                                      value={llmData.apiKey}
                                      onChange={handleInputChange}
                                      fullWidth
                                      className="w-full bg-white border-2 border-green-700 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
                                    />
                                  )
                                )
                              )}
                            </div>
                          )}

                          {/* Private */}
                          {llmType === "private" && (
                            <div className="space-y-3">
                              <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                                Select an existing endpoint or create a new one:
                              </Typography>
                              
                              <div className="flex items-center space-x-1">
                                <FormControl sx={{ minWidth: 250 }} size="small">
                                  <InputLabel size="small" className="text-gray-700">
                                    Endpoint
                                  </InputLabel>
                                  <Select
                                    value={selectedEndpointId || ""}
                                    label="Endpoint"
                                    size="small"
                                    onChange={(e) => {
                                      const epId = e.target.value;
                                      setSelectedEndpointId(epId);
                                      const chosen = privateEndpoints.find(ep => ep.id === epId);
                                      if (chosen) {
                                        setLlmData(prev => ({
                                          ...prev,
                                          llmName: chosen.endpoint_name,
                                          url: chosen.endpoint_url,
                                          header_template: chosen.header_template,
                                          payload_template: chosen.payload_template
                                        }));
                                      }
                                    }}
                                    className="bg-white rounded text-gray-900 focus:outline-none focus:ring-green-500"
                                  >
                                    {privateEndpoints.length > 0 ? (
                                      privateEndpoints.map((ep) => (
                                        <MenuItem key={ep.id} value={ep.id}>
                                          {ep.endpoint_name}
                                        </MenuItem>
                                      ))
                                    ) : (
                                      <MenuItem disabled>No endpoints found</MenuItem>
                                    )}
                                  </Select>
                                </FormControl>

                                <IconButton
                                  color="primary"
                                  onClick={handleAddEndpoint}
                                  size="small"
                                  sx={{
                                       backgroundColor: theme.tmryk_background_color,
                                       color: "#FFFFFF",
                                       width: 32,
                                       height: 32,
                                       "&:hover": {
                                         backgroundColor: "#073F8E",
                                       },
                                    }}
                                >
                                  <AddIcon fontSize="small" />
                                </IconButton>

                                <IconButton
                                  color="primary"
                                  onClick={handleUpdateEndpoint}
                                  disabled={!selectedEndpointId}
                                  size="small"
                                  sx={{
                                       backgroundColor: !selectedEndpointId ? "#cccccc" : "#FFAC33",
                                       color: "white",
                                       width: 32,
                                       height: 32,
                                       "&:hover": {
                                         backgroundColor: !selectedEndpointId ? "#cccccc" : "#F29100",
                                       },
                                    }}
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>

                                <IconButton
                                  color="error"
                                  onClick={handleDeleteEndpoint}
                                  disabled={!selectedEndpointId}
                                  size="small"
                                  sx={{
                                       backgroundColor: !selectedEndpointId ? "#cccccc" : "#DD4B39",
                                       color: "white",
                                       width: 32,
                                       height: 32,
                                       "&:hover": {
                                         backgroundColor: !selectedEndpointId ? "#cccccc" : "#C23321",
                                       },
                                    }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </div>

                              {/* Endpoint fields in a more compact container */}
                              <div className="bg-gray-50 p-2 rounded border border-gray-200 space-y-2">
                                {/* Endpoint URL */}
                                <TextField
                                  label="Endpoint URL"
                                  name="url"
                                  value={llmData.url}
                                  fullWidth
                                  disabled
                                  size="small"
                                  className="bg-white border border-gray-300 rounded"
                                />

                                {/* Endpoint Header */}
                                <TextField
                                  label="Endpoint Header"
                                  name="header_template"
                                  value={llmData.header_template}
                                  fullWidth
                                  multiline
                                  rows={1}
                                  disabled
                                  size="small"
                                  className="bg-white border border-gray-300 rounded"
                                />
                                
                                {/* Endpoint Data */}
                                <TextField
                                  label="Endpoint Data"
                                  name="payload_template"
                                  value={llmData.payload_template}
                                  fullWidth
                                  multiline
                                  rows={1}
                                  disabled
                                  size="small"
                                  className="bg-white border border-gray-300 rounded"
                                />
                              </div>
                            </div>
                          )}

                          {/* Generations section - Compact layout */}
                          <div className="mt-4 bg-gray-50 p-3 rounded border border-gray-200">
                            <div className="flex items-center mb-2">
                              <Typography variant="body1" sx={{ fontWeight: 'medium', color: theme.tmryk_background_color }}>
                                Generations
                              </Typography>
                              <Tooltip title="Higher values provide a more reliable score as more samples are collected. This only applies to individual scans, not the Generate Trust Score function.">
                                <div className="ml-2 text-gray-500 cursor-help">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-4 w-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                  </svg>
                                </div>
                              </Tooltip>
                            </div>
                            
                            <div className="mb-2 bg-white rounded border border-gray-200 p-2">
                              <div className="flex items-center">
                                <TextField
                                  label="Generations per prompt"
                                  type="number"
                                  value={llmData.generations || 10}
                                  onChange={handleGenerationsInputChange}
                                  inputProps={{ step: 1 }}
                                  variant="outlined"
                                  size="small"
                                  sx={{ 
                                    width: 180,
                                    '& .MuiOutlinedInput-root': {
                                      '&.Mui-focused fieldset': {
                                        borderColor: theme.tmryk_background_color,
                                      },
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                      color: theme.tmryk_background_color,
                                    }
                                  }}
                                />
                              </div>
                              <Typography variant="caption" color="textSecondary" sx={{ mt: 0.5, display: 'block' }}>
                                Higher values provide a more reliable score but will take more time to process
                              </Typography>
                            </div>
                          </div>

                          {/* Rate Limit input - Compact style */}
                          <div className="mt-3 bg-gray-50 p-3 rounded border border-gray-200">
                            <div className="flex items-center mb-2">
                              <Typography variant="body1" sx={{ fontWeight: 'medium', color: theme.tmryk_background_color }}>
                                API Rate Limiting
                              </Typography>
                              <Tooltip title="Controls how many API calls are made per minute">
                                <div className="ml-2 text-gray-500 cursor-help">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-4 w-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                  </svg>
                                </div>
                              </Tooltip>
                            </div>
                            
                            {/* Default option - more compact */}
                            <div className="p-1 bg-white rounded border border-gray-200 mb-2 flex items-center">
                              <div className={`w-3 h-3 rounded-full ml-1 mr-2 ${!isRateLimitEnabled ? 'bg-green-500' : 'bg-gray-300'}`}></div>
                              <Typography variant="body2">
                                Default: No rate limiting (maximum speed)
                              </Typography>
                            </div>
                            
                            {/* Customize option - more compact */}
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isRateLimitEnabled}
                                  onChange={handleRateLimitToggle}
                                  style={{ color: theme.tmryk_background_color }}
                                  size="small"
                                />
                              }
                              label={<Typography variant="body2">Enable rate limiting</Typography>}
                              sx={{ mb: 1 }}
                            />
                            
                            {isRateLimitEnabled && (
                              <div className="pl-4 pr-2 py-1 bg-white rounded border border-gray-200">
                                <div className="flex items-center">
                                  <TextField
                                    label="Requests per minute"
                                    type="text"
                                    value={rateLimit}
                                    onChange={handleRateLimitInputChange}
                                    onBlur={handleRateLimitBlur}
                                    variant="outlined"
                                    size="small"
                                    sx={{ 
                                      width: 180,
                                      '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                          borderColor: theme.tmryk_background_color,
                                        },
                                      },
                                      '& .MuiInputLabel-root.Mui-focused': {
                                        color: theme.tmryk_background_color,
                                      }
                                    }}
                                  />
                                </div>
                                <Typography variant="caption" color="textSecondary" sx={{ mt: 0.5, display: 'block' }}>
                                  Lower values reduce risk of being rate limited by the API for the LLM
                                </Typography>
                              </div>
                            )}
                          </div>
                        </div>

                        {error && (
                          <Alert severity="error" className="mt-4">
                            {error}
                          </Alert>
                        )}

                        <div className="flex justify-between items-center p-6 bg-white rounded-b-lg">
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: theme.tmryk_background_color,
                              color: 'white',
                              width: '200px',
                              height: '48px',
                              textTransform: 'none',
                              fontWeight: 'bold',
                            }}
                            onClick={handleGenerateTrustScore}
                          >
                            Generate Trust Score
                          </Button>
                          <div className="flex space-x-3">
                            <Button
                              variant="outlined"
                              onClick={handleCloseModal}
                              ref={cancelButtonRef}
                              startIcon={<ArrowBack />}
                              style={{
                                borderColor: theme.tmryk_background_color,
                                color: theme.tmryk_background_color,
                                width: "150px",
                                height: "48px",
                                textTransform: "none",
                                fontWeight: "bold",
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: theme.tmryk_background_color,
                                color: "white",
                                width: "150px",
                                height: "48px",
                                textTransform: "none",
                                fontWeight: "bold",
                              }}
                              endIcon={<ArrowForward />}
                              onClick={handleNext}
                            >
                              Next
                            </Button>
                          </div>
                        </div>
                      </form>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* PROBES */}
      <RunScanProbesModal
        isProbesModalOpen={isProbesModalOpen}
        setIsProbesModalOpen={setIsProbesModalOpen}
        setProbes={setProbes}
        setProbeType={setProbeType}
        setIsSummaryModalOpen={setIsSummaryModalOpen}
        setIsModalOpen={setIsModalOpen}
        aiPolicyType={aiPolicyType}
        llmType={llmType}
        llmData={llmData}
        credentialsFile={credentialsFile}
        azureEndpoint={azureEndpoint}
        azureApiVersion={azureApiVersion}
        azureDeploymentName={azureDeploymentName}
      />
      
      {/* SUMMARY */}
      <RunScanSummaryModal
        isSummaryModalOpen={isSummaryModalOpen}
        setIsSummaryModalOpen={setIsSummaryModalOpen}
        llmData={llmData}
        llmType={llmType}
        probes={probes}
        probeType={probeType}
        tenantId={tenantId}
        username={username}
        accessToken={accessToken}
        setScans={setScans}
        setIsProbesModalOpen={setIsProbesModalOpen}
        setIsModalOpen={setIsModalOpen}
        llmEnvName={llmEnvName}
        publicTenantId={publicTenantId}
        credentialsFile={credentialsFile}
        azureEndpoint={azureEndpoint}
        azureApiVersion={azureApiVersion}
        azureDeploymentName={azureDeploymentName}
        isActive={isActive}
        onRequestAccess={onRequestAccess}
        rateLimit={rateLimit}
        isRateLimitEnabled={isRateLimitEnabled}
      />

      {/* TRUST SCORE */}
      <GenerateTrustScoreModal
        isOpen={isGenerateTrustScoreModalOpen}
        setIsOpen={setIsGenerateTrustScoreModalOpen}
        setIsModalOpen={setIsModalOpen}
        llmType={llmType}
        llmData={llmData}
        tenantId={tenantId}
        username={username}
        accessToken={accessToken}
        setScans={setScans}
        llmEnvName={llmEnvName}
        publicTenantId={publicTenantId}
        credentialsFile={credentialsFile}
        azureEndpoint={azureEndpoint}
        azureApiVersion={azureApiVersion}
        azureDeploymentName={azureDeploymentName}
        isActive={isActive}
        onRequestAccess={onRequestAccess}
        rateLimit={rateLimit}
        isRateLimitEnabled={isRateLimitEnabled}
      />

      {/* CREATE/UPDATE Endpoint Modal */}
      {llmType === "private" && (
        <CreateOrUpdateTenantEndpointModal
            isOpen={isCreateOrUpdateModalOpen}
            setIsOpen={() => handleCloseChildModal(false)}
            tenantId={tenantId}
            existingEndpointId={editEndpointId}
            defaultData={
              editEndpointId
                ? privateEndpoints.find((x) => x.id === editEndpointId) || {}
                : {}
            }
            onSaveSuccess={handleSaveSuccess}
          />
        )}    

      {/* DELETE Endpoint Modal */}
      {llmType === "private" && (
        <DeleteTenantEndpointModal
          isOpen={isDeleteModalOpen}
          setIsOpen={() => handleCloseChildModal(false)}
          endpointId={deleteEndpointId}
          endpointName={deleteEndpointName}
          onDeleteSuccess={handleDeleteSuccess}
        />
      )}
    </>
  );
}