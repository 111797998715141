import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Tabs,
  Tab,
  Checkbox,
  FormControlLabel,
  Divider,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";

// Define sensitive data entities
const GLOBAL_ENTITIES = [
  { id: "CREDIT_CARD", label: "Credit Card Numbers" },
  { id: "CRYPTO", label: "Cryptocurrency Addresses" },
  { id: "DATE_TIME", label: "Date and Time Information" },
  { id: "EMAIL_ADDRESS", label: "Email Addresses" },
  { id: "IBAN_CODE", label: "IBAN Codes" },
  { id: "IP_ADDRESS", label: "IP Addresses" },
  { id: "NRP", label: "National Registration/ID Numbers" },
  { id: "LOCATION", label: "Location Information" },
  { id: "PERSON", label: "Person Names" },
  { id: "PHONE_NUMBER", label: "Phone Numbers" },
  { id: "MEDICAL_LICENSE", label: "Medical License Numbers" },
  { id: "URL", label: "URLs" },
];

const US_ENTITIES = [
  { id: "US_BANK_NUMBER", label: "US Bank Account Numbers" },
  { id: "US_DRIVER_LICENSE", label: "US Driver's License Numbers" },
  { id: "US_ITIN", label: "US Individual Taxpayer Identification Numbers" },
  { id: "US_PASSPORT", label: "US Passport Numbers" },
  { id: "US_SSN", label: "US Social Security Numbers" },
];

const SensitiveDataConfigModal = ({
  isOpen,
  onClose,
  configId,
  configType,
  currentEntities = [],
  onSave,
}) => {
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [loading, setLoading] = useState(false);

  // Get the appropriate entities list based on the config type
  const getEntitiesList = () => {
    // Include all entities for all configuration types
    const globalEntitiesList = [...GLOBAL_ENTITIES];

    return { globalEntitiesList, usEntitiesList: US_ENTITIES };
  };

  const { globalEntitiesList, usEntitiesList } = getEntitiesList();

  useEffect(() => {
    // Initialize selected entities from currentEntities or default to all
    if (currentEntities && currentEntities.length > 0) {
      setSelectedEntities(currentEntities);
    } else {
      // Default to all entities
      const allEntities = [
        ...globalEntitiesList.map((entity) => entity.id),
        ...usEntitiesList.map((entity) => entity.id),
      ];
      setSelectedEntities(allEntities);
    }
  }, [currentEntities, configType]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleToggleEntity = (entityId) => {
    setSelectedEntities((prev) => {
      if (prev.includes(entityId)) {
        return prev.filter((id) => id !== entityId);
      } else {
        return [...prev, entityId];
      }
    });
  };

  const handleToggleAll = (entities) => {
    const entityIds = entities.map((entity) => entity.id);
    const allSelected = entityIds.every((id) => selectedEntities.includes(id));

    if (allSelected) {
      // Deselect all in this section
      setSelectedEntities((prev) =>
        prev.filter((id) => !entityIds.includes(id))
      );
    } else {
      // Select all in this section
      const newSelected = [...selectedEntities];
      entityIds.forEach((id) => {
        if (!newSelected.includes(id)) {
          newSelected.push(id);
        }
      });
      setSelectedEntities(newSelected);
    }
  };

  const handleSave = async () => {
    if (selectedEntities.length === 0) {
      toast.error("Please select at least one entity to detect");
      return;
    }

    setLoading(true);
    try {
      await onSave(selectedEntities);
      toast.success(`${configType} sensitive data detection settings updated`);
    } catch (error) {
      console.error("Error saving sensitive data config:", error);
      toast.error("Failed to update sensitive data detection settings");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "8px",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <DialogTitle sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
        <Typography variant="h6" component="div">
          Configure {configType} Sensitive Data Detection
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          Choose the types of sensitive data to be detected and masked.
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="sensitive data categories"
            sx={{ px: 2 }}
          >
            <Tab label="Global Entities" />
            <Tab label="US-Specific Entities" />
          </Tabs>
        </Box>

        <Box sx={{ p: 3 }}>
          {selectedTab === 0 && (
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={globalEntitiesList.every((entity) =>
                      selectedEntities.includes(entity.id)
                    )}
                    indeterminate={
                      globalEntitiesList.some((entity) =>
                        selectedEntities.includes(entity.id)
                      ) &&
                      !globalEntitiesList.every((entity) =>
                        selectedEntities.includes(entity.id)
                      )
                    }
                    onChange={() => handleToggleAll(globalEntitiesList)}
                  />
                }
                label={
                  <Typography variant="subtitle1">
                    Select All Global Entities
                  </Typography>
                }
              />
              <Divider sx={{ my: 1 }} />
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: 1,
                  mt: 2,
                }}
              >
                {globalEntitiesList.map((entity) => (
                  <FormControlLabel
                    key={entity.id}
                    control={
                      <Checkbox
                        checked={selectedEntities.includes(entity.id)}
                        onChange={() => handleToggleEntity(entity.id)}
                      />
                    }
                    label={entity.label}
                  />
                ))}
              </Box>
            </Box>
          )}

          {selectedTab === 1 && (
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={usEntitiesList.every((entity) =>
                      selectedEntities.includes(entity.id)
                    )}
                    indeterminate={
                      usEntitiesList.some((entity) =>
                        selectedEntities.includes(entity.id)
                      ) &&
                      !usEntitiesList.every((entity) =>
                        selectedEntities.includes(entity.id)
                      )
                    }
                    onChange={() => handleToggleAll(usEntitiesList)}
                  />
                }
                label={
                  <Typography variant="subtitle1">
                    Select All US-Specific Entities
                  </Typography>
                }
              />
              <Divider sx={{ my: 1 }} />
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: 1,
                  mt: 2,
                }}
              >
                {usEntitiesList.map((entity) => (
                  <FormControlLabel
                    key={entity.id}
                    control={
                      <Checkbox
                        checked={selectedEntities.includes(entity.id)}
                        onChange={() => handleToggleEntity(entity.id)}
                      />
                    }
                    label={entity.label}
                  />
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>

      <DialogActions
        sx={{ p: 2, borderTop: `1px solid ${theme.palette.divider}` }}
      >
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          disabled={loading || selectedEntities.length === 0}
          startIcon={loading && <CircularProgress size={20} color="inherit" />}
        >
          Save Configuration
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SensitiveDataConfigModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  configId: PropTypes.string.isRequired,
  configType: PropTypes.string.isRequired,
  currentEntities: PropTypes.array,
  onSave: PropTypes.func.isRequired,
};

export default SensitiveDataConfigModal;
