import React from "react";

export const LlmRunScanIcon = ({}) => {
  return (<div style={{marginRight: '8px', paddingBottom: '2px'}}>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="white" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_89_826)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 9.01625C1.8096 6.49565 4.815 4.2335 9.0162 4.3304C13.185 4.23343 16.1904 6.4955 18 9.01625C16.1904 11.5369 13.185 13.799 9.0162 13.6697C4.81515 13.799 1.80975 11.5369 0 9.01625ZM9.0162 5.0414C11.1813 5.0414 12.9588 6.81875 12.9588 9.01625C12.9588 11.2138 11.1814 12.9911 9.0162 12.9911C6.8187 12.9911 5.04135 11.2138 5.04135 9.01625C5.04135 6.81875 6.8187 5.0414 9.0162 5.0414Z" fill="#FFFFFF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.5691 9.01626C11.5691 10.4262 10.4262 11.5693 9.01613 11.5693C7.60614 11.5693 6.46313 10.4263 6.46313 9.01626C6.46313 7.60626 7.60612 6.46326 9.01613 6.46326C10.4261 6.46326 11.5691 7.60624 11.5691 9.01626Z" fill="#FFFFFF"/>
</g>
<defs>
<clipPath id="clip0_89_826">
<rect width="18" height="18" fill="white"/>
</clipPath>
</defs>
</svg>
</div>
  );
};
