import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CircularProgress, Button, Typography, Paper, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Alert } from '@mui/material';
import client from '../../client';
import theme from '../../theme';

function UserInfoUpdateModal({ isModalOpen, setIsModalOpen, user, updatedUserInfo, fetchUserInfo, setSnackbarMessage, setSnackbarSeverity, setSnackbarOpen }) {
  const formRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleUserUpdate = async () => {
    setIsLoading(true);
    setError(null);

    const reqBody = {
      username: updatedUserInfo.username,
      email: updatedUserInfo.email,
      tenant_name: updatedUserInfo.tenantName
    };

    try {
      const response = await client.post('/update_user_info', reqBody);
      fetchUserInfo();
      setSnackbarMessage(response.data.message);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error updating user info:", error);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setError(error.response?.data?.detail || "An unexpected error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Transition.Root show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => setIsModalOpen(false)}>
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                  <form ref={formRef} className="space-y-6 px-4 py-5 sm:p-6" onSubmit={(e) => e.preventDefault()}>
                    <Typography variant="h6" component="h3" style={{ color: theme.tmryk_background_color }}>
                      Confirm User Update
                    </Typography>
                    <Alert severity="warning">
                      Updating your user information may cause issues with ongoing LLM Scans under the "AI Policy" type.
                    </Alert>
                    <Typography variant="body1" gutterBottom>
                      Are you sure you want to update the user information?
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table aria-label="user info changes">
                        <TableHead>
                          <TableRow>
                            <TableCell><strong>Field</strong></TableCell>
                            <TableCell><strong>Current</strong></TableCell>
                            <TableCell><strong>Updated</strong></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell><strong>Username</strong></TableCell>
                            <TableCell>{user.username}</TableCell>
                            <TableCell>{updatedUserInfo.username}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell><strong>Email</strong></TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{updatedUserInfo.email}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell><strong>Company Name</strong></TableCell>
                            <TableCell>{user.tenantName}</TableCell>
                            <TableCell>{updatedUserInfo.tenantName}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box display="flex" justifyContent="flex-end" mt={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUserUpdate}
                        disabled={isLoading}
                        style={{ backgroundColor: theme.tmryk_background_color, color: 'white', marginRight: '8px' }}
                      >
                        {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Update'}
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => setIsModalOpen(false)}
                        disabled={isLoading}
                        style={{ 
                          borderColor: theme.tmryk_background_color, 
                          color: theme.tmryk_background_color, 
                          backgroundColor: 'white'
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {error && (
        <Transition.Root show={true} as={Fragment}>
          <Dialog as="div" className="relative z-50" onClose={() => setError(null)}>
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                    <div className="space-y-6 px-4 py-5 sm:p-6">
                      <Typography variant="h6" component="h3" style={{ color: theme.tmryk_background_color }}>
                        Error
                      </Typography>
                      <Typography variant="body1">{error}</Typography>
                      <Box display="flex" justifyContent="flex-end" mt={3}>
                        <Button
                          variant="outlined"
                          onClick={() => setError(null)}
                          style={{ 
                            borderColor: theme.tmryk_background_color, 
                            color: theme.tmryk_background_color, 
                            backgroundColor: 'white'
                          }}
                        >
                          Close
                        </Button>
                      </Box>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  );
}

export default UserInfoUpdateModal;
