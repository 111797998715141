import React from 'react';
import theme from '../../../theme';

// Use React.forwardRef to wrap the SelectFilesButtonComponent function
const SelectFilesButtonComponent = React.forwardRef(({ selectedFiles, setSelectedFiles }, ref) => {
  const handleFileChange = (e) => {
    setSelectedFiles([...e.target.files]);
  };

  return (
    <div className="flex items-center mb-2">
      {/* Attach the ref to the form element */}
      <form ref={ref} encType="multipart/form-data" method="post">
        <label className={`flex items-center bg-[${theme.tmryk_background_color}] hover:bg-[#172E6C] text-white py-2 px-4 rounded-md shadow-md cursor-pointer mt-2`}>
          Browse...
          <input
            className="hidden"
            name="files"
            type="file"
            multiple
            onChange={handleFileChange}
          />
        </label>
      </form>
      {selectedFiles.length > 0 && (
        <div className="ml-4">
          <p className="text-sm text-gray-600">
            {selectedFiles.length} files selected
          </p>
        </div>
      )}
    </div>
  );
});

// Export the component wrapped in React.forwardRef
export default SelectFilesButtonComponent;
