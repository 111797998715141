import React, { useState } from "react";
import { Box, Button, Paper, Slider, Switch, Typography, Chip, Divider, CircularProgress } from "@mui/material";
import { Settings, Star as StarIcon } from "@mui/icons-material";
import theme from "../../theme";
import styles from "./UnifiedToggle.module.css";

/**
 * A reusable metric row (toggle) component.
 * Used for displaying and controlling various metrics in the Trust Metrics panel.
 */
function UnifiedToggle({
  label,
  toggleable,
  isOn,
  onToggle,
  threshold,
  onThresholdChangeCommitted,
  score,
  onConfigure,
  topicGuardData,
  isLoading,
}) {
  const [isToggling, setIsToggling] = useState(false);
  
  // Helper function to determine if score is below threshold
  const isScoreBelowThreshold = () => {
    if (score === "N/A" || threshold === undefined) return false;
    return score < threshold;
  };

  // Check if this is a sensitive data detection metric or Topic Guard
  const isSensitiveDataDetection = label.includes("Sensitive Data Detection");
  const isTopicGuard = label === "Topic Guard";

  // Get topics and configuration for topic guard
  const relevantTopics = topicGuardData?.relevant_topics || [];
  const disallowedTopics = topicGuardData?.disallowed_topics || [];
  // Get primary topics 
  const primaryTopics = topicGuardData?.primary_topics || [];
  // Get score threshold
  const scoreThreshold = topicGuardData?.score_threshold;

  // Handle toggle with loading state to prevent UI flicker
  const handleToggleClick = async (checked) => {
    // Don't toggle again if already in progress
    if (isToggling) return;
    
    // Set toggling state
    setIsToggling(true);
    
    try {
      // Call the onToggle handler
      const success = await onToggle(checked);
      
      // If the toggle was unsuccessful, don't keep the loading state
      if (!success) {
        setIsToggling(false);
      }
    } catch (error) {
      console.error("Error toggling:", error);
      // Make sure we reset the loading state if there's an error
      setIsToggling(false);
    } finally {
      // Set a reasonable timeout to prevent rapid toggling
      // But don't leave it in loading state forever if there's an issue
      setTimeout(() => {
        setIsToggling(false);
      }, 300); // Reduced from 500ms to 300ms for better responsiveness
    }
  };

  return (
    <Paper
      sx={{
        p: 1.5,
        my: 2,
        backgroundColor: "#f8f9fa",
        boxShadow: 3,
        borderRadius: 2,
      }}
      className="hover:bg-[#ececec]"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle2" sx={{ fontWeight: "medium" }}>
          {label}
        </Typography>
        <Box display="flex" alignItems="center">
          {(isSensitiveDataDetection || isTopicGuard) && isOn && onConfigure && (
            <Button
              size="small"
              startIcon={<Settings fontSize="small" />}
              onClick={onConfigure}
              sx={{
                mr: 1,
                color: theme.tmryk_background_color,
                fontWeight: "normal",
                "&:hover": {
                  backgroundColor: "rgba(3, 26, 88, 0.04)",
                },
              }}
              disabled={isToggling}
            >
              Configure
            </Button>
          )}
          {toggleable && (
            <>
              {isToggling ? (
                <CircularProgress size={20} sx={{ mr: 1 }} />
              ) : (
                <Switch
                  checked={isOn}
                  onChange={(e) => handleToggleClick(e.target.checked)}
                  color="primary"
                  size="small"
                  disabled={isToggling || isLoading}
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: theme.tmryk_background_color,
                      "&:hover": {
                        backgroundColor: "rgba(3, 26, 88, 0.08)",
                      },
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: theme.tmryk_background_color,
                    },
                  }}
                />
              )}
            </>
          )}
        </Box>
      </Box>
      
      {/* Display primary topics (Title) for TopicGuard with bigger styling */}
      {isTopicGuard && isOn && !isLoading && !isToggling && (
        <div className={styles.topicGuardInfo}>
          {primaryTopics && primaryTopics.length > 0 && (
            <div className={styles.primaryTopics}>
              <div className={styles.sectionLabel}>Primary Topics:</div>
              <div className={styles.topicsList}>
                {primaryTopics.map((topic, i) => (
                  <div key={i} className={styles.topic}>
                    {topic}
                  </div>
                ))}
              </div>
            </div>
          )}
          {relevantTopics && relevantTopics.length > 0 && (
            <div className={styles.allowedTopics}>
              <div className={styles.sectionLabel}>Allowed Topics:</div>
              <div className={styles.topicsList}>
                {relevantTopics.map((topic, i) => (
                  <div key={i} className={styles.topic}>
                    {topic}
                  </div>
                ))}
              </div>
            </div>
          )}
          {disallowedTopics && disallowedTopics.length > 0 && (
            <div className={styles.disallowedTopics}>
              <div className={styles.sectionLabel}>Disallowed Topics:</div>
              <div className={styles.topicsList}>
                {disallowedTopics.map((topic, i) => (
                  <div key={i} className={styles.topic}>
                    {topic}
                  </div>
                ))}
              </div>
            </div>
          )}
          
          {/* Topic Guard Threshold Slider */}
          <div className={styles.thresholdSlider}>
            <Slider
              value={scoreThreshold || 500}
              step={50}
              min={0}
              max={1000}
              valueLabelDisplay="auto"
              onChangeCommitted={(e, val) => onThresholdChangeCommitted && onThresholdChangeCommitted(val)}
              size="small"
              marks={Array.from({ length: 21 }, (_, i) => ({ value: i * 50 }))}
              sx={{
                color: theme.tmryk_background_color,
                "& .MuiSlider-thumb": {
                  "&:hover, &.Mui-active": {
                    boxShadow: `0px 0px 0px 8px rgba(3, 26, 88, 0.16)`,
                  },
                },
                "& .MuiSlider-valueLabel": {
                  backgroundColor: theme.tmryk_background_color,
                },
              }}
            />
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="caption" color="textSecondary">
                Threshold: {scoreThreshold || 500}
              </Typography>
              {score !== "N/A" && score !== undefined && score !== null && (
                <Typography variant="body2" color="textSecondary">
                  <span
                    style={{
                      padding: "2px 6px",
                      backgroundColor: "#e0e0e0",
                      borderRadius: "4px",
                      fontWeight: "bold",
                      color: score < (scoreThreshold || 500) ? "#d32f2f" : "#2ba560",
                    }}
                  >
                    Score: {score}
                  </span>
                </Typography>
              )}
            </Box>
          </div>
        </div>
      )}

      {!isSensitiveDataDetection && !isTopicGuard && threshold !== undefined && (
        <Box sx={{ mt: 1 }}>
          <Slider
            value={threshold}
            step={50}
            min={0}
            max={1000}
            disabled={toggleable && !isOn}
            valueLabelDisplay="auto"
            onChangeCommitted={(e, val) => onThresholdChangeCommitted(val)}
            size="small"
            marks={Array.from({ length: 21 }, (_, i) => ({ value: i * 50 }))}
            sx={{
              color: theme.tmryk_background_color,
              "& .MuiSlider-thumb": {
                "&:hover, &.Mui-active": {
                  boxShadow: `0px 0px 0px 8px rgba(3, 26, 88, 0.16)`,
                },
              },
              "& .MuiSlider-valueLabel": {
                backgroundColor: theme.tmryk_background_color,
              },
            }}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="caption" color="textSecondary">
              Threshold: {threshold}
            </Typography>
            {score !== "N/A" && (
              <Typography variant="body2" color="textSecondary">
                <span
                  style={{
                    padding: "2px 6px",
                    backgroundColor: "#e0e0e0",
                    borderRadius: "4px",
                    fontWeight: "bold",
                    color: isScoreBelowThreshold() ? "#d32f2f" : "#2ba560",
                  }}
                >
                  Score: {score}
                </span>
              </Typography>
            )}
          </Box>
        </Box>
      )}
      
      {!isSensitiveDataDetection && !isTopicGuard &&
        threshold === undefined &&
        score !== undefined &&
        score !== "N/A" && (
          <Typography
            variant="caption"
            color="textSecondary"
            sx={{ mt: 0.5, display: "block" }}
          >
            <span
              style={{
                padding: "2px 6px",
                backgroundColor: "#e0e0e0",
                borderRadius: "4px",
                fontWeight: "bold",
                color: isScoreBelowThreshold() ? "#d32f2f" : "inherit",
              }}
            >
              Score: {score}
            </span>
          </Typography>
        )}
    </Paper>
  );
}

export default UnifiedToggle;
