import React, { useState } from 'react';
import {
  Button, CssBaseline, TextField, FormControlLabel,
  Checkbox, Link, Grid, Box, Typography, Container, createTheme, ThemeProvider, Alert
} from '@mui/material';
import tmrykLogo from '../assets/images/tmryk-logo-purple-large.png';
import client from '../client';
import SaaSAgreementModal from '../components/specific/SaaSAgreementModal';

const defaultTheme = createTheme();

export default function Register() {
  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [emailError, setEmailError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [companyNameError, setCompanyNameError] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [isSaaSAgreementModalOpen, setIsSaaSAgreementModalOpen] = useState(false); 

  const handleSubmit = (event) => {
    event.preventDefault();
    setEmailError('');
    setUsernameError('');
    setCompanyNameError('');
    setGeneralError('');

    const data = new FormData(event.currentTarget);

    let reqBody = {
      "id": 0,
      "tenant_id": 0,
      "username": data.get('username'),
      "email": data.get('email'),
      "hashed_password": data.get('password'),
      "is_active": true,
      "is_tenant_admin": true,
      "is_superuser": false,
      "role_id": 0
    };

    client.post(`/tmryk-signup?company_name=${data.get('company_name')}`, reqBody)
      .then(response => {
        console.log(response);
        let loginReqBody = new FormData();
        loginReqBody.append('username', data.get('username'));
        loginReqBody.append('password', data.get('password'));

        return client.post("/studio-login", loginReqBody);
      })
      .then(loginResponse => {
        if (loginResponse.status === 200) {
          window.location.href = '/configs';
        } else {
          setGeneralError("Login failed. Please try again.");
        }
      })
      .catch(error => {
        if (error.response && error.response.data.detail) {
          const errorMsg = error.response.data.detail;
          if (errorMsg.includes("Username already exists")) {
            setUsernameError(errorMsg);
          } else if (errorMsg.includes("Email already exists")) {
            setEmailError(errorMsg);
          } else if (errorMsg.includes("Company name already exists")) {
            setCompanyNameError(errorMsg);
          } else {
            setGeneralError('An unexpected error occurred. Please try again.');
          }
        } else {
          setGeneralError('An unexpected error occurred. Please try again.');
        }
      });
  };

  return (
    <div>
      <div>
        <img src={tmrykLogo} alt="tmryk logo" className='w-[32rem] h-48 mx-auto mt-10' />
      </div>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5">
              Create an Account
            </Typography>
            {generalError && <Alert severity="error" sx={{ mt: 2, width: '100%' }}>{generalError}</Alert>}
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="company_name"
                label="Company Name"
                id="company_name"
                autoComplete="company-name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                error={!!companyNameError}
                helperText={companyNameError}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!!emailError}
                helperText={emailError}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                error={!!usernameError}
                helperText={usernameError}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label={
                  <Typography>
                    I agree to the <Link href="#" onClick={() => setIsSaaSAgreementModalOpen(true)}>SaaS User Agreement</Link>
                  </Typography>
                }
                onChange={(event) => setChecked(event.target.checked)}
              />
              <Button
                disabled={!checked || !email || !username || !password || !companyName}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>
              <Grid container className='justify-center'>
                <Grid item>
                  <Link href="/login" variant="body2">
                    {"Already have an account? Sign In"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      <SaaSAgreementModal isModalOpen={isSaaSAgreementModalOpen} setIsModalOpen={setIsSaaSAgreementModalOpen} />
    </div>
  );
}
