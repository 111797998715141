import { useState } from "react";
import { toast } from "react-toastify";
import client from "../client";

/**
 * Custom hook for managing chat API operations
 */
const useChatApi = () => {
  const [promptMessage, setPromptMessage] = useState("");
  const [apiResponse, setApiResponse] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [runDetails, setRunDetails] = useState(null);
  const [generatedPrompts, setGeneratedPrompts] = useState([]);
  const [selectedGeneratedPrompt, setSelectedGeneratedPrompt] = useState(null);
  const [originalPrompt, setOriginalPrompt] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [showTestPolicyError, setShowTestPolicyError] = useState({});

  // Submit a prompt to the chat API
  const submitPrompt = async (configId) => {
    setRunDetails(null);
    setShowTestPolicyError({});

    if (!configId) {
      toast.error("Select a Policy");
      return false;
    }
    if (!promptMessage.trim()) {
      toast.error("Please enter a prompt to receive a response.");
      return false;
    }

    setApiResponse("");
    setIsSubmitting(true);

    try {
      const response = await client.post("/v1/chat/completions", {
        config_id: configId,
        messages: [{ role: "user", content: promptMessage }],
        stream: false,
      });

      const botMessage = response.data.messages[0];
      const content = botMessage.content;

      const bot_message_log_summary =
        response.data.log || "No log summary available";
      const general_stats =
        bot_message_log_summary.split("# General stats")[1]?.split("# Detailed stats")[0]?.trim() ||
        "No general stats available";
      const detailed_stats =
        bot_message_log_summary.split("# Detailed stats")[1]?.trim() ||
        "No detailed stats available";

      const runDetailsFromApi = {
        trust_score: response.data.metrics?.trust_score,
        model_score: response.data.metrics?.model_score || "N/A",
        bot_message_log_summary,
        general_stats,
        detailed_stats,
        violation: response.data.metrics?.violation || false,
        jailbreak_detection: response.data.metrics?.jailbreak_detection || false,
        bias_detection: response.data.metrics?.bias_detection || false,
        bias_score: response.data.metrics?.bias_score || {
          input: 0.0,
          output: 0.0,
        },
        llama_guard_categories:
          response.data.metrics?.llama_guard_categories || { input: "", output: "" },
        moderation_score_input: response.data.metrics?.moderation_score_input,
        moderation_score_output: response.data.metrics?.moderation_score_output,
        hallucination_score: response.data.metrics?.hallucination_score || 0.0,
        information: response.data.metrics?.information || [],
        jailbreak_score: response.data.metrics?.jailbreak_score || "N/A",
        topic_score: response.data.metrics?.topic_score,
        topic_relevance_score: response.data.metrics?.topic_score,
      };

      setApiResponse(content);
      setRunDetails(runDetailsFromApi);

      toast.success("Prompt submitted successfully!");
      return true;
    } catch (error) {
      if (error.response?.data?.detail) {
        const errorMsg = error.response.data.detail;
        if (typeof errorMsg === "string") {
          console.error("Failed to submit prompt:", errorMsg);
          toast.error("Failed to submit prompt:" + errorMsg);
        }
        if (typeof errorMsg === "object" && errorMsg.hasOwnProperty("message")) {
          toast.error("Failed to submit prompt.");
          setShowTestPolicyError(errorMsg);
        }
      }
      return false;
    } finally {
      setIsSubmitting(false);
    }
  };

  // Generate prompt variations
  const generatePromptVariations = async () => {
    setShowTestPolicyError({});
    if (!promptMessage.trim()) {
      toast.error("Please enter a prompt to generate variations");
      return false;
    }

    setIsGenerating(true);
    setOriginalPrompt(promptMessage);
    setGeneratedPrompts([]);

    try {
      const response = await client.post(
        `/generate_prompt?user_prompt=${encodeURIComponent(promptMessage)}`
      );
      setGeneratedPrompts(response.data.variations);
      setSelectedGeneratedPrompt(null);
      toast.success("Prompt variations generated successfully!");
      return true;
    } catch (error) {
      console.error("Failed to generate prompt variations:", error);
      toast.error("Failed to generate prompt variations: " + error.message);
      return false;
    } finally {
      setIsGenerating(false);
    }
  };

  // Handle generated prompt selection
  const handleGeneratedPromptClick = (index) => {
    if (selectedGeneratedPrompt === index) {
      setSelectedGeneratedPrompt(null);
      setPromptMessage(originalPrompt);
    } else {
      setSelectedGeneratedPrompt(index);
      setPromptMessage(generatedPrompts[index]);
    }
  };

  // Clear the response
  const clearResponse = () => {
    setApiResponse("");
    setRunDetails(null);
  };

  return {
    promptMessage,
    setPromptMessage,
    apiResponse,
    isSubmitting,
    runDetails,
    generatedPrompts,
    selectedGeneratedPrompt,
    isGenerating,
    showTestPolicyError,
    submitPrompt,
    generatePromptVariations,
    handleGeneratedPromptClick,
    clearResponse,
    setShowTestPolicyError,
  };
};

export default useChatApi; 