import React, { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  Button,
  TextField,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Typography,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
} from '@mui/material';
import GavelIcon from '@mui/icons-material/Gavel';
import SecurityIcon from '@mui/icons-material/Security';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { toast } from 'react-toastify';
import client from '../../../client';
import theme from '../../../theme';
import {
  generateCustomConfig,
  manageApiKey,
  generateConfigPayload,
} from '../../../constants/llmConfigUtilities';
import LLM_TYPES from '../../../constants/supportedModels';

// Mapping policy keys to directory names
const POLICY_DIRECTORIES = {
  euAiAct: 'EU AI Act',
  nistAiRmf: 'NIST AI RMF',
  iso42001: 'ISO 42001',
};

// Helper components for form fields
const EngineSelect = ({ engine, setEngine }) => (
  <FormControl fullWidth>
    <InputLabel className="text-gray-700">Engine</InputLabel>
    <Select
      name="engine"
      label="Engine"
      value={engine || ''}
      onChange={(e) => setEngine(e.target.value)}
      className="w-full bg-white rounded text-gray-900 focus:outline-none focus:ring-green-500"
    >
      {Object.keys(LLM_TYPES).map((key) => (
        <MenuItem key={key} value={key}>
          {key}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

const ModelSelect = ({ engine, model, setModel }) => {
  const modelOptions = engine
    ? LLM_TYPES[engine].models.map((model) => (
        <MenuItem key={model} value={model}>
          {model}
        </MenuItem>
      ))
    : [];

  return (
    <FormControl fullWidth>
      <InputLabel className="text-gray-700">Model</InputLabel>
      <Select
        name="model"
        label="Model"
        value={model || ''}
        onChange={(e) => setModel(e.target.value)}
        className="w-full bg-white rounded text-gray-900 focus:outline-none focus:ring-green-500"
      >
        {modelOptions}
      </Select>
    </FormControl>
  );
};

const APIKeyField = ({ engine, apiKey, setApiKey }) => {
  const requiresKey = LLM_TYPES[engine]?.requiredToAddKey;
  if (!requiresKey) return null;

  return (
    <TextField
      label="API Key"
      name="apiKey"
      value={apiKey}
      onChange={(e) => setApiKey(e.target.value)}
      fullWidth
      className="w-full bg-white border-2 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
    />
  );
};

const AzureFields = ({
  azureEndpoint,
  setAzureEndpoint,
  azureApiVersion,
  setAzureApiVersion,
  azureDeploymentName,
  setAzureDeploymentName,
}) => {
  const handleEndpointChange = (e) => {
    const value = e.target.value;
    setAzureEndpoint(value);

    // Try to extract Azure details from full URL if pasted
    try {
      if (value.includes('/openai/deployments/')) {
        const url = new URL(value);
        // Extract base endpoint
        setAzureEndpoint(`${url.protocol}//${url.hostname}`);

        // Extract deployment name from path
        const pathParts = url.pathname.split('/');
        const deploymentIndex = pathParts.indexOf('deployments');
        if (deploymentIndex !== -1 && deploymentIndex + 1 < pathParts.length) {
          setAzureDeploymentName(pathParts[deploymentIndex + 1]);
        }

        // Extract API version from query parameters
        const apiVersion = url.searchParams.get('api-version');
        if (apiVersion) {
          setAzureApiVersion(apiVersion);
        }
      }
    } catch (error) {
      // If URL parsing fails, just keep the value as is
      console.error('Failed to parse Azure URL:', error);
    }
  };

  return (
    <>
      <TextField
        label="Azure Endpoint"
        name="azureEndpoint"
        value={azureEndpoint}
        onChange={handleEndpointChange}
        fullWidth
        className="w-full bg-white border-2 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
        helperText="You can paste a full Azure OpenAI URL to auto-fill all fields"
      />
      <TextField
        label="API Version"
        name="azureApiVersion"
        value={azureApiVersion}
        onChange={(e) => setAzureApiVersion(e.target.value)}
        fullWidth
        className="w-full bg-white border-2 rounded p-3 text-gray-900"
      />
      <TextField
        label="Deployment Name"
        name="azureDeploymentName"
        value={azureDeploymentName}
        onChange={(e) => setAzureDeploymentName(e.target.value)}
        fullWidth
        className="w-full bg-white border-2 rounded p-3 text-gray-900"
      />
    </>
  );
};

const CredentialUpload = ({ engine, credentialsFile, setCredentialsFile }) => {
  if (engine !== 'vertexai') return null;

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/json') {
      setCredentialsFile(file);
    } else {
      toast.error('Please upload a valid JSON file.');
    }
  };

  return (
    <div className="mt-4">
      <input
        type="file"
        accept=".json"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="credentials-file"
      />
      <label htmlFor="credentials-file">
        <Button
          variant="contained"
          component="span"
          style={{
            backgroundColor: theme.tmryk_background_color,
            color: 'white',
            marginBottom: '10px',
          }}
        >
          Upload Google Credentials JSON
        </Button>
      </label>
      {credentialsFile && <p>Selected File: {credentialsFile.name}</p>}
    </div>
  );
};

const PolicyOptionsList = ({ options, setOptions, setPolicyName }) => {
  const handleToggle = (selectedOption) => () => {
    const isCurrentlyChecked = options[selectedOption];

    let newOptions;
    if (isCurrentlyChecked) {
      // Unselect the currently selected option
      newOptions = {
        euAiAct: false,
        nistAiRmf: false,
        iso42001: false,
      };
    } else {
      // Select the new option and unselect others
      newOptions = {
        euAiAct: false,
        nistAiRmf: false,
        iso42001: false,
        [selectedOption]: true,
      };
    }

    setOptions(newOptions);

    // Update policyName based on the selected option
    const selectedPolicy = newOptions[selectedOption]
      ? POLICY_DIRECTORIES[selectedOption]
      : '';
    setPolicyName(selectedPolicy);
  };

  const regulationOptions = [
    { key: 'euAiAct', label: 'EU AI Act', Icon: GavelIcon },
    { key: 'nistAiRmf', label: 'NIST AI RMF', Icon: SecurityIcon },
    { key: 'iso42001', label: 'ISO 42001', Icon: AccountBalanceIcon },
  ];

  return (
    <List>
      {regulationOptions.map(({ key, label, Icon }) => (
        <ListItem key={key}>
          <ListItemIcon>
            <Icon style={{ color: theme.tmryk_background_color }} />
          </ListItemIcon>
          <ListItemText primary={label} sx={{ mr: 3 }} />
          <Checkbox
            edge="end"
            onChange={handleToggle(key)}
            checked={options[key]}
            color="primary"
            inputProps={{
              'aria-labelledby': `checkbox-list-label-${key}`,
            }}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default function AddConfigModal({
  isModalOpen,
  setIsModalOpen,
  onConfigAdded,
}) {
  const [mode, setMode] = useState('add'); // 'add' or 'import'
  const [configName, setConfigName] = useState('');
  const [engine, setEngine] = useState('');
  const [model, setModel] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [credentialsFile, setCredentialsFile] = useState(null);
  const [options, setOptions] = useState({
    euAiAct: false,
    nistAiRmf: false,
    iso42001: false,
  });
  const [policyName, setPolicyName] = useState('');
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState('');
  const fileInputRef = useRef(null);
  const importFileRef = useRef(null);
  const [importFile, setImportFile] = useState(null);

  // Azure-specific state
  const [azureEndpoint, setAzureEndpoint] = useState('');
  const [azureApiVersion, setAzureApiVersion] = useState('');
  const [azureDeploymentName, setAzureDeploymentName] = useState('');

  const validateForm = () => {
    if (mode === 'add') {
      if (!configName.trim()) return 'Policy name is required.';
      if (!engine) return 'Engine is required.';
      if (!model) return 'Model is required.';
      if (LLM_TYPES[engine]?.requiredToAddKey && !apiKey.trim())
        return 'API key is required.';
      if (engine === 'azure') {
        if (!azureEndpoint.trim()) return 'Azure endpoint is required.';
        if (!azureApiVersion.trim()) return 'Azure API version is required.';
        if (!azureDeploymentName.trim())
          return 'Azure deployment name is required.';
      }
      if (engine === 'vertexai' && !credentialsFile)
        return 'Credentials file is required for Vertex AI engine.';
    } else if (mode === 'import') {
      if (!importFile) return 'Please select a policy file to import.';
      // Additional validation for file type can be added here
    }
    return '';
  };

  const handleFileChange = (event) => {
    setSelectedFiles((prev) => [...prev, ...Array.from(event.target.files)]);
  };

  const handleImportFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImportFile(file);
    }
  };

  const removeFile = (fileName) => {
    setSelectedFiles((prev) => prev.filter((file) => file.name !== fileName));
  };

  const removeImportFile = () => {
    setImportFile(null);
  };

  const handleSubmit = async () => {
    const validationMessage = validateForm();
    if (validationMessage) {
      setValidationError(validationMessage);
      return;
    }

    setLoading(true);
    try {
      if (mode === 'add') {
        // Handle adding a new policy
        const azureParams =
          engine === 'azure'
            ? {
                azure_endpoint: azureEndpoint.trim(),
                api_version: azureApiVersion.trim(),
                deployment_name: azureDeploymentName.trim(),
              }
            : null;

        const configDataPayload = generateConfigPayload(
          engine,
          model,
          {},
          azureParams
        );

        const customConfig = generateCustomConfig(engine, model);

        const formData = new FormData();

        let finalConfigName = configName;
        if (policyName) {
          finalConfigName = `${configName} (${policyName})`;
        }

        formData.append('config_name', finalConfigName);

        // Append policy_name only if a policy is selected
        if (policyName) {
          formData.append('policy_name', policyName);
        }

        formData.append(
          'config_data',
          JSON.stringify({ 'models.yml': configDataPayload })
        );

        if (customConfig) {
          formData.append(
            'files',
            new Blob([customConfig['config.py']], { type: 'text/plain' }),
            'config.py'
          );
        }

        selectedFiles.forEach((file) => formData.append('files', file));

        if (engine === 'vertexai' && credentialsFile) {
          formData.append('files', credentialsFile, 'google-creds.json');
        }

        await client.post('/create_config', formData);
        await manageApiKey(finalConfigName, apiKey, engine);

        onConfigAdded({ id: finalConfigName, engine, model, apiKey, ...options });
        toast.success('Policy created successfully!');
      } else if (mode === 'import') {
        // Handle importing a policy
        const importFormData = new FormData();
        importFormData.append('file', importFile);

        await client.post('/import_policy', importFormData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        toast.success('Policy imported successfully!');
        if (onConfigAdded) {
          await onConfigAdded();
        }
      }
      setIsModalOpen(false);
    } catch (error) {
      console.error(error);
      toast.error(
        mode === 'add'
          ? 'An error occurred while creating the policy.'
          : error.response?.data?.detail || 'Error importing policy.'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
    // Reset all states
    setMode('add');
    setConfigName('');
    setEngine('');
    setModel('');
    setApiKey('');
    setSelectedFiles([]);
    setCredentialsFile(null);
    setOptions({
      euAiAct: false,
      nistAiRmf: false,
      iso42001: false,
    });
    setPolicyName('');
    setValidationError('');
    setImportFile(null);
  };

  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
            <Dialog.Panel className="pointer-events-auto w-screen max-w-lg bg-white shadow-xl rounded-lg">
              <div className="px-4 py-4 sm:px-6 bg-white rounded-t-lg flex justify-between items-center">
                <Dialog.Title
                  className="text-lg font-medium"
                  style={{
                    color: theme.tmryk_background_color,
                  }}
                >
                  Add Policy
                </Dialog.Title>
                <Button
                  onClick={handleClose}
                  style={{
                    color: theme.tmryk_background_color,
                    borderColor: theme.tmryk_background_color,
                  }}
                >
                  Close
                </Button>
              </div>

              <Divider />

              <div className="relative flex-1 px-4 pb-6 sm:px-6">
                {/* Mode Selection */}
                <FormControl component="fieldset" sx={{ marginTop: 2 }}>
                  <RadioGroup
                    row
                    value={mode}
                    onChange={(e) => {
                      setMode(e.target.value);
                      setValidationError('');
                      // Reset import file when switching modes
                      if (e.target.value === 'add') {
                        setImportFile(null);
                      } else {
                        setConfigName('');
                        setEngine('');
                        setModel('');
                        setApiKey('');
                        setSelectedFiles([]);
                        setCredentialsFile(null);
                        setOptions({
                          euAiAct: false,
                          nistAiRmf: false,
                          iso42001: false,
                        });
                        setPolicyName('');
                      }
                    }}
                  >
                    <FormControlLabel
                      value="add"
                      control={<Radio />}
                      label="Add New Policy"
                    />
                    <FormControlLabel
                      value="import"
                      control={<Radio />}
                      label="Import Policy"
                    />
                  </RadioGroup>
                </FormControl>

                <Divider sx={{ marginY: 2 }} />

                {/* Conditional Rendering Based on Mode */}
                {mode === 'add' ? (
                  // Add New Policy Form
                  <>
                    {validationError && (
                      <Alert severity="error" className="mb-4">
                        {validationError}
                      </Alert>
                    )}
                    <form className="space-y-6">
                      <TextField
                        label="Policy Name"
                        name="config_name"
                        value={configName}
                        onChange={(e) => setConfigName(e.target.value)}
                        fullWidth
                        className="w-full bg-white border-2 rounded p-3 text-gray-900"
                      />

                      <EngineSelect engine={engine} setEngine={setEngine} />
                      <ModelSelect
                        engine={engine}
                        model={model}
                        setModel={setModel}
                      />
                      <APIKeyField
                        engine={engine}
                        apiKey={apiKey}
                        setApiKey={setApiKey}
                      />
                      {engine === 'azure' && (
                        <AzureFields
                          azureEndpoint={azureEndpoint}
                          setAzureEndpoint={setAzureEndpoint}
                          azureApiVersion={azureApiVersion}
                          setAzureApiVersion={setAzureApiVersion}
                          azureDeploymentName={azureDeploymentName}
                          setAzureDeploymentName={setAzureDeploymentName}
                        />
                      )}
                      <CredentialUpload
                        engine={engine}
                        credentialsFile={credentialsFile}
                        setCredentialsFile={setCredentialsFile}
                      />

                      <Box
                        mt={4}
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <PolicyOptionsList
                          options={options}
                          setOptions={setOptions}
                          setPolicyName={setPolicyName}
                        />
                      </Box>

                      <Typography
                        variant="subtitle1"
                        className="mt-2 text-gray-700"
                        style={{
                          textAlign: 'left',
                          color: theme.tmryk_background_color,
                        }}
                      >
                        Add Files (Optional)
                      </Typography>
                      <div className="mt-2 flex justify-start">
                        <Button
                          variant="contained"
                          onClick={() => fileInputRef.current.click()}
                          style={{
                            backgroundColor: theme.tmryk_background_color,
                            color: 'white',
                          }}
                        >
                          Add Files
                        </Button>
                        <input
                          ref={fileInputRef}
                          type="file"
                          multiple
                          className="hidden"
                          onChange={handleFileChange}
                        />
                      </div>
                      <div className="mt-2">
                        {selectedFiles.map((file) => (
                          <div
                            key={file.name}
                            className="flex items-center justify-between mt-2"
                          >
                            <span>{file.name}</span>
                            <button
                              type="button"
                              onClick={() => removeFile(file.name)}
                              className="bg-red-500 text-white rounded-full px-2 py-1 ml-2"
                            >
                              -
                            </button>
                          </div>
                        ))}
                      </div>

                      {/* Action Buttons */}
                      <div className="mt-4 flex justify-end space-x-3">
                        <Button
                          variant="outlined"
                          onClick={handleClose}
                          style={{
                            color: theme.tmryk_background_color,
                            borderColor: theme.tmryk_background_color,
                            width: '150px',
                            height: '48px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textTransform: 'none',
                            fontWeight: 'bold',
                          }}
                          disabled={loading}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          onClick={handleSubmit}
                          style={{
                            backgroundColor: theme.tmryk_background_color,
                            color: 'white',
                            width: '150px',
                            height: '48px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textTransform: 'none',
                            fontWeight: 'bold',
                            boxShadow:
                              '0 3px 5px 2px rgba(84, 130, 78, .3)',
                          }}
                          disabled={loading}
                        >
                          {loading ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                            'Submit'
                          )}
                        </Button>
                      </div>
                    </form>
                  </>
                ) : (
                  // Import Policy Section
                  <>
                    {validationError && (
                      <Alert severity="error" sx={{ mb: 2 }}>
                        {validationError}
                      </Alert>
                    )}
                    <Box
                      sx={{
                        mb: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          mb: 2,
                          color: 'text.secondary',
                          textAlign: 'left',
                          width: '100%',
                          display: 'block',
                        }}
                      >
                        Please upload a policy file in .zip format. The file
                        should contain your policy configuration.
                      </Typography>
                      <input
                        style={{ display: 'none' }}
                        id="import-policy-file"
                        type="file"
                        onChange={handleImportFileChange}
                      />
                      <label htmlFor="import-policy-file">
                        <Button
                          variant="contained"
                          component="span"
                          sx={{
                            backgroundColor: theme.tmryk_background_color,
                            color: 'white',
                            '&:hover': {
                              backgroundColor:
                                theme.tmryk_hover_background_color,
                            },
                            display: 'inline-flex',
                            minWidth: 'auto',
                            width: 'auto',
                          }}
                        >
                          Select Policy File
                        </Button>
                      </label>
                      {importFile && (
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', mt: 2 }}
                        >
                          <Typography variant="body1">
                            Selected file: {importFile.name}
                          </Typography>
                          <Button
                            onClick={removeImportFile}
                            color="error"
                            sx={{ ml: 2 }}
                          >
                            Remove
                          </Button>
                        </Box>
                      )}
                    </Box>

                    {/* Action Buttons */}
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 2,
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={handleClose}
                        sx={{
                          color: theme.tmryk_background_color,
                          borderColor: theme.tmryk_background_color,
                          textTransform: 'none',
                          fontWeight: 'bold',
                          '&:hover': {
                            borderColor: theme.tmryk_hover_background_color,
                          },
                        }}
                        disabled={loading}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleSubmit}
                        sx={{
                          backgroundColor: theme.tmryk_background_color,
                          color: 'white',
                          textTransform: 'none',
                          fontWeight: 'bold',
                          '&:hover': {
                            backgroundColor:
                              theme.tmryk_hover_background_color,
                          },
                        }}
                        disabled={loading}
                      >
                        {loading ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          'Import Policy'
                        )}
                      </Button>
                    </Box>
                  </>
                )}
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
