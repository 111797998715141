import { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  Button,
  Checkbox,
  Alert,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Card,
  CardContent,
  Grid,
  Divider,
  Tooltip,
  CircularProgress,
  IconButton,
} from '@mui/material';
import {
  ArrowBack,
  Close,
  WarningAmber,
  Visibility as ViewIcon,
} from '@mui/icons-material';
import { toast } from 'react-toastify';
import client from '../../../client';
import theme from '../../../theme';
import ViewListModal from '../../common/ViewListModal';
import BiasDetectionModal from './BiasDetectionModal'

const regularProbes = {
  dan: 'DAN',
  atkgen: 'Attack Generation',
  glitch: 'Glitch',
  knownbadsignatures: 'Known Bad Signatures',
  lmrc: 'LMRC',
  packagehallucination: 'Package Hallucination',
  promptinject: 'Prompt Inject',
  tap: 'TAP',
  xss: 'XSS',
  grandma: 'Grandma'
};

const regularHelperTexts = {
  dan: 'Detects and mitigates DAN-like behaviors.',
  atkgen: 'Generates attack vectors for testing.',
  glitch: 'Detects and handles glitch behaviors.',
  knownbadsignatures: 'Identifies known bad signatures.',
  lmrc: 'Language Model Risk Cards testing.',
  packagehallucination: 'Detects package hallucinations.',
  promptinject: 'Tests for prompt injection vulnerabilities.',
  tap: 'Tests for TAP vulnerabilities.',
  xss: 'Cross-Site Scripting (XSS) detection.',
  grandma: "Tests model's ability to generate safe and appropriate content", 
};

const owaspProbes = {
  'owasp:llm01': 'Prompt Injection (LLM01)',
  'owasp:llm02': 'Insecure Output Handling (LLM02)',
  'owasp:llm03': 'Training Data Poisoning (LLM03)',
  'owasp:llm04': 'Model Denial of Service (LLM04)',
  'owasp:llm05': 'Supply Chain Vulnerabilities (LLM05)',
  'owasp:llm06': 'Sensitive Information Disclosure (LLM06)',
  'owasp:llm07': 'Insecure Plugin Design (LLM07)',
  'owasp:llm08': 'Excessive Agency (LLM08)',
  'owasp:llm09': 'Overreliance (LLM09)',
  'owasp:llm10': 'Model Theft (LLM10)',
};

const owaspHelperTexts = {
  'owasp:llm01': 'Tests for prompt injection vulnerabilities.',
  'owasp:llm02': 'Tests for insecure output handling.',
  'owasp:llm03': 'Tests for training data poisoning.',
  'owasp:llm04': 'Tests for model denial of service.',
  'owasp:llm05': 'Tests for supply chain vulnerabilities.',
  'owasp:llm06': 'Tests for sensitive information disclosure.',
  'owasp:llm07': 'Tests for insecure plugin design.',
  'owasp:llm08': 'Tests for excessive agency.',
  'owasp:llm09': 'Tests for overreliance.',
  'owasp:llm10': 'Tests for model theft.',
};

const ethicsProbes = {
  'avid-effect:ethics:E0100': 'Bias/Discrimination',
  'avid-effect:ethics:E0101': 'Group fairness',
  // 'avid-effect:ethics:E0102': 'Individual fairness',
  // 'avid-effect:ethics:E0200': 'Explainability',
  // 'avid-effect:ethics:E0201': 'Global explanations',
  // 'avid-effect:ethics:E0202': 'Local explanations',
  // 'avid-effect:ethics:E0300': 'User actions',
  'avid-effect:ethics:E0301': 'Toxicity',
  'avid-effect:ethics:E0302': 'Polarization/ Exclusion',
  // 'avid-effect:ethics:E0400': 'Misinformation',
  // 'avid-effect:ethics:E0401': 'Deliberative Misinformation',
  'avid-effect:ethics:E0402': 'Generative Misinformation',
};

const ethicsHelperTexts = {
  'avid-effect:ethics:E0100': 'Detects bias and discrimination in model outputs.',
  'avid-effect:ethics:E0101': 'Evaluates fairness across different groups.',
  'avid-effect:ethics:E0102': 'Assesses fairness at the individual level.',
  'avid-effect:ethics:E0200': 'Examines the explainability of model decisions.',
  'avid-effect:ethics:E0201': 'Provides global explanations for model outputs.',
  'avid-effect:ethics:E0202': 'Offers local explanations for specific model predictions.',
  'avid-effect:ethics:E0300': 'Analyzes user actions and their impact on the model.',
  'avid-effect:ethics:E0301': 'Identifies toxic content generated by the model.',
  'avid-effect:ethics:E0302': 'Detects polarization and exclusion in model outputs.',
  'avid-effect:ethics:E0400': 'Identifies misinformation generated by the model.',
  'avid-effect:ethics:E0401': 'Detects deliberate misinformation spread through the model.',
  'avid-effect:ethics:E0402': 'Identifies misinformation generated through model interactions.',
};

const disabledOwaspProbes = ['owasp:llm03', 'owasp:llm04', 'owasp:llm07', 'owasp:llm08'];

export default function RunScanProbesModal({ isProbesModalOpen, setIsProbesModalOpen, setProbes, setProbeType, setIsSummaryModalOpen, setIsModalOpen, aiPolicyType,llmType,llmData }) {
  const cancelButtonRef = useRef(null);
  const [selectedProbes, setSelectedProbes] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showUnavailableAlert, setShowUnavailableAlert] = useState(false);
  const [probeType, setProbeTypeState] = useState('owasp');
  const [warningText, setWarningText] = useState('');
  const [customSuites, setCustomSuites] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false); // State to manage ViewListModal
  const [selectedSuite, setSelectedSuite] = useState(null); // State to store the selected suite for viewing
  

  useEffect(() => {
    if (probeType === 'custom') {
      const fetchCustomSuites = async () => {
        setIsLoading(true);
        try {
          const response = await client.get('/custom_suite_list');
          setCustomSuites(response.data);
        } catch (error) {
          toast.error('Error fetching custom suites');
        } finally {
          setIsLoading(false);
        }
      };
      fetchCustomSuites();
    }
  }, [probeType]);

  const handleProbesChange = (value, checked) => {
    if (disabledOwaspProbes.includes(value)) {
      setShowUnavailableAlert(true);
      return;
    }

    if (probeType === 'owasp') {
      if (selectedProbes.length >= 1 && !selectedProbes.includes(value)) {
        setWarningText('You cannot select more than 1 OWASP Probe');
        return;
      }
    } else if (probeType === 'custom') {
      if (checked) {
        setSelectedProbes([value]);
      } else {
        setSelectedProbes([]);
      }
      setShowError(false);
      setShowUnavailableAlert(false);
      setWarningText('');
      return;
    } else {
      if (selectedProbes.length >= 3 && !selectedProbes.includes(value) && checked) {
        setWarningText('You cannot select more than 3 Probes');
        return;
      }
    }

    setSelectedProbes(prev => {
      if (checked) {
        return [...prev, value];
      } else {
        return prev.filter(probe => probe !== value);
      }
    });

    setShowError(false);
    setShowUnavailableAlert(false);
    setWarningText('');
  };

  const handleProbeTypeChange = (event) => {
    const newProbeType = event.target.value;
    setProbeTypeState(newProbeType);
    setProbeType(newProbeType);
    setSelectedProbes([]);
    setShowError(false);
    setShowUnavailableAlert(false);
    setWarningText('');
  };

  const handleNext = () => {
    if (selectedProbes.length === 0) {
      setShowError(true);
      return;
    }
    setProbes(selectedProbes);
    setIsSummaryModalOpen(true);
    setIsProbesModalOpen(false);
    setShowError(false);
    setShowUnavailableAlert(false);
    setWarningText('');
  };

  const handleBack = () => {
    setIsProbesModalOpen(false);
    setIsModalOpen(true);
    setShowError(false);
    setShowUnavailableAlert(false);
    setWarningText('');
  };

  const handleClose = () => {
    setIsProbesModalOpen(false);
    setIsModalOpen(false);
    setShowError(false);
    setShowUnavailableAlert(false);
    setWarningText('');
  };

  const handleViewSuite = (suite) => {
    setSelectedSuite(suite);
    setViewModalOpen(true);
    setIsProbesModalOpen(false); // Close RunScanProbesModal when ViewListModal opens
  };

  return (
    <>
      <Transition.Root show={isProbesModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={handleClose}>
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden flex justify-center items-center">
              <Transition.Child
                as="div"
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-y-[-100%]"
                enterTo="translate-y-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-y-0"
                leaveTo="translate-y-[-100%]"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-6xl h-full max-h-[90vh] flex flex-col bg-white shadow-xl rounded-lg overflow-y-auto">
                  <div className="px-4 pt-6 sm:px-6 bg-white rounded-t-lg">
                    <div className="flex items-start justify-between">
                      <div className="flex items-center">
                        <ArrowBack
                          onClick={handleBack}
                          className="h-6 w-6 mr-2 cursor-pointer"
                          style={{ color: theme.tmryk_background_color }}
                        />
                        <Dialog.Title className="text-lg font-medium" style={{ color: theme.tmryk_background_color }}>
                          Select Scan Type
                        </Dialog.Title>
                      </div>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-white hover:bg-[#031A58] focus:outline-none focus:ring-2"
                          onClick={handleClose}
                        >
                          <span className="sr-only">Close panel</span>
                          <Close className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <RadioGroup row value={probeType} onChange={handleProbeTypeChange} className="mt-4">
                      <FormControlLabel
                        value="owasp"
                        control={<Radio style={{ color: theme.tmryk_background_color }} />}
                        label={<Typography variant="body1" style={{ color: theme.tmryk_background_color }}>OWASP</Typography>}
                      />
                      <FormControlLabel
                        value="regular"
                        control={<Radio style={{ color: theme.tmryk_background_color }} />}
                        label={<Typography variant="body1" style={{ color: theme.tmryk_background_color }}>Probes</Typography>}
                      />
                      <FormControlLabel
                        value="custom"
                        control={<Radio style={{ color: theme.tmryk_background_color }} />}
                        label={<Typography variant="body1" style={{ color: theme.tmryk_background_color }}>Custom Suite</Typography>}
                      />
                      <FormControlLabel
                        value="ethics"
                        control={<Radio style={{ color: theme.tmryk_background_color }} />}
                        label={<Typography variant="body1" style={{ color: theme.tmryk_background_color }}>Ethics</Typography>}
                      />
                      {['public', 'ai_policy'].includes(llmType) && (
                        <FormControlLabel
                          value="bias"
                          control={<Radio style={{ color: theme.tmryk_background_color }} />}
                          label={<Typography variant="body1" style={{ color: theme.tmryk_background_color }}>Bias Detection (Beta)</Typography>}
                        />
                      )}
                    </RadioGroup>
                    <Typography variant="body2" className="mt-2 text-gray-500">
                      {probeType === 'regular'
                        ? 'Please select your probes for scanning (Max: 3)'
                        : probeType === 'owasp'
                        ? 'Please select your OWASP category for scanning (Max: 1)'
                        : probeType === 'custom'
                        ? 'Please select a custom suite for scanning (Max: 1)'
                        : probeType === 'bias'
                        ? 'Please select a protected class for bias detection'
                        : probeType === 'ethics'
                        ? 'Please select an ethics probe for scanning (Max: 3)'
                        : ''}
                    </Typography>
                    {showError && <Alert severity="error" className="mt-2">Please select at least one probe.</Alert>}
                    {showUnavailableAlert && <Alert severity="warning" className="mt-2">Probe is not available yet for the scan.</Alert>}
                    {warningText && <Alert severity="warning" className="mt-2">{warningText}</Alert>}
                  </div>
                  <div className="flex-1 px-4 py-6 sm:px-6 overflow-y-auto">
                  <div className="flex-1 px-4 py-6 sm:px-6 overflow-y-auto">
                    <Grid container spacing={3}>
                      {probeType === 'bias' ? (
                        <BiasDetectionModal config={aiPolicyType} llmData = {llmData} llmType = {llmType} />
                      ) : (
                        <>
                          {probeType === 'regular' && Object.keys(regularProbes).map((testKey) => (
                            <Grid item xs={12} md={6} key={testKey}>
                              <Card
                                variant="outlined"
                                sx={{ boxShadow: 3, borderRadius: 2, ':hover': { boxShadow: 6 } }}
                                onClick={() => handleProbesChange(testKey, !selectedProbes.includes(testKey))}
                              >
                                <CardContent>
                                  <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                      <Checkbox
                                        value={testKey}
                                        checked={selectedProbes.indexOf(testKey) > -1}
                                        onChange={(e) => handleProbesChange(e.target.value, e.target.checked)}
                                        disabled={selectedProbes.length >= 3 && selectedProbes.indexOf(testKey) === -1}
                                        style={{ color: theme.tmryk_background_color }}
                                        onClick={(e) => e.stopPropagation()}
                                      />
                                    </Grid>
                                    <Grid item xs>
                                      <Typography variant="body1" className="font-medium">{regularProbes[testKey]}</Typography>
                                      <Typography variant="body2" className="text-gray-500">{regularHelperTexts[testKey]}</Typography>
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                          {probeType === 'owasp' && Object.keys(owaspProbes).map((testKey) => (
                            <Grid item xs={12} md={6} key={testKey}>
                              <Card
                                variant="outlined"
                                sx={{ boxShadow: 3, borderRadius: 2, ':hover': { boxShadow: 6 } }}
                                onClick={() => handleProbesChange(testKey, !selectedProbes.includes(testKey))}
                              >
                                <CardContent>
                                  <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                      <Checkbox
                                        value={testKey}
                                        checked={selectedProbes.indexOf(testKey) > -1}
                                        onChange={(e) => handleProbesChange(e.target.value, e.target.checked)}
                                        disabled={selectedProbes.length >= 1 && selectedProbes.indexOf(testKey) === -1 || disabledOwaspProbes.includes(testKey)}
                                        style={{ color: theme.tmryk_background_color }}
                                        onClick={(e) => e.stopPropagation()}
                                      />
                                    </Grid>
                                    <Grid item xs>
                                      <Typography variant="body1" className="font-medium">{owaspProbes[testKey]}</Typography>
                                      <Typography variant="body2" className="text-gray-500">{owaspHelperTexts[testKey]}</Typography>
                                    </Grid>
                                    {disabledOwaspProbes.includes(testKey) && (
                                      <Grid item>
                                        <Tooltip title="Probe Not available">
                                          <WarningAmber style={{ color: theme.tmryk_warning_color }} />
                                        </Tooltip>
                                      </Grid>
                                    )}
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                          {probeType === 'ethics' && Object.keys(ethicsProbes).map((testKey) => (
                            <Grid item xs={12} md={6} key={testKey}>
                              <Card
                                variant="outlined"
                                sx={{ boxShadow: 3, borderRadius: 2, ':hover': { boxShadow: 6 } }}
                                onClick={() => handleProbesChange(testKey, !selectedProbes.includes(testKey))}
                              >
                                <CardContent>
                                  <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                      <Checkbox
                                        value={testKey}
                                        checked={selectedProbes.indexOf(testKey) > -1}
                                        onChange={(e) => handleProbesChange(e.target.value, e.target.checked)}
                                        disabled={selectedProbes.length >= 3 && selectedProbes.indexOf(testKey) === -1}
                                        style={{ color: theme.tmryk_background_color }}
                                        onClick={(e) => e.stopPropagation()}
                                      />
                                    </Grid>
                                    <Grid item xs>
                                      <Typography variant="body1" className="font-medium">{ethicsProbes[testKey]}</Typography>
                                      <Typography variant="body2" className="text-gray-500">{ethicsHelperTexts[testKey]}</Typography>
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                          {probeType === 'custom' && (
                            isLoading ? (
                              <div className="flex justify-center items-center min-h-[200px] w-full">
                                <CircularProgress />
                              </div>
                            ) : customSuites.map((suite, index) => (
                              <Grid item xs={12} md={6} key={index}>
                                <Card
                                  variant="outlined"
                                  sx={{ boxShadow: 3, borderRadius: 2, ':hover': { boxShadow: 6 } }}
                                  onClick={() => handleProbesChange(suite.list_name, !selectedProbes.includes(suite.list_name))}
                                >
                                  <CardContent>
                                    <Grid container spacing={2} alignItems="center">
                                      <Grid item>
                                        <Checkbox
                                          value={suite.list_name}
                                          checked={selectedProbes.indexOf(suite.list_name) > -1}
                                          onChange={(e) => handleProbesChange(e.target.value, e.target.checked)}
                                          disabled={selectedProbes.length >= 1 && selectedProbes.indexOf(suite.list_name) === -1}
                                          style={{ color: theme.tmryk_background_color }}
                                          onClick={(e) => e.stopPropagation()}
                                        />
                                      </Grid>
                                      <Grid item xs>
                                        <Typography variant="body1" className="font-medium" style={{ color: selectedProbes.includes(suite.list_name) ? theme.tmryk_background_color : 'black' }}>
                                          {suite.list_name}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" style={{ color: selectedProbes.includes(suite.list_name) ? theme.tmryk_background_color : 'gray' }}>
                                          Created by: {suite.created_by} | Date: {suite.date}
                                        </Typography>
                                      </Grid>
                                      <Grid item>
                                        <IconButton onClick={() => handleViewSuite(suite)} style={{ color: theme.tmryk_background_color }}>
                                          <ViewIcon />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </Card>
                              </Grid>
                            ))
                          )}
                        </>
                      )}
                    </Grid>
                  </div>
                  </div>
                  <Divider className="mt-4" />
                  <div className="flex justify-end space-x-3 p-6 bg-white rounded-b-lg">
                    <Button
                      variant="outlined"
                      onClick={handleBack}
                      ref={cancelButtonRef}
                      startIcon={<ArrowBack />}
                      style={{
                        borderColor: theme.tmryk_background_color,
                        color: theme.tmryk_background_color,
                        width: '9.375rem', // 150px
                        height: '3rem', // 48px
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textTransform: 'none',
                        fontWeight: 'bold',
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: theme.tmryk_background_color,
                        color: 'white',
                        width: '9.375rem', // 150px
                        height: '3rem', // 48px,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textTransform: 'none',
                        fontWeight: 'bold',
                        boxShadow: '0 3px 5px 2px rgba(84, 130, 78, .3)',
                      }}
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ViewListModal
        isOpen={viewModalOpen}
        setIsOpen={setViewModalOpen}
        listName={selectedSuite?.list_name}
        setIsModalOpen={setIsProbesModalOpen} // Set this to reopen RunScanProbesModal
      />
    </>
  );
}