import React from 'react';

export default function SelectFilesListComponent({ selectedFiles }) {

  return (
    <div className="mb-4">
      <ul className="list-disc list-inside">
        {selectedFiles.map((file, index) => (
          <li key={index} className="text-sm text-gray-600">
            {file.name}
          </li>
        ))}
      </ul>
    </div>
  );
}
