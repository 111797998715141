import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  TextField,
  FormControlLabel,
  Button,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  InputLabel,
  Alert,
  Typography,
  Slider,
  Box,
  Checkbox,
  Tooltip,
} from "@mui/material";
import { Close, ArrowBack, ArrowForward } from "@mui/icons-material";
import { toast } from 'react-toastify';
import client from "../../../client";
import theme from "../../../theme";
import CreatePolicyScanModal from "./CreatePolicyScanModal";
import RunScanProbesModal from "./RunScanProbesModal";
import RunScanSummaryModal from "./RunScanSummaryModal";
import GenerateTrustScoreModal from "./GenerateTrustScoreModal";
import { LLM_TYPES } from "../../../constants/supportedModels";

const CredentialUpload = ({ credentialsFile, setCredentialsFile }) => {
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/json') {
      setCredentialsFile(file);
    } else {
      toast.error("Please upload a valid JSON file.");
    }
  };

  return (
    <div className="mt-4">
      <input
        type="file"
        accept=".json"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="credentials-file"
      />
      <label htmlFor="credentials-file">
        <Button variant="contained" component="span" style={{ backgroundColor: theme.tmryk_background_color, color: 'white', marginBottom:'10px' }}>
          Upload Google Credentials JSON
        </Button>
      </label>
      {credentialsFile && <p>Selected File: {credentialsFile.name}</p>}
    </div>
  );
};

const AzureFields = ({
  azureEndpoint,
  setAzureEndpoint,
  azureApiVersion,
  setAzureApiVersion,
  azureDeploymentName,
  setAzureDeploymentName,
  apiKey,
  setApiKey,
}) => {
  const handleEndpointChange = (e) => {
    const value = e.target.value;
    setAzureEndpoint(value);

    // Try to extract Azure details from full URL if pasted
    try {
      if (value.includes('/openai/deployments/')) {
        const url = new URL(value);
        // Extract base endpoint
        setAzureEndpoint(`${url.protocol}//${url.hostname}`);

        // Extract deployment name from path
        const pathParts = url.pathname.split('/');
        const deploymentIndex = pathParts.indexOf('deployments');
        if (deploymentIndex !== -1 && deploymentIndex + 1 < pathParts.length) {
          setAzureDeploymentName(pathParts[deploymentIndex + 1]);
        }

        // Extract API version from query parameters
        const apiVersion = url.searchParams.get('api-version');
        if (apiVersion) {
          setAzureApiVersion(apiVersion);
        }
      }
    } catch (error) {
      // If URL parsing fails, just keep the value as is
      console.error('Failed to parse Azure URL:', error);
    }
  };

  return (
    <>
      <TextField
        label="API Key"
        name="apiKey"
        value={apiKey}
        onChange={(e) => setApiKey(e.target.value)}
        fullWidth
        className="w-full bg-white border-2 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
      />
      <TextField
        label="Azure Endpoint"
        name="azureEndpoint"
        value={azureEndpoint}
        onChange={handleEndpointChange}
        fullWidth
        className="w-full bg-white border-2 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
        helperText="You can paste a full Azure OpenAI URL to auto-fill all fields"
      />
      <TextField
        label="API Version"
        name="azureApiVersion"
        value={azureApiVersion}
        onChange={(e) => setAzureApiVersion(e.target.value)}
        fullWidth
        className="w-full bg-white border-2 rounded p-3 text-gray-900"
      />
      <TextField
        label="Deployment Name"
        name="azureDeploymentName"
        value={azureDeploymentName}
        onChange={(e) => setAzureDeploymentName(e.target.value)}
        fullWidth
        className="w-full bg-white border-2 rounded p-3 text-gray-900"
      />
    </>
  );
};

export default function RunScanModal({
  isModalOpen,
  setIsModalOpen,
  setScans,
  tenantId,
  username,
  accessToken,
  publicTenantId,
  isActive,
  onRequestAccess
}) {
  const formRef = useRef(null);
  const cancelButtonRef = useRef(null);
  const [llmType, setLlmType] = useState("ai_policy");
  const [aiPolicyType, setAiPolicyType] = useState("");
  const [configs, setConfigs] = useState([]);
  const [llmEnvName, setLlmEnvName] = useState("");
  const [credentialsFile, setCredentialsFile] = useState(null);
  const [llmData, setLlmData] = useState({
    llmName: "",
    llmModel: "",
    apiKey: "",
    generations: "",
    aiPolicyType: "",
  });
  const [isProbesModalOpen, setIsProbesModalOpen] = useState(false);
  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState(false);
  const [isGenerateTrustScoreModalOpen, setIsGenerateTrustScoreModalOpen] = useState(false);
  const [probes, setProbes] = useState([]);
  const [probeType, setProbeType] = useState("owasp");
  const [isCreatePolicyModalOpen, setIsCreatePolicyModalOpen] = useState(false);
  const [error, setError] = useState("");
  const [isDefault, setIsDefault] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");

  // Azure-specific state variables
  const [azureEndpoint, setAzureEndpoint] = useState("");
  const [azureApiVersion, setAzureApiVersion] = useState("");
  const [azureDeploymentName, setAzureDeploymentName] = useState("");

  const fetchConfigs = async () => {
    try {
      const response = await client.get("/v1/rails/configs");
      setConfigs(response.data);
    } catch (error) {
      console.error("Error fetching configs:", error);
    }
  };

  useEffect(() => {
    fetchConfigs();
  }, []);

  useEffect(() => {
    setError("");
  }, [llmType]);

  const handleInputChange = (event) => {
    setLlmData({ ...llmData, [event.target.name]: event.target.value });
  };

  const handleTypeChange = (event) => {
    setLlmType(event.target.value);
    setError("");
  };

  const handleAiPolicyChange = (event) => {
    setAiPolicyType(event.target.value);
    setLlmData({ ...llmData, aiPolicyType: event.target.value });
    setError("");
  };

  const handleLlmModelChange = (event) => {
    const selectedLlmModel = event.target.value;
    setLlmData({
      ...llmData,
      llmModel: selectedLlmModel,
      llmName: "", // Reset llmName when model changes
      apiKey: "",   // Reset API Key when model changes
    });
    // Set the environment variable name for the API key
    setLlmEnvName(LLM_TYPES[selectedLlmModel]?.keyName?.toUpperCase() || "");
    setError("");
    
    // Reset credentials file if changing from VertexAI
    if (selectedLlmModel !== 'vertexai') {
      setCredentialsFile(null);
    }

    // Reset Azure fields if changing from Azure
    if (selectedLlmModel !== 'azure') {
      setAzureEndpoint("");
      setAzureApiVersion("");
      setAzureDeploymentName("");
    }
  };

  const handleSliderChange = (event, newValue) => {
    if (newValue <= 10) {
      setLlmData({ ...llmData, generations: newValue });
      setError("");
    }
  };

  const handleDefaultChange = (event) => {
    setIsDefault(event.target.checked);
    if (event.target.checked) {
      setLlmData({ ...llmData, generations: "" });
    }
    setError("");
  };

  const validateFields = () => {
    if (llmType === "ai_policy" && !aiPolicyType)
      return "Please select AI Policy Type";

    if (llmType === "public") {
      if (!llmData.llmModel) return "Please select a Model";
      if (!llmData.llmName) return "Please enter LLM Name";

      // Remove azure from the exclusion list since it now requires an API key
      if (!llmData.apiKey && !['bedrock', 'vertexai'].includes(llmData.llmModel))
        return "Please enter API Key";

      if (llmData.llmModel === 'vertexai' && !credentialsFile) 
        return "Please upload Google Credentials JSON file";

      if (llmData.llmModel === 'azure') {
        if (!llmData.apiKey) return "Please enter API Key";
        if (!azureEndpoint) return "Please enter Azure Endpoint";
        if (!azureApiVersion) return "Please enter Azure API Version";
        if (!azureDeploymentName) return "Please enter Azure Deployment Name";
      }
    }

    if (llmType === "private") {
      if (!llmData.url) return "Please enter Endpoint URL";
      if (!llmData.header_template) return "Please enter Endpoint Header";
      if (!llmData.payload_template) return "Please enter Endpoint Data";
    }

    return "";
  };

  const handleNext = () => {
    const errorMessage = validateFields();
    if (errorMessage) {
      setError(errorMessage);
      return;
    }
    setError("");
    setIsModalOpen(false);
    setIsProbesModalOpen(true);
  };

  const handlePolicyCreationSuccess = async (newPolicyName) => {
    await fetchConfigs();
    setAiPolicyType(newPolicyName);
    setSuccessMessage(
      `Policy '${newPolicyName}' created successfully! You can now start scanning using your new policy.`
    );
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setError("");
  };
  
  const handleGenerateTrustScore = () => {
    if (llmType !== 'public') {
      // Show a warning if the selected LLM type is not public
      setError(
        'Generate Trust Score is only available for Public LLM. Please select Public LLM to proceed.'
      );
      return;
    }
  
    const errorMessage = validateFields();
    if (errorMessage) {
      setError(errorMessage);
      return;
    }
  
    setError('');
    setIsModalOpen(false);
    setIsGenerateTrustScoreModalOpen(true);
  };

  return (
    <>
      <Transition.Root show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={handleCloseModal}>
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden flex justify-center items-center ml-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-y-[-100%]"
                enterTo="translate-y-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-y-0"
                leaveTo="translate-y-[-100%]"
              >
                <Dialog.Panel
                  className={`pointer-events-auto w-screen max-w-6xl h-full ${
                    llmType === "ai_policy"
                      ? "max-h-[65vh]"
                      : llmType === "private"
                      ? "max-h-[94vh]"
                      : "max-h-[80vh]"
                  } flex flex-col bg-white shadow-xl rounded-lg overflow-y-auto`}
                >
                  <div className="px-4 pt-6 sm:px-6 bg-white rounded-t-lg">
                    <div className="flex items-start justify-between">
                      <Dialog.Title
                        className="text-xl font-semibold"
                        style={{ color: theme.tmryk_background_color }}
                      >
                        Run LLM Scan
                      </Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-white hover:bg-[#031A58] focus:outline-none focus:ring-2"
                          onClick={handleCloseModal}
                        >
                          <span className="sr-only">Close panel</span>
                          <Close className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative flex-1 px-4 py-6 sm:px-6 overflow-y-auto">
                    {isCreatePolicyModalOpen ? (
                      <CreatePolicyScanModal
                        setIsCreatePolicyModalOpen={setIsCreatePolicyModalOpen}
                        setAiPolicyType={setAiPolicyType}
                        onSuccess={handlePolicyCreationSuccess}
                      />
                    ) : (
                      <form ref={formRef} className="space-y-6">
                        {successMessage && (
                          <Alert severity="success" className="mt-4">
                            {successMessage}
                          </Alert>
                        )}
                        <FormControl component="fieldset">
                          <FormLabel
                            component="legend"
                            style={{ color: "Black" }}
                          >
                            Select LLM Type
                          </FormLabel>
                          <RadioGroup
                            aria-label="llmType"
                            name="llmType"
                            value={llmType}
                            onChange={handleTypeChange}
                            row
                          >
                            <FormControlLabel
                              value="ai_policy"
                              control={
                                <Radio
                                  sx={{
                                    "&.Mui-checked": {
                                      color: theme.tmryk_background_color,
                                    },
                                  }}
                                />
                              }
                              label="AI Policy"
                            />
                            <FormControlLabel
                              value="public"
                              control={
                                <Radio
                                  sx={{
                                    "&.Mui-checked": {
                                      color: theme.tmryk_background_color,
                                    },
                                  }}
                                />
                              }
                              label="Public LLM"
                            />
                            <FormControlLabel
                              value="private"
                              control={
                                <Radio
                                  sx={{
                                    "&.Mui-checked": {
                                      color: theme.tmryk_background_color,
                                    },
                                  }}
                                />
                              }
                              label="Private Endpoint"
                            />
                          </RadioGroup>
                        </FormControl>

                        <div className="space-y-4">
                          {llmType === "ai_policy" && (
                            <div className="flex items-center space-x-4">
                              <FormControl fullWidth>
                                <InputLabel className="text-gray-700">
                                  Select AI Policy Type
                                </InputLabel>
                                <Select
                                  name="aiPolicyType"
                                  label="Select AI Policy Type"
                                  value={aiPolicyType}
                                  onChange={handleAiPolicyChange}
                                  className="w-full bg-white rounded text-gray-900 focus:outline-none focus:ring-green-500"
                                >
                                  {configs.map((config, index) => (
                                    <MenuItem key={index} value={config.id}>
                                      {config.id}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setIsCreatePolicyModalOpen(true)}
                                style={{
                                  backgroundColor: theme.tmryk_background_color,
                                  color: "white",
                                  width: "180px",
                                  height: "48px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  textTransform: "none",
                                  fontWeight: "bold",
                                  boxShadow:
                                    "0 3px 5px 2px rgba(84, 130, 78, .3)",
                                }}
                              >
                                Create AI Policy
                              </Button>
                            </div>
                          )}

                          {llmType === "public" && (
                            <div className="space-y-4">
                              <FormControl fullWidth>
                                <InputLabel className="text-gray-700">
                                  Select Model
                                </InputLabel>
                                <Select
                                  name="llmModel"
                                  label="Select Model"
                                  value={llmData.llmModel}
                                  onChange={handleLlmModelChange}
                                  className="w-full bg-white rounded text-gray-900 focus:outline-none focus:ring-green-500"
                                >
                                  {Object.keys(LLM_TYPES).map((key) => (
                                    <MenuItem key={key} value={key}>
                                      {key}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>

                              {llmData.llmModel && (
                                <FormControl fullWidth>
                                  <InputLabel className="text-gray-700">
                                    Select LLM
                                  </InputLabel>
                                  <Select
                                    name="llmName"
                                    label="Select LLM"
                                    value={llmData.llmName}
                                    onChange={handleInputChange}
                                    className="w-full bg-white rounded text-gray-900 focus:outline-none focus:ring-green-500"
                                  >
                                    {LLM_TYPES[llmData.llmModel].models.map(
                                      (model, index) => (
                                        <MenuItem key={index} value={model}>
                                          {model}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                              )}

                              {llmData.llmModel === 'azure' ? (
                                <AzureFields 
                                  azureEndpoint={azureEndpoint}
                                  setAzureEndpoint={setAzureEndpoint}
                                  azureApiVersion={azureApiVersion}
                                  setAzureApiVersion={setAzureApiVersion}
                                  azureDeploymentName={azureDeploymentName}
                                  setAzureDeploymentName={setAzureDeploymentName}
                                  apiKey={llmData.apiKey}
                                  setApiKey={(value) => setLlmData({ ...llmData, apiKey: value })}
                                />
                              ) : (
                                llmData.llmModel === 'vertexai' ? (
                                  <CredentialUpload 
                                    credentialsFile={credentialsFile} 
                                    setCredentialsFile={setCredentialsFile} 
                                  />
                                ) : (
                                  llmData.llmModel && LLM_TYPES[llmData.llmModel].requiredToAddKey && (
                                    <TextField
                                      label="API Key"
                                      name="apiKey"
                                      value={llmData.apiKey}
                                      onChange={handleInputChange}
                                      fullWidth
                                      className={`w-full bg-white border-2 border-green-700 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500`}
                                    />
                                  )
                                )
                              )}
                            </div>
                          )}

                          {llmType === "private" && (
                            <div className="space-y-4">
                              <Typography variant="body2" color="textSecondary">
                                Please enter your Private Endpoint URL here
                              </Typography>
                              <TextField
                                label="Endpoint URL"
                                name="url"
                                value={llmData.url}
                                onChange={handleInputChange}
                                fullWidth
                                className="w-full bg-white border-2 border-green-700 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
                              />
                              <Typography variant="body2" color="textSecondary">
                                Enter your endpoint's header, Example:{" "}
                                {
                                  '{ "Authorization": "Bearer YOUR_TOKEN", "Content-Type": "application/json" }'
                                }
                              </Typography>
                              <TextField
                                label="Endpoint Header"
                                name="header_template"
                                value={llmData.header_template}
                                onChange={handleInputChange}
                                fullWidth
                                className="w-full bg-white border-2 border-green-700 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
                              />
                              <Typography variant="body2" color="textSecondary">
                                For your payload template, replace where you
                                inject user prompts with exactly this text
                                ("prompt").
                                <br />
                                Example:{" "}
                                {
                                  '{"messages": [{"role": "user","content": "prompt"}]}'
                                }
                              </Typography>
                              <TextField
                                label="Endpoint Data"
                                name="payload_template"
                                value={llmData.payload_template}
                                onChange={handleInputChange}
                                fullWidth
                                className="w-full bg-white border-2 border-green-700 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
                              />
                            </div>
                          )}

                          <div className="flex flex-col space-y-2">
                            <Typography variant="subtitle1">
                              Iterations
                            </Typography>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isDefault}
                                  onChange={handleDefaultChange}
                                  style={{
                                    color: theme.tmryk_background_color,
                                  }}
                                />
                              }
                              label={
                                <Typography variant="body2">Default</Typography>
                              }
                            />
                            <Typography variant="body2" color="textSecondary">
                              For the most optimal results, you can select
                              Default
                            </Typography>
                            <Box sx={{ width: 1060, ml: 2 }}>
                              <Slider
                                value={isDefault ? 1 : llmData.generations}
                                onChange={handleSliderChange}
                                min={1}
                                max={50}
                                marks={[
                                  { value: 1, label: "1" },
                                  { value: 5, label: "5" },
                                  { value: 10, label: "10" },
                                  { value: 50, label: "50" },
                                ]}
                                step={1}
                                valueLabelDisplay="auto"
                                valueLabelFormat={(value) => value}
                                disabled={isDefault}
                                sx={{
                                  color: theme.tmryk_background_color,
                                  "& .MuiSlider-thumb": {
                                    backgroundColor:
                                      theme.tmryk_background_color,
                                  },
                                  "& .MuiSlider-track": {
                                    backgroundColor:
                                      theme.tmryk_background_color,
                                  },
                                  "& .MuiSlider-mark": {
                                    backgroundColor: "white",
                                  },
                                }}
                              />
                              {llmData.generations > 10 && (
                                <Tooltip title="To increase the limit beyond 10 iterations, a payment is required.">
                                  <div>
                                    <Slider
                                      value={50}
                                      min={10}
                                      max={50}
                                      marks={[
                                        { value: 10, label: "10" },
                                        { value: 50, label: "50" },
                                      ]}
                                      valueLabelDisplay="auto"
                                      disabled
                                      sx={{
                                        color: theme.tmryk_background_color,
                                        "& .MuiSlider-thumb": {
                                          backgroundColor:
                                            theme.tmryk_background_color,
                                        },
                                        "& .MuiSlider-track": {
                                          backgroundColor:
                                            theme.tmryk_background_color,
                                        },
                                        "& .MuiSlider-mark": {
                                          backgroundColor: "white",
                                        },
                                      }}
                                    />
                                  </div>
                                </Tooltip>
                              )}
                            </Box>
                            <Typography variant="body2" color="textSecondary">
                              Select the number of Iterations
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              Note: To increase the limit beyond 10 iterations,
                              a payment is required.
                            </Typography>
                          </div>
                        </div>
                        {error && (
                          <Alert severity="error" className="mt-4">
                            {error}
                          </Alert>
                        )}
                        <div className="flex justify-between items-center p-6 bg-white rounded-b-lg">
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: theme.tmryk_background_color,
                              color: 'white',
                              width: '200px',
                              height: '48px',
                              textTransform: 'none',
                              fontWeight: 'bold',
                            }}
                            onClick={handleGenerateTrustScore}
                          >
                            Generate Trust Score
                          </Button>
                          <div className="flex space-x-3">
                            <Button
                              variant="outlined"
                              onClick={handleCloseModal}
                              ref={cancelButtonRef}
                              startIcon={<ArrowBack />}
                              style={{
                                borderColor: theme.tmryk_background_color,
                                color: theme.tmryk_background_color,
                                width: "150px",
                                height: "48px",
                                textTransform: "none",
                                fontWeight: "bold",
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: theme.tmryk_background_color,
                                color: "white",
                                width: "150px",
                                height: "48px",
                                textTransform: "none",
                                fontWeight: "bold",
                              }}
                              endIcon={<ArrowForward />}
                              onClick={handleNext}
                            >
                              Next
                            </Button>
                          </div>
                        </div>
                      </form>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <RunScanProbesModal
        isProbesModalOpen={isProbesModalOpen}
        setIsProbesModalOpen={setIsProbesModalOpen}
        setProbes={setProbes}
        setProbeType={setProbeType}
        setIsSummaryModalOpen={setIsSummaryModalOpen}
        setIsModalOpen={setIsModalOpen}
        aiPolicyType={aiPolicyType}
        llmType={llmType}
        llmData={llmData}
        credentialsFile={credentialsFile}
        azureEndpoint={azureEndpoint}
        azureApiVersion={azureApiVersion}
        azureDeploymentName={azureDeploymentName}
      />
      
      <RunScanSummaryModal
        isSummaryModalOpen={isSummaryModalOpen}
        setIsSummaryModalOpen={setIsSummaryModalOpen}
        llmData={llmData}
        llmType={llmType}
        probes={probes}
        probeType={probeType}
        tenantId={tenantId}
        username={username}
        accessToken={accessToken}
        setScans={setScans}
        setIsProbesModalOpen={setIsProbesModalOpen}
        setIsModalOpen={setIsModalOpen}
        llmEnvName={llmEnvName}
        publicTenantId={publicTenantId}
        credentialsFile={credentialsFile}
        azureEndpoint={azureEndpoint}
        azureApiVersion={azureApiVersion}
        azureDeploymentName={azureDeploymentName}
        isActive={isActive} 
        onRequestAccess={onRequestAccess} 
      />

      <GenerateTrustScoreModal
        isOpen={isGenerateTrustScoreModalOpen}
        setIsOpen={setIsGenerateTrustScoreModalOpen}
        setIsModalOpen={setIsModalOpen}
        llmType={llmType}
        llmData={llmData}
        tenantId={tenantId}
        username={username}
        accessToken={accessToken}
        setScans={setScans}
        llmEnvName={llmEnvName}
        publicTenantId={publicTenantId}
        credentialsFile={credentialsFile}
        azureEndpoint={azureEndpoint}
        azureApiVersion={azureApiVersion}
        azureDeploymentName={azureDeploymentName}
        isActive={isActive}
        onRequestAccess={onRequestAccess}
      />
    </>
  );
}
