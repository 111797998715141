import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Box, Tabs, Tab, TextField, IconButton, Popover } from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import theme from '../theme';
import ScanDashboardModal from '../components/modals/dashboard/ScanDashboardModal';
import ViolationsDashboardModal from '../components/modals/dashboard/ViolationsDashboardModal';
import UsageDashboardModal from '../components/modals/dashboard/UsageDashboardModal';

const DashboardPage = ({ isActive }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 365);
    return thirtyDaysAgo;
  });
  const [endDate, setEndDate] = useState(new Date());
  
  const [anchorEl, setAnchorEl] = useState(null); // State for the filter popover

  // Define tabs based on isActive
  const tabs = isActive
    ? ['Trust Scores', 'Alerts', 'Usage Metrics']
    : ['Trust Scores']; 

  useEffect(() => {
    if (selectedTab >= tabs.length) {
      setSelectedTab(0);
    }
  }, [tabs, selectedTab]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <AppBar position="static" style={{ backgroundColor: theme.tmryk_background_color }}>
          <Toolbar>
            <Typography variant="h6" style={{ color: 'white', marginRight: '20px' }}>
              Dashboard
            </Typography>

            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              textColor="inherit"
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{
                style: {
                  backgroundColor: 'white',
                },
              }}
              sx={{
                '& .MuiTab-root': {
                  color: 'white',
                },
                '& .Mui-selected': {
                  borderBottom: '2px solid white',
                },
              }}
            >
              {tabs.map((label, index) => (
                <Tab key={index} label={label} />
              ))}
            </Tabs>

            <Box sx={{ flexGrow: 1 }} />

            {/* Filter icon */}
            <IconButton
              color="inherit"
              aria-describedby={id}
              onClick={handleFilterClick}
              sx={{ color: 'white' }}
            >
              <FilterList />
            </IconButton>

            {/* Popover for date filtering */}
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleFilterClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
                {/* Start Date */}
                <DesktopDatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  maxDate={endDate}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth />
                  )}
                />

                {/* End Date */}
                <DesktopDatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  minDate={startDate}
                  maxDate={new Date()}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth />
                  )}
                />
              </Box>
            </Popover>
          </Toolbar>
        </AppBar>

        <Box sx={{ padding: 2, backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
          {/* Render content based on selectedTab */}
          {tabs[selectedTab] === 'Trust Scores' && (
            <ScanDashboardModal startDate={startDate} endDate={endDate} />
          )}
          {tabs[selectedTab] === 'Alerts' && isActive && (
            <ViolationsDashboardModal startDate={startDate} endDate={endDate} />
          )}
          {tabs[selectedTab] === 'Usage Metrics' && (
            <UsageDashboardModal startDate={startDate} endDate={endDate} />
          )}
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default DashboardPage;
