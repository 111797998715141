import React from 'react';
import theme from '../theme';  // Make sure to adjust the import path if necessary

function AboutPage() {
    return (
        <div className="flex justify-center items-center h-full bg-gray-50">
            <div className="max-w-4xl p-5 rounded-lg shadow-lg bg-white text-center">
                <div className="text-2xl font-semibold mb-4" style={{ color: theme.tmryk_background_color}}>
                    Tumeryk AI Firewall v1.1
                </div>
                <h1 className="text-sm font-semibold mb-6">
                    Acknowledgement
                </h1>
                <div className="bg-gray-100 p-4 rounded">
                    <div className="p-3 bg-white rounded shadow overflow-x-auto">
                        <div className="text-left mb-4">
                            <h2 className="text-lg font-semibold mb-2">NeMo Guardrails</h2>
                            <table className="table-auto w-full text-xs font-mono mx-auto mb-6">
                                <tbody>
                                    <tr>
                                        <td className="px-2 py-1 font-bold">Licence</td>
                                        <td className="px-2 py-1">rebedea-etal-2023-nemo</td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 py-1 font-bold">Title</td>
                                        <td className="px-2 py-1">
                                            NeMo Guardrails: A Toolkit for Controllable and Safe LLM
                                            Applications with Programmable Rails
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 py-1 font-bold">Authors</td>
                                        <td className="px-2 py-1">
                                            Rebedea, Traian; Dinu, Razvan; Sreedhar, Makesh Narsimhan;
                                            Parisien, Christopher; Cohen, Jonathan
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 py-1 font-bold">Editors</td>
                                        <td className="px-2 py-1">Feng, Yansong; Lefever, Els</td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 py-1 font-bold">Conference</td>
                                        <td className="px-2 py-1">
                                            Proceedings of the 2023 Conference on Empirical Methods in
                                            Natural Language Processing: System Demonstrations
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 py-1 font-bold">Month and Year</td>
                                        <td className="px-2 py-1">December 2023</td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 py-1 font-bold">Location</td>
                                        <td className="px-2 py-1">Singapore</td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 py-1 font-bold">Publisher</td>
                                        <td className="px-2 py-1">
                                            Association for Computational Linguistics
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 py-1 font-bold">URL</td>
                                        <td className="px-2 py-1">
                                            <a
                                                href="https://aclanthology.org/2023.emnlp-demo.40"
                                                className="text-blue-500 hover:text-blue-700"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                https://aclanthology.org/2023.emnlp-demo.40
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 py-1 font-bold">DOI</td>
                                        <td className="px-2 py-1">
                                            10.18653/v1/2023.emnlp-demo.40
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 py-1 font-bold">Pages</td>
                                        <td className="px-2 py-1">431–445</td>
                                    </tr>
                                </tbody>
                            </table>
                            <h2 className="text-lg font-semibold mb-2">Garak</h2>
                            <table className="table-auto w-full text-xs font-mono mx-auto">
                                <tbody>
                                    <tr>
                                        <td className="px-2 py-1 font-bold">Title</td>
                                        <td className="px-2 py-1">
                                            garak: A Framework for Large Language Model Red Teaming
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 py-1 font-bold">Authors</td>
                                        <td className="px-2 py-1">
                                            Leon Derczynski, Erick Galinkin, Subho Majumdar
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 py-1 font-bold">Year</td>
                                        <td className="px-2 py-1">2024</td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 py-1 font-bold">URL</td>
                                        <td className="px-2 py-1">
                                            <a
                                                href="https://garak.ai"
                                                className="text-blue-500 hover:text-blue-700"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                https://garak.ai
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutPage;
