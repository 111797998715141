import React, { useEffect, useState, useRef } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Paper, Typography, Box, TablePagination, IconButton, Menu, MenuItem, CircularProgress, Tooltip } from '@mui/material';
import { Sort as SortIcon } from '@mui/icons-material';
import client from '../../../client';
import theme from '../../../theme';

const ViolationsDashboardModal = ({ startDate, endDate }) => {
  const [violationsData, setViolationsData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true); 
  const cellRefs = useRef([]); 
  const textRefs = useRef([]); 
  const [overflowing, setOverflowing] = useState([]); 

  useEffect(() => {
    fetchViolationsData();
  }, [startDate, endDate, sortOrder]);

  useEffect(() => {
    checkOverflow();
  }, [violationsData, page, rowsPerPage]);

  const fetchViolationsData = () => {
    setLoading(true); 
    const params = {
      start_date: startDate.toISOString().split('T')[0],
      end_date: endDate.toISOString().split('T')[0],
      sort_order: sortOrder,
    };

    client.get('/violations_table', { params })
      .then(response => {
        setViolationsData(response.data);
        setLoading(false); 
      })
      .catch(error => {
        console.error('Error fetching violations data:', error);
        setViolationsData([]);
        setLoading(false); 
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortClick = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleSortClose = () => {
    setSortAnchorEl(null);
  };

  const handleSortChange = (newOrder) => {
    setSortOrder(newOrder);
    setSortAnchorEl(null);
  };

  const checkOverflow = () => {
    const results = textRefs.current.map((ref, index) => {
      const cellWidth = cellRefs.current[index]?.clientWidth || 0;
      return ref && ref.scrollWidth > cellWidth;
    });
    setOverflowing(results);
  };

  return (
    <Paper style={{ padding: 16, boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', borderRadius: 8, overflowX: 'auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: theme.tmryk_background_color, padding: 1, borderRadius: '8px 8px 0 0' }}>
        <Typography variant="h6" style={{ color: 'white', marginLeft: '16px' }}>
          Violations
        </Typography>
        <div>
          <IconButton style={{ color: 'white', marginRight: '16px' }} onClick={handleSortClick}>
            <SortIcon />
          </IconButton>
          <Menu anchorEl={sortAnchorEl} open={Boolean(sortAnchorEl)} onClose={handleSortClose}>
            <MenuItem onClick={() => handleSortChange('desc')}>Newest to Oldest</MenuItem>
            <MenuItem onClick={() => handleSortChange('asc')}>Oldest to Newest</MenuItem>
          </Menu>
        </div>
      </Box>
      <Box sx={{ position: 'relative', minHeight: '200px' }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
            <CircularProgress />
          </Box>
        ) : violationsData.length === 0 ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
            <Typography variant="body1" color="textSecondary">
              No violation data available for the selected period.
            </Typography>
          </Box>
        ) : (
          <>
            <Table style={{ width: '100%', tableLayout: 'auto' }}>
              <TableHead style={{ backgroundColor: 'gray' }}>
                <TableRow style={{ height: '42px', backgroundColor: '#E0E0E0' }}>
                  <TableCell style={{ color: 'black', padding: '6px 20px', borderBottom: 'none' }}>Violation ID</TableCell>
                  <TableCell style={{ color: 'black', padding: '6px 20px', borderBottom: 'none' }}>User Name</TableCell>
                  <TableCell style={{ color: 'black', padding: '6px 20px', borderBottom: 'none' }}>Config Name</TableCell>
                  <TableCell style={{ color: 'black', padding: '6px 20px', borderBottom: 'none' }}>Date</TableCell>
                  <TableCell style={{ color: 'black', padding: '6px 20px', borderBottom: 'none' }}>Violating Message</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {violationsData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((violation, index) => (
                    <TableRow key={index} style={{ height: '54px', backgroundColor: "white" }}>
                      <TableCell style={{ color: theme.tmryk_black_text_color, padding: '6px 20px', borderBottom: 'none' }}>{violation.id}</TableCell>
                      <TableCell style={{ color: theme.tmryk_black_text_color, padding: '6px 20px', borderBottom: 'none' }}>{violation.username}</TableCell>
                      <TableCell style={{ color: theme.tmryk_black_text_color, padding: '6px 20px', borderBottom: 'none' }}>{violation.config_name}</TableCell>
                      <TableCell style={{ color: theme.tmryk_black_text_color, padding: '6px 20px', borderBottom: 'none' }}>{violation.date}</TableCell>
                      <TableCell style={{ color: theme.tmryk_black_text_color, padding: '6px 20px', borderBottom: 'none', maxWidth: '200px' }}>
                        <Tooltip
                          title={violation.violating_message}
                          arrow
                          placement="bottom-start"
                          disableHoverListener={!overflowing[index]}
                        >
                          <span
                            ref={(el) => textRefs.current[index] = el}
                            style={{ display: 'inline-block', width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                          >
                            {violation.violating_message}
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={violationsData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton
              showLastButton
            />
          </>
        )}
      </Box>
    </Paper>
  );
};

export default ViolationsDashboardModal;
