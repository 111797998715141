import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  TextField,
  CircularProgress,
  IconButton,
  Divider,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Tooltip,
} from "@mui/material";
import {
  Add as AddIcon,
  Close as CloseIcon,
  ArrowForward as ArrowForwardIcon,
  ArrowBack as ArrowBackIcon,
  AutoAwesome as AutoAwesomeIcon,
  PlaylistAddCheck as PlaylistAddCheckIcon,
  PlaylistRemove as PlaylistRemoveIcon,
  Message as MessageIcon,
  Star as StarIcon,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";
import * as yamlParser from "js-yaml";

const TopicGuardConfigModal = ({
  isOpen,
  onClose,
  configId,
  currentConfig = {
    relevant_topics: [],
    disallowed_topics: [],
    system_prompt: "",
    off_topic_message: "",
    primary_topics: [],
  },
  onSave,
  client,
}) => {
  const theme = useTheme();
  const dialogRef = useRef(null);
  const modalContentRef = useRef(null);
  const [config, setConfig] = useState({
    relevant_topics: [],
    disallowed_topics: [],
    system_prompt: "",
    off_topic_message: "",
    primary_topics: [],
    score_threshold: 500,
  });
  const [topicInput, setTopicInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isGeneratingPrompt, setIsGeneratingPrompt] = useState(false);

  useEffect(() => {
    // Initialize config from currentConfig
    if (currentConfig) {
      setConfig({
        relevant_topics: currentConfig.relevant_topics || [],
        disallowed_topics: currentConfig.disallowed_topics || [],
        system_prompt: currentConfig.system_prompt || "",
        off_topic_message: currentConfig.off_topic_message || "",
        primary_topics: currentConfig.primary_topics || [],
        score_threshold: currentConfig.score_threshold || 500,
      });
    }

    // Load topics from files
    loadConfigFiles();
  }, [currentConfig, configId]);

  // Prevent unexpected modal closing
  useEffect(() => {
    const handleClickInside = (event) => {
      // Prevent modal from closing when clicking inside
      if (modalContentRef.current && modalContentRef.current.contains(event.target)) {
        event.stopPropagation();
      }
    };

    // Add event listener when modal is open
    if (isOpen) {
      document.addEventListener('mousedown', handleClickInside);
    }
    
    // Clean up
    return () => {
      document.removeEventListener('mousedown', handleClickInside);
    };
  }, [isOpen]);

  const loadConfigFiles = async () => {
    if (!configId) return;

    setLoading(true);
    try {
      // Check if config files exist for this config
      const response = await client.get(`/read_config?config_name=${configId}`);
      
      // Load from consolidated topic_guard.yml file
      if (response.data && response.data["topic_guard.yml"]) {
        try {
          const yamlContent = response.data["topic_guard.yml"];
          const parsed = yamlParser.load(yamlContent);
          
          if (parsed) {
            setConfig({
              relevant_topics: parsed.relevant_topics || [],
              disallowed_topics: parsed.disallowed_topics || [],
              system_prompt: parsed.system_prompt || "",
              off_topic_message: parsed.off_topic_message || "",
              primary_topics: parsed.primary_topics || [],
              score_threshold: parsed.score_threshold || 500,
            });
          }
        } catch (err) {
          console.error("Error parsing topic_guard.yml:", err);
        }
      }

      // Also check thresholds.yml for the score threshold
      if (response.data && response.data["thresholds.yml"]) {
        try {
          const thresholdsYaml = yamlParser.load(response.data["thresholds.yml"]);
          if (thresholdsYaml && 
              thresholdsYaml.topic_guard_threshold && 
              thresholdsYaml.topic_guard_threshold.threshold) {
            setConfig(prev => ({
              ...prev,
              score_threshold: thresholdsYaml.topic_guard_threshold.threshold
            }));
          }
        } catch (err) {
          console.error("Error parsing thresholds.yml:", err);
        }
      }
    } catch (error) {
      console.error("Error loading config files:", error);
      toast.error("Failed to load configuration files");
    } finally {
      setLoading(false);
    }
  };

  const handleTopicInputChange = (event) => {
    setTopicInput(event.target.value);
  };

  const handleSystemPromptChange = (event) => {
    setConfig({
      ...config,
      system_prompt: event.target.value,
    });
  };
  
  const handleOffTopicMessageChange = (event) => {
    setConfig({
      ...config,
      off_topic_message: event.target.value,
    });
  };

  const handleAddTopic = () => {
    if (!topicInput.trim()) return;

    // Check for duplicates in both lists
    if (
      config.relevant_topics.includes(topicInput.trim()) ||
      config.disallowed_topics.includes(topicInput.trim())
    ) {
      toast.warn("This topic already exists");
      return;
    }

    // Add to allowed topics by default
    setConfig({
      ...config,
      relevant_topics: [...config.relevant_topics, topicInput.trim()],
    });
    setTopicInput("");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleAddTopic();
    }
  };

  const handleRemoveTopic = (topic, list) => {
    if (list === "allowed") {
      setConfig({
        ...config,
        relevant_topics: config.relevant_topics.filter((t) => t !== topic),
        // Also remove from primary_topics to prevent orphaned primary topics
        primary_topics: config.primary_topics.filter((t) => t !== topic),
      });
    } else {
      setConfig({
        ...config,
        disallowed_topics: config.disallowed_topics.filter((t) => t !== topic),
      });
    }
  };

  const handleMoveToAllowed = (topic) => {
    if (config.relevant_topics.includes(topic)) return;
    
    setConfig({
      ...config,
      relevant_topics: [...config.relevant_topics, topic],
      disallowed_topics: config.disallowed_topics.filter((t) => t !== topic),
    });
  };

  const handleMoveToDisallowed = (topic) => {
    if (config.disallowed_topics.includes(topic)) return;
    
    setConfig({
      ...config,
      disallowed_topics: [...config.disallowed_topics, topic],
      relevant_topics: config.relevant_topics.filter((t) => t !== topic),
      // Also remove from primary_topics to prevent orphaned primary topics
      primary_topics: config.primary_topics.filter((t) => t !== topic),
    });
  };

  const handleClose = () => {
    // Close the modal and call the parent's onClose
    onClose();
  };

  const togglePrimaryTopic = (topic) => {
    if (config.primary_topics.includes(topic)) {
      // Remove from primary topics
      setConfig({
        ...config,
        primary_topics: config.primary_topics.filter(t => t !== topic)
      });
    } else {
      // Add to primary topics
      setConfig({
        ...config,
        primary_topics: [...config.primary_topics, topic]
      });
    }
  };

  const generateSystemPrompt = () => {
    setIsGeneratingPrompt(true);
    
    try {
      if (config.relevant_topics.length === 0 && config.disallowed_topics.length === 0) {
        toast.warn("Please add at least one topic before generating a system prompt");
        setIsGeneratingPrompt(false);
        return;
      }
      
      // Divide topics into primary and non-primary
      const primaryTopics = config.relevant_topics.filter(topic => 
        config.primary_topics.includes(topic)
      );
      
      const secondaryTopics = config.relevant_topics.filter(topic => 
        !config.primary_topics.includes(topic)
      );
      
      // Use a hard-coded template for Human Resources context
      const hrTemplate = `You are TopicGuard, a classifier that determines if user queries are on-topic.

## Permissible Topics
${primaryTopics.length > 0 ? 
  `### Primary Topics (Most Important):\n${primaryTopics.map(topic => `- ${topic}`).join('\n')}` : ''}

${secondaryTopics.length > 0 ? 
  `### Other Allowed Topics:\n${secondaryTopics.map(topic => `- ${topic}`).join('\n')}` : ''}

## Disallowed Topics and Content
Do not engage in discussions about the following topics:
${config.disallowed_topics.map(topic => `- ${topic}`).join('\n')}

## Evaluation Guidelines
You must determine if the user's message is on-topic based on the permissible topics listed above.
Primary topics should be given higher priority when determining relevance.
Any content related to disallowed topics should be automatically classified as off-topic.

Reply only with the classification "on-topic" or "off-topic".`;
      
      // Set the generated prompt
      setConfig(prev => ({
        ...prev,
        system_prompt: hrTemplate
      }));
      
      // Also generate default off-topic message if empty
      if (!config.off_topic_message) {
        const defaultOffTopicMessage = `I'm sorry, but your question appears to be off-topic for this conversation. I'm designed to help with ${primaryTopics.length > 0 ? primaryTopics.join(', ') : 'specific topics'} according to my guidelines. Could you please ask a question related to the allowed topics?`;
        
        setConfig(prev => ({
          ...prev,
          off_topic_message: defaultOffTopicMessage
        }));
      }
      
      toast.success("System prompt generated successfully!");
    } catch (error) {
      console.error("Error generating system prompt:", error);
      toast.error("Failed to generate system prompt");
    } finally {
      setIsGeneratingPrompt(false);
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      // Ensure we have a default off-topic message if empty
      let offTopicMessage = config.off_topic_message;
      if (!offTopicMessage) {
        const primaryTopics = config.relevant_topics.filter(topic => 
          config.primary_topics.includes(topic)
        );
        
        const secondaryTopics = config.relevant_topics.filter(topic => 
          !config.primary_topics.includes(topic)
        );
        
        offTopicMessage = `I'm unable to respond to this request as it appears to be outside the scope of topics I can assist with. 

I'm specifically designed to help with ${primaryTopics.join(', ')}${secondaryTopics.length > 0 ? ' and ' + secondaryTopics.join(', ') : ''}.

Please feel free to ask questions related to these areas.`;
      }

      // Format the YAML with special handling for multi-line strings
      const yamlData = {
        relevant_topics: config.relevant_topics,
        disallowed_topics: config.disallowed_topics,
        primary_topics: config.primary_topics,
        system_prompt: config.system_prompt,
        off_topic_message: offTopicMessage,
        score_threshold: config.score_threshold || 500,
        nemoguardrails: {
          off_topic_response_enabled: true
        }
      };
      
      // Use js-yaml to format the YAML properly with block scalar style for multi-line strings
      const yamlOptions = {
        lineWidth: -1, // Prevent line wrapping
        noRefs: true,  // Don't use references 
        noCompatMode: true
      };
      
      // Prepare the YAML content
      let yamlContent = yamlParser.dump(yamlData, yamlOptions);
      
      // Save configuration to topic_guard.yml file
      await client.post("/edit_config", yamlContent, {
        params: { config_name: configId, file_name: 'topic_guard.yml' }
      });
      console.log("Saved topic_guard.yml file");
      
      // Create the messages.co file with the NemoGuardRails format
      const messagesCoContent = `
define bot refuse off topic
  "${offTopicMessage.replace(/"/g, '\\"')}"
`;
      
      // Save the messages.co file
      await client.post("/edit_config", messagesCoContent, {
        params: { config_name: configId, file_name: 'messages.co' }
      });
      console.log("Saved messages.co file with off-topic message");

      // Update thresholds.yml with the topic guard score threshold
      try {
        // First fetch the current thresholds.yml content
        const thresholdsResponse = await client.get(`/read_config?config_name=${configId}`);
        let thresholdsContent = thresholdsResponse.data["thresholds.yml"] || "";
        let thresholdsData = {};
        
        try {
          // Try to parse existing thresholds
          thresholdsData = yamlParser.load(thresholdsContent) || {};
        } catch (err) {
          console.error("Error parsing thresholds.yml:", err);
          // If parsing fails, create a new object
          thresholdsData = {};
        }
        
        // Update the topic guard threshold
        thresholdsData.topic_guard_threshold = {
          active: true,
          threshold: config.score_threshold || 500
        };
        
        // Save updated thresholds
        const updatedThresholdsContent = yamlParser.dump(thresholdsData);
        await client.post("/edit_config", updatedThresholdsContent, {
          params: { config_name: configId, file_name: 'thresholds.yml' }
        });
        console.log("Updated thresholds.yml with topic guard threshold");
      } catch (thresholdErr) {
        console.error("Error updating thresholds.yml:", thresholdErr);
        // Continue with the save process even if threshold update fails
      }

      // Then update the config in the parent component
      await onSave({
        ...config,
        relevant_topics: config.relevant_topics,
        disallowed_topics: config.disallowed_topics,
        off_topic_message: offTopicMessage,
        primary_topics: config.primary_topics,
        score_threshold: config.score_threshold || 500
      });
      
      onClose();
    } catch (error) {
      console.error("Error saving Topic Guard config:", error);
      toast.error("Failed to update Topic Guard configuration");
    } finally {
      setIsSaving(false);
    }
  };

  const topicCardSx = {
    height: "320px",
    display: "flex",
    flexDirection: "column",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "8px",
    overflow: "hidden",
  };

  const topicCardContentSx = {
    flex: 1,
    overflowY: "auto",
    padding: "12px",
    backgroundColor: "#f9f9f9",
  };

  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => {
        // Prevent closing when clicking backdrop or pressing escape
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          return;
        }
        handleClose();
      }}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "8px",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        },
        ref: dialogRef
      }}
      disableEscapeKeyDown={false} // We handle this in onClose
      aria-labelledby="topic-guard-config-dialog-title"
    >
      <DialogTitle
        id="topic-guard-config-dialog-title"
        sx={{
          borderBottom: `1px solid ${theme.palette.divider}`,
          bgcolor: theme.tmryk_background_color,
          color: "white",
        }}
      >
        <Typography variant="h6" component="div" sx={{ color: "black" }}>
          Configure Topic Guard
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: "black" }}
        >
          Manage allowed and disallowed topics to ensure conversations stay on-topic according to your policy guidelines. Settings are saved in a single configuration file.
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'black',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent ref={modalContentRef} sx={{ p: 3 }}>

        {/* Topic Input */}
        <Box sx={{ mb: 3 }}>

          <Box sx={{ display: "flex", my: 2 }}>
            <TextField
              label="Enter a topic"
              variant="outlined"
              fullWidth
              value={topicInput}
              onChange={handleTopicInputChange}
              onKeyPress={handleKeyPress}
              placeholder="E.g., Customer Support, Technical Documentation, etc."
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={handleAddTopic}
                    color="primary"
                    disabled={!topicInput.trim()}
                    sx={{ color: theme.tmryk_background_color }}
                  >
                    <AddIcon />
                  </IconButton>
                ),
              }}
            />
            <Button
              variant="contained"
              onClick={handleAddTopic}
              disabled={!topicInput.trim()}
              sx={{
                ml: 1,
                backgroundColor: theme.tmryk_background_color,
                "&:hover": { backgroundColor: "#172E6C" },
              }}
            >
              Add
            </Button>
          </Box>
        </Box>

        {/* Topics Two Column Layout */}
        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Grid item xs={12} md={5}>
            <Card sx={{
              ...topicCardSx,
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              border: '1px solid #e0e0e0'
            }}>
              <CardHeader
                title={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <PlaylistAddCheckIcon
                      sx={{ color: "green", mr: 1 }}
                      fontSize="small"
                    />
                    <Typography variant="subtitle1" fontWeight="bold">
                      Allowed Topics ({config.relevant_topics.length})
                    </Typography>
                  </Box>
                }
                sx={{
                  backgroundColor: "#e8f5e9",
                  p: 1.5,
                  "& .MuiCardHeader-content": { overflow: "hidden" },
                }}
              />
              <CardContent sx={{
                ...topicCardContentSx,
                backgroundColor: '#fafafa'
              }}>
                {loading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <CircularProgress
                      size={30}
                      sx={{ color: theme.tmryk_background_color }}
                    />
                  </Box>
                ) : config.relevant_topics.length === 0 ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      color: "text.secondary",
                      fontStyle: "italic",
                    }}
                  >
                    <Typography variant="body2">
                      No allowed topics defined yet
                    </Typography>
                  </Box>
                ) : (
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    {config.relevant_topics.map((topic, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          p: 1,
                          borderRadius: 1,
                          backgroundColor: config.primary_topics.includes(topic) ? "#e3f2fd" : "white",
                          boxShadow: "0px 1px 2px rgba(0,0,0,0.05)",
                          "&:hover": {
                            backgroundColor: config.primary_topics.includes(topic) ? "#bbdefb" : "#f5f5f5",
                          },
                          borderLeft: config.primary_topics.includes(topic) ? "4px solid #2196f3" : "none",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            flexGrow: 1,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontWeight: config.primary_topics.includes(topic) ? "bold" : "normal",
                          }}
                        >
                          {topic}
                        </Typography>
                        <Box>
                          <Tooltip title={config.primary_topics.includes(topic) ? "Remove from primary topics" : "Mark as primary topic"}>
                            <IconButton
                              size="small"
                              onClick={() => togglePrimaryTopic(topic)}
                              sx={{ color: config.primary_topics.includes(topic) ? "#1976d2" : "grey" }}
                            >
                              <StarIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Move to disallowed">
                            <IconButton
                              size="small"
                              onClick={() => handleMoveToDisallowed(topic)}
                              sx={{ color: "red" }}
                            >
                              <ArrowForwardIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Remove">
                            <IconButton
                              size="small"
                              onClick={() => handleRemoveTopic(topic, "allowed")}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} md={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2, alignItems: "center" }}>
              <Typography variant="body2" sx={{ fontWeight: "medium", color: "text.secondary" }}>
                Move Topics
              </Typography>
              <ArrowForwardIcon fontSize="large" sx={{ color: theme.tmryk_background_color }} />
              <ArrowBackIcon fontSize="large" sx={{ color: theme.tmryk_background_color }} />
            </Box>
          </Grid>
          
          <Grid item xs={12} md={5}>
            <Card sx={{
              ...topicCardSx,
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              border: '1px solid #e0e0e0'
            }}>
              <CardHeader
                title={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <PlaylistRemoveIcon
                      sx={{ color: "red", mr: 1 }}
                      fontSize="small"
                    />
                    <Typography variant="subtitle1" fontWeight="bold">
                      Disallowed Topics ({config.disallowed_topics.length})
                    </Typography>
                  </Box>
                }
                sx={{
                  backgroundColor: "#ffebee",
                  p: 1.5,
                  "& .MuiCardHeader-content": { overflow: "hidden" },
                }}
              />
              <CardContent sx={{
                ...topicCardContentSx,
                backgroundColor: '#fafafa'
              }}>
                {loading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <CircularProgress
                      size={30}
                      sx={{ color: theme.tmryk_background_color }}
                    />
                  </Box>
                ) : config.disallowed_topics.length === 0 ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      color: "text.secondary",
                      fontStyle: "italic",
                    }}
                  >
                    <Typography variant="body2">
                      No disallowed topics defined yet
                    </Typography>
                  </Box>
                ) : (
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    {config.disallowed_topics.map((topic, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          p: 1,
                          borderRadius: 1,
                          backgroundColor: "white",
                          boxShadow: "0px 1px 2px rgba(0,0,0,0.05)",
                          "&:hover": {
                            backgroundColor: "#f5f5f5",
                          },
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            flexGrow: 1,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {topic}
                        </Typography>
                        <Box>
                          <Tooltip title="Move to allowed">
                            <IconButton
                              size="small"
                              onClick={() => handleMoveToAllowed(topic)}
                              sx={{ color: "green" }}
                            >
                              <ArrowBackIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Remove">
                            <IconButton
                              size="small"
                              onClick={() => handleRemoveTopic(topic, "disallowed")}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Divider sx={{ my: 3 }} />

        {/* System Prompt Section with Generate Button */}
        <Box sx={{ my: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 2 }}>
            <Typography
              variant="subtitle1"
              sx={{ color: theme.tmryk_black_text_color, fontWeight: 500 }}
            >
              System Prompt
            </Typography>

            <Button
              variant="contained"
              startIcon={<AutoAwesomeIcon />}
              onClick={generateSystemPrompt}
              disabled={isGeneratingPrompt || (config.relevant_topics.length === 0 && config.disallowed_topics.length === 0)}
              sx={{
                backgroundColor: theme.tmryk_background_color,
                "&:hover": { backgroundColor: "#172E6C" },
              }}
            >
              {isGeneratingPrompt ? (
                <>
                  <CircularProgress size={20} sx={{ mr: 1, color: "white" }} />
                  Generating...
                </>
              ) : (
                "Generate Template"
              )}
            </Button>
          </Box>

          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={6}
            value={config.system_prompt}
            onChange={handleSystemPromptChange}
            placeholder="Enter system prompt to guide the model's behavior for topic relevance checks"
            sx={{ mb: 2 }}
          />

          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            The system prompt provides context to TopicGuard about how to evaluate topic relevance. It defines the policy guidelines for determining if content is on-topic or off-topic.
          </Typography>
        </Box>
        
        <Divider sx={{ my: 3 }} />
        
        {/* Off-Topic Message Section - Updated for NemoGuardRails */}
        <Box sx={{ my: 2 }}>
          <Typography
            variant="subtitle1"
            sx={{ mb: 2, color: theme.tmryk_black_text_color, fontWeight: 500, display: 'flex', alignItems: 'center' }}
          >
            <MessageIcon sx={{ mr: 1, color: 'error.main' }} />
            Message if Off-Topic
          </Typography>
          
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            value={config.off_topic_message}
            onChange={handleOffTopicMessageChange}
            placeholder='Enter message to display when content is off-topic. Will be formatted as "define bot refuse off topic" in messages.co'
            sx={{ mb: 2 }}
          />
          
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            This message will be saved to a <code>messages.co</code> file in the format <code>define bot refuse off topic</code> followed by your message. This follows NemoGuardRails standard format for handling off-topic responses.
          </Typography>
        </Box>
        
        <Divider sx={{ my: 3 }} />
        
        {/* Topic Guard Score Section */}
        <Box sx={{ my: 2 }}>
          <Typography
            variant="subtitle1"
            sx={{ mb: 2, color: theme.tmryk_black_text_color, fontWeight: 500, display: 'flex', alignItems: 'center' }}
          >
            <PlaylistAddCheckIcon sx={{ mr: 1, color: 'primary.main' }} />
            Topic Guard Score Threshold
          </Typography>
          
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <TextField
              variant="outlined"
              type="number"
              label="Score Threshold"
              value={config.score_threshold || 500}
              onChange={(e) => setConfig({
                ...config,
                score_threshold: parseInt(e.target.value, 10)
              })}
              InputProps={{
                inputProps: {
                  min: 0,
                  max: 1000,
                  step: 50
                }
              }}
              sx={{ width: '200px' }}
            />
            <Typography variant="body2" color="text.secondary">
              Value between 0-1000
            </Typography>
          </Box>
          
          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            Set the confidence threshold for topic classification. Higher values require more confidence before a topic is considered relevant. This threshold will be saved to <code>thresholds.yml</code>.
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions sx={{ borderTop: `1px solid ${theme.palette.divider}` }}>
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          disabled={isSaving}
          startIcon={isSaving && <CircularProgress size={20} color="inherit" />}
          sx={{
            backgroundColor: theme.tmryk_background_color,
            "&:hover": { backgroundColor: "#172E6C" },
            color: "white",
          }}
        >
          Save Configuration
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TopicGuardConfigModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  configId: PropTypes.string.isRequired,
  currentConfig: PropTypes.shape({
    relevant_topics: PropTypes.arrayOf(PropTypes.string),
    disallowed_topics: PropTypes.arrayOf(PropTypes.string),
    system_prompt: PropTypes.string,
    off_topic_message: PropTypes.string,
    primary_topics: PropTypes.arrayOf(PropTypes.string),
    score_threshold: PropTypes.number,
  }),
  onSave: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
};

export default TopicGuardConfigModal; 