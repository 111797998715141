import React, { useState, useEffect, useRef } from 'react';
import tmrykPurpleLogo from '../../assets/images/tmryk-logo-purple.png';
import { Link } from 'react-router-dom';
import client from '../../client';
import theme from '../../theme';

function NavBarComponent() {
  const [user, setUser] = useState({ username: '', email: '', tenantName: '' });
  const [tokenUsage, setTokenUsage] = useState({ monthlyUsage: 0, monthlyQuota: 0 });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const fetchUserInfo = () => {
    client.get('/user_info')
      .then(response => {
        const userData = response.data;
        userData.username = userData.username.charAt(0).toUpperCase() + userData.username.slice(1);
        setUser(userData);
      })
      .catch(error => console.error('Error fetching user info:', error));
  };

  useEffect(() => {
    fetchUserInfo();

    // Fetch token usage data
    client.get('/monthly_quota_usage')
      .then(response => {
        const { monthly_usage, monthly_quota } = response.data;
        setTokenUsage({
          monthlyUsage: monthly_usage,
          monthlyQuota: monthly_quota,
        });
      })
      .catch(error => console.error('Error fetching token usage:', error));

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleMenuClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await client.post("/tmryk-logout");
      window.location.href = "/";
    } catch (error) {
      window.location.href = "/";
    }
  };

  const formattedMonthlyUsage = tokenUsage.monthlyUsage.toLocaleString();
  const formattedMonthlyQuota = tokenUsage.monthlyQuota.toLocaleString();
  const isQuotaExceeded = tokenUsage.monthlyUsage >= tokenUsage.monthlyQuota;

  return (
    <div className="fixed top-0 w-full h-20 bg-white border-b border-gray-200 flex items-center justify-between px-6 z-30">
      <div className="flex items-center">
        <Link to="/about">
          <img src={tmrykPurpleLogo} alt="tmryk logo" className="w-[165px] h-[50px] mr-10" />
        </Link>
        <span className="text-xl font-semibold" style={{ color: theme.tmryk_background_color}}>AI Security Studio</span>
      </div>
      <div className="relative text-lg font-semibold" ref={dropdownRef}>
        <button
          onClick={handleMenuClick}
          type="button"
          className={`flex items-center text-md bg-[${theme.tmryk_background_color}] rounded-full ${dropdownOpen ? `focus:ring-4 focus:ring-[${theme.tmryk_background_color}]` : ''}`}
        >
          <span className="sr-only">Open user menu</span>
          <span className="text-white px-4">{user.username}</span>
        </button>
        {dropdownOpen && (
          <div
            className="z-50 absolute right-0 mt-2 w-48 text-base list-none bg-white divide-y divide-gray-100 rounded shadow"
            id="dropdown-user"
          >
            <div className="px-4 py-3" role="none">
              <p className={`text-sm text-[${theme.tmryk_black_text_color}]`} role="none">
                {user.username}
              </p>
              <p className={`text-sm font-medium text-[${theme.tmryk_black_text_color}] truncate`} role="none">
                {user.email}
              </p>
              <p className={`text-sm font-medium text-[${theme.tmryk_black_text_color}] truncate`} role="none">
                {user.tenantName}
              </p>
              <div className="mt-2">
                <p className={`text-xs font-medium ${isQuotaExceeded ? 'text-red-700' : `text-[${theme.tmryk_black_text_color}]`}`} role="none">
                  Token Credits
                </p>
                <p className={`text-xs ${isQuotaExceeded ? 'text-red-700' : `text-[${theme.tmryk_black_text_color}]`}`} role="none">
                  Token Usage: {formattedMonthlyUsage}
                </p>
                <p className={`text-xs ${isQuotaExceeded ? 'text-red-700' : `text-[${theme.tmryk_black_text_color}]`}`} role="none">
                  Max Limit: {formattedMonthlyQuota}
                </p>
              </div>
            </div>
            <ul className="py-1" role="none">
              <li>
                <Link to="/user_settings" className={`block px-4 py-2 text-sm text-[${theme.tmryk_black_text_color}] hover:bg-gray-100`} role="menuitem">
                  User Settings
                </Link>
              </li>
              <li>
                <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-sm text-red-700 hover:bg-gray-100" role="menuitem">
                  Logout
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default NavBarComponent;
