import React, { useEffect, useState } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { PieChart, LineChart } from '@mui/x-charts';
import client from '../../../client';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { BarChart } from '@mui/x-charts/BarChart';

const ScanDashboardModal = ({ startDate, endDate }) => {
  const [realTimeTrustScoreData, setRealTimeTrustScoreData] = useState(null);
  const [lineChartData, setLineChartData] = useState([]);
  const [loadingDynamicScoreConfigs, setLoadingDynamicScoreConfigs] = useState(true);
  const [dynamicScoreConfigs, setDynamicScoreConfigs] = useState([]);
  const [selectedAiPolicy, setSelectedAiPolicy] = useState('');

  // Alias category scores data from the new endpoint
  const [aliasCategoryScoresData, setAliasCategoryScoresData] = useState([]);
  const [loadingAliasCategoryScoresData, setLoadingAliasCategoryScoresData] = useState(true);

  // Old alias scores data - if still needed
  const [aliasScoresData, setAliasScoresData] = useState([]);
  const [loadingAliasScoresData, setLoadingAliasScoresData] = useState(true);

  // Single state variable for model selection
  const [selectedModel, setSelectedModel] = useState('');

  const categories = [
    { label: 'Excellent', minScore: 800, maxScore: 1000, color: '#448448' },
    { label: 'Good', minScore: 600, maxScore: 800, color: '#50C878' },
    { label: 'Fair', minScore: 400, maxScore: 600, color: '#ffdd33' },
    { label: 'Poor', minScore: 200, maxScore: 400, color: '#FFBF00' },
    { label: 'Very Poor', minScore: 0, maxScore: 200, color: '#ff4637' },
  ];

  useEffect(() => {
    fetchDynamicScoreConfigs();
    fetchAliasScoresData(); // If still needed for other charts
    fetchAliasCategoryScoresData();
  }, []);

  const fetchDynamicScoreConfigs = async () => {
    try {
      const response = await client.get('/api/dynamic_scores/configs');
      setDynamicScoreConfigs(response.data);
      setLoadingDynamicScoreConfigs(false);
    } catch (error) {
      console.error('Error fetching dynamic score configs:', error);
      setLoadingDynamicScoreConfigs(false);
    }
  };

  // Fetch alias scores data (if needed for other charts)
  const fetchAliasScoresData = async () => {
    try {
      const response = await client.get('/api/alias_scores');
      setAliasScoresData(response.data);
      setLoadingAliasScoresData(false);
    } catch (error) {
      console.error('Error fetching alias scores data:', error);
      setLoadingAliasScoresData(false);
    }
  };

  // Fetch the new alias category scores data
  const fetchAliasCategoryScoresData = async () => {
    try {
      const response = await client.get('/api/alias_category_scores');
      setAliasCategoryScoresData(response.data);
      setLoadingAliasCategoryScoresData(false);
    } catch (error) {
      console.error('Error fetching alias category scores data:', error);
      setLoadingAliasCategoryScoresData(false);
    }
  };

  useEffect(() => {
    fetchRealTimeTrustScoreData();
    fetchTrendData();
  }, [startDate, endDate, selectedAiPolicy]);

  // Auto-select the first model on page load when aliasCategoryScoresData is loaded
  useEffect(() => {
    if (aliasCategoryScoresData.length > 0 && !selectedModel) {
      const models = aliasCategoryScoresData.map((item) => item.alias);
      if (models.length > 0) {
        setSelectedModel(models[0]);
      }
    }
  }, [aliasCategoryScoresData, selectedModel]);

  const fetchRealTimeTrustScoreData = () => {
    client
      .get('/real_time_trust_scores', {
        params: {
          start_date: startDate.toISOString().split('T')[0],
          end_date: endDate.toISOString().split('T')[0],
          ai_policy: selectedAiPolicy || undefined,
        },
      })
      .then((response) => {
        const data = response.data;
        if (
          data &&
          (data.average_trust_score != null || data.average_score != null) &&
          ['excellent', 'good', 'fair', 'poor', 'very_poor'].some(
            (key) => typeof data[key] === 'number' && data[key] > 0
          )
        ) {
          setRealTimeTrustScoreData(data);
        } else {
          setRealTimeTrustScoreData(null);
        }
      })
      .catch((error) => {
        console.error('Error fetching real-time trust score data:', error);
        setRealTimeTrustScoreData(null);
      });
  };

  const fetchTrendData = () => {
    client
      .get('/trend_data', {
        params: {
          start_date: startDate.toISOString().split('T')[0],
          end_date: endDate.toISOString().split('T')[0],
          interval: 'week',
          ai_policy: selectedAiPolicy || undefined,
        },
      })
      .then((response) => {
        const trendData = response.data.ai_trust_score.map((item) => ({
          x: item.period,
          y: item.average_score,
        }));
        setLineChartData([{ name: 'AI Trust Score', data: trendData, color: '#4caf50' }]);
      })
      .catch((error) => {
        console.error('Error fetching trend data:', error);
        setLineChartData([]);
      });
  };

  const handleAiPolicyChange = (event) => {
    setSelectedAiPolicy(event.target.value);
  };

  const handleModelChange = (event) => {
    const value = event.target.value || '';
    setSelectedModel(value);
  };

  // Function to render the Bar Chart using aliasCategoryScoresData
  const renderBarChart = () => {
    if (!selectedModel || selectedModel === '') {
      return (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">Select a Model to display data</Typography>
        </Box>
      );
    }

    // Find the selected model data from aliasCategoryScoresData
    const modelData = aliasCategoryScoresData.find((item) => item.alias === selectedModel);
    if (!modelData) {
      return (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">No data available for the selected model.</Typography>
        </Box>
      );
    }

    const categoryEntries = Object.entries(modelData.categories);
    if (categoryEntries.length === 0) {
      return (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">No categories found for this model.</Typography>
        </Box>
      );
    }

    // Prepare the bar chart data
    const barChartData = categoryEntries.map(([categoryName, score]) => {
      return { x: categoryName, y: score };
    });

    // Apply colors
    const barColors = barChartData.map((item) => {
      const categoryObj = categories.find(
        (cat) => item.y >= cat.minScore && item.y <= cat.maxScore
      );
      return categoryObj ? categoryObj.color : '#cccccc'; 
    });

    return (
      <Box sx={{ width: '100%', height: '100%', flexGrow: 1 }}>
        <BarChart
          xAxis={[
            {
              scaleType: 'band',
              data: barChartData.map((item) => item.x),
              label: 'Categories',
              labelStyle: {
                fontSize: 12,
              },
              tickLine: true,
              tickLabelStyle: {
                textAnchor: 'middle',
                fontSize: 12,
              },
            },
          ]}
          yAxis={[
            {
              scaleType: 'linear',
              min: 0,
              max: 1000, 
              label: 'Category Score',
              ticks: [0, 250, 500, 750, 1000],
            },
          ]}
          series={[
            {
              data: barChartData.map((item) => item.y),
              id: 'dynamic-bar-chart',
            },
          ]}
          tooltip={{ show: true }}
          sx={{
            width: '100%',
            height: '100%',
            marginBottom: '50px',
            [`& .MuiBarElement-root`]: barChartData.reduce((style, _, index) => {
              style[`&:nth-of-type(${index + 1})`] = { fill: barColors[index] };
              return style;
            }, {}),
            [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
              transform: 'translateX(-10px)',
            },
            [`& .${axisClasses.directionX} .${axisClasses.label}`]: {
              transform: 'translateY(5px)',
            },
          }}
        />
      </Box>
    );
  };

  const renderLineChart = () => {
    if (lineChartData.length > 0) {
      const xData = lineChartData[0].data.map((item) => item.x); // Dates
      const yData = lineChartData[0].data.map((item) => item.y); // Scores

      return (
        <Box sx={{ width: '100%', height: '100%', flexGrow: 1 }}>
          <LineChart
            xAxis={[{ data: xData, label: 'Period', scaleType: 'point' }]}
            yAxis={[
              { label: 'Trust Score', min: 0, max: 1000, ticks: [0, 250, 500, 750, 1000] },
            ]}
            series={[
              {
                data: yData,
                label: 'AI Trust Score',
                color: lineChartData[0].color,
                strokeWidth: 2,
              },
            ]}
            tooltip={{ show: true }}
            sx={{
              width: '100%',
              height: '100%',
              paddingBottom: '50px',
              [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
                transform: 'translateX(-10px)',
              },
            }}
          />
        </Box>
      );
    }
    return (
      <Box
        sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Typography variant="h6">No data available for the selected range.</Typography>
      </Box>
    );
  };

  const generateModelMenuItems = () => {
    const items = [
      <MenuItem value="" key="all-models">
        <em>Select a Model</em>
      </MenuItem>,
    ];

    // Collect models from aliasCategoryScoresData and aliasScoresData if needed
    const modelsSet = new Set();
    aliasCategoryScoresData.forEach((item) => modelsSet.add(item.alias));
    aliasScoresData.forEach((item) => modelsSet.add(item.alias));

    const models = Array.from(modelsSet);
    models.forEach((alias) => {
      items.push(
        <MenuItem key={alias} value={alias}>
          {alias}
        </MenuItem>
      );
    });

    return items;
  };

  const getAliasScoreData = () => {
    if (!selectedModel || !aliasScoresData) {
      return null;
    }

    const aliasData = aliasScoresData.find((item) => item.alias === selectedModel);
    if (aliasData) {
      return aliasData.score.score; 
    } else {
      return null;
    }
  };

  const renderModelTrustScoresChart = () => {
    if (!selectedModel || selectedModel === '') {
      return (
        <Box
          sx={{
            height: 250,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">Select a Model to display data</Typography>
        </Box>
      );
    }
  
    const modelTrustScoreAverage = getAliasScoreData();
    if (modelTrustScoreAverage == null) {
      return (
        <Box
          sx={{
            height: 250,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">No data available for the selected model.</Typography>
        </Box>
      );
    }
  
    // Determine the category for the modelTrustScoreAverage
    const categoryObj = categories.find(
      (cat) => modelTrustScoreAverage >= cat.minScore && modelTrustScoreAverage <= cat.maxScore
    );
    const color = categoryObj ? categoryObj.color : '#cccccc';
  
    // Single slice data
    const chartData = [
      { label: 'Model Score', value: 1, color: color },
    ];
  
    return (
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          height: 250,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <PieChart
          series={[
            {
              data: chartData,
              innerRadius: 50,
              outerRadius: 90,
              label: () => '',
            },
          ]}
          slotProps={{
            legend: {
              direction: 'row',
              position: { vertical: 'bottom', horizontal: 'middle' },
              padding: -3,
              itemMarkWidth: 11,
              itemMarkHeight: 11,
            },
          }}
          margin={{ top: 70, bottom: 100, left: 100, right: 100 }}
        />
        <Typography
          variant="h6"
          align="center"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -90%)',
            pointerEvents: 'none',
          }}
        >
          {modelTrustScoreAverage}
        </Typography>
      </Box>
    );
  };

  const renderRealTimePieChart = () => {
    if (!realTimeTrustScoreData) {
      return (
        <Box
          sx={{
            height: 250,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">No data available for the selected time range.</Typography>
        </Box>
      );
    }

    const averageScore =
      realTimeTrustScoreData.average_trust_score !== undefined
        ? realTimeTrustScoreData.average_trust_score
        : realTimeTrustScoreData.average_score;

    const chartData = [
      { label: 'Excellent', value: realTimeTrustScoreData.excellent, color: '#448448' },
      { label: 'Good', value: realTimeTrustScoreData.good, color: '#50C878' },
      { label: 'Fair', value: realTimeTrustScoreData.fair, color: '#ffdd33' },
      { label: 'Poor', value: realTimeTrustScoreData.poor, color: '#FFBF00' },
      { label: 'Very Poor', value: realTimeTrustScoreData.very_poor, color: '#ff4637' },
    ];

    return (
      <>
        <PieChart
          series={[
            {
              data: chartData,
              innerRadius: 50,
              outerRadius: 90,
              label: () => '',
            },
          ]}
          slotProps={{
            legend: {
              direction: 'row',
              position: { vertical: 'bottom', horizontal: 'middle' },
              padding: -3,
              itemMarkWidth: 11,
              itemMarkHeight: 11,
            },
          }}
          margin={{ top: 70, bottom: 100, left: 100, right: 100 }}
        />
        <Typography
          variant="h6"
          align="center"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -90%)',
            pointerEvents: 'none',
          }}
        >
          {averageScore}
        </Typography>
      </>
    );
  };

  return (
    <Box>
      <Grid container spacing={3} justifyContent="center">
        {/* Real Time Trust Scores */}
        <Grid item xs={12} md={6}>
          <Paper
            style={{
              padding: 16,
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              borderRadius: 8,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="h6" align="center" gutterBottom>
                Real Time Trust Scores
              </Typography>
              <FormControl variant="outlined" sx={{ minWidth: 150 }} size="small">
                <InputLabel>AI Policy</InputLabel>
                <Select
                  value={selectedAiPolicy}
                  onChange={handleAiPolicyChange}
                  label="AI Policy"
                >
                  <MenuItem value="">
                    <em>All AI Policies</em>
                  </MenuItem>
                  {loadingDynamicScoreConfigs ? (
                    <MenuItem disabled>Loading...</MenuItem>
                  ) : (
                    dynamicScoreConfigs.map((config) => (
                      <MenuItem key={config} value={config}>
                        {config}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 250,
              }}
            >
              {renderRealTimePieChart()}
            </Box>
          </Paper>
        </Grid>

        {/* Model Trust Score */}
        <Grid item xs={12} md={6}>
          <Paper
            style={{
              padding: 16,
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              borderRadius: 8,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="h6" align="center" gutterBottom>
                Model Trust Score
              </Typography>
              <FormControl variant="outlined" sx={{ minWidth: 150 }} size="small">
                <InputLabel>Model</InputLabel>
                <Select
                  value={selectedModel || ''}
                  onChange={handleModelChange}
                  label="Model"
                >
                  {loadingAliasCategoryScoresData && loadingAliasScoresData ? (
                    <MenuItem disabled>Loading...</MenuItem>
                  ) : (
                    generateModelMenuItems()
                  )}
                </Select>
              </FormControl>
            </Box>
            {renderModelTrustScoresChart()}
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3} justifyContent="center" sx={{ marginTop: 3 }}>
        {/* Trust Score Trend Over Time */}
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              padding: 2,
              height: '400px',
              display: 'flex',
              flexDirection: 'column',
              minHeight: '300px',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6" align="center" gutterBottom>
                Trust Score Trend Over Time
              </Typography>
              <FormControl variant="outlined" sx={{ minWidth: 150 }} size="small">
                <InputLabel>AI Policy</InputLabel>
                <Select
                  value={selectedAiPolicy}
                  onChange={handleAiPolicyChange}
                  label="AI Policy"
                >
                  <MenuItem value="">
                    <em>All AI Policies</em>
                  </MenuItem>
                  {loadingDynamicScoreConfigs ? (
                    <MenuItem disabled>Loading...</MenuItem>
                  ) : (
                    dynamicScoreConfigs.map((config) => (
                      <MenuItem key={config} value={config}>
                        {config}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ flexGrow: 1, height: '100%' }}>{renderLineChart()}</Box>
          </Paper>
        </Grid>

        {/* Featured Model Trust Score Categories */}
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              padding: 2,
              height: '400px',
              display: 'flex',
              flexDirection: 'column',
              minHeight: '300px',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6" align="center" gutterBottom>
                Featured Model Category Scores
              </Typography>
              <FormControl variant="outlined" sx={{ minWidth: 150 }} size="small">
                <InputLabel>Model</InputLabel>
                <Select
                  value={selectedModel || ''}
                  onChange={handleModelChange}
                  label="Model"
                >
                  {loadingAliasCategoryScoresData && loadingAliasScoresData ? (
                    <MenuItem disabled>Loading...</MenuItem>
                  ) : (
                    generateModelMenuItems()
                  )}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ flexGrow: 1, height: '100%' }}>{renderBarChart()}</Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ScanDashboardModal;
