import React, { useState } from 'react';

const ExpandableCodeBlock = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const codeString = Array.isArray(children) ? children.join('\n') : children;
  const codeLines = codeString.split('\n');

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="code-block">
      <pre className={`rounded-lg p-4 bg-blue-100 text-gray-900 overflow-x-auto`}>
        <code>
          {isExpanded
            ? codeString
            : (
              <>
                {codeLines.slice(0, 3).join('\n')}
                {codeLines.length > 3 && (
                  <span
                    className="text-blue-500 cursor-pointer hover:underline"
                    onClick={toggleExpansion}
                  >
                    {'\nShow More'}
                  </span>
                )}
              </>
            )}
        </code>
      </pre>
      {codeLines.length > 3 && (
        <button
          className="mt-2 text-blue-500 hover:underline"
          onClick={toggleExpansion}
        >
          {isExpanded ? 'Show less' : ''}
        </button>
      )}
    </div>
  );
};

export default ExpandableCodeBlock;