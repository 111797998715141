import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { python } from "@codemirror/lang-python";
import { yaml } from "@codemirror/lang-yaml";
import { oneDark } from "@codemirror/theme-one-dark";
import theme from "../../theme";

const EditPolicyTab = ({
  showError,
  error,
  fileNames,
  selectedFileName,
  selectedFileContent,
  setSelectedFileContent,
  selectFile,
  saveEditedContent,
  handleCheckClick,
  isChecking,
  setIsAddFileModalOpen,
  setIsDeleteFileModalOpen,
  readOnly,
}) => {
  const getFileMode = (fileName) => {
    if (fileName.endsWith(".co")) return javascript();
    if (fileName.endsWith(".yml") || fileName.endsWith(".yaml")) return yaml();
    if (fileName.endsWith(".py")) return python();
    return javascript();
  };

  const renderFileSelector = () => {
    if (fileNames.length < 3) {
      return (
        <div style={{ display: "flex", overflowX: "auto" }}>
          {fileNames.map((fileName, index) => (
            <button
              key={index}
              onClick={() => selectFile(fileName)}
              style={{
                background: selectedFileName === fileName ? theme.tmryk_background_color : "white",
                color: selectedFileName === fileName ? "white" : "black",
                fontWeight: selectedFileName === fileName ? "bold" : "normal",
                margin: "0 5px",
                padding: "5px 5px",
                border: "1px solid gray",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              {fileName.endsWith(".co")
                ? `Colang (${fileName.replace(".co", "")})`
                : fileName.endsWith(".yml") || fileName.endsWith(".yaml")
                ? `Configuration (${fileName.replace(/\.(yml|yaml)$/, "")})`
                : fileName}
            </button>
          ))}
        </div>
      );
    } else {
      return (
        <select
          value={selectedFileName}
          onChange={(e) => selectFile(e.target.value)}
          style={{ padding: "5px 5px" }}
        >
          <option value="">Select a File</option>
          {fileNames.map((fileName, index) => (
            <option key={index} value={fileName}>
              {fileName.endsWith(".co")
                ? `Colang (${fileName.replace(".co", "")})`
                : fileName.endsWith(".yml") || fileName.endsWith(".yaml")
                ? `Configuration (${fileName.replace(/\.(yml|yaml)$/, "")})`
                : fileName}
            </option>
          ))}
        </select>
      );
    }
  };

  return (
    <Box flex={1} sx={{ overflowY: "auto", p: 2 }}>
      {showError && (
        <Box className="w-full mb-4 mt-2 border rounded-[4px]">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#5f2120" }} />}
              aria-controls="validate-error-details-header"
              id="validate-error-details-header"
              style={{
                backgroundColor: "#fdeded",
                color: "#5f2120",
                borderRadius: "4px",
              }}
            >
              <Typography>
                An error occurred during validation. Please expand to view more details.
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{
                backgroundColor: "white",
                color: "#5f2120",
                borderRadius: "4px",
              }}
            >
              {error}
            </AccordionDetails>
          </Accordion>
        </Box>
      )}

      <Box
        sx={{
          marginTop: 2,
          padding: 2,
          backgroundColor: "white",
          border: "1px solid #e0e0e0",
          borderRadius: 2,
          boxShadow: `0px 2px 4px ${theme.tmryk_box_shadow}`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 1,
          }}
        >
          <Typography variant="subtitle1" sx={{ color: theme.tmryk_black_text_color, fontWeight: "500" }}>
            Edit Selected File
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {renderFileSelector()}
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsAddFileModalOpen(true)}
              sx={{
                backgroundColor: theme.tmryk_background_color,
                "&:hover": { backgroundColor: "#172E6C" },
                color: "white",
              }}
            >
              Add File
            </Button>
          </Box>
        </Box>

        <CodeMirror
          value={selectedFileContent}
          height="300px"
          extensions={[getFileMode(selectedFileName)]}
          theme={oneDark}
          onChange={(value) => setSelectedFileContent(value)}
          readOnly={readOnly}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, marginTop: 2 }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCheckClick}
            disabled={!selectedFileName || isChecking}
            sx={{
              backgroundColor: "#f8f9fa",
              color: theme.tmryk_background_color,
              "&:hover": { backgroundColor: "#f1f3f5" },
            }}
          >
            {isChecking ? <CircularProgress size={24} color="inherit" /> : "Validate"}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={saveEditedContent}
            disabled={!selectedFileName || readOnly}
            sx={{
              backgroundColor: theme.tmryk_background_color,
              "&:hover": { backgroundColor: "#172E6C" },
              color: "white",
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => setIsDeleteFileModalOpen(true)}
            disabled={!selectedFileName || readOnly}
            sx={{
              "&:hover": { backgroundColor: "#d32f2f" },
            }}
          >
            Delete
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default EditPolicyTab; 