import React, { useReducer, useCallback, useMemo } from "react";
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  CircularProgress,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Box as MuiBox,
} from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import client from "../../../client";
import PropTypes from "prop-types";

// Constants for options and default values
const BIAS_OPTIONS = [
  "Race",
  "Color",
  "Religion",
  "Sex/gender",
  "Gender identity",
  "Sexual orientation",
  "Marital status",
  "Age",
];

const DISCRIMINATION_OPTIONS = [
  "FCRA Discrimination",
  "EEOA Discrimination",
  "Manual Input",
];

const NUM_PROMPTS = 2;
const DEFAULT_SCORE = "N/A";

// Initial state for useReducer
const initialState = {
  selectedBias: "",
  selectedDiscriminationType: "Manual Input", // Set "Manual Input" as default
  promptMessage: "",
  responses: {},
  variations: [],
  selectedVariation: "",
  score: "",
  isGenerating: false,
  isSubmitting: false,
  generatedPrompts: [],
  selectedGeneratedPrompt: null,
  originalPrompt: "",
  errorOpen: false,
  errorMessage: "",
  showTextbox: true, // Show textbox by default for "Manual Input"
};

// Reducer function to manage state transitions
function reducer(state, action) {
  switch (action.type) {
    case "SET_SELECTED_BIAS":
      return { ...state, selectedBias: action.payload };
    case "SET_SELECTED_DISCRIMINATION_TYPE":
      return {
        ...state,
        selectedDiscriminationType: action.payload,
        promptMessage: "",
        generatedPrompts: [],
        responses: {},
        score: "",
        selectedGeneratedPrompt: null,
        variations: [],
        selectedVariation: "",
        showTextbox: action.payload === "Manual Input" ? true : false, // Show textbox only for Manual Input initially
      };
    case "SET_PROMPT_MESSAGE":
      return { ...state, promptMessage: action.payload };
    case "SET_RESPONSES":
      return { ...state, responses: action.payload };
    case "SET_VARIATIONS":
      return { ...state, variations: action.payload };
    case "SET_SELECTED_VARIATION":
      return { ...state, selectedVariation: action.payload };
    case "SET_SCORE":
      return { ...state, score: action.payload };
    case "SET_IS_GENERATING":
      return { ...state, isGenerating: action.payload };
    case "SET_IS_SUBMITTING":
      return { ...state, isSubmitting: action.payload };
    case "SET_GENERATED_PROMPTS":
      return { ...state, generatedPrompts: action.payload };
    case "SET_SELECTED_GENERATED_PROMPT":
      return { ...state, selectedGeneratedPrompt: action.payload };
    case "SET_ORIGINAL_PROMPT":
      return { ...state, originalPrompt: action.payload };
    case "SET_ERROR_OPEN":
      return { ...state, errorOpen: action.payload };
    case "SET_ERROR_MESSAGE":
      return { ...state, errorMessage: action.payload };
    case "RESET_RESPONSES":
      return {
        ...state,
        responses: {},
        score: "",
        variations: [],
        selectedVariation: "",
      };
    case "SHOW_TEXTBOX":
      return { ...state, showTextbox: true };
    case "HIDE_TEXTBOX":
      return { ...state, showTextbox: false };
    default:
      return state;
  }
}

// Styled components using Material-UI's styled API
const Container = styled("div")(({ theme }) => ({
  margin: theme.spacing(2),
  width: "100%",
}));

const StyledBox = styled(MuiBox)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  position: "relative",
  boxShadow: theme.shadows[2],
}));

const Header = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: theme.spacing(1),
}));

const PromptButtonsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
}));

const StyledButton = styled(Button)(({ theme, active }) => ({
  backgroundColor: active ? "rgb(3, 26, 88)" : "rgb(3, 26, 88)",
  color: "white",
  "&:hover": {
    backgroundColor: "rgb(2, 20, 70)",
  },
  transition: "background-color 0.3s",
}));

const ResponseSelect = styled(Select)(({ theme }) => ({
  minWidth: 150,
  backgroundColor: theme.palette.background.paper,
  marginRight: theme.spacing(1),
}));

const ScoreBadge = styled(MuiBox)(({ theme }) => ({
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: theme.shadows[1],
  "& .MuiTypography-root": {
    fontSize: "1rem",
    fontWeight: 400,
  },
}));

const CustomButton = styled(Button)({
  backgroundColor: "rgb(3, 26, 88)",
  color: "white",
  "&:hover": {
    backgroundColor: "rgb(2, 20, 70)",
  },
  "&:disabled": {
    backgroundColor: "rgba(3, 26, 88, 0.5)",
    color: "rgba(255, 255, 255, 0.7)",
  },
});

const ErrorIconStyled = styled(ErrorOutline)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

// Main Component
const BiasDetectionModal = ({ config, llmData, llmType }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    selectedBias,
    selectedDiscriminationType,
    promptMessage,
    responses,
    variations,
    selectedVariation,
    score,
    isGenerating,
    isSubmitting,
    generatedPrompts,
    selectedGeneratedPrompt,
    originalPrompt,
    errorOpen,
    errorMessage,
    showTextbox,
  } = state;

  // Event Handlers with useCallback for performance optimization
  const handleBiasChange = useCallback((event) => {
    dispatch({ type: "SET_SELECTED_BIAS", payload: event.target.value });
  }, []);

  const handleDiscriminationTypeChange = useCallback((event) => {
    dispatch({
      type: "SET_SELECTED_DISCRIMINATION_TYPE",
      payload: event.target.value,
    });
  }, []);

  const handleGenerateClick = useCallback(async () => {
    if (!selectedBias || !selectedDiscriminationType) {
      dispatch({
        type: "SET_ERROR_MESSAGE",
        payload:
          "Please select both a discrimination type and a protected class before generating prompts.",
      });
      dispatch({ type: "SET_ERROR_OPEN", payload: true });
      return;
    }

    if (selectedDiscriminationType === "Manual Input") {
      dispatch({ type: "SET_GENERATED_PROMPTS", payload: [] });
      toast.info("Manual input selected, please enter your prompt manually.");
      return;
    }

    dispatch({ type: "SET_IS_GENERATING", payload: true });
    dispatch({ type: "SET_ORIGINAL_PROMPT", payload: promptMessage });
    dispatch({ type: "RESET_RESPONSES" });

    try {
      const response = await client.post(
        `/generate_discrimination_prompts?discrimination_type=${encodeURIComponent(
          selectedDiscriminationType
        )}&policy=${encodeURIComponent(
          config
        )}&bias_type=${encodeURIComponent(
          selectedBias
        )}&num_prompts=${NUM_PROMPTS}&model=${encodeURIComponent(
          llmData.llmName
        )}&model_type=${encodeURIComponent(
          llmType
        )}&engine=${encodeURIComponent(
          llmData.llmModel
        )}&api_key=${encodeURIComponent(llmData.apiKey)}`
      );

      const data = response.data;
      if (data && data.responses) {
        const generatedPromptKeys = Object.keys(data.responses);
        dispatch({ type: "SET_GENERATED_PROMPTS", payload: generatedPromptKeys });
        dispatch({ type: "SET_RESPONSES", payload: data.responses });
        dispatch({ type: "SET_SCORE", payload: data.overall_score || DEFAULT_SCORE });
        dispatch({ type: "SET_SELECTED_GENERATED_PROMPT", payload: null });
        toast.success("Prompts generated successfully!");

        // Show the textbox after generating prompts
        dispatch({ type: "SHOW_TEXTBOX" });
      } else {
        throw new Error("Invalid response from server.");
      }
    } catch (error) {
      console.error("Failed to generate prompts:", error);
      toast.error("Failed to generate prompts: " + error.message);
    } finally {
      dispatch({ type: "SET_IS_GENERATING", payload: false });
    }
  }, [
    selectedBias,
    selectedDiscriminationType,
    config,
    llmData,
    llmType,
    promptMessage,
  ]);

  const handleGeneratedPromptClick = useCallback(
    (index) => {
      if (selectedGeneratedPrompt === index) {
        dispatch({ type: "SET_SELECTED_GENERATED_PROMPT", payload: null });
        dispatch({ type: "SET_PROMPT_MESSAGE", payload: originalPrompt });
        dispatch({ type: "HIDE_TEXTBOX" });
      } else {
        dispatch({ type: "SET_SELECTED_GENERATED_PROMPT", payload: index });
        const selectedPrompt = generatedPrompts[index];
        dispatch({ type: "SET_PROMPT_MESSAGE", payload: selectedPrompt });

        const promptResponses = responses[selectedPrompt] || [];
        const variationList = promptResponses.map((entry) => entry.bias);
        dispatch({ type: "SET_VARIATIONS", payload: variationList });

        if (promptResponses.length > 0) {
          dispatch({
            type: "SET_SELECTED_VARIATION",
            payload: promptResponses[0].bias,
          });
        } else {
          dispatch({ type: "SET_SELECTED_VARIATION", payload: "" });
        }

        // Show the textbox after selecting a prompt
        dispatch({ type: "SHOW_TEXTBOX" });
      }
    },
    [selectedGeneratedPrompt, generatedPrompts, responses, originalPrompt]
  );

  const handleSubmit = useCallback(async () => {
    if (!promptMessage.trim()) {
      toast.error("Please enter a prompt.");
      return;
    }

    dispatch({ type: "SET_IS_SUBMITTING", payload: true });
    dispatch({ type: "RESET_RESPONSES" });

    try {
      const response = await client.post(
        `/generate_prompt_with_bias?prompt=${encodeURIComponent(
          promptMessage
        )}&biasType=${encodeURIComponent(
          selectedBias
        )}&policy=${encodeURIComponent(
          config
        )}&model=${encodeURIComponent(
          llmData.llmName
        )}&model_type=${encodeURIComponent(
          llmType
        )}&engine=${encodeURIComponent(
          llmData.llmModel
        )}&api_key=${encodeURIComponent(llmData.apiKey)}`
      );

      const data = response.data;
      const { responses: apiResponses, score: apiScore, variations: apiVariations } = data;

      const responsesMap = {};
      apiVariations.forEach((variation) => {
        responsesMap[variation] = apiResponses[variation] || "";
      });

      dispatch({ type: "SET_RESPONSES", payload: responsesMap });
      dispatch({ type: "SET_SCORE", payload: apiScore || DEFAULT_SCORE });
      dispatch({ type: "SET_VARIATIONS", payload: apiVariations });
      dispatch({
        type: "SET_SELECTED_VARIATION",
        payload: apiVariations[0] || "",
      });
    } catch (error) {
      console.error("Failed to submit prompt:", error);
      toast.error("Failed to submit prompt: " + error.message);
    } finally {
      dispatch({ type: "SET_IS_SUBMITTING", payload: false });
    }
  }, [promptMessage, selectedBias, config, llmData, llmType]);

  const handleErrorClose = useCallback(() => {
    dispatch({ type: "SET_ERROR_OPEN", payload: false });
  }, []);

  const handleVariationChange = useCallback((event) => {
    dispatch({ type: "SET_SELECTED_VARIATION", payload: event.target.value });
  }, []);

  const isSubmitDisabled = useMemo(() => {
    return isSubmitting || !promptMessage.trim();
  }, [isSubmitting, promptMessage]);

  return (
    <Container>
      {/* Dropdowns Section */}
      <Grid container spacing={2} alignItems="center">
        {/* Discrimination Type Selector */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="discrimination-type-select-label">
              Select Discrimination Type
            </InputLabel>
            <Select
              labelId="discrimination-type-select-label"
              id="discrimination-type-select"
              value={selectedDiscriminationType}
              onChange={handleDiscriminationTypeChange}
              label="Select Discrimination Type"
            >
              {DISCRIMINATION_OPTIONS.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Bias Selector */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="bias-select-label">Select Protected Class</InputLabel>
            <Select
              labelId="bias-select-label"
              id="bias-select"
              value={selectedBias}
              onChange={handleBiasChange}
              label="Select Protected Class"
            >
              {BIAS_OPTIONS.map((bias) => (
                <MenuItem key={bias} value={bias}>
                  {bias}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Prompt and Response Sections */}
      <Grid container spacing={2}>
        {/* Prompt Section */}
        <Grid item xs={12} md={6}>
          <StyledBox>
            <Header>
              <Typography variant="h6">Prompt</Typography>
              {/* Show Generate Prompts button only for FCRA and EEOA */}
              {(selectedDiscriminationType === "FCRA Discrimination" ||
                selectedDiscriminationType === "EEOA Discrimination") && (
                <PromptButtonsContainer>
                  {generatedPrompts.map((prompt, index) => (
                    <StyledButton
                      key={prompt}
                      variant="contained"
                      active={selectedGeneratedPrompt === index}
                      onClick={() => handleGeneratedPromptClick(index)}
                    >
                      Prompt {index + 1}
                    </StyledButton>
                  ))}
                </PromptButtonsContainer>
              )}
            </Header>

            {/* Textbox */}
            {showTextbox && (
              <TextField
                variant="outlined"
                multiline
                rows={3}
                placeholder={
                  selectedDiscriminationType === "Manual Input"
                    ? "Enter your prompt here"
                    : "The generated prompt will appear here"
                }
                value={promptMessage}
                onChange={(e) =>
                  dispatch({ type: "SET_PROMPT_MESSAGE", payload: e.target.value })
                }
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 1,
                  },
                }}
              />
            )}

            {/* Action Buttons */}
            <Grid container spacing={2} style={{ marginTop: "16px" }}>
              {/* Generate Prompts Button */}
              {(selectedDiscriminationType === "FCRA Discrimination" ||
                selectedDiscriminationType === "EEOA Discrimination") && (
                <Grid item xs={12} sm={6}>
                  <CustomButton
                    variant="contained"
                    onClick={handleGenerateClick}
                    disabled={isGenerating || !selectedBias || !selectedDiscriminationType}
                    startIcon={
                      isGenerating ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : null
                    }
                    fullWidth
                  >
                    {isGenerating ? "Generating..." : "Generate Prompts"}
                  </CustomButton>
                </Grid>
              )}

              {/* Submit Button for Manual Input */}
              {selectedDiscriminationType === "Manual Input" && (
                <Grid item xs={12} sm={6}>
                  <CustomButton
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={isSubmitDisabled}
                    startIcon={
                      isSubmitting ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : null
                    }
                    fullWidth
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </CustomButton>
                </Grid>
              )}

              {/* Submit Button for FCRA and EEOA after generating prompts */}
              {(selectedDiscriminationType === "FCRA Discrimination" ||
                selectedDiscriminationType === "EEOA Discrimination") &&
                showTextbox && (
                  <Grid item xs={12}>
                    <CustomButton
                      variant="contained"
                      onClick={handleSubmit}
                      disabled={isSubmitDisabled}
                      startIcon={
                        isSubmitting ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : null
                      }
                      fullWidth
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </CustomButton>
                  </Grid>
                )}
            </Grid>
          </StyledBox>
        </Grid>

        {/* Response Section */}
        <Grid item xs={12} md={6}>
          <StyledBox>
            <Header>
              <Typography variant="h6">Response</Typography>
              <MuiBox sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                {variations.length > 0 && (
                  <FormControl variant="outlined" size="small">
                    <InputLabel id="variation-select-label">Select Bias</InputLabel>
                    <ResponseSelect
                      labelId="variation-select-label"
                      id="variation-select"
                      value={selectedVariation}
                      onChange={handleVariationChange}
                      label="Select Bias"
                    >
                      {variations.map((variation) => (
                        <MenuItem key={variation} value={variation}>
                          {variation}
                        </MenuItem>
                      ))}
                    </ResponseSelect>
                  </FormControl>
                )}
                <ScoreBadge>
                  <Typography>{score}</Typography>
                </ScoreBadge>
              </MuiBox>
            </Header>
            <TextField
              variant="outlined"
              multiline
              rows={6}
              value={
                selectedDiscriminationType === "Manual Input"
                  ? responses[selectedVariation] || ""
                  : responses[promptMessage]?.find(
                      (entry) => entry.bias === selectedVariation
                    )?.response || ""
              }
              fullWidth
              placeholder="The generated response will appear here"
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: 1,
                },
                backgroundColor: "#fafafa",
              }}
            />
          </StyledBox>
        </Grid>
      </Grid>

      {/* Error Dialog */}
      <Dialog
        open={errorOpen}
        onClose={handleErrorClose}
        aria-labelledby="error-dialog-title"
        aria-describedby="error-dialog-description"
      >
        <DialogTitle
          id="error-dialog-title"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <ErrorOutline color="error" sx={{ marginRight: 1 }} />
          Error
        </DialogTitle>
        <DialogContent>
          <Typography id="error-dialog-description">{errorMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleErrorClose}>
            OK
          </CustomButton>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

// PropTypes for type checking
BiasDetectionModal.propTypes = {
  config: PropTypes.string.isRequired,
  llmData: PropTypes.shape({
    llmName: PropTypes.string.isRequired,
    llmModel: PropTypes.string.isRequired,
    apiKey: PropTypes.string.isRequired,
  }).isRequired,
  llmType: PropTypes.string.isRequired,
};

export default React.memo(BiasDetectionModal);
