import React from 'react';
import { useLocation } from 'react-router-dom';
import ButtonComponent from '../common/ButtonComponent';
import LogoutButtonComponent from '../common/LogoutButtonComponent';
import theme from '../../theme';

function SidebarComponent() {
    const location = useLocation();
    const selectedColor = `bg-[${theme.tmryk_background_color}] text-white`;
    const commonStyling = `hover:bg-[#031A58] hover:text-white rounded-lg p-2`;

    const isSelected = (route) => location.pathname === route;

    return (
        <div className={`h-full w-[13rem] flex flex-col border-r border-gray-200 justify-between bg-white shadow-lg text-${theme.tmryk_black_text_color} pt-5`}>
            <div className='space-y-8'>
                <ButtonComponent
                    classNames={`${commonStyling} ${isSelected('/dashboard') ? selectedColor : ''}`}
                    text="Dashboard"
                    link_to="/dashboard"
                />
                <ButtonComponent
                    classNames={`${commonStyling} ${isSelected('/configs') ? selectedColor : ''}`}
                    text="AI Policies"
                    link_to="/configs"
                />
                <ButtonComponent
                    classNames={`${commonStyling} ${isSelected('/roles') ? selectedColor : ''}`}
                    text="Roles"
                    link_to="/roles"
                />
                <ButtonComponent
                    classNames={`${commonStyling} ${isSelected('/users') ? selectedColor : ''}`}
                    text="Users"
                    link_to="/users"
                />
                <ButtonComponent
                    classNames={`${commonStyling} ${isSelected('/llm_scan') ? selectedColor : ''}`}
                    text="LLM Scan"
                    link_to="/llm_scan"
                />
            </div>
            <div className='mb-16 space-y-4'>
            <hr style={{ border: `1px solid ${theme.lineColor || '#ddd'}`, margin: '10px' }} />
                <ButtonComponent
                    classNames={`${commonStyling} ${isSelected('/guide') ? selectedColor : ''}`}
                    text="Getting Started"
                    link_to="/guide"
                />
                <ButtonComponent
                    classNames={`${commonStyling} ${isSelected('/ai-policy-catalog') ? selectedColor : ''}`}
                    text="AI Policy Catalog"
                    link_to="https://github.com/tumeryk/Tumeryk-Policies-Sample"
                    external // Optional prop to open link in new tab
                />
                <ButtonComponent
                    classNames={`${commonStyling} ${isSelected('/docs') ? selectedColor : ''}`}
                    text="Documentation"
                    link_to="/docs"
                />
                <LogoutButtonComponent
                    classNames="hover:bg-gray-400 bg-gray-200 rounded-3xl p-2 mt-4"
                    text="Logout"
                    link_to="/"
                />
            </div>
        </div>
    );
}

export default SidebarComponent;
