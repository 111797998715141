/**
 * Centralized configuration for supported LLM providers and their models
 */
export const LLM_TYPES = {
    openai: {
      keyName: 'openai_api_key',
      models: [
        'gpt-4o',
        'gpt-4o-mini',
        'gpt-4-turbo',
        'gpt-4',
        'gpt-3.5-turbo-instruct',
        'gpt-3.5-turbo'
      ],
      requiredToAddKey: true,
    },
    huggingface_hub: {
      keyName: 'huggingfacehub_api_token',
      models: [
        'meta-llama/Meta-Llama-3-8B-Instruct',
        'mistralai/Mixtral-8x22B-v0.1',
        'mistralai/Mistral-7B-v0.3',
        'google/gemma-2-9b-it',
        'Qwen/Qwen2-72B-Instruct'
      ],
      requiredToAddKey: true,
    },
    octo: {
      keyName: 'octo_api_token',
      models: [
        'meta-llama-3-8b-instruct',
        'meta-llama-3-70b-instruct',
        'mistral-7b-instruct-v0.3',
        'mixtral-8x7b-instruct'
      ],
      requiredToAddKey: true,
    },
    replicate: {
      keyName: 'replicate_api_token',
      models: [
        'meta/meta-llama-3-70b-instruct',
        'meta/llama-2-70b-chat',
        'mistralai/mistral-7b-instruct-v0.2'
      ],
      requiredToAddKey: true,
    },
    bedrock: {
      keyName: '',
      models: [
        'anthropic.claude-v2',
        'anthropic.claude-3-sonnet-20240229-v1:0',
        'anthropic.claude-3-5-sonnet-20240620-v1:0',
        'anthropic.claude-3-haiku-20240307-v1:0',
        'anthropic.claude-instant-v1',
        'us.amazon.nova-pro-v1:0',
        'us.amazon.nova-lite-v1:0',
        'us.amazon.nova-micro-v1:0',
        'meta.llama3-1-70b-instruct-v1:0'
      ],
      requiredToAddKey: false,
    },
    sambanova: {
      keyName: 'sambanova_api_key',
      models: [
        'Meta-Llama-3.1-405B-Instruct',
        'Meta-Llama-3.1-70B-Instruct',
        'Meta-Llama-3.2-1B-Instruct',
        'Meta-Llama-3.2-3B-Instruct'
      ],
      requiredToAddKey: true,
    },
    vertexai: {
      keyName: '',
      models: ['gemini-1.0-pro', 'gemini-1.5-flash', 'gemini-1.5-pro'],
      requiredToAddKey: false,
    },
    azure: {
      keyName: 'api_key',
      models: ['gpt-4o-mini', 'gpt-4o', 'gpt-4-turbo', 'gpt-4', 'gpt-3.5-turbo'],
      requiredToAddKey: true,
      requiresEndpoint: true,
    },
    nim: {
      keyName: 'api_key',
      models: [
        'nvidia/llama-3.1-nemotron-70b-instruct',
        'meta/llama-3.1-405b-instruct',
        'meta/llama-3.2-3b-instruct',
        'nvidia/nemotron-4-340b-reward',
        'meta/llama-3.1-8b-instruct',
        'nv-mistralai/mistral-nemo-12b-instruct',
        'mistralai/mixtral-8x22b-instruct-v0.1',
        'nvidia/nemotron-4-340b-instruct',
        'google/gemma-2-9b-it'
      ],
      requiredToAddKey: true,
    },
  };
  export default LLM_TYPES;
