import { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CircularProgress, Alert } from '@mui/material';
import client from '../../../client';
import theme from '../../../theme';

export default function EditRoleModal({ isModalOpen, setIsModalOpen, configs, selectedRole, onRoleUpdated }) {
    const formRef = useRef(null);
    const cancelButtonRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [roleData, setRoleData] = useState({
        role_id: '',
        role_name: '',
        config_id: ''
    });

    useEffect(() => {
        if (selectedRole) {
            setRoleData({
                role_id: selectedRole.role_id,
                role_name: selectedRole.role_name,
                config_id: selectedRole.config_id
            });
        }
    }, [selectedRole]);

    const updateRole = async () => {
        setLoading(true);
        setError(null);

        const formData = new FormData(formRef.current);
        const roleData = {
            role_id: formData.get("role_id"),
            role_name: formData.get("role_name"),
            config_id: formData.get("config_id")
        };

        if (!roleData.role_name || !roleData.config_id) {
            setError('Role name and AI policy selection are required.');
            setLoading(false);
            return;
        }

        const endpoint = `/update_role/${roleData.role_id}?new_role_name=${roleData.role_name}&new_config_id=${roleData.config_id}`;

        try {
            await client.post(endpoint, roleData);
            onRoleUpdated();
            setIsModalOpen(false);
        } catch (error) {
            setError("Error updating role. Please try again.");
            console.error("Error updating role:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Transition.Root show={isModalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={() => setIsModalOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                <div className="flex h-5/6 flex-col overflow-y-scroll bg-white shadow-xl rounded-lg"
                                    style={{ marginTop: '80px', marginRight: '15px' }}>
                                    <div className="px-4 pt-6 sm:px-6 bg-white rounded-t-lg">
                                        <div className="flex items-start justify-between">
                                            <Dialog.Title className="text-lg font-medium" style={{ color: theme.tmryk_background_color }}>Edit Role</Dialog.Title>
                                            <div className="ml-3 flex h-7 items-center">
                                                <button
                                                    type="button"
                                                    className="rounded-md bg-white text-gray-400 hover:text-white hover:bg-[#031A58] focus:outline-none focus:ring-2"
                                                    onClick={() => setIsModalOpen(false)}
                                                >
                                                    <span className="sr-only">Close panel</span>
                                                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="relative flex-1 px-4 py-6 sm:px-6">
                                        <form ref={formRef} className="space-y-6" onSubmit={(e) => e.preventDefault()}>
                                            {error && (
                                                <Alert severity="error">
                                                    {error}
                                                </Alert>
                                            )}
                                            <div className="space-y-4">
                                                <input className="w-full bg-white border-2 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-white-500" name="role_id" placeholder="Role ID" type="text" value={roleData.role_id} readOnly />
                                                <input className="w-full bg-white border-2 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-white-500" name="role_name" placeholder="Role Name" type="text" value={roleData.role_name} onChange={(e) => setRoleData({ ...roleData, role_name: e.target.value })} required />
                                                <select className="w-full bg-white border-2 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-white-500" name="config_id" value={roleData.config_id} onChange={(e) => setRoleData({ ...roleData, config_id: e.target.value })} required>
                                                    {configs.map((config) => (
                                                        <option key={config.id} value={config.id}>{config.id}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="mt-4 flex justify-end justify-center ml-2 space-x-3">
                                                <button type="submit" onClick={updateRole} className="inline-flex justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 disabled:opacity-50" disabled={loading}
                                                    style={{
                                                        backgroundColor: theme.tmryk_background_color,
                                                        color: 'white',
                                                        width: '150px',
                                                        height: '48px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        textTransform: 'none',
                                                        fontWeight: 'bold',
                                                        boxShadow: '0 3px 5px 2px rgba(84, 130, 78, .3)',
                                                    }}
                                                >
                                                    {loading ? <CircularProgress size={24} color="inherit" /> : 'Update Role'}
                                                </button>
                                                <button type="button" onClick={() => setIsModalOpen(false)} className="ml-3 inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                                    style={{
                                                        borderColor: theme.tmryk_background_color,
                                                        color: theme.tmryk_background_color,
                                                        width: '150px',
                                                        height: '48px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        textTransform: 'none',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
