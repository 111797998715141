import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Button, Alert, CircularProgress } from '@mui/material';
import { ArrowBack, Close,  } from '@mui/icons-material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import theme from '../../../theme';
import llmScanClient from '../../../llmScanClient';

export default function GenerateTrustScoreModal({
  isOpen,
  setIsOpen,
  setIsModalOpen,
  llmType,
  llmData,
  tenantId,
  username,
  accessToken,
  setScans,
  llmEnvName,
  publicTenantId,
  credentialsFile,
  azureEndpoint,
  azureApiVersion,
  azureDeploymentName,
  isActive,
  onRequestAccess,
}) {
  const cancelButtonRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleBack = () => {
    setErrorMessage('');
    setIsOpen(false);
    setIsModalOpen(true);
  };

  const handleGenerate = async () => {
    setLoading(true);
    setErrorMessage('');
    try {
      // Implement the API call to generate trust score
      // Since probes are predefined, we can set them here
    const predefinedProbes = [
      'grandma.Win11',
			'packagehallucination.Python',
			'realtoxicityprompts.RTPInsult',
			'glitch',
			'goodside.Tag',
			'goodside.Davidjl',
			'tap',
			'xss',
			'promptinject.HijackHateHumansMini',
			'promptinject.HijackKillHumansMini',
			'dan.AntiDAN',
			'dan.AutoDANCached'
    ]; 
    const probeType = 'trust_score';

      let url = '';
      let payload = {};

      // The logic here is similar to the one in RunScanSummaryModal
      if (llmType === 'ai_policy') {
        const formattedAiPolicyType = encodeURIComponent(llmData.aiPolicyType.replace(/[_/]/g, '-'));
        url = `/command_scan_guardrails?tenant_id=${tenantId}&username=${username}&model_type=AIPolicy&model_name=${formattedAiPolicyType}&probe_type=${probeType}&taxonomy=owasp`;
        if (llmData.generations) {
          url += `&generations=${llmData.generations}`;
        }
        payload = {
          "accessToken": String(accessToken),
          "configId": llmData.aiPolicyType
        };
      } else if (llmType === 'private') {
        url = `/command_scan_private?tenant_id=${tenantId}&username=${username}&probe=${predefinedProbes.join(',')}&probe_type=${probeType}`;
        if (llmData.generations) {
          url += `&generations=${llmData.generations}`;
        }

        let headerTemplate;
        let payloadTemplate;
        try {
          headerTemplate = JSON.parse(llmData.header_template);
          payloadTemplate = JSON.parse(llmData.payload_template);
        } catch (parseError) {
          setLoading(false);
          setErrorMessage('Invalid JSON format in Header or Payload Template. Please check your input.');
          return;
        }

        payload = {
          url: llmData.url,
          header_template: headerTemplate,
          payload_template: payloadTemplate
        };
      } else {
        const encodedLlmName = encodeURIComponent(llmData.llmName);
        url = `/command_scan?model=${llmData.llmModel}&name=${encodedLlmName}&probe=${predefinedProbes.join(',')}&probe_type=${probeType}&taxonomy=owasp`;
        // url = `/command_scan?model=${llmData.llmModel}&name=${encodedLlmName}&probe=${predefinedProbes.join(',')}&probe_tags=${predefineProbeTags.join(',')}&probe_type=${probeType}&taxonomy=owasp`;
        url += `&llm_env_name=${llmEnvName || ''}&llm_key=${llmData.apiKey || ''}&tenant_id=${tenantId}&username=${username}`;

        // Add Azure-specific parameters if it's an Azure model
        if (llmData.llmModel === 'azure') {
          url += `&azure_endpoint=${encodeURIComponent(azureEndpoint)}`;
          url += `&azure_api_version=${encodeURIComponent(azureApiVersion)}`;
          url += `&azure_deployment_name=${encodeURIComponent(azureDeploymentName)}`;
          url += `&llm_key=${encodeURIComponent(llmData.apiKey)}`;  // Include Azure API key
        }

        // if (llmData.generations) {
        //   url += `&generations=${llmData.generations}`;
        // }

        url += `&generations=1`;
        payload = null;
      }

      // Handle VertexAI credentials if needed
      if (llmData.llmModel === 'vertexai' && credentialsFile) {
        const formData = new FormData();
        formData.append('credentials_file', credentialsFile, 'credentials.json');
        try {
          await llmScanClient.post(`/upload_vertexai_credentials?tenant_id=${tenantId}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Accept': 'application/json'
            },
            withCredentials: true
          });
        } catch (error) {
          console.error('VertexAI credentials upload error:', error);
          setErrorMessage('Failed to upload VertexAI credentials: ' + (error.response?.data?.detail || error.message));
          setLoading(false);
          return;
        }
      }

      const response = await llmScanClient.post(url, payload);
      if (response.data.error) {
        setErrorMessage(response.data.error);
        setLoading(false);
        return;
      }
      // Update scans list
      const responseList = await llmScanClient.get(`/list_runs/${tenantId}?public_tenant_id=${publicTenantId}`);
      setScans(responseList.data.results);
      // Close all modals
      setIsOpen(false);
      setIsModalOpen(false);
      setLoading(false);
    } catch (error) {
      console.error('Error generating trust score', error);
      setErrorMessage('There was an error generating the trust score. Please try again.');
      setLoading(false);
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setIsOpen}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden flex justify-center items-center ml-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-y-[-100%]"
              enterTo="translate-y-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-y-0"
              leaveTo="translate-y-[-100%]"
            >
              <Dialog.Panel className="pointer-events-auto w-screen max-w-md h-full max-h-[30vh] flex flex-col bg-white shadow-xl rounded-lg overflow-y-auto">
                <div className="px-4 pt-6 sm:px-6 bg-white rounded-t-lg">
                  <div className="flex items-start justify-between">
                    <Dialog.Title className="text-lg font-medium" style={{ color: theme.tmryk_background_color }}>
                      Generate Trust Score
                    </Dialog.Title>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-white hover:bg-[#031A58] focus:outline-none focus:ring-2"
                        onClick={() => setIsOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <Close className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="relative flex-1 px-4 py-6 sm:px-6 overflow-y-auto">
                  {errorMessage && (
                    <Alert severity="error" className="mb-4">
                      {errorMessage}
                    </Alert>
                  )}
                  <p className="text-gray-700 text-base">
                    Generating a trust score will take approximately <strong>4 hours</strong> and will consume a significant amount of tokens to complete the scan. Do you still want to proceed?
                  </p>
                </div>
                <div className="flex justify-end space-x-3 p-6 bg-white rounded-b-lg">
                  <Button
                    variant="outlined"
                    onClick={handleBack}
                    startIcon={<ArrowBack />}
                    style={{
                      borderColor: theme.tmryk_background_color,
                      color: theme.tmryk_background_color,
                      width: '150px',
                      height: '48px',
                      textTransform: 'none',
                      fontWeight: 'bold',
                    }}
                  >
                    Back
                  </Button>
                  {isActive ? (
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: theme.tmryk_background_color,
                        color: 'white',
                        width: '150px',
                        height: '48px',
                        textTransform: 'none',
                        fontWeight: 'bold',
                      }}
                      onClick={handleGenerate}
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          Generating...
                          <CircularProgress size={20} color="inherit" style={{ marginLeft: '8px' }} />
                        </>
                      ) : (
                        'Generate'
                      )}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: theme.tmryk_background_color,
                        color: 'white',
                        width: '180px',
                        height: '48px',
                        textTransform: 'none',
                        fontWeight: 'bold',
                      }}
                      startIcon={<LockOpenIcon />}
                      onClick={onRequestAccess}
                    >
                      Request Access
                    </Button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
