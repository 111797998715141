import { Fragment, useState, useRef, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { TextField, Button, Alert, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import client from '../../../client';  // Adjust the import path as needed
import theme from '../../../theme';

export default function CreatePolicyScanModal({ setIsCreatePolicyModalOpen, setAiPolicyType, onSuccess }) {
  const [policyData, setPolicyData] = useState({
    policyName: '',
    engine: '',
    model: '',
    apiKey: ''
  });
  const [error, setError] = useState('');
  const [tenantId, setTenantId] = useState('');
  const [isSuperuser, setIsSuperuser] = useState(false);
  const [inputTenantId, setInputTenantId] = useState('');
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    client.get("/user_info")
      .then(response => {
        const data = response.data;
        setTenantId(data.tenantId);
        setIsSuperuser(data.is_superuser);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPolicyData({ ...policyData, [name]: value });
  };

  const handleTenantIdChange = (event) => {
    setInputTenantId(event.target.value);
  };

  const validateFields = () => {
    if (!policyData.policyName) return 'Please enter Policy Name';
    if (!policyData.engine) return 'Please enter Engine';
    if (!policyData.model) return 'Please enter Model';
    if (!policyData.apiKey) return 'Please enter API Key';
    if (isSuperuser && !inputTenantId) return 'Please enter Tenant ID';
    return '';
  };

  const handleCreatePolicy = async () => {
    const errorMessage = validateFields();
    if (errorMessage) {
      setError(errorMessage);
      return;
    }

    const finalTenantId = isSuperuser ? inputTenantId : tenantId;

    // Create config with YAML
    const configData = {
      tenant_id: finalTenantId, // Use tenant ID obtained from user_info API or input field
      "config.yml": `models:\n  - type: main\n    engine: ${policyData.engine}\n    model: ${policyData.model}\n\n\nstreaming: True\n\n`
    };

    const createConfigEndpoint = `/create_config_with_yaml?config_name=${policyData.policyName}`;
    try {
      const createConfigResponse = await client.post(createConfigEndpoint, configData);

      if (createConfigResponse.status === 200) {
        const setTokenEndpoint = `/llm_api_key?config_id=${policyData.policyName}&llm_api_key_name=openai_api_key&llm_api_key_value=${policyData.apiKey}`;
        const setTokenResponse = await client.post(setTokenEndpoint);

        if (setTokenResponse.status === 200) {
          setAiPolicyType(policyData.policyName);
          setIsCreatePolicyModalOpen(false);
          onSuccess(policyData.policyName);  // Call the onSuccess function with the newly created policy name
        } else {
          setError(setTokenResponse.data.message || 'Failed to set API key');
        }
      } else {
        setError(createConfigResponse.data.error || 'Failed to create config');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        setError(error.response.data.detail);
      } else {
        console.error("Error creating policy:", error);
        setError('Failed to create policy');
      }
    }
  };

  const handleBack = () => {
    setIsCreatePolicyModalOpen(false);
  };

  const modelOptions = policyData.engine === 'openai' 
    ? [
        { label: 'GPT-4o', value: 'gpt-4o' },
        { label: 'GPT-3.5 Turbo', value: 'gpt-3.5-turbo' },
        { label: 'GPT-4 Turbo', value: 'gpt-4-turbo' }
      ]
    : [];

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleBack}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden flex justify-center items-center ml-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-y-[-100%]"
              enterTo="translate-y-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-y-0"
              leaveTo="translate-y-[-100%]"
            >
              <Dialog.Panel className="pointer-events-auto w-screen max-w-6xl">
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl rounded-lg">
                  <div className="px-4 pt-6 sm:px-6 bg-white rounded-t-lg">
                    <div className="flex items-start justify-between">
                      <div className="flex items-center">
                        <svg
                          onClick={handleBack}
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 mr-2 cursor-pointer"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          style={{ color: theme.tmryk_background_color }}
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                        </svg>
                        <Dialog.Title className="text-lg font-medium" style={{ color: theme.tmryk_background_color }}>
                          Create Policy
                        </Dialog.Title>
                      </div>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-white hover:bg-[#031A58] focus:outline-none focus:ring-2"
                          onClick={handleBack}
                        >
                          <span className="sr-only">Close panel</span>
                          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>
                    </div>
                    {error && <Alert severity="error" className="mt-4">{error}</Alert>}
                  </div>
                  <div className="relative flex-1 px-4 py-6 sm:px-6">
                    <div className="space-y-4">
                      <TextField
                        label="Policy Name"
                        name="policyName"
                        value={policyData.policyName}
                        onChange={handleInputChange}
                        fullWidth
                        className="w-full bg-white border-2 border-green-700 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
                      />
                      {isSuperuser && (
                        <TextField
                          label="Tenant ID"
                          name="tenantId"
                          value={inputTenantId}
                          onChange={handleTenantIdChange}
                          fullWidth
                          className="w-full bg-white border-2 border-green-700 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
                        />
                      )}
                      <FormControl fullWidth>
                        <InputLabel className="text-gray-700">Engine</InputLabel>
                        <Select
                          name="engine"
                          label="Engine"
                          value={policyData.engine}
                          onChange={handleInputChange}
                          className="w-full bg-white rounded text-gray-900 focus:outline-none focus:ring-green-500"
                        >
                          <MenuItem value="openai">OpenAI</MenuItem>
                          {/* Add more options as needed */}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth>
                        <InputLabel className="text-gray-700">Model</InputLabel>
                        <Select
                          name="model"
                          label="Model"
                          value={policyData.model}
                          onChange={handleInputChange}
                          className="w-full bg-white rounded text-gray-900 focus:outline-none focus:ring-green-500"
                        >
                          {modelOptions.map((model) => (
                            <MenuItem key={model.value} value={model.value}>{model.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        label="API Key"
                        name="apiKey"
                        value={policyData.apiKey}
                        onChange={handleInputChange}
                        fullWidth
                        className="w-full bg-white border-2 border-green-700 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
                      />
                    </div>
                    <div className="flex justify-end space-x-3 p-6 bg-white rounded-b-lg">
                      <Button
                        variant="outlined"
                        onClick={handleBack}
                        ref={cancelButtonRef}
                        style={{
                          borderColor: theme.tmryk_background_color,
                          color: theme.tmryk_background_color,
                          width: '150px',
                          height: '48px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textTransform: 'none',
                          fontWeight: 'bold',
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: theme.tmryk_background_color,
                          color: 'white',
                          width: '150px',
                          height: '48px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textTransform: 'none',
                          fontWeight: 'bold',
                          boxShadow: '0 3px 5px 2px rgba(84, 130, 78, .3)',
                        }}
                        onClick={handleCreatePolicy}
                      >
                        Create
                      </Button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
