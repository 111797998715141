import React from 'react';
import Documentation from '../components/common/Documentation';

const DocsPage = () => {
  return (
    <div className="p-8">
      <Documentation markdownUrl="/tumeryk_docs/docs.md" />
    </div>
  );
};

export default DocsPage;
