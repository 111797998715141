// llmConfigUtilities.js

import FALLBACK_LLM_TYPES from './supportedModels';
import client from '../client';

// Generate custom configurations based on engine requirements
export const generateCustomConfig = (engine, model) => {
  if (engine === 'vertexai') {
    return {
      'config.py': `
import os
import pathlib
current = pathlib.Path(__file__).parent.resolve()
credential_path = f"{current}/google-creds.json"
os.environ['GOOGLE_APPLICATION_CREDENTIALS'] = credential_path
      `,
    };
  } else if (['bedrock', 'sambanova', 'cerebras', "deepseek"].includes(engine)) {
    return {
      'config.py': `
import custom_llms.custom_llm_class_registry_tool as custom_llm_class_registry_tool
custom_llm_class_registry_tool.register_custom_llm_provider_class("${engine}/${model}")
      `,
    };
  }
  return null;
};

// API Key management
export const manageApiKey = async (
  configId,
  apiKey,
  engine,
  isUpdate = false,
  newConfigName = null,
  modelTypes = null
) => {
  // Use provided modelTypes if available, otherwise fall back to static import
  const LLM_TYPES = modelTypes || FALLBACK_LLM_TYPES;
  
  if (!LLM_TYPES[engine]?.requiredToAddKey) {
    return null;
  };

  const keyName = LLM_TYPES[engine].keyName;

  // Conditionally build the endpoint
  const baseEndpoint = `/llm_api_key?config_id=${configId}&llm_api_key_name=${keyName}&llm_api_key_value=${apiKey}`;
  const endpoint =
    isUpdate && newConfigName
      ? `${baseEndpoint}&new_config_name=${encodeURIComponent(newConfigName)}`
      : baseEndpoint;

  // Use the appropriate request method
  const request = isUpdate ? client.put : client.post;
  return request(endpoint);
};

// Common configuration payload generator for models.yml
export const generateConfigPayload = (engine, model,features, azureParams = null) => {

  let config = `models:\n  - type: main\n    engine: ${engine}\n    model: ${model}`;

  // Add Azure-specific parameters
  if (engine === 'azure' && azureParams) {
    config += `\n    parameters:\n      azure_endpoint: "${azureParams.azure_endpoint}"\n      api_version: "${azureParams.api_version}"\n      deployment_name: "${azureParams.deployment_name}"`;
  }

  // Add repo_id parameter for huggingface_hub models
  if (engine === 'huggingface_hub') {
    config += `\n    parameters:\n      repo_id: "${model}"`;
  }

  return config;
};
