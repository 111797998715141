import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CircularProgress, Button } from '@mui/material';
import client from '../../../client';
import theme from '../../../theme';

function DeleteRoleModal({ isModalOpen, setIsModalOpen, selectedRole, onRoleDeleted }) {
  const formRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const deleteRole = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await client.post(`/delete_role/${selectedRole.role_id}`);
      onRoleDeleted();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error deleting role:", error);
      setError(error.response?.data?.detail || "An unexpected error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Transition.Root show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => setIsModalOpen(false)}>
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <form ref={formRef} className="space-y-6 px-4 py-5 sm:p-6" onSubmit={(e) => e.preventDefault()}>
                    <h3 className="text-lg leading-6 font-medium text-gray-900" style={{ color: theme.tmryk_background_color }}>Confirm Delete</h3>
                    <p>Are you sure you want to delete "{selectedRole?.role_name}"?</p>
                    <input type="hidden" name="role_id" value={selectedRole?.role_id} />
                    <div className="flex justify-end space-x-3">
                      <Button
                        variant="contained"
                        color="error"
                        onClick={deleteRole}
                        disabled={isLoading}
                        style={{
                          backgroundColor: isLoading ? undefined : 'red',
                          color: 'white'
                        }}
                      >
                        {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Delete'}
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => setIsModalOpen(false)}
                        disabled={isLoading}
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {error && (
        <Transition.Root show={true} as={Fragment}>
          <Dialog as="div" className="relative z-50" onClose={() => setError(null)}>
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="space-y-6 px-4 py-5 sm:p-6">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Error</h3>
                      <p>{error}</p>
                      <div className="flex justify-end space-x-3">
                        <Button
                          variant="outlined"
                          onClick={() => setError(null)}
                        >
                          Close
                        </Button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  );
}

export default DeleteRoleModal;
