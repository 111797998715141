import React from "react";
import {
  Box,
  Chip,
  FormControl,
  ListSubheader,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {MenuProps} from "../../constants/common";

export default function MultiSelectComponent({
  label,
  options,
  selectedItems,
  setSelectedItems,
}) {
  const handleSelectChange = (event) => {
    const value =
      event.target.value.length === 0 ? ["All"] : event.target.value;
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes("All") && value.length > 1) {
        return value.filter((item) => item !== "All"); // Remove 'All' and set the new selection
      } else if (value.includes("All")) {
        return ["All"]; // Add selected value(s) to the list
      } else {
        return value;
      }
    });
  };

  // Remove the item from the selected items after click on delete chip button
  const handleChipRemove = (value, event) => {
    event.stopPropagation(); // Prevent dropdown from toggling
    let updatedSelected = selectedItems.filter((item) => item !== value);
    updatedSelected = updatedSelected.length === 0 ? ["All"] : updatedSelected;
    setSelectedItems(updatedSelected);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
      }}
    >
      <Typography variant="body1" marginRight={2}>
        {label}:
      </Typography>
      <FormControl variant="outlined" sx={{ minWidth: 200 }} size="small">
        <Select
          value={selectedItems}
          multiple
          onChange={handleSelectChange}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  onMouseDown={(event) => event.stopPropagation()} // Prevent dropdown toggle
                  onDelete={
                    value !== "All"
                      ? (event) => handleChipRemove(value, event)
                      : undefined
                  }
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          <MenuItem key={`all-${label}`} value={"All"}>
            <em>All</em>
          </MenuItem>
          {options
            .map((item) => {
              if (typeof item === "string") {
                // Render individual string options
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              } else if (item.options.length > 0) {
                return [
                  <ListSubheader key={`${item.group}-header`}>
                    {item.group}
                  </ListSubheader>,
                  ...item.options.map((opt) => (
                    <MenuItem key={opt} value={opt}>
                      {opt
                        .replace("AIPolicy-", "")
                        .replace("PrivateEndpoint-", "")}
                    </MenuItem>
                  )),
                ];
              }
              return null; // Skip empty groups
            })
            .flat()}
        </Select>
      </FormControl>
    </Box>
  );
}
