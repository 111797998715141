import React from "react";

export const ActionDotIcon = ({}) => {
  return (
    <svg width="28" height="6" viewBox="0 0 28 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="3" cy="3" r="3" fill="#D6D6D6"/>
<circle cx="14" cy="3" r="3" fill="#D6D6D6"/>
<circle cx="25" cy="3" r="3" fill="#D6D6D6"/>
</svg>
  );
};