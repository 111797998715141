import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ButtonComponent from "../common/ButtonComponent";
import LogoutButtonComponent from "../common/LogoutButtonComponent";
import theme from "../../theme";
import client from "../../client";

function SidebarComponent({ isActive }) {
  const location = useLocation();
  const selectedColor = `bg-[${theme.tmryk_background_color}] text-white`;
  const commonStyling = `rounded-lg p-2`;
  const hoverStyling = `hover:bg-[#031A58] hover:text-white`;
  const disabledStyling = `bg-gray-200 text-gray-400 cursor-not-allowed`;
  const [isSuperuser, setIsSuperuser] = useState(false);
  const [isTenantAdmin, setIsTenantAdmin] = useState(false);

  useEffect(() => {
    // Fetch user info to determine if user is a superuser or tenant admin
    client.get("/user_info")
      .then(response => {
        if (response.status === 200) {
          setIsSuperuser(response.data.is_superuser);
          setIsTenantAdmin(response.data.is_tenant_admin);
        }
      })
      .catch(error => console.error('Error fetching user info:', error));
  }, []);

  const isSelected = (route) => location.pathname === route;

  return (
    <div
      className={`h-full w-[13rem] flex flex-col border-r border-gray-200 justify-between bg-white shadow-lg text-${theme.tmryk_black_text_color} pt-5`}
    >
      <div className="space-y-8">
        {/* AI Scoring Button */}
        <ButtonComponent
          classNames={`${commonStyling} ${
            isSelected("/ai_scanner") ||
            isSelected("/dashboard") ||
            isSelected("/")
              ? selectedColor
              : ""
          } ${hoverStyling}`}
          text="AI Scoring"
          link_to="/ai_scanner"
        />
        {/* AI Controller Button */}
        <ButtonComponent
          classNames={`${commonStyling} ${
            isSelected("/configs") ? selectedColor : ""
          } ${isActive ? hoverStyling : disabledStyling}`}
          text="AI Controller"
          link_to="/configs"
          disabled={!isActive}
        />
        {/* Roles Button */}
        <ButtonComponent
          classNames={`${commonStyling} ${
            isSelected("/roles") ? selectedColor : ""
          } ${isActive ? hoverStyling : disabledStyling}`}
          text="Roles"
          link_to="/roles"
          disabled={!isActive}
        />
        {/* Users Button */}
        <ButtonComponent
          classNames={`${commonStyling} ${
            isSelected("/users") ? selectedColor : ""
          } ${isActive ? hoverStyling : disabledStyling}`}
          text="Users"
          link_to="/users"
          disabled={!isActive}
        />
        
        {/* LLM Models Admin - Visible to superusers and tenant admins */}
        {(isSuperuser || isTenantAdmin) && isActive && (
          <ButtonComponent
            classNames={`${commonStyling} ${
              isSelected("/admin/llm-models") ? selectedColor : ""
            } ${hoverStyling}`}
            text="LLM Models Admin"
            link_to="/admin/llm-models"
          />
        )}
      </div>
      <div className="mb-16 space-y-4">
        <hr
          style={{
            border: `1px solid ${theme.lineColor || "#ddd"}`,
            margin: "10px",
          }}
        />
        <ButtonComponent
          classNames={`${commonStyling} ${
            isSelected("/guide") ? selectedColor : ""
          } ${hoverStyling}`}
          text="Getting Started"
          link_to="/guide"
        />
        {/* <ButtonComponent
          classNames={`${commonStyling} ${
            isSelected("/ai-policy-catalog") ? selectedColor : ""
          } ${hoverStyling}`}
          text="AI Policy Catalog"
          link_to="https://github.com/tumeryk/Tumeryk-Policies-Sample"
          external
        /> */}
        <ButtonComponent
          classNames={`${commonStyling} ${
            isSelected("/docs") ? selectedColor : ""
          } ${hoverStyling}`}
          text="Documentation"
          link_to="/docs"
        />
        <LogoutButtonComponent
          classNames="hover:bg-gray-400 bg-gray-200 rounded-3xl p-2 mt-4"
          text="Logout"
          link_to="/"
        />
      </div>
    </div>
  );
}

export default SidebarComponent;
