import React, { useEffect, useState } from 'react';
import { Button, TextField, IconButton, InputAdornment, Snackbar, Alert, CircularProgress, Box } from '@mui/material';
import client from '../client';
import theme from '../theme';
import UserInfoUpdateModal from '../components/specific/UserInfoUpdateModal';

const UserSettingsPage = () => {
    const [user, setUser] = useState({ username: '', email: '', tenantName: '' });
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [updatedUserInfo, setUpdatedUserInfo] = useState({});

    const fetchUserInfo = () => {
        client.get('/user_info')
            .then(response => {
                setUser(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching user info:', error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchUserInfo();
    }, []);

    const handleCurrentPasswordChange = (e) => {
        setCurrentPassword(e.target.value);
    };

    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
    };

    const handleConfirmNewPasswordChange = (e) => {
        setConfirmNewPassword(e.target.value);
    };

    const handlePasswordSubmit = (e) => {
        e.preventDefault();

        const reqBody = {
            current_password: currentPassword,
            new_password: newPassword,
            confirm_password: confirmNewPassword
        };

        client.post('/update_password', reqBody)
            .then(response => {
                setSnackbarMessage(response.data.msg);
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
            })
            .catch(error => {
                setSnackbarMessage(error.response.data.detail);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            });
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const toggleShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleShowConfirmNewPassword = () => {
        setShowConfirmNewPassword(!showConfirmNewPassword);
    };

    const handleUserInfoChange = (e) => {
        const { name, value } = e.target;
        setUser((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleUserInfoSubmit = (e) => {
        e.preventDefault();
        setUpdatedUserInfo(user);
        setIsModalOpen(true);
    };

    const renderUserInfo = () => (
        <div className="p-4 bg-white mb-10 mt-32 border border-gray-200 rounded-lg shadow-sm sm:p-6">
            <h3 className="mb-4 text-xl font-semibold" style={{ color: theme.tmryk_background_color }}>User Information</h3>
            <form onSubmit={handleUserInfoSubmit}>
                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-2">
                        <label className="block mb-2 text-sm font-medium text-gray-900">Username</label>
                        <TextField
                            variant="outlined"
                            name="username"
                            value={user.username}
                            onChange={handleUserInfoChange}
                            fullWidth
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg"
                        />
                    </div>
                    <div className="col-span-6 sm:col-span-2">
                        <label className="block mb-2 text-sm font-medium text-gray-900">Current Email</label>
                        <TextField
                            variant="outlined"
                            name="email"
                            value={user.email}
                            onChange={handleUserInfoChange}
                            fullWidth
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg"
                        />
                    </div>
                    <div className="col-span-6 sm:col-span-2">
                        <label className="block mb-2 text-sm font-medium text-gray-900">Company Name</label>
                        <TextField
                            variant="outlined"
                            name="tenantName"
                            value={user.tenantName}
                            onChange={handleUserInfoChange}
                            fullWidth
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg"
                        />
                    </div>
                </div>
                <div className="flex justify-end mt-4">
                    <Button
                        type="submit"
                        variant="contained"
                        style={{ backgroundColor: theme.tmryk_background_color, color: 'white' }}
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md"
                    >
                        Save
                    </Button>
                </div>
            </form>
        </div>
    );

    const renderPasswordSettings = () => (
        <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm sm:p=6 w-full">
            <h3 className="mb-4 text-xl font-semibold" style={{ color: theme.tmryk_background_color }}>Change Password</h3>
            <form onSubmit={handlePasswordSubmit}>
                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-2">
                        <label htmlFor="current-password" className="block mb-2 text-sm font-medium text-gray-900">Current Password</label>
                        <TextField
                            type="password"
                            name="current-password"
                            id="current-password"
                            variant="outlined"
                            fullWidth
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg"
                            placeholder="••••••••"
                            required
                            value={currentPassword}
                            onChange={handleCurrentPasswordChange}
                        />
                    </div>
                    <div className="col-span-6 sm:col-span-2">
                        <label htmlFor="new-password" className="block mb-2 text-sm font-medium text-gray-900">New Password</label>
                        <TextField
                            type={showNewPassword ? "text" : "password"}
                            name="new-password"
                            id="new-password"
                            variant="outlined"
                            fullWidth
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg"
                            placeholder="••••••••"
                            required
                            value={newPassword}
                            onChange={handleNewPasswordChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle new password visibility"
                                            onClick={toggleShowNewPassword}
                                            edge="end"
                                        >
                                            {showNewPassword ?
                                                <svg width="24" height="24" viewBox="0 0 24 24"><path d="M12 4.5C7.25 4.5 3.4 7.58 1.5 12c1.9 4.42 5.75 7.5 10.5 7.5s8.6-3.08 10.5-7.5C20.6 7.58 16.75 4.5 12 4.5zm0 12c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" /></svg>
                                                :
                                                <svg width="24" height="24" viewBox="0 0 24 24"><path d="M12 4.5C7.25 4.5 3.4 7.58 1.5 12c1.9 4.42 5.75 7.5 10.5 7.5s8.6-3.08 10.5-7.5C20.6 7.58 16.75 4.5 12 4.5zm0 12c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm-1-9h2v6h-2V7zm1 9c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" /></svg>
                                            }
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="confirm-new-password" className="block mb-2 text-sm font-medium text-gray-900">Confirm New Password</label>
                        <TextField
                            type={showConfirmNewPassword ? "text" : "password"}
                            name="confirm-new-password"
                            id="confirm-new-password"
                            variant="outlined"
                            fullWidth
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg"
                            placeholder="••••••••"
                            required
                            value={confirmNewPassword}
                            onChange={handleConfirmNewPasswordChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle confirm new password visibility"
                                            onClick={toggleShowConfirmNewPassword}
                                            edge="end"
                                        >
                                            {showConfirmNewPassword ?
                                                <svg width="24" height="24" viewBox="0 0 24 24"><path d="M12 4.5C7.25 4.5 3.4 7.58 1.5 12c1.9 4.42 5.75 7.5 10.5 7.5s8.6-3.08 10.5-7.5C20.6 7.58 16.75 4.5 12 4.5zm0 12c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" /></svg>
                                              :
                                              <svg width="24" height="24" viewBox="0 0 24 24"><path d="M12 4.5C7.25 4.5 3.4 7.58 1.5 12c1.9 4.42 5.75 7.5 10.5 7.5s8.6-3.08 10.5-7.5C20.6 7.58 16.75 4.5 12 4.5zm0 12c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" /></svg>
                                            }
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </div>
                </div>
                <div className="flex justify-end mt-4">
                    <Button
                        type="submit"
                        variant="contained"
                        style={{ backgroundColor: theme.tmryk_background_color, color: 'white' }}
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md"
                    >
                        Save Password
                    </Button>
                </div>
            </form>
        </div>
    );

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <div className="flex h-full justify-center">
            <div className="w-full max-w-4xl space-y-8">
                {renderUserInfo()}
                {renderPasswordSettings()}
            </div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} // Position the snackbar to the right side of the screen
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <UserInfoUpdateModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                user={user}
                updatedUserInfo={updatedUserInfo}
                fetchUserInfo={fetchUserInfo}
                setSnackbarMessage={setSnackbarMessage}
                setSnackbarSeverity={setSnackbarSeverity}
                setSnackbarOpen={setSnackbarOpen}
            />
        </div>
    );
};

export default UserSettingsPage;
