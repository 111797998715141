import React, { useState } from 'react';
import {
  Button, CssBaseline, TextField, FormControlLabel,
  Checkbox, Link, Grid, Box, Typography, Container, createTheme, ThemeProvider, Alert
} from '@mui/material';
import tmrykLogo from '../assets/images/tmryk-logo-login.png';
import client from '../client';
import SaaSAgreementModal from '../components/specific/SaaSAgreementModal';

const defaultTheme = createTheme();

export default function Register() {
  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [emailError, setEmailError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [companyNameError, setCompanyNameError] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [isSaaSAgreementModalOpen, setIsSaaSAgreementModalOpen] = useState(false);

  // Define the blocked email domains
  const BLOCKED_EMAIL_DOMAINS = [
    'gmail.com', 'yahoo.com', 'outlook.com', 'aol.com', 'protonmail.com', 'mail.com',
    'prompt.security', 'lakera.com', 'wiz.com', 'adversa.ai', 'aishield.com',
    'protectai.com', 'patronus.ai'
  ];

  // Email regex for validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSubmit = (event) => {
    event.preventDefault();
    setEmailError('');
    setUsernameError('');
    setCompanyNameError('');
    setGeneralError('');

    const data = new FormData(event.currentTarget);

    // Extract and validate the email
    const emailInput = data.get('email').trim();
    const lowerCaseEmail = emailInput.toLowerCase();

    // Email format validation
    if (!emailRegex.test(emailInput)) {
      setEmailError('Please enter a valid email address.');
      return; // Prevent form submission
    }

    // Extract the domain
    const emailDomain = lowerCaseEmail.substring(lowerCaseEmail.lastIndexOf('@') + 1);

    // Domain restriction validation
    if (BLOCKED_EMAIL_DOMAINS.includes(emailDomain)) {
      setEmailError('Email domain not allowed. Please use a business email.');
      return; // Prevent form submission
    }

    // Prepare the request body
    // let reqBody = {
    //   "username": data.get('username'),
    //   "email": lowerCaseEmail, // Use the sanitized email
    //   "password": data.get('password'), // Send 'password' instead of 'hashed_password'
    // };

    let reqBody = {
      "id": 0,
      "tenant_id": 0,
      "username": data.get('username'),
      "email": lowerCaseEmail, 
      "hashed_password": data.get('password'),
      "is_active": true,
      "is_tenant_admin": true,
      "is_superuser": false,
      "role_id": 0
    };

    client.post(`/tmryk-signup?company_name=${data.get('company_name')}`, reqBody)
      .then(response => {
        console.log(response);
        let loginReqBody = new FormData();
        loginReqBody.append('username', data.get('username'));
        loginReqBody.append('password', data.get('password'));

        return client.post("/studio-login", loginReqBody);
      })
      .then(loginResponse => {
        if (loginResponse.status === 200) {
          window.location.href = '/dashboard';
        } else {
          setGeneralError("Login failed. Please try again.");
        }
      })
      .catch(error => {
        if (error.response && error.response.data.detail) {
          const errorMsg = error.response.data.detail;
          if (errorMsg.includes("Username already in use within the same tenant")) {
            setUsernameError(errorMsg);
          } else if (errorMsg.includes("Email already exists")) {
            setEmailError(errorMsg);
          } else if (errorMsg.includes("Company name already exists")) {
            setCompanyNameError(errorMsg);
          } else if (errorMsg.includes("Email domain not allowed") || errorMsg.includes("Please enter a valid email address")) {
            setEmailError(errorMsg);
          } else {
            setGeneralError('An unexpected error occurred. Please try again.');
          }
        } else {
          setGeneralError('An unexpected error occurred. Please try again.');
        }
      });
  };

  return (
    <div>
      <div>
        <img src={tmrykLogo} alt="tmryk logo" className='w-[32rem] h-48 mx-auto mt-10' />
      </div>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5">
              Create an Account
            </Typography>
            {generalError && <Alert severity="error" sx={{ mt: 2, width: '100%' }}>{generalError}</Alert>}
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="company_name"
                label="Company Name"
                id="company_name"
                autoComplete="company-name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                error={!!companyNameError}
                helperText={companyNameError}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!!emailError}
                helperText={emailError}
                type="email" // Added type="email" for HTML5 validation
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                error={!!usernameError}
                helperText={usernameError}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormControlLabel
                control={<Checkbox checked={checked} onChange={(event) => setChecked(event.target.checked)} color="primary" />}
                label={
                  <Typography>
                    I agree to the{' '}
                    <Link href="#" onClick={() => setIsSaaSAgreementModalOpen(true)}>
                      SaaS User Agreement
                    </Link>
                  </Typography>
                }
              />
              <Button
                disabled={!checked || !email || !username || !password || !companyName}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>
              <Grid container className='justify-center'>
                <Grid item>
                  <Link href="/login" variant="body2">
                    {"Already have an account? Sign In"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      <SaaSAgreementModal isModalOpen={isSaaSAgreementModalOpen} setIsModalOpen={setIsSaaSAgreementModalOpen} />
    </div>
  );
}
