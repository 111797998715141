import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { faClock, faBook, faVideo, faClipboard, faLink} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import theme from "../theme";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DocsPage from './DocsPage';

function GuidePage() {
  // State for selected tab and modal visibility
  const [selectedTab, setSelectedTab] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const supportEmail = "support@tumeryk.com";
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);


  // Refs for tab buttons and highlight element
  const tabsRef = useRef([]);
  const highlightRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (copied) {
      setShowCopiedMessage(true);
      const timer = setTimeout(() => {
        setShowCopiedMessage(false);
        setCopied(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [copied]);

  // Update highlight position and size based on selected tab
  useEffect(() => {
    const updateHighlight = () => {
      if (highlightRef.current && tabsRef.current[selectedTab]) {
        const tab = tabsRef.current[selectedTab];
        highlightRef.current.style.width = `${tab.offsetWidth}px`;
        highlightRef.current.style.height = `${tab.offsetHeight}px`;
        highlightRef.current.style.transform = `translateX(${tab.offsetLeft}px)`;
      }
    };

    updateHighlight(); // Update on initial render

    // Update highlight on window resize
    window.addEventListener('resize', updateHighlight);
    return () => window.removeEventListener('resize', updateHighlight);
  }, [selectedTab]); // Dependencies for effect

  // Tab titles
  const tabs = ['Quick Start', 'AI Scoring', 'SDK', 'About', 'Documentation'];

  // Content for each tab
  const tabContent = [

    // Quick Start Tab
    <div style={{ color: theme.tmryk_background_color }}>
      <h1 style={{ marginBottom: '10px', fontWeight: 'bold', fontSize: '24px' }}>Overview</h1>
      <p style={{fontSize : "20px"}}>
        AI Security Studio serves as a system administrator tool designed to work alongside chatbots, 
        providing a suite of customizable policies and guardrails that ensure secure and compliant interactions. 
        By managing chatbot behavior and monitoring inputs and outputs, Security Studio enables administrators 
        to enforce governance rules, prevent misuse, and maintain the integrity of AI deployments, making it an 
        essential companion to any chatbot setup.
      </p>
      <br />
      <Card 
        title="Navigating the Dashboard" 
        description="Explore Security Studio's comprehensive Dashboard and its key features." 
        duration="2 minute read"
        articleTitle="Dashboard"
        articleSubtitle="AI Security Studio provides a visually informative, real-time dashboard which displays and moniters all scans along with their corresponding trust scores."
        articleContents={[
          { title: "Dashboard Key Features:",
            contents: [
              "Trust Scores: The Dashboard's landing page, displaying a breakdown of the trust score for specified models",
              "Alerts: Lists any policy violations that are encountered, along with details such as date of violation, which policy was violated, which user made the violation, and the prompt that triggered the alert.",
              "Usage Metrics: Tracks total number of calls, total amount of time taken, and total tokens used for all policies/scans that have been run",
            ]

          }
        ]} 
      />
      <Card 
        title="Trust Scores: What and Why" 
        description="Explore Security Studio's powerful Trust Score tool." 
        duration="2 minute read"
        articleTitle="Trust Score"
        articleSubtitle=""
        articleIntro="The Tumeryk AI Trust Score (R) evaluates and scores the trustworthiness of the generated prompt responses. Using a proprietary algorithm, the AI Trust Score accounts for model and real time prompt response risks including bias, jailbreak propensity, off-topic responses, toxicity, privacy, data leakage, intellectual property and hallucinations.

This score can be integrated into business processes to help determine whether the content should be accepted, flagged, or blocked, thus allowing organizations to mitigate risks associated with AI-generated"
        articleContents={[
          { title: "", 
            contents: [
              "Model Specific Component (MSC): Derived from running various security probes against models",
              "Real Time Component (RTC): Each prompt is evaluated at run time with security probes such as hallucination, toxicity, and data leakage.",
            ] 
          },
        ]}
        articleNotes=""
      />
      <Card 
        title="Running a Public Scan" 
        description="Explore this high-level overview of the built-in AI Scoring and its features"  
        duration="2 minute read"
        articleTitle="Running a Public Scan"
        articleSubtitle="Navigate to the AI Scoring tab on the left-hand side navigation panel to begin."
        articleIntro="Scan results will be displayed in real time within the AI Scoring tab's homepage. Additionally, The AI Scoring provides various OWASP scans and additional probes regardless of scan type."
        articleContents={[
          { title: "Steps:", 
            contents: [
              "1. Click on the 'Run Scan' button in the top right corner and select the Public LLM option.", 
              "2. Select the model and LLM to scan along with the corresponding API Key.",
              "3a. If you would like to simply run a scan on the selected model, pick the desired security probes and generate a report; The probe options available are: OWASP, Tumeryk Probes, Custom Suite, Ethics, or Bias Detection",
              "3b. If you would only like to generate a trust score for the selected model: Click on the 'Generate Trust Score' button to calculate a Model Specific trust score for the specified model. The trust score is recorded and is used to determine the overall trust score for the model. NOTE: Generating a trust score can take up to around 4 hours.",
              "4. Review details",
              "5. Run Scan."
            ]
          }
        ]}
        articleNotes="In order to run a Public Scan for a selected model, a valid API Key must exist for the corresponding model."
      />
      <Card 
        title="Sample Policies" 
        description="Explore the three basic policies included with AI Security Studio: hello_world, guardrails_only, and RAG" 
        duration="3 minute read"
        articleTitle="Basic Policies"
        articleSubtitle="The following policies come pre-built in the AI Controller section and can be directly modified for desired usage. Navigate to AI Controller to try them out."
        articleIntro="After navigating to the AI Controller Tab to the left hand side, click on the box next to any policy to view its file contents in the 'Advance Configuration' Section on the right side of the screen. Please note that the prebuilt policies still require a unique LLM provider key to function."
        articleContents={[
          { title: "Policy I. hello_world", contents: ["This policy implements input and output rails that allows a chatbot to engage in polite greetings and basic emotional conversations while refusing to discuss specified topics such as politics or the stock market.", ] },
          { title: "Policy II. guardrails_only", contents: ["This policy incorporates custom rails to monitor both the user’s input and the bot’s output in regards to whether or not the key word “dummy” is detected. If the key word is detected, the interaction is halted and a denied message is displayed; otherwise, the interaction is allowed to continue with an approved message. "] },
          { title: "Policy III. Retrival Augmented Generation (RAG)", contents: ["This policy incorporates a knowledge base file, the singular source of data/information that determines the scope of the bot’s generated responses; in this case, the knowledge base file is an employee handbook (employee_handbook.md) for the Tumeryk Company, so any queries not relating to the handbook will fail to produce the correlating response and alert the prompter of the bot’s response scope."] }
        ]}
        articleNotes="Each policy is comprised of three types of files: Configuration, Colang, and Action python files. Different policies will have different contents for their respective files "
      />
      <Card 
        title="Create a Policy" 
        description="Learn how to build a Policy from scratch" 
        duration="5 minute video"
        videoWalkthrough={"https://tumeryk.com/videos/create_policy_wkthr.mp4"}
        videoInfo="Follow along to get started!"
      />
      <Card 
        title="RAG Chatbot" 
        description="Learn how to build and deploy Retrieval-Augmented Generation Chatbot in minutes" 
        duration="5 minute video" 
        videoWalkthrough={"https://tumeryk.com/videos/rag_chatbot_wkthr.mp4"}
        videoInfo="Follow along to get started!"
      /> 
    </div>,

    // AI Scoring Tab
    <div style={{ color: theme.tmryk_background_color }}>
      <h1 style={{ marginBottom: '10px', fontWeight: 'bold', fontSize: '24px' }}>Overview</h1>
        <p style={{fontSize : "20px"}}>
          The AI Scoring is a comprehensive tool designed to evaluate and secure large language models (LLMs) 
          by identifying security vulnerabilities. Leveraging a variety of scans,  
          the scanner provides a holistic assessment of LLM integrity. Its flexible framework allows for the detection 
          of security weaknesses, improper output generation, hallucinations, and biases to ensure robust and 
          reliable LLM deployments. 
        </p>
        <br />
        <Card 
        title="Types of Scans" 
        description="Learn about the different kinds of scans you can run with the AI Scoring." 
        duration="5 minute read"
        articleTitle="Types of AI Scorings"
        articleSubtitle="Explore the various scan types available in the AI Scoring and how they help safeguard your AI models."
        articleIntro="This article will briefly go through the different types of scans provided within the AI Scoring. Whether testing AI policies, public models, or private endpoints, each scan type plays a crucial role in evaluating and securing AI deployments."
        articleContents={[
          { title: "Model Scan Types", 
            contents: [
              "The AI Scoring offers three different types of model scans:",
              "AI Policy: For testing AI Policies either provided by Tumeryk or created within Security Studio", 
              "Public LLM: For testing models hosted by cloud providers, must have appropriate API key in order to run",
              "Private Endpoint: For testing models hosted by your own private endpoint"
            ] 
          },
          { title: "OWASP Scans", 
            contents: [
              "Combining probes used to test the OWASP LLM Top 10 vulnerabilities, the OWASP scans are designed to test a model's ability to handle and process user inputs in a secure and compliant manner.",
              "Selecting the preferred OWASP scan will include all of the probes that test the selected vulnerability."
            ] 
          },
          { title: "Probe Scans", 
            contents: [
              "Tumeryk's AI Scoring can also run scans with a provided selection of probes, and currently supports a maximum of 3 individual probes in a single scan at a time.",
              "For a more detailed look into each of the unique probes, please refer to the Documentation located at the bottom of the left-hand side panel."
            ] 
          }
        ]}
      />
      <Card
        title="Reading AI Scoring Results"
        description="View and manage your AI Scoring results, including model details, test probes, and status reports, all in one place, with quick access to detailed vulnerability reports."
        duration="2 minute read"
        articleTitle="Reading Scan Results"
        articleSubtitle="Navigating to the AI Scoring page will display a list of all existing AI Scoring results."
        articleInfo=""
        articleContents={[
          { title: "From left to right, the results are displayed with:",
            contents: [
              "• LLM Model: The name of the service provider (e.g. Open AI)",
              "• Model Name: The name of the model that was scanned for vulnerabilities",
              "• Test Probes: The probes used in the scan",
              "• Date: The date and time the scan was run",
              "• Status: Links to the report of the model scan",
            ]
          }
        ]}
        articleNotes="When you run a model scan, the results will populate in the AI Scoring page once the scan has been completed. Clicking on the 'view' button will provide a detailed report of the scan results."
      />
    </div>,

    // SDK Tab
    <div style={{ color: theme.tmryk_background_color }}>
      <h1 style={{ marginBottom: '10px', fontWeight: 'bold', fontSize: '24px' }}>Overview</h1>
      <p style={{fontSize : "20px"}}>
        Tumeryk provides the necessary SDK's and API documentation to create customized apps that can leverage Tumeryk Guardrail's features and functionality.
      </p>
      <br />
      <Card 
      title="Fast API" 
      description="Use Tumeryk Guard's fine-grained API to manage authentication, policies, chat completions, tenants, role management, and more." 
      duration="Link"
      onClick={() => {
        window.open("https://chat.tmryk.com/docs", "_blank");}}
      />
      <Card 
      title="Python SDK" 
      description="Get started here to build an app with Tumeryk Guardrails API."  
      duration="Link"
      onClick={() => {
        window.open("https://pypi.org/project/tumeryk-guardrails/", "_blank");}}
      />    
    </div>,

    // About Tab
    <div style={{ color: theme.tmryk_background_color }}>
      <h2 style={{ marginBottom: '10px', fontWeight: 'bold', fontSize: "25px" }}>About Tumeryk AI Security Studio</h2>
      <p style={{fontSize : "20px"}}>
        Tumeryk AI Security Studio is a comprehensive solution that evaluates Gen AI risks by identifying vulnerabilities, 
        preventing misuse, and maintaining the integrity of AI deployments. Utilizing programmable guardrails, Security Studio 
        provides customizable Gen AI governance policies for controlling and protecting the cognition layer which comprises the 
        LLM and associated data infrastructure. <br /> <br />
      </p>
      <Card title="Licensing" description="NVIDIA Licensing Details" duration="" onClick={() => navigate('/about')} />
    </div>,

    <DocsPage />
  ]
  // Define background color for tab titles
  const titleBackgroundColor = theme.tmryk_background_color;

  // Functions to open and close the support modal
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '90vh', overflow: 'hidden' }}>
      {/* Header with title */}
      <div style={{ marginBottom: '20px' }}>
        <div
          className="text-2xl font-semibold"
          style={{
            color: 'white',
            backgroundColor: titleBackgroundColor,
            padding: '20px',
          }}
        >
          Getting Started with AI Security Studio
        </div>
        {/* Tab buttons and highlight */}
        <div style={{ padding: '20px', position: 'sticky' , top: '0', zIndex: '1000'}}>
          <div style={{ display: 'flex', justifyContent: 'left', position: 'relative' }}>
            {tabs.map((tab, index) => (
              <button
                key={index}
                ref={el => tabsRef.current[index] = el} 
                onClick={() => setSelectedTab(index)}
                style={{
                  padding: '10px 20px',
                  margin: '0 10px',
                  backgroundColor: 'transparent',
                  color: selectedTab === index ? 'white' : 'black',
                  border: 'none',
                  borderRadius: '20px',
                  position: 'relative',
                  zIndex: 1,
                }}
              >
                {tab}
              </button>
            ))}
            {/* Highlight element */}
            <div
              ref={highlightRef}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                backgroundColor: titleBackgroundColor,
                borderRadius: '20px',
                transition: 'transform 0.3s ease, width 0.3s ease, height 0.3s ease',
                zIndex: 0,
                width: '0px',
                height: '100%',
              }}
            />
          </div>
        </div>
      </div>
      {/* Main content and sidebar */}
      <div style={{ display: 'flex', flexGrow: 1, overflowY: 'auto' }}>
        {/* Main content area */}
        <div style={{ width: '66.67%', marginRight: '20px', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
          <div style={{ padding: '0 20px', display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
            <div style={{ width: '100%' }}>
              {tabContent[selectedTab]} {/* Render content based on selected tab */}
            </div>
          </div>
        </div>
        {/* Sidebar with support contact and community link */}
        <div style={{
          width: '25%',
          height: '250px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          position: 'relative'
        }}>
          <div style={{
            backgroundColor: theme.tmryk_background_color,
            padding: '20px',
            borderRadius: '8px',
            flex: 1,
            display: 'flex',
            marginTop: '7px',
            flexDirection: 'column',
            overflow: 'hidden',
          }}>
            <h2 style={{ 
                color: 'white', 
                margin: '0 0 10px',
                fontWeight: 'bold',
            }}>
              <FontAwesomeIcon icon={faQuestionCircle} style={{ marginRight: '8px' }} />
              Support and Feedback
            </h2>
            <p style={{ color: 'white', marginBottom: '30px' }}>
              If you have any questions or need further assistance, please reach out to our support team.
            </p>
            <button onClick={openModal} style={{
              position: 'relative',
              bottom: '10px',
              left: '50%',
              transform: 'translateX(-50%)',
              marginTop: '10px',
              padding: '10px 20px',
              backgroundColor: 'white',
              color: theme.tmryk_background_color,
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}>
              Contact Support
            </button>
          </div>
        </div>
      </div>

      {/* Modal for support contact */}
      {isModalOpen && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000
        }}>
          <div style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px',
            maxWidth: '500px',
            width: '100%',
            textAlign: 'center',
          }}>
            <h2 style={{ margin: '0 0 10px' }}>Support & Feedback</h2>
            <p>If you need assistance or want to provide feedback, please contact us by emailing:</p>
            
            {/* Support Email Section */}
            <ul style={{ listStyle: 'none', padding: 0, margin: '10px 0' }}>
              <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                <strong>Email:</strong> 
                <span>{supportEmail}</span>
                <CopyToClipboard text={supportEmail} onCopy={() => setCopied(true)}>
                  <FontAwesomeIcon 
                    icon={faClipboard} 
                    style={{ cursor: 'pointer', color: copied ? 'green' : 'black', marginLeft: '8px' }} 
                    title={copied ? 'Copied' : 'Copy to clipboard'}
                  />
                </CopyToClipboard>
              </li>
            </ul>

            {/* Close Modal Button */}
            <button onClick={closeModal} style={{
              marginTop: '10px',
              padding: '10px 20px',
              backgroundColor: '#ccc',
              color: 'black',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}>
              Close
            </button>
          </div>
        </div>
      )}
      {/* "Copied to Clipboard" pop-up */}
      {showCopiedMessage && (
        <div style={{
          position: 'fixed',
          top: '20px',
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          color: 'white',
          padding: '10px 20px',
          borderRadius: '4px',
          zIndex: 1001,
        }}>
          Copied to Clipboard
        </div>
      )}
    </div>
  );
}

function Card({ title, description, duration, articleTitle, articleSubtitle, articleIntro, articleContents, articleNotes, onClick, videoWalkthrough, videoInfo }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoWindow, setVideoWindow] = useState(null);

  // Determine icon based on activity or read duration
  const getIcon = (duration) => {
    if (duration.includes('activity')) {
      return faClock;
    } else if (duration.includes('read')) {
      return faBook; 
    } else if (duration.includes('video')) {
      return faVideo; 
    } else if (duration.includes('Link')) {
      return faLink; 
    } else {
      return null;
    }
  }

  const openReadWindow = () => {
    const screenWidth = window.screen.width; 
    const windowWidth = 600; 
    const windowHeight = 700; 
    const leftPosition = screenWidth - windowWidth; 

    const newWindow = window.open('', '_blank', `width=${windowWidth},height=${windowHeight},left=${leftPosition},top=0`);
    newWindow.document.open();
    newWindow.document.write(`
      <html>
        <head>
          <title>${title}</title>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
          <style>
            body {
              font-family: Arial, sans-serif;
              background-color: white;
              color: black;
              margin: 0;
              padding: 0;
              height: 100vh;
              display: flex;
              flex-direction: column;
              overflow: hidden;
            }
            .header {
              background-color: ${theme.tmryk_background_color};
              color: white;
              width: 100%;
              padding: 20px;
              text-align: center;
              box-sizing: border-box;
            }
            .subtitle {
              padding-left: 20px;
              font-weight: bold;
            }
            .content {
              padding: 20px;
              text-align: left;
              width: 100%;
              max-width: 600px;
              box-sizing: border-box;
              flex: 1;
              overflow-y: auto;
              padding-bottom: 20px; /* Increased padding to accommodate button */
              padding-top: 5px;
              margin-bottom: 40px; /* Ensure space above the button */
            }

            .footer {
              padding: 20px;
              font-style: italic;
              margin-bottom: 20px;
            }
            h2 {
              font-size: 24px;
              margin: 0;
              font-weight: bold;
            }
            h4 {
              cursor: pointer;
              color: ${theme.tmryk_background_color};
              margin-top: 20px;
              padding-left: 20px; /* Space between the title and the left side */
              position: relative;
              display: flex;
              align-items: center;
            }
            h4 i {
              position: absolute;
              right: 10px; /* Position of the icon */
              font-size: 1rem;
              transition: transform 0.3s ease;
            }
            p {
              font-size: 16px;
              line-height: 1.5;
              margin: 20px 0;
              padding-left: 20px; /* Align content with titles */
            }
            button {
              position: fixed;
              bottom: 0;
              width: 100%;
              padding: 10px 20px;
              background-color: ${theme.tmryk_background_color};
              color: white;
              border: none;
              border-radius: 4px;
              cursor: pointer;
              font-size: 16px;
              box-sizing: border-box;
              z-index: 1; /* Ensure button is above other content */
            }
            button:hover {
              background-color: white;
              color: ${theme.tmryk_background_color};
              border: 1px solid ${theme.tmryk_background_color};
            }
            .dropdown-content {
              display: none;
              padding-left: 20px;
            }
            .dropdown-content.active {
              display: block;
            }
          </style>
        </head>
        <body>
          <div class="header">
            <h2>${articleTitle}</h2>
          </div>
          ${articleSubtitle ? `
            <div class="subtitle">
              <h3>${articleSubtitle}</h3>
            </div>` : ''}
          ${articleIntro ? `<p style="padding-left: 30px; padding-right: 10px">${articleIntro}</p>` : ''}
          ${articleContents.length > 0 ? `
            <div class="content">
              ${articleContents.length === 1 ? `
                <h4>
                  ${articleContents[0].title}
                </h4>
                <div class="simple-content">
                  ${articleContents[0].contents.map(content => `<p>${content}</p>`).join('')}
                </div>
              ` : articleContents.map((item, index) => `
                <h4 onclick="toggleDropdown(${index})">
                  <i class="fas fa-chevron-down" id="icon-${index}"></i>
                  ${item.title}
                </h4>
                <div class="dropdown-content" id="content-${index}">
                  ${item.contents.map(content => `<p>${content}</p>`).join('')}
                </div>
              `).join('')}
              ${articleNotes ? `<p style="font-style: italic;">Note: ${articleNotes}</p>` : ''}
            </div>
          ` : ''}
          <button onclick="window.close()">Close</button>
          <script>
            let expandedSections = [];
            
            function toggleDropdown(index) {
              const content = document.getElementById('content-' + index);
              const icon = document.getElementById('icon-' + index);
              
              if (expandedSections.includes(index)) {
                expandedSections = expandedSections.filter(i => i !== index);
                content.classList.remove('active');
                icon.classList.remove('fa-chevron-up');
                icon.classList.add('fa-chevron-down');
              } else {
                expandedSections.push(index);
                content.classList.add('active');
                icon.classList.remove('fa-chevron-down');
                icon.classList.add('fa-chevron-up');
              }
            }
          </script>
        </body>
      </html>
    `);
    newWindow.document.close();
  };
  // Function to open the Video Walkthrough in a separate window
const openVideoWindow = () => {
  const newWindow = window.open('', '_blank', 'width=800,height=600,right=0');
  
  newWindow.document.open();
  newWindow.document.write(`
    <html>
      <head>
        <title>${title} - Video Walkthrough</title>
        <style>
          body { margin: 0; display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100vh; background-color: #000; color: white; }
          #container { text-align: center; }
          video { width: 100%; height: auto; display: none; }
          #videoInfo { margin: 20px 0; }
          button { padding: 10px 20px; background-color: ${theme.tmryk_background_color}; color: white; border: none; border-radius: 4px; cursor: pointer; }
        </style>
      </head>
      <body>
        <div id="container">
          <h2 id="videoTitle">${title}</h2>
          <p id="videoInfo">${videoInfo}</p>
          <button id="continueButton">Continue</button>
          <video id="video" controls>
            <source src="${videoWalkthrough}" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
        <script>
          document.getElementById('continueButton').addEventListener('click', function() {
            const videoElement = document.getElementById('video');
            const videoInfo = document.getElementById('videoInfo');
            const videoTitle = document.getElementById('videoTitle');
            videoElement.style.display = 'block'; // Show the video
            videoElement.play(); // Start playing the video
            videoInfo.style.display = 'none'; // Hide the video info
            videoTitle.style.display = 'none'; // Hide the title
            this.style.display = 'none'; // Hide the continue button
            // Optionally, you can notify the opener if needed
            window.opener.postMessage('startPlayback', '*');
          });
        </script>
      </body>
    </html>
  `);
  newWindow.document.close();
};

// Function to start video playback if required
const startVideoPlayback = () => {
  if (videoWindow) {
    const videoElement = videoWindow.document.getElementById('video');
    if (videoElement) {
      videoElement.play();
      setIsModalOpen(false); // Close the modal if needed
    }
  }
};

// Effect to handle messages from the newly opened window
useEffect(() => {
  const handleMessage = (event) => {
    if (event.data === 'startPlayback') {
      startVideoPlayback();
    }
  };
  window.addEventListener('message', handleMessage);
  return () => {
    window.removeEventListener('message', handleMessage);
  };
}, [videoWindow]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        padding: '16px',
        border: `1px solid ${theme.cardBorderColor || '#ddd'}`,
        margin: '8px 0',
        borderRadius: '8px',
        backgroundColor: theme.cardBackgroundColor || '#fff',
        boxShadow: `0 4px 8px ${theme.cardShadowColor || 'rgba(0, 0, 0, 0.1)'}`,
        position: 'relative',
      }}
    >
      <div style={{ flexGrow: 1 }}>
        <h3 style={{ margin: '0 0 8px', fontSize: '1.25rem', fontWeight: '600' }}>{title}</h3>
        <p style={{ margin: '0 0 16px', fontSize: '1rem', marginBottom: '55px' }}>{description}</p>
      </div>
      {duration && (
        <div style={{
          marginTop: 'auto',
          fontSize: '0.875rem',
          color: 'white',
          backgroundColor: theme.tmryk_background_color,
          padding: '8px 10px',
          borderRadius: '4px',
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          bottom: '16px',
          right: '16px',
        }}>
          <FontAwesomeIcon icon={getIcon(duration)} style={{ marginRight: '4px', color: 'white' }} />
          {duration}
        </div>
      )}
      <button
        style={{
          marginTop: 'auto',
          padding: '8px 16px',
          backgroundColor: theme.tmryk_background_color,
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          alignSelf: 'center',
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          bottom: '16px',
          left: '16px'
        }}
        onClick={
          duration.includes('video') ? openVideoWindow :
          duration.includes('read') ? openReadWindow :
          title.includes('Licensing') ? onClick :
          onClick
        }
      >
        {duration.includes('video') ? (
          <>
            <FontAwesomeIcon icon={faVideo} style={{ marginRight: '8px' }} />
            Video Walkthrough
          </>
        ) : duration.includes('read') ? (
          "Read"
        ) : title.includes('Licensing') ? (
          <>
            Go to Page
          </>
        ) : (
          <>
          <FontAwesomeIcon icon={faLink} style={{ marginRight: '8px' }} />
          Go to Link
          </>
        )}
      </button>
    </div>
  );
}

export default GuidePage;