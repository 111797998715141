import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Box,
  Chip,
  Typography,
  Paper,
  CircularProgress,
  Alert,
} from "@mui/material";
import { Close as CloseIcon, Cancel as CancelIcon, Add as AddIcon, ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { toast } from "react-toastify";
import client from "../../../client";
import theme from "../../../theme";

const EditCustomListModal = ({ isOpen, setIsOpen, listName, setIsModalOpen, fetchUpdatedList }) => {
  const [newListName, setNewListName] = useState(listName);
  const [substrings, setSubstrings] = useState([]);
  const [substring, setSubstring] = useState("");
  const [newSubstrings, setNewSubstrings] = useState([]);
  const [markedSubstrings, setMarkedSubstrings] = useState([]);
  const [promptResponses, setPromptResponses] = useState([]);
  const [markedPrompts, setMarkedPrompts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (isOpen) {
      fetchListDetails();
    }
  }, [isOpen]);

  const fetchListDetails = async () => {
    setIsLoading(true);
    try {
      const response = await client.get(`/custom_suite_list/${listName}`);
      setSubstrings(response.data.substrings || []);
      setPromptResponses(Object.entries(response.data.prompts_responses || {}));
      setNewListName(response.data.config.list_name || listName);
    } catch (error) {
      console.error("Error fetching list details:", error);
      toast.error("Error fetching list details");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddSubstring = () => {
    if (substring.trim()) {
      setSubstrings([...substrings, substring.trim()]);
      setNewSubstrings([...newSubstrings, substring.trim()]);
      setSubstring("");
    }
  };

  const handleRemoveSubstring = (index) => {
    const updatedMarked = [...markedSubstrings];
    const substringIndex = updatedMarked.indexOf(index);
    if (substringIndex > -1) {
      updatedMarked.splice(substringIndex, 1);
    } else {
      updatedMarked.push(index);
    }
    setMarkedSubstrings(updatedMarked);
  };

  const handleRemovePromptResponse = (index) => {
    const updatedMarked = [...markedPrompts];
    const promptIndex = updatedMarked.indexOf(index);
    if (promptIndex > -1) {
      updatedMarked.splice(promptIndex, 1);
    } else {
      updatedMarked.push(index);
    }
    setMarkedPrompts(updatedMarked);
  };

  const handleSaveChanges = async () => {
    setIsLoading(true);
    setError("");
    try {
      const updatedSubstrings = substrings.filter((_, index) => !markedSubstrings.includes(index));
      const updatedPromptsResponses = Object.fromEntries(
        promptResponses.filter((_, index) => !markedPrompts.includes(index))
      );

      await client.put(`/custom_suite_list/${listName}`, {
        list_name: newListName,
        created_by: "current_user",
        prompts_responses: updatedPromptsResponses,
        substrings: updatedSubstrings,
      });
      toast.success("Prompt list updated successfully!");
      await fetchUpdatedList();
      setIsOpen(false);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error updating list:", error);
      setError("Error updating list: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsModalOpen(false); // Close RunScanProbesModal when ViewListModal closes
  };

  const handleBack = () => {
    setIsOpen(false);
    setIsModalOpen(true); // Reopen RunScanProbesModal when navigating back
  };

  const isNewSubstring = (substr) => newSubstrings.includes(substr);

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle style={{ color: theme.tmryk_background_color }}>
        <div className="flex items-start justify-between w-full">
          <div className="flex items-center">
            <Typography variant="h6" component="h3" className="text-lg font-medium ml-2" style={{ color: theme.tmryk_background_color }}>
              Edit Custom List
            </Typography>
          </div>
          <div className="ml-3 flex h-7 items-center">
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{ position: "absolute", right: 8, top: 8, color: "gray" }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent style={{ overflowY: "auto", maxHeight: "70vh" }}>
        {error && (
          <Alert severity="error" onClose={() => setError("")} style={{ marginBottom: "20px" }}>
            {error}
          </Alert>
        )}
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box mb={2} mt={2}>
              <TextField
                label="List Name"
                value={newListName}
                onChange={(e) => setNewListName(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </Box>
            <Typography variant="subtitle1" component="h3" style={{ color: theme.tmryk_background_color, marginTop: "20px" }}>
              Prompts and Responses
            </Typography>
            <Box style={{ overflowY: "auto", maxHeight: "300px" }}>
              {promptResponses.length === 0 ? (
                <Typography variant="body1" style={{ color: theme.tmryk_background_color }}>
                  No prompts and responses available.
                </Typography>
              ) : (
                promptResponses.map(([prompt, response], index) => (
                  <Paper key={index} elevation={3} style={{ padding: "20px", marginBottom: "20px", backgroundColor: markedPrompts.includes(index) ? "gray" : "white" }}>
                    <Box display="flex" justifyContent="right" alignItems="center">
                      <IconButton onClick={() => handleRemovePromptResponse(index)}>
                        {markedPrompts.includes(index) ? <AddIcon style={{ color: theme.tmryk_background_color }} /> : <CancelIcon style={{ color: theme.tmryk_background_color }} />}
                      </IconButton>
                    </Box>
                    <Typography
                      variant="body1"
                      style={{
                        marginBottom: "20px",
                        textDecoration: markedPrompts.includes(index) ? "line-through" : "none",
                      }}
                    >
                      <strong>Prompt:</strong> {prompt}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        textDecoration: markedPrompts.includes(index) ? "line-through" : "none",
                      }}
                    >
                      <strong>Response:</strong> {response}
                    </Typography>
                  </Paper>
                ))
              )}
            </Box>
            <Typography variant="subtitle1" component="h3" style={{ color: theme.tmryk_background_color, marginTop: "20px" }}>
              Denied Substrings
            </Typography>
            <Box mb={2} mt={1} display="flex" alignItems="center">
              <TextField
                label="Add Substring"
                value={substring}
                onChange={(e) => setSubstring(e.target.value)}
                variant="outlined"
                style={{ marginRight: "10px", flex: 1 }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleAddSubstring();
                    e.preventDefault();
                  }
                }}
              />
              <Button
                variant="contained"
                style={{
                  backgroundColor: theme.tmryk_background_color,
                  color: "white",
                  height: "56px"
                }}
                onClick={handleAddSubstring}
              >
                Add
              </Button>
            </Box>
            <Box>
              {substrings.length === 0 ? (
                <Typography variant="body1" style={{ color: theme.tmryk_background_color }}>
                  No substrings available.
                </Typography>
              ) : (
                substrings.map((substr, index) => (
                  <Chip
                    key={index}
                    label={substr}
                    onDelete={() => {
                      if (isNewSubstring(substr)) {
                        setNewSubstrings(newSubstrings.filter((s) => s !== substr));
                        setSubstrings(substrings.filter((s) => s !== substr));
                      } else {
                        handleRemoveSubstring(index);
                      }
                    }}
                    style={{
                      margin: "5px",
                      backgroundColor: isNewSubstring(substr) ? "green" : markedSubstrings.includes(index) ? "gray" : theme.tmryk_background_color,
                      color: "white",
                      textDecoration: markedSubstrings.includes(index) && !isNewSubstring(substr) ? "line-through" : "none",
                    }}
                    deleteIcon={
                      isNewSubstring(substr) ? (
                        <CancelIcon style={{ color: "white" }} />
                      ) : markedSubstrings.includes(index) ? (
                        <AddIcon style={{ color: "white" }} />
                      ) : (
                        <CancelIcon style={{ color: "white" }} />
                      )
                    }
                  />
                ))
              )}
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleBack}
          style={{
            borderColor: theme.tmryk_background_color,
            color: theme.tmryk_background_color,
            backgroundColor: "white",
            marginRight: "10px",
          }}
        >
          Back
        </Button>
        <Button
          onClick={handleSaveChanges}
          color="primary"
          variant="contained"
          style={{ backgroundColor: theme.tmryk_background_color, color: "white" }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCustomListModal;
