import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
} from '@mui/material';
import theme from '../../../theme';
import client from '../../../client';
import { toast } from 'react-toastify';

function ExportPolicyModal({ isModalOpen, setIsModalOpen, selectedConfig }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleExport = async () => {
    if (!selectedConfig) {
      toast.error('No policy selected');
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await client.get(
        `/export_policy?policy_name=${encodeURIComponent(selectedConfig.id)}`,
        {
          responseType: 'blob',
        }
      );

      // Create a blob from the response data
      const blob = new Blob([response.data], { type: 'application/octet-stream' });

      // Create a download link and trigger the download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${selectedConfig.id}_policy.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      toast.success('Policy exported successfully!');
      handleClose();
    } catch (error) {
      console.error('Error exporting policy:', error);
      toast.error(error.response?.data?.detail || 'Error exporting policy');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog 
      open={isModalOpen} 
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ backgroundColor: theme.tmryk_background_color, color: 'white' }}>
        Export Policy
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Export the selected policy.
          {selectedConfig && (
            <Box component="span" sx={{ display: 'block', mt: 1, fontWeight: 'bold' }}>
              Selected Policy: {selectedConfig.id}
            </Box>
          )}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleExport}
          disabled={isSubmitting}
          sx={{
            backgroundColor: theme.tmryk_background_color,
            color: 'white',
            '&:hover': {
              backgroundColor: '#172E6C',
            },
          }}
        >
          Export
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ExportPolicyModal;
