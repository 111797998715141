const theme = {
    tmryk_logo_green: 'text-[#46864a]',
    tmyrk_background_dark_green: { backgroundColor: '#54824E'},
    tmyrk_background_light_green: {backgroundColor: '#F5FAF5'},
    table_button_styling: { color: 'text-white', fontWeight: 'bold', marginBottom: 8, textTransform: 'none', width: '8rem' },
    green_button_styling: { backgroundColor: 'green', color: 'text-white', fontWeight: 'bold', marginBottom: 8, textTransform: 'none', width: '8rem' },
    tmryk_background_color: '#031A58',
    tmryk_background_class_color: 'bg-[#031A58]',
    tmryk_secondary_color: '#D100EB',
    tmryk_black_text_color: '#222222',
    tmryk_light_blue_color: '#13B8FF',
    tmryk_box_shadow: 'rgba(3, 26, 88, 0.2)',
    tmryk_alternative_dark_blue_color: '#818DAC',
    tmryk_warning_color: "#ff9800"
}

export default theme;