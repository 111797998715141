// AIScannerPage.jsx
import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  CircularProgress,
} from "@mui/material";
import theme from "../theme";
import LlmScanPage from "./LlmScanPage";
import ModelChartPanel from "../components/modals/llmScan/ModelChartPanel";
import ComparisonDashboardModal from "../components/modals/dashboard/ComparisonDashboardModal";

import client from "../client";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

function AIScannerPage({ isActive, onRequestAccess }) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [tenantId, setTenantId] = useState("");
  const [isLoading, setLoading] = useState(true);
  const location = useLocation();

  // Function to parse query parameters from the URL
  const queryParams = new URLSearchParams(location.search);

  // Set default values from query params on page load
  useEffect(() => {
    const from_login = queryParams.get("login") || false;
    if (from_login) {
      toast.success(
        "Import AI policies from the AI policy catalog by downloading the desired .zip file and importing it into Tumeryk Studio.",
        {
          style: {
            width: "600px",
          },
          position: 'top-center'
        }
      );
    }
  }, [location.search]); // Re-run whenever the query string changes

  // STEP 1: Build an array of { key: string, label: ReactNode } for the Tabs
  // We do NOT compare the label node with ===. Instead, we compare .key.
  const tabsData = useMemo(() => {
    // Build the label node for "AI Trust Score" with TM in superscript
    const aiTrustLabel = (
      <span>
        AI Trust Score<sup style={{ fontSize: "0.6em" }}>TM</sup>
      </span>
    );

    if (isActive) {
      return [
        { key: "AI_TRUST", label: aiTrustLabel },
        { key: "ANALYTICS", label: "Analytics" },
        { key: "SCANNER", label: "AI Scoring" },
      ];
    } else {
      return [
        { key: "AI_TRUST", label: aiTrustLabel },
        { key: "ANALYTICS", label: "Analytics" },
      ];
    }
  }, [isActive]);

  // On mount, fetch user info (like tenant ID, etc.)
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        setLoading(true);
        const userInfoResponse = await client.get("/user_info");
        setTenantId(userInfoResponse?.data?.tenantId || "");
      } catch (error) {
        console.error("Failed to fetch user info:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchUserInfo();
  }, []);

  // Ensure selectedTab stays within current # of tabs if isActive changes
  useEffect(() => {
    if (selectedTab >= tabsData.length) {
      setSelectedTab(0);
    }
  }, [tabsData, selectedTab]);

  // Called when user clicks a different tab
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
      <AppBar
        position="static"
        style={{ backgroundColor: theme.tmryk_background_color }}
      >
        <Toolbar>
          {/* 
            Map over tabsData to display each tab.
            selectedTab is the index, so we compare that in the content below.
          */}
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            textColor="inherit"
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{
              style: { backgroundColor: "white" },
            }}
            sx={{
              "& .MuiTab-root": { color: "white" },
              "& .Mui-selected": { borderBottom: "2px solid white" },
            }}
          >
            {tabsData.map((tabObj, idx) => (
              <Tab key={tabObj.key} label={tabObj.label} />
            ))}
          </Tabs>
        </Toolbar>
      </AppBar>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{ padding: 2, backgroundColor: "#f5f5f5", minHeight: "100vh" }}
        >
          {/* 
            Render content based on the "key" of the selected tab. 
            Compare a string, not the React element label.
          */}
          {tabsData[selectedTab].key === "AI_TRUST" && (
            <ModelChartPanel tenantId={tenantId} />
          )}

          {tabsData[selectedTab].key === "ANALYTICS" && (
            <ComparisonDashboardModal tenantId={tenantId} />
          )}

          {tabsData[selectedTab].key === "SCANNER" && (
            <LlmScanPage
              isActive={isActive}
              onRequestAccess={onRequestAccess}
            />
          )}
        </Box>
      )}
    </Box>
  );
}

export default AIScannerPage;
