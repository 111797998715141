import React, { useEffect, useState } from "react";
import {
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  CircularProgress,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  createTheme,
  ThemeProvider,
  Alert,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  FormHelperText,
  AlertTitle,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import tmrykLogo from "../assets/images/tmryk-logo-login.png";
import client from "../client";
import { useLocation } from "react-router-dom";
import SaaSAgreementModal from "../components/specific/SaaSAgreementModal";
import { toast } from "react-toastify";
import config from "../config";

const defaultTheme = createTheme();

export default function Register() {
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [emailError, setEmailError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [passwordsMatchError, setPasswordsMatchError] = useState("");
  const [generalError, setGeneralError] = useState("");
  const [isSaaSAgreementModalOpen, setIsSaaSAgreementModalOpen] =
    useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showVerificationMessage, setShowVerificationMessage] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [public_tenant, setPublicTenant] = useState(false);

  const location = useLocation();

  // Function to parse query parameters from the URL
  const queryParams = new URLSearchParams(location.search);

  // Define the blocked email domains
  const BLOCKED_EMAIL_DOMAINS = [
    "gmail.com",
    "yahoo.com",
    "outlook.com",
    "aol.com",
    "protonmail.com",
    "mail.com",
    "prompt.security",
    "lakera.com",
    "wiz.com",
    "adversa.ai",
    "aishield.com",
    "protectai.com",
    "patronus.ai",
  ];

  // Email regex for validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Set default values from query params on page load
  useEffect(() => {
    setUsername(
      `${queryParams.get("first_name") || ""}${
        queryParams.get("last_name") || ""
      }` || ""
    );
    setEmail(queryParams.get("email") || "");
    if (queryParams.get("public_tenant") === "true") {
      setPublicTenant(queryParams.get("public_tenant"));
    }
  }, [location.search]); // Re-run whenever the query string changes

  const handlePasswordVisibility = () => setShowPassword((prev) => !prev);
  const handleConfirmPasswordVisibility = () =>
    setShowConfirmPassword((prev) => !prev);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setShowVerificationMessage(false);
    setShowPassword(false);
    setShowConfirmPassword(false);
    setPasswordsMatchError("");
    setEmailError("");
    setUsernameError("");
    setCompanyNameError("");
    setGeneralError("");

    // Validate Confirm Password
    if (password !== confirmPassword) {
      setIsSubmitting(false);
      setPasswordsMatchError("Passwords do not match!");
      return;
    }

    // Extract and validate the email
    const emailInput = email.trim();
    const lowerCaseEmail = emailInput.toLowerCase();

    // Email format validation
    if (!emailRegex.test(emailInput)) {
      setIsSubmitting(false);
      setEmailError("Please enter a valid email address.");
      return; // Prevent form submission
    }

    // Extract the domain
    const emailDomain = lowerCaseEmail.substring(
      lowerCaseEmail.lastIndexOf("@") + 1
    );

    // Domain restriction validation
    if (BLOCKED_EMAIL_DOMAINS.includes(emailDomain)) {
      setIsSubmitting(false);
      setEmailError("Email domain not allowed. Please use a business email.");
      return; // Prevent form submission
    }

    let reqBody = {
      id: 0,
      username: username,
      email: lowerCaseEmail,
      password: password,
    };

    client
      .post(
        `/tmryk-signup?company_name=${companyName}&public_tenant=${public_tenant}`,
        reqBody
      )
      .then((response) => {
        setIsSubmitting(false);
        if (response.status === 201) {
          toast.success(response.data.detail);
          setShowVerificationMessage(true);
          setCompanyName("");
          setEmail("");
          setUsername("");
          setPassword("");
          setConfirmPassword("");
          setTermsAgreed(false);
        } else {
          setGeneralError(
            "Facing an issue while creating account. Please try again."
          );
          toast.error(response.data.detail);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data.detail) {
          const errorMsg = error.response.data.detail;
          if (errorMsg.includes("Username already in use")) {
            setUsernameError(errorMsg);
          } else if (errorMsg.includes("Email already registered")) {
            setEmailError(errorMsg);
          } else if (errorMsg.includes("Company name already exists")) {
            setCompanyNameError(errorMsg);
          } else if (
            errorMsg.includes("Email domain not allowed") ||
            errorMsg.includes("Please enter a valid email address")
          ) {
            setEmailError(errorMsg);
          } else if (errorMsg.includes("sending an verification email")) {
            setGeneralError(errorMsg);
          } else {
            setGeneralError("An unexpected error occurred. Please try again.");
          }
        } else {
          setGeneralError("An unexpected error occurred. Please try again.");
        }
        setIsSubmitting(false);
      });
  };

  return (
    <div>
      <div>
        <img
          src={tmrykLogo}
          alt="tmryk logo"
          className="w-[32rem] h-48 mx-auto"
        />
      </div>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 2,
              marginBottom: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              Create an Account
            </Typography>
            {generalError && (
              <Alert severity="error" sx={{ mt: 2, width: "100%" }}>
                {generalError}
              </Alert>
            )}
            {showVerificationMessage && (
              <Alert severity="success" sx={{ mt: 2, width: "100%" }}>
                <AlertTitle>Verification Email Sent</AlertTitle>
                Please verify your account to complete registration.
                <br />
                After verifying your email, click{" "}
                <Link
                  href={public_tenant ? `${config.CHATBOT_URL}/` : "/login"}
                  style={{ color: "inherit" }}
                >
                  here
                </Link>{" "}
                to log in.
              </Alert>
            )}
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                name="company_name"
                label="Company Name"
                id="company_name"
                autoComplete="company-name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                error={!!companyNameError}
                helperText={companyNameError}
                disabled={isSubmitting}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                autoFocus
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                type="email" // Added type="email" for HTML5 validation
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!!emailError}
                helperText={emailError}
                disabled={isSubmitting}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                error={!!usernameError}
                helperText={usernameError}
                disabled={isSubmitting}
              />
              <FormControl
                margin="normal"
                fullWidth
                required
                variant="outlined"
                disabled={isSubmitting}
              >
                <InputLabel>Password</InputLabel>
                <OutlinedInput
                  required
                  fullWidth
                  label="Password"
                  name="password"
                  id="password"
                  autoComplete="current-password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={handlePasswordVisibility} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <FormControl
                margin="normal"
                fullWidth
                required
                error={!!passwordsMatchError}
                variant="outlined"
                disabled={isSubmitting}
              >
                <InputLabel>Confirm Password</InputLabel>
                <OutlinedInput
                  required
                  fullWidth
                  label="Confirm Password"
                  name="confirm_password"
                  id="confirm_password"
                  autoComplete="confirm-password"
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleConfirmPasswordVisibility}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText>{passwordsMatchError}</FormHelperText>
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={isSubmitting}
                    checked={termsAgreed}
                    onChange={(event) => setTermsAgreed(event.target.checked)}
                    name="termsAgreed"
                    color="primary"
                  />
                }
                label={
                  <Typography>
                    I agree to the{" "}
                    <Link
                      href="#"
                      onClick={() => setIsSaaSAgreementModalOpen(true)}
                    >
                      SaaS User Agreement
                    </Link>
                  </Typography>
                }
              />
              <Button
                disabled={
                  !termsAgreed ||
                  !email ||
                  !username ||
                  !password ||
                  !companyName ||
                  isSubmitting
                }
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {!isSubmitting ? "Sign Up" : <CircularProgress size="1.5rem" />}
              </Button>
              <Grid container className="justify-center">
                <Grid item>
                  <Link
                    href={public_tenant ? `${config.CHATBOT_URL}/` : "/login"}
                    variant="body2"
                  >
                    {"Already have an account? Sign In"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      <SaaSAgreementModal
        isModalOpen={isSaaSAgreementModalOpen}
        setIsModalOpen={setIsSaaSAgreementModalOpen}
      />
    </div>
  );
}
