import React, { useState } from 'react';
import {
  Button, CssBaseline, TextField, FormControlLabel,
  Checkbox, Link, Grid, Box, Typography, Container, createTheme, ThemeProvider, Alert
} from '@mui/material';
import tmrykLogo from '../assets/images/tmryk-logo-purple-large.png';
import client from "../client";

const defaultTheme = createTheme();

export default function SignIn() {
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    client.post("/studio-login", data)
      .then((response) => {
        if (response.status === 200) {
          const { access_token } = response.data;
          sessionStorage.setItem('showQuotaWarning', 'true');
          sessionStorage.setItem('access_token', access_token);
          window.location.href = "/configs";
        } else {
          setErrorMessage('Login failed. Please check your username and password.');
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403 && error.response.data.detail === "User account is inactive.") {
          setErrorMessage('This account has been deactivated. Please contact support@tumeryk.com.');
        } else {
          setErrorMessage('We did not find an account for this username and password.');
        }
        console.log('ERROR:', error.config.url, "|", error.message);
      });
  };

  return (
    <div>
      <div>
        <img src={tmrykLogo} alt="tmryk logo" className='w-[32rem] h-48 mx-auto mt-10' />
      </div>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            {errorMessage && <Alert severity="error" sx={{ mt: 2, width: '100%' }}>{errorMessage}</Alert>}
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="https://tumeryk.com/sign-up" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
}
