import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Menu, MenuItem, CircularProgress, Box } from '@mui/material';
import client from '../client';
import theme from '../theme';
import AddUserModal from '../components/modals/users/AddUserModal';
import EditUserModal from '../components/modals/users/EditUserModal';
import DeleteUserModal from '../components/modals/users/DeleteUserModal';
import { ActionDotIcon } from '../assets/images/icons/ActionDotIcon/ActionDotIcon';

function UserManagementPage() {
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [data, setData] = useState([]);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        setLoading(true);
        try {
            const usersResponse = await client.get("/users");
            setUsers(usersResponse.data);
            const rolesResponse = await client.get("/roles");
            setRoles(rolesResponse.data);
            const userInfoResponse = await client.get("/user_info");
            setCurrentUser(userInfoResponse.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const newData = users.map(user => ({
            user_id: user.id,
            user_name: user.username,
            email: user.email,
            role_name: roles.find(role => role.role_id === user.role_id)?.role_name || "",
            role_id: user.role_id,
            is_tenant_admin: user.is_tenant_admin,
            is_superuser: user.is_superuser,
            isSelected: false
        }));
        setData(newData);
    }, [users, roles]);

    const handleSelectAll = (event) => {
        const newSelectAll = event.target.checked;
        setSelectAll(newSelectAll);
        const newData = data.map(user => ({
            ...user,
            isSelected: newSelectAll
        }));
        setData(newData);
        setSelectedUsers(newSelectAll ? newData.map(user => user.user_id) : []);
    };

    const handleSelectUser = (event, user_id) => {
        const newSelectedUsers = event.target.checked
            ? [...selectedUsers, user_id]
            : selectedUsers.filter(id => id !== user_id);
        setSelectedUsers(newSelectedUsers);
        const newData = data.map(user => ({
            ...user,
            isSelected: newSelectedUsers.includes(user.user_id)
        }));
        setData(newData);
        setSelectAll(newData.every(user => user.isSelected));
    };

    const handleMenuClick = (event, user) => {
        setAnchorEl(event.currentTarget);
        setSelectedUser(user);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleEditClick = () => {
        setIsEditModalOpen(true);
        handleMenuClose();
    };

    const handleDeleteClick = () => {
        setIsDeleteModalOpen(true);
        handleMenuClose();
    };

    const handleUserAdded = () => {
        fetchData();
    };

    const handleUserUpdated = () => {
        fetchData();
    };

    const handleUserDeleted = () => {
        fetchData();
    };

    return (
        <div className="flex h-full w-full">
            <div style={{ width: '100vw', padding: '6px', marginTop: '180px', margin: 'auto' }} className="h-full">
                <div className="flex justify-between p-4 items-center mb-4">
                    <div>
                        <div className="text-2xl font-semibold" style={{ color: theme.tmryk_background_color }}>
                            User Management
                        </div>
                        <div className="text-sm" style={{ color: theme.tmryk_background_color }}>
                            Manage user roles and information
                        </div>
                    </div>
                    <div className="flex">
                        <Button
                            variant="contained"
                            style={{
                                backgroundColor: theme.tmryk_background_color,
                                color: 'white',
                                width: '150px',
                                height: '34px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: '20px'
                            }}
                            onClick={() => setIsAddModalOpen(true)}
                        >
                            Add User
                        </Button>
                    </div>
                </div>
                <div style={{ maxHeight: "700px", overflowY: "auto" }}>
                    {loading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Table style={{ width: '100%' }}>
                            <TableHead>
                                <TableRow style={{ height: '42px', maxWidth: '1122px', background: theme.tmryk_background_color }}>
                                    <TableCell style={{ color: 'white', padding: '6px 64px 6px 0', borderBottom: 'none' }}>
                                        <Checkbox
                                            checked={selectAll}
                                            onChange={handleSelectAll}
                                            sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }}
                                        />
                                    </TableCell>
                                    <TableCell style={{ color: 'white', padding: '6px 80px 6px 0', borderBottom: 'none' }}>User ID</TableCell>
                                    <TableCell style={{ color: 'white', padding: '6px 80px 6px 0', borderBottom: 'none' }}>User Name</TableCell>
                                    <TableCell style={{ color: 'white', padding: '6px 80px 6px 0', borderBottom: 'none'}}>Email</TableCell>
                                    <TableCell style={{ color: 'white', padding: '6px 80px 6px 0', borderBottom: 'none'}}>Role</TableCell>
                                    <TableCell style={{ color: 'white', padding: '6px 32px 6px 0', borderBottom: 'none'}} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((user, index) => (
                                    <TableRow key={index} style={{ height: '54px', maxWidth: '1122px', backgroundColor: "white"}}>
                                        <TableCell style={{ color: theme.tmryk_black_text_color, padding: '6px 64px 6px 0', borderBottom: 'none'}}>
                                            <Checkbox
                                                checked={user.isSelected}
                                                onChange={(event) => handleSelectUser(event, user.user_id)}
                                                sx={{ color: theme.tmryk_background_color, '&.Mui-checked': { color: theme.tmryk_background_color } }}
                                            />
                                        </TableCell>
                                        <TableCell style={{ color: theme.tmryk_black_text_color, padding: '6px 80px 6px 0', borderBottom: 'none'}}>{user.user_id}</TableCell>
                                        <TableCell style={{ color: theme.tmryk_black_text_color, padding: '6px 80px 6px 0', borderBottom: 'none', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '200px' }}>{user.user_name}</TableCell>
                                        <TableCell style={{ color: theme.tmryk_black_text_color, padding: '6px 80px 6px 0', borderBottom: 'none', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '200px' }}>{user.email}</TableCell>
                                        <TableCell style={{ color: theme.tmryk_black_text_color, padding: '6px 80px 6px 0', borderBottom: 'none', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '200px' }}>{user.role_name}</TableCell>
                                        <TableCell style={{ color: theme.tmryk_black_text_color, padding: '6px 32px 6px 0', borderBottom: 'none', }}>
                                            <Button onClick={(event) => handleMenuClick(event, user)}>
                                                <ActionDotIcon />
                                            </Button>
                                            <Menu
                                                anchorEl={anchorEl}
                                                open={Boolean(anchorEl)}
                                                onClose={handleMenuClose}
                                                getContentAnchorEl={null}
                                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                sx={{
                                                    '& .MuiPaper-root': {
                                                        boxShadow: `0px 2px 8px ${theme.tmryk_box_shadow}`,
                                                        borderRadius: '8px',
                                                        backgroundColor: 'white',
                                                    },
                                                }}
                                            >
                                                <MenuItem onClick={handleEditClick}>Edit</MenuItem>
                                                <MenuItem
                                                    onClick={handleDeleteClick}
                                                    sx={{
                                                        color: 'red',
                                                        '&:hover': {
                                                            backgroundColor: '#FDECEA',
                                                            color: 'red',
                                                        },
                                                    }}
                                                >
                                                    Delete
                                                </MenuItem>
                                            </Menu>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </div>
            </div>

            {isAddModalOpen && <AddUserModal isModalOpen={isAddModalOpen} setIsModalOpen={setIsAddModalOpen} roles={roles} currentUser={currentUser} onUserAdded={handleUserAdded} />}
            {isEditModalOpen && <EditUserModal isModalOpen={isEditModalOpen} setIsModalOpen={setIsEditModalOpen} users={users} selectedUser={selectedUser} currentUser={currentUser} roles={roles} onUserUpdated={handleUserUpdated} />}
            {isDeleteModalOpen && <DeleteUserModal isModalOpen={isDeleteModalOpen} setIsModalOpen={setIsDeleteModalOpen} selectedUser={selectedUser} onUserDeleted={handleUserDeleted} />}
        </div>
    );
}

export default UserManagementPage;