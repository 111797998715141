import React, { Fragment, useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import client from '../../../client';
import theme from '../../../theme';
import config from '../../../config';
import { toast } from 'react-toastify';
import {
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Checkbox,
  Box,
} from '@mui/material';

// Define LLM Types
const LLM_TYPES = {
  azure: {
    keyName: 'api_key',
    models: ['gpt-4o-mini', 'gpt-4o', 'gpt-4-turbo', 'gpt-4', 'gpt-3.5-turbo'],
    requiredToAddKey: true,
    requiresEndpoint: true,
  },
  openai: {
    keyName: 'openai_api_key',
    models: ['gpt-4o', 'gpt-4o-mini', 'gpt-4-turbo', 'gpt-4', 'gpt-3.5-turbo-instruct', 'gpt-3.5-turbo'],
    requiredToAddKey: true,
  },
  huggingface_hub: {
    keyName: 'huggingfacehub_api_token',
    models: [
      'meta-llama/Meta-Llama-3-8B-Instruct',
      'mistralai/Mixtral-8x22B-v0.1',
      'mistralai/Mistral-7B-v0.3',
      'google/gemma-2-9b-it',
      'Qwen/Qwen2-72B-Instruct',
    ],
    requiredToAddKey: true,
  },
  bedrock: {
    keyName: '',
    models: [
      'amazon.titan-text-lite-v1',
      'anthropic.claude-v2',
      'anthropic.claude-v2:1',
      'anthropic.claude-3-sonnet-20240229-v1:0',
      'anthropic.claude-3-5-sonnet-20240620-v1:0',
      'anthropic.claude-3-haiku-20240307-v1:0',
      'anthropic.claude-instant-v1',
      'cohere.command-text-v14',
      'meta.llama3-1-405b-instruct-v1:0',
      'meta.llama3-1-70b-instruct-v1:0',
      'mistral.mistral-large-2407-v1:0',
    ],
    requiredToAddKey: false,
  },
  sambanova: {
    keyName: 'api_key',
    models: [
      'Meta-Llama-3.1-405B-Instruct',
      'Meta-Llama-3.1-70B-Instruct',
      'Meta-Llama-3.2-1B-Instruct',
      'Meta-Llama-3.2-3B-Instruct',
    ],
    requiredToAddKey: true,
  },
  vertexai: {
    keyName: '',
    models: ['gemini-1.0-pro', 'gemini-1.5-flash'],
    requiredToAddKey: false,
  },
};

// Reusable Form Field Components
const EngineSelect = ({ engine, setEngine }) => (
  <FormControl fullWidth>
    <InputLabel className="text-gray-700">Engine</InputLabel>
    <Select
      name="engine"
      label="Engine"
      value={engine || ''} 
      onChange={(e) => setEngine(e.target.value)}
      className="w-full bg-white rounded text-gray-900 focus:outline-none focus:ring-green-500"
    >
      {Object.keys(LLM_TYPES).map((key) => (
        <MenuItem key={key} value={key}>
          {key}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

const ModelSelect = ({ engine, model, setModel }) => {
  const modelOptions = useMemo(() => {
    return engine
      ? LLM_TYPES[engine].models.map((model) => (
          <MenuItem key={model} value={model}>
            {model}
          </MenuItem>
        ))
      : [];
  }, [engine]);

  return (
    <FormControl fullWidth>
      <InputLabel className="text-gray-700">Model</InputLabel>
      <Select
        name="model"
        label="Model"
        value={model || ''} 
        onChange={(e) => setModel(e.target.value)}
        className="w-full bg-white rounded text-gray-900 focus:outline-none focus:ring-green-500"
      >
        {modelOptions}
      </Select>
    </FormControl>
  );
};

const APIKeyField = ({ engine, apiKey, setApiKey }) => {
  const requiresKey = LLM_TYPES[engine]?.requiredToAddKey;
  if (!requiresKey) return null;

  return (
    <TextField
      label="API Key"
      name="apiKey"
      value={apiKey}
      onChange={(e) => setApiKey(e.target.value)}
      fullWidth
      className="w-full bg-white border-2 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
    />
  );
};

const AzureFields = ({ azureEndpoint, setAzureEndpoint, azureApiVersion, setAzureApiVersion, azureDeploymentName, setAzureDeploymentName }) => {
  const handleEndpointChange = (e) => {
    const value = e.target.value;
    setAzureEndpoint(value);
    
    // Try to extract Azure details from full URL if pasted
    try {
      if (value.includes('/openai/deployments/')) {
        const url = new URL(value);
        // Extract base endpoint
        setAzureEndpoint(`${url.protocol}//${url.hostname}`);

        
        // Extract deployment name from path
        const pathParts = url.pathname.split('/');
        const deploymentIndex = pathParts.indexOf('deployments');
        if (deploymentIndex !== -1 && deploymentIndex + 1 < pathParts.length) {
          setAzureDeploymentName(pathParts[deploymentIndex + 1]);
        }
        
        // Extract API version from query parameters
        const apiVersion = url.searchParams.get('api-version');
        if (apiVersion) {
          setAzureApiVersion(apiVersion);
        }
      }
    } catch (error) {
      // If URL parsing fails, just keep the value as is
      console.error('Failed to parse Azure URL:', error);
    }
  };

  return (
    <>
      <TextField
        label="Azure Endpoint"
        name="azureEndpoint"
        value={azureEndpoint}
        onChange={handleEndpointChange}
        fullWidth
        className="w-full bg-white border-2 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
        helperText="You can paste a full Azure OpenAI URL to auto-fill all fields"
      />
      <TextField
        label="API Version"
        name="azureApiVersion"
        value={azureApiVersion}
        onChange={(e) => setAzureApiVersion(e.target.value)}
        fullWidth
        className="w-full bg-white border-2 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
      />
      <TextField
        label="Deployment Name"
        name="azureDeploymentName"
        value={azureDeploymentName}
        onChange={(e) => setAzureDeploymentName(e.target.value)}
        fullWidth
        className="w-full bg-white border-2 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
      />
    </>
  );
};

const CredentialUpload = ({ engine, credentialsFile, setCredentialsFile }) => {
  if (engine !== 'vertexai') return null;

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/json') {
      setCredentialsFile(file);
    } else {
      toast.error('Please upload a valid JSON file.');
    }
  };

  return (
    <div className="mt-4">
      <input
        type="file"
        accept=".json"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="credentials-file"
      />
      <label htmlFor="credentials-file">
        <Button
          variant="contained"
          component="span"
          style={{
            backgroundColor: theme.tmryk_background_color,
            color: 'white',
            marginBottom: '10px',
          }}
        >
          Upload Google Credentials JSON
        </Button>
      </label>
      {credentialsFile && <p>Selected File: {credentialsFile.name}</p>}
    </div>
  );
};

const PolicyOptions = ({ options, setOptions }) => {
  const handleCheckboxChange = (option) => (event) => {
    const updatedOptions = { jailbreakDetection: false, moderation: false, hallucination: false };
    updatedOptions[option] = event.target.checked;
    setOptions(updatedOptions);
  };

  return (
    <Box>
      {Object.keys(options).map((option) => (
        <FormControlLabel
          key={option}
          control={
            <Checkbox
              checked={options[option]}
              onChange={handleCheckboxChange(option)}
              sx={{
                color: theme.tmryk_background_color,
                '&.Mui-checked': { color: theme.tmryk_background_color },
              }}
            />
          }
          label={option.replace(/([A-Z])/g, ' $1').trim()}
          sx={{ justifyContent: 'flex-start', width: '100%' }}
        />
      ))}
    </Box>
  );
};

export default function AddConfigModal({ isModalOpen, setIsModalOpen, onConfigAdded, selectedConfig }) {
  // Refs
  const formRef = useRef(null);
  const cancelButtonRef = useRef(null);
  const fileInputRef = useRef(null);

  // State Management
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [creationType, setCreationType] = useState('basic');
  const [configName, setConfigName] = useState('');
  const [engine, setEngine] = useState(selectedConfig?.engine || '');
  const [model, setModel] = useState(selectedConfig?.model || '');
  const [apiKey, setApiKey] = useState('');
  const [tenantId, setTenantId] = useState('');
  const [credentialsFile, setCredentialsFile] = useState(null);
  const [isSuperuser, setIsSuperuser] = useState(false);
  const [error, setError] = useState('');
  const [validationError, setValidationError] = useState('');
  const [loading, setLoading] = useState(false);
  const [configs, setConfigs] = useState([]);
  const [selectedConfigToUpdate, setSelectedConfigToUpdate] = useState('');
  const [options, setOptions] = useState({
    jailbreakDetection: false,
    moderation: false,
    hallucination: false,
  });

  // Azure-specific state
  const [azureEndpoint, setAzureEndpoint] = useState('');
  const [azureApiVersion, setAzureApiVersion] = useState('');
  const [azureDeploymentName, setAzureDeploymentName] = useState('');

  // Fetch User Info and Configurations
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await client.get('/user_info');
        const data = response.data;
        setTenantId(data.tenantId);
        setIsSuperuser(data.is_superuser);
      } catch {
        setError('Failed to fetch user info');
      }
    };

    const fetchConfigs = async () => {
      try {
        const response = await client.get('/v1/rails/configs');
        setConfigs(response.data);
      } catch {
        setError('Failed to fetch configurations');
      }
    };

    fetchUserInfo();
    fetchConfigs();
  }, []);

  // Reset fields on creation type change
  useEffect(() => {
    setValidationError('');
    setApiKey('');
    if (creationType !== 'update') {
      setConfigName('');
      setAzureEndpoint('');
      setAzureApiVersion('');
      setAzureDeploymentName('');
    }
  }, [creationType]);

  // Populate Form for Update
  useEffect(() => {
    if (selectedConfig) {
      setCreationType('update');
      setSelectedConfigToUpdate(selectedConfig.id);
      setConfigName(selectedConfig.id);
      setEngine(selectedConfig.engine || '');
      setModel(selectedConfig.model || '');
      if (selectedConfig.engine === 'azure') {
        setAzureEndpoint(selectedConfig.parameters?.azure_endpoint || '');
        setAzureApiVersion(selectedConfig.parameters?.api_version || '');
        setAzureDeploymentName(selectedConfig.parameters?.deployment_name || '');
      }
      setOptions({
        jailbreakDetection: selectedConfig.jailbreakDetection,
        moderation: selectedConfig.moderation,
        hallucination: selectedConfig.hallucination,
      });
    }
  }, [selectedConfig]);

  // Form Validation
  const validateForm = useCallback(() => {
    if (creationType !== 'update' && !configName.trim()) return 'Policy name is required.';
    if (!engine) return 'Engine is required.';
    if (!model) return 'Model is required.';
    if (LLM_TYPES[engine]?.requiredToAddKey && !apiKey.trim()) return 'API key is required.';
    if (engine === 'azure') {
      if (!azureEndpoint.trim()) return 'Azure endpoint is required.';
      if (!azureApiVersion.trim()) return 'Azure API version is required.';
      if (!azureDeploymentName.trim()) return 'Azure deployment name is required.';
    }
    if (engine === 'vertexai' && !credentialsFile) return 'Credentials file is required for Vertex AI engine.';
    if (creationType === 'advanced' && selectedFiles.length === 0) return 'At least one file must be selected.';
    return '';
  }, [creationType, configName, engine, model, apiKey, azureEndpoint, azureApiVersion, azureDeploymentName, selectedFiles.length, credentialsFile]);

  // API Key Management
  const setApiKeyEndpoint = useCallback(async (configId, apiKeyValue) => {
    const keyName = LLM_TYPES[engine].keyName;
    const endpoint = `/llm_api_key?config_id=${configId}&llm_api_key_name=${keyName}&llm_api_key_value=${apiKeyValue}`;
    try {
      const response = await client.post(endpoint);
      return response;
    } catch (error) {
      throw new Error(error.response?.data?.detail || 'Failed to set API key');
    }
  }, [engine]);

  const updateApiKeyEndpoint = useCallback(async (configId, apiKeyValue) => {
    const keyName = LLM_TYPES[engine].keyName;
    const endpoint = `/llm_api_key?config_id=${configId}&llm_api_key_name=${keyName}&llm_api_key_value=${apiKeyValue}`;
    try {
      const response = await client.put(endpoint);
      return response;
    } catch (error) {
      throw new Error(error.response?.data?.detail || 'Failed to update API key');
    }
  }, [engine]);

  // Generate Custom Config for Specific Engines
  const generateCustomConfig = useCallback((engine, selectedModel) => {
    if (engine === 'vertexai') {
      return {
        'config.py': `
import os
import pathlib

current = pathlib.Path(__file__).parent.resolve()
credential_path = f"{current}/google-creds.json"
os.environ['GOOGLE_APPLICATION_CREDENTIALS'] = credential_path
        `,
      };
    } else if (['bedrock', 'sambanova'].includes(engine)) {
      return {
        'config.py': `
import custom_llms.custom_llm_class_registry_tool as custom_llm_class_registry_tool
custom_llm_class_registry_tool.register_custom_llm_provider_class("${engine}/${selectedModel}")
        `,
      };
    }
    return null;
  }, []);

  // Handle File Selection
  const handleFileChange = (event) => {
    setSelectedFiles((prev) => [...prev, ...Array.from(event.target.files)]);
  };

  const removeFile = (fileName) => {
    setSelectedFiles((prev) => prev.filter((file) => file.name !== fileName));
  };

  // Create or Update Configuration
  const handleConfigCreationOrUpdate = useCallback(async (configId, configDataPayload, isUpdate) => {
    try {
      let endpoint;
      let formData = new FormData();

      if (creationType === 'advanced') {
        selectedFiles.forEach((file) => formData.append('files', file));
        formData.append('new_config_name', configName);
        formData.append('config_data', JSON.stringify(configDataPayload));
        
        const customConfig = generateCustomConfig(engine, model);
        if (customConfig) {
          formData.append('files', new Blob([customConfig['config.py']], { type: 'text/plain' }), 'config.py');
        }
        
        if (engine === 'vertexai' && model.includes('gemini') && credentialsFile) {
          formData.append('files', credentialsFile, 'google-creds.json');
        }

        endpoint = isUpdate ? `/edit_config?config_name=${configId}` : '/create_config_with_files';
        await createConfig(formData, endpoint);
      } else {
        const yamlPayload = { tenant_id: isSuperuser ? tenantId : '', 'config.yml': configDataPayload['config.yml'] };
        endpoint = isUpdate ? `/edit_config?config_name=${configId}&file_name=config.yml&content=${encodeURIComponent(yamlPayload['config.yml'])}` : `/create_config_with_yaml?config_name=${configId}`;
        await createConfig(yamlPayload, endpoint);
      }

      if (creationType !== 'advanced' && (engine === 'vertexai' || !['openai', 'huggingface_hub'].includes(engine))) {
        const extraFiles = new FormData();
        
        if (generateCustomConfig(engine, model)) {
          extraFiles.append('files', new Blob([generateCustomConfig(engine, model)['config.py']], { type: 'text/plain' }), 'config.py');
        }
        
        if (engine === 'vertexai' && model.includes('gemini') && credentialsFile) {
          extraFiles.append('files', credentialsFile, 'google-creds.json');
        }
        
        if (extraFiles.has('files')) {
          await client.post(`/add_files_to_config?config_name=${configId}`, extraFiles);
        }
      }

      const apiKeyValue = LLM_TYPES[engine].requiredToAddKey ? apiKey : '';
      const apiEndpoint = isUpdate ? updateApiKeyEndpoint : setApiKeyEndpoint;
      await apiEndpoint(configId, apiKeyValue);

      const newConfig = {
        id: configId,
        engine,
        model,
        apiKey,
        jailbreakDetection: options.jailbreakDetection,
        moderation: options.moderation,
        hallucination: options.hallucination,
      };
      onConfigAdded(newConfig);
      toast.success(isUpdate ? 'Policy updated successfully!' : 'Policy created successfully!');
      setIsModalOpen(false);
    } catch (error) {
      setError(error.message || 'Failed to create or update policy');
    } finally {
      setLoading(false);
    }
  }, [
    engine,
    model,
    creationType,
    generateCustomConfig,
    selectedFiles,
    configName,
    apiKey,
    credentialsFile,
    options,
    onConfigAdded,
    setIsModalOpen,
    setError,
    setLoading,
    setApiKeyEndpoint,
    updateApiKeyEndpoint,
  ]);

  const createConfig = useCallback(async (configData, endpoint) => {
    try {
      const response = await client.post(endpoint, configData);
      if (response.status !== 200) {
        throw new Error(response.data.error || 'Failed to create or update Policy');
      }
      return response;
    } catch (error) {
      throw new Error(error.response?.data?.detail || 'Failed to create or update Policy');
    }
  }, []);

  const createNewConfig = async () => {
    const validationMessage = validateForm();
    if (validationMessage) {
      setValidationError(validationMessage);
      return;
    }

    setLoading(true);

    const configId = creationType === 'update' ? selectedConfigToUpdate : configName;

    let configData = `models:\n  - type: main\n    engine: ${engine}\n    model: ${model}`;

    // Add Azure-specific parameters
    if (engine === 'azure') {
      configData += `\n    parameters:\n      azure_endpoint: "${azureEndpoint}"\n      api_version: "${azureApiVersion}"\n      deployment_name: "${azureDeploymentName}"`;
    }

    // Append Policy Options
    if (options.jailbreakDetection) {
      configData += `\n\nrails:\n  config:\n    jailbreak_detection:\n      server_endpoint: "${config.JAILBREAK_DETECTION_URL}"\n      lp_threshold: 89.79\n      ps_ppl_threshold: 1845.65\n\n  input:\n    flows:\n      - jailbreak detection heuristics`;
    } else if (options.moderation) {
      configData += `\n  - type: llama_guard\n    engine: vllm_openai\n    parameters:\n      openai_api_base: "${config.MODERATION_URL}"\n      model_name: "meta-llama/LlamaGuard-7b"\nrails:\n  input:\n    flows:\n      - llama guard check input\n  output:\n    flows:\n      - llama guard check output`;
    } else if (options.hallucination) {
      configData += `\n\nrails:\n  config:\n    fact_checking:\n      parameters:\n        endpoint: "${config.HALLUCINATION_URL}"\n  output:\n    flows:\n      - alignscore check facts`;
    }

    const configDataPayload = {
      tenant_id: isSuperuser ? tenantId : '',
      'config.yml': configData,
    };

    await handleConfigCreationOrUpdate(configId, configDataPayload, creationType === 'update');
  };

  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={setIsModalOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                <div
                  className="flex h-5/6 flex-col overflow-y-scroll bg-white shadow-xl rounded-lg"
                  style={{ marginTop: '40px', marginRight: '15px' }}
                >
                  {/* Header */}
                  <div className="px-4 py-6 sm:px-6 bg-white rounded-t-lg">
                    <div className="flex items-start justify-between">
                      <Dialog.Title
                        className="text-lg font-medium"
                        style={{ color: theme.tmryk_background_color }}
                      >
                        {creationType === 'update' ? 'Update Policy' : 'Add New Policy'}
                      </Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-white hover:bg-[#031A58] focus:outline-none focus:ring-2"
                          onClick={() => setIsModalOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <svg
                            className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    {/* Error Messages */}
                    {error && <Alert severity="error" className="mt-4">{error}</Alert>}
                    {validationError && <Alert severity="error" className="mt-4">{validationError}</Alert>}
                  </div>

                  {/* Body */}
                  <div className="relative flex-1 px-4 pb-6 sm:px-6">
                    <form ref={formRef} className="space-y-6" onSubmit={(e) => e.preventDefault()}>
                      {/* Creation Type Selection */}
                      <RadioGroup
                        value={creationType}
                        onChange={(e) => setCreationType(e.target.value)}
                        row
                      >
                        <FormControlLabel
                          value="basic"
                          control={
                            <Radio
                              sx={{
                                '&.Mui-checked': { color: theme.tmryk_background_color },
                              }}
                            />
                          }
                          label="Basic Creation"
                        />
                        <FormControlLabel
                          value="advanced"
                          control={
                            <Radio
                              sx={{
                                '&.Mui-checked': { color: theme.tmryk_background_color },
                              }}
                            />
                          }
                          label="Advanced Creation"
                        />
                        <FormControlLabel
                          value="update"
                          control={
                            <Radio
                              sx={{
                                '&.Mui-checked': { color: theme.tmryk_background_color },
                              }}
                            />
                          }
                          label="Update Policy"
                        />
                      </RadioGroup>

                      {/* Conditional Rendering Based on Creation Type */}
                      {(creationType === 'basic' || creationType === 'advanced' || creationType === 'update') && (
                        <>
                          {/* Update Specific Fields */}
                          {creationType === 'update' && (
                            <FormControl fullWidth>
                              <InputLabel className="text-gray-700">Select Policy to Update</InputLabel>
                              <Select
                                name="selectedConfigToUpdate"
                                label="Select Policy to Update"
                                value={selectedConfigToUpdate}
                                onChange={(e) => {
                                  const config = configs.find((c) => c.id === e.target.value);
                                  setSelectedConfigToUpdate(e.target.value);
                                  if (config) {
                                    setConfigName(config.id);
                                    setEngine(config.engine);
                                    setModel(config.model);
                                    setApiKey('');
                                    if (config.engine === 'azure') {
                                      setAzureEndpoint(config.parameters?.azure_endpoint || '');
                                      setAzureApiVersion(config.parameters?.api_version || '');
                                      setAzureDeploymentName(config.parameters?.deployment_name || '');
                                    }
                                    setOptions({
                                      jailbreakDetection: config.jailbreakDetection,
                                      moderation: config.moderation,
                                      hallucination: config.hallucination,
                                    });
                                  }
                                }}
                                className="w-full bg-white rounded text-gray-900 focus:outline-none focus:ring-green-500"
                              >
                                {configs.map((config) => (
                                  <MenuItem key={config.id} value={config.id}>
                                    {config.id}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}

                          {/* Policy Name */}
                          {(creationType === 'basic' || creationType === 'advanced') && (
                            <TextField
                              label="New Policy Name"
                              name="new_config_name"
                              value={configName}
                              onChange={(e) => setConfigName(e.target.value)}
                              fullWidth
                              className="w-full bg-white border-2 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
                            />
                          )}

                          {/* Tenant ID for Superusers */}
                          {(creationType === 'basic' || creationType === 'advanced') && isSuperuser && (
                            <TextField
                              label="Tenant ID"
                              name="tenantId"
                              value={tenantId}
                              onChange={(e) => setTenantId(e.target.value)}
                              fullWidth
                              className="w-full bg-white border-2 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
                              />
                            )}
  
                            {/* Engine Selection */}
                            <EngineSelect engine={engine} setEngine={setEngine} />
  
                            {/* Model Selection */}
                            <ModelSelect engine={engine} model={model} setModel={setModel} />
  
                            {/* Azure Fields */}
                            {engine === 'azure' && (
                              <AzureFields
                                azureEndpoint={azureEndpoint}
                                setAzureEndpoint={setAzureEndpoint}
                                azureApiVersion={azureApiVersion}
                                setAzureApiVersion={setAzureApiVersion}
                                azureDeploymentName={azureDeploymentName}
                                setAzureDeploymentName={setAzureDeploymentName}
                              />
                            )}
  
                            {/* API Key Field or Credentials Upload */}
                             {/* API Key Field */}
                             {engine === 'vertexai' ? (
                              <CredentialUpload engine={engine} credentialsFile={credentialsFile} setCredentialsFile={setCredentialsFile} />
                            ) : (
                              <APIKeyField engine={engine} apiKey={apiKey} setApiKey={setApiKey} />
                            )}
  
                            {/* Advanced Options */}
                            {creationType === 'advanced' && (
                              <>
                                {/* Policy Options */}
                                <PolicyOptions options={options} setOptions={setOptions} />
  
                                {/* File Upload */}
                                <div className="mt-2 flex justify-start">
                                  <Button
                                    variant="contained"
                                    onClick={() => fileInputRef.current.click()}
                                    style={{ backgroundColor: theme.tmryk_background_color, color: 'white' }}
                                  >
                                    Add Files
                                  </Button>
                                  <input
                                    ref={fileInputRef}
                                    type="file"
                                    multiple
                                    className="hidden"
                                    onChange={handleFileChange}
                                  />
                                </div>
                                {/* Selected Files List */}
                                <div className="mt-2">
                                  {selectedFiles.map((file) => (
                                    <div key={file.name} className="flex items-center justify-between mt-2">
                                      <span>{file.name}</span>
                                      <button
                                        type="button" // Added type="button" to prevent form submission
                                        onClick={() => removeFile(file.name)}
                                        className="bg-red-500 text-white rounded-full px-2 py-1 ml-2"
                                      >
                                        -
                                      </button>
                                    </div>
                                  ))}
                                </div>
                              </>
                            )}
  
                          </>
                        )}
  
                        {/* Action Buttons */}
                        <div className="mt-4 flex justify-end space-x-3">
                          <Button
                            variant="outlined"
                            onClick={() => setIsModalOpen(false)}
                            ref={cancelButtonRef}
                            style={{
                              borderColor: theme.tmryk_background_color,
                              color: theme.tmryk_background_color,
                              width: '150px',
                              height: '48px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              textTransform: 'none',
                              fontWeight: 'bold',
                            }}
                            disabled={loading}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: theme.tmryk_background_color,
                              color: 'white',
                              width: '150px',
                              height: '48px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              textTransform: 'none',
                              fontWeight: 'bold',
                              boxShadow: '0 3px 5px 2px rgba(84, 130, 78, .3)',
                            }}
                            onClick={createNewConfig}
                            disabled={loading}
                          >
                            {loading ? (
                              <CircularProgress size={24} color="inherit" />
                            ) : creationType === 'update' ? (
                              'Update Policy'
                            ) : (
                              'Add Policy'
                            )}
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
  