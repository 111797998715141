import './App.css';

// Pages
import LoginPage from "./pages/LoginPage";
import ConfigsPage from "./pages/ConfigsPage";
import ManageRolesPage from "./pages/ManageRolesPage";
import LlmScanPage from "./pages/LlmScanPage";
import UsersPage from './pages/UsersPage';
import AboutPage from './pages/AboutPage';
import RegistrationPage from './pages/RegistrationPage';
import UserSettingsPage from './pages/UserSettingsPage';
import DocsPage from './pages/DocsPage'
import DashboardPage from './pages/DashboardPage';
import GuidePage from './pages/GuidePage';

// Sidebar
import SidebarComponent from './components/layout/SidebarComponent';
import NavBarComponent from './components/layout/NavBarComponent';

// Configs
import client from "./client";

// React
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect, useState } from 'react';

// Material-UI
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import muiTheme from './muiTheme';

// Toast
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Quota Warning Modal
import QuotaExceededModal from './components/specific/QuotaExceededModal';
import AccessModal from './components/specific/AccessModal';


function App() {
  // 0: Checking login status
  // 1: Not logged in
  // 2: Logged in
  const [loggedIn, setLoggedIn] = useState(0); 
  const [isActive, setIsActive] = useState(true); 
  const [quotaWarning, setQuotaWarning] = useState({ open: false, title: '', message: '', severity: 'warning' });
  const [accessModalOpen, setAccessModalOpen] = useState(false);


  useEffect(() => {
    client.get("/check_login")
      .then(response => {
        if (response.status === 200) {
          setLoggedIn(2);
          setIsActive(response.data.is_active);
          // Handle quota warning only if user is active
          if (response.data.is_active && sessionStorage.getItem('showQuotaWarning') === 'true') {
            checkQuota();
          }
        } else {
          setLoggedIn(1);
        }
      })
      .catch(error => { setLoggedIn(1) });
  }, []);

  const checkQuota = () => {
    client.get("/monthly_quota_usage")
      .then(response => {
        const { monthly_usage, monthly_quota } = response.data;
        const remainingQuota = monthly_quota - monthly_usage;

        const formattedMonthlyQuota = monthly_quota.toLocaleString();
        const formattedRemainingQuota = remainingQuota.toLocaleString();

        if (remainingQuota <= 0) {
          setQuotaWarning({
            open: true,
            title: 'Quota Exceeded',
            message: `You have exceeded your monthly token quota, ${formattedMonthlyQuota}. Please upgrade your plan or contact support at support@tumeryk.com.`,
            severity: 'error'
          });
        }
        sessionStorage.removeItem('showQuotaWarning');
      })
      .catch(error => console.error('Error fetching quota usage:', error));
  };

  const handleCloseQuotaWarning = () => {
    setQuotaWarning({ ...quotaWarning, open: false });
  };

  useEffect(() => {
    const handleUserInfoUpdated = () => {
      document.dispatchEvent(new Event('fetchUserInfo'));
    };
    document.addEventListener('userInfoUpdated', handleUserInfoUpdated);

    return () => {
      document.removeEventListener('userInfoUpdated', handleUserInfoUpdated);
    };
  }, []);

  const handleCloseAccessModal = () => {
    setAccessModalOpen(false);
  };

  const handleOpenAccessModal = () => {
    setAccessModalOpen(true);
  };

  return loggedIn === 2 ? (
    <MuiThemeProvider theme={muiTheme}>
      <div className="flex flex-col h-screen">
        <ToastContainer />
        {!isActive && (
          <AccessModal
            open={accessModalOpen}
            handleClose={handleCloseAccessModal}
            title="Request Access"
            message="Your account is inactive. Please request access."
            severity="warning"
          />
        )}
        <QuotaExceededModal
          open={quotaWarning.open}
          handleClose={handleCloseQuotaWarning}
          title={quotaWarning.title}
          message={quotaWarning.message}
          severity={quotaWarning.severity}
        />
        <Router>
          <div className="flex flex-1 overflow-hidden pt-20">
            <NavBarComponent isActive={isActive} onRequestAccess={handleOpenAccessModal} />
            <SidebarComponent isActive={isActive} />
            <div className="flex-1 overflow-auto">
              <Routes>
                {isActive ? (
                  <>
                    {/* Routes accessible to active users */}
                    <Route path="/" element={<ConfigsPage />} />
                    <Route path="/configs" element={<ConfigsPage />} />
                    <Route path="/roles" element={<ManageRolesPage />} />
                    <Route path="/llm_scan" element={<LlmScanPage isActive={isActive} onRequestAccess={handleOpenAccessModal}/>} />
                    <Route path="/users" element={<UsersPage />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/user_settings" element={<UserSettingsPage />} />
                    <Route path="/docs" element={<DocsPage />} />
                    <Route path="/dashboard" element={<DashboardPage isActive={isActive} />} />
                    <Route path="/guide" element={<GuidePage />} />
                  </>
                ) : (
                  <>
                    {/* Routes accessible to inactive users */}
                    <Route path="/" element={<GuidePage />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/user_settings" element={<UserSettingsPage />} />
                    <Route path="/guide" element={<GuidePage />} />
                    <Route path="/docs" element={<DocsPage />} />
                    <Route path="/dashboard" element={<DashboardPage isActive={isActive} />} />
                    <Route path="/llm_scan" element={<LlmScanPage isActive={isActive} onRequestAccess={handleOpenAccessModal}/>} />
                  </>
                )}
              </Routes>
            </div>
          </div>
        </Router>
      </div>
    </MuiThemeProvider>
  ) : loggedIn === 1 ? (
    <div>
      <Router>
        <Routes>
          <Route path="/register" element={<RegistrationPage />} />
          <Route path="*" element={<LoginPage />} />
        </Routes>
      </Router>
    </div>
  ) : (
    <div></div>
  ); 
}

export default App;
