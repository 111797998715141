import React, { useEffect, useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Typography, IconButton, CircularProgress, Paper } from "@mui/material";
import { Close as CloseIcon, ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { toast } from "react-toastify";
import client from "../../client";
import theme from "../../theme";

export default function ViewListModal({
  isOpen,
  setIsOpen,
  listName,
  setIsModalOpen,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [listDetails, setListDetails] = useState(null);

  useEffect(() => {
    const fetchListDetails = async () => {
      setIsLoading(true);
      try {
        const response = await client.get(`/custom_suite_list/${listName}`);
        setListDetails(response.data);
      } catch (error) {
        console.error("Error fetching list details:", error);
        toast.error("Error fetching list details");
      } finally {
        setIsLoading(false);
      }
    };

    if (isOpen && listName) {
      fetchListDetails();
    }
  }, [isOpen, listName]);

  const handleClose = () => {
    setIsOpen(false);
    setIsModalOpen(false); // Close RunScanProbesModal when ViewListModal closes
  };

  const handleBack = () => {
    setIsOpen(false);
    setIsModalOpen(true); // Reopen RunScanProbesModal when navigating back
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleClose}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden flex justify-center items-center">
            <Transition.Child
              as="div"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-y-[-100%]"
              enterTo="translate-y-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-y-0"
              leaveTo="translate-y-[-100%]"
            >
              <Dialog.Panel className="pointer-events-auto w-screen max-w-lg h-full max-h-[80vh] flex flex-col bg-white shadow-xl rounded-lg overflow-y-auto">
                <div className="px-4 pt-6 sm:px-6 bg-white rounded-t-lg">
                  <div className="flex items-start justify-between w-full">
                    <div className="flex items-center">
                      <IconButton onClick={handleBack} style={{ color: theme.tmryk_background_color }}>
                        <ArrowBackIcon />
                      </IconButton>
                      <Dialog.Title className="text-lg font-medium ml-2" style={{ color: theme.tmryk_background_color }}>
                        View List
                      </Dialog.Title>
                    </div>
                    <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-white hover:bg-[#031A58] focus:outline-none focus:ring-2"
                          onClick={handleClose}
                        >
                          <span className="sr-only">Close panel</span>
                          <CloseIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                  </div>
                </div>
                {isLoading ? (
                  <div className="flex justify-center items-center min-h-[200px]">
                    <CircularProgress />
                  </div>
                ) : (
                  <div className="relative flex-1 px-4 py-6 sm:px-6 overflow-y-auto">
                    {listDetails ? (
                      <>
                        <Typography variant="h6" style={{ color: theme.tmryk_background_color, marginBottom: '10px' }}>
                          {listDetails.config.list_name}
                        </Typography>
                        <Paper elevation={3} style={{ padding: '10px', marginBottom: '10px' }}>
                          <Typography variant="body1" style={{ color: theme.tmryk_background_color }}>
                            Created by: {listDetails.config.created_by}
                          </Typography>
                          <Typography variant="body1" style={{ color: theme.tmryk_background_color }}>
                            Date: {listDetails.config.date}
                          </Typography>
                        </Paper>
                        <Typography variant="subtitle1" style={{ color: theme.tmryk_background_color, marginBottom: '10px' }}>
                          Prompts and Responses
                        </Typography>
                        <Paper elevation={3} style={{ padding: '10px', marginBottom: '20px', maxHeight: '200px', overflowY: 'auto' }}>
                          {listDetails.prompts_responses && Object.keys(listDetails.prompts_responses).length > 0 ? (
                            Object.entries(listDetails.prompts_responses).map(([prompt, response], index) => (
                              <div key={index} style={{ marginBottom: '10px' }}>
                                <Typography variant="body2" style={{ fontWeight: 'bold' }}>Prompt:</Typography>
                                <Typography variant="body2">{prompt}</Typography>
                                <Typography variant="body2" style={{ fontWeight: 'bold' }}>Response:</Typography>
                                <Typography variant="body2">{response}</Typography>
                              </div>
                            ))
                          ) : (
                            <Typography variant="body2" style={{ color: theme.tmryk_background_color }}>
                              No prompts and responses available.
                            </Typography>
                          )}
                        </Paper>
                        <Typography variant="subtitle1" style={{ color: theme.tmryk_background_color, marginBottom: '10px' }}>
                          Denied Substrings
                        </Typography>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          {Array.isArray(listDetails.substrings) && listDetails.substrings.length > 0 ? (
                            listDetails.substrings.map((substr, index) => (
                              <Paper key={index} elevation={1} style={{ padding: '5px 10px', margin: '5px', backgroundColor: theme.tmryk_background_color, color: 'white' }}>
                                <Typography variant="body2">{substr}</Typography>
                              </Paper>
                            ))
                          ) : (
                            <Typography variant="body2" style={{ color: theme.tmryk_background_color }}>
                              No substrings available.
                            </Typography>
                          )}
                        </div>
                      </>
                    ) : (
                      <Typography variant="body1" style={{ color: theme.tmryk_background_color }}>
                        No details available.
                      </Typography>
                    )}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
