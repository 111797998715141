import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Alert, CircularProgress } from '@mui/material';
import client from '../../../client';
import theme from '../../../theme';

function DeleteUserModal({ isModalOpen, setIsModalOpen, selectedUser, onUserDeleted }) {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const deleteUser = async () => {
        setLoading(true);
        setError(null);
        try {
            await client.post(`/delete_user/${selectedUser.user_id}`);
            onUserDeleted();
            setIsModalOpen(false);
        } catch (error) {
            const errorMessage = error.response?.data?.detail || "Error deleting user. Please try again.";
            setError(errorMessage);
            console.error("Error deleting user:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Transition.Root show={isModalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={() => setIsModalOpen(false)}>
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="space-y-6 px-4 py-5 sm:p-6">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900" style={{ color: theme.tmryk_background_color }}>Confirm Delete</h3>
                                    <p>Are you sure you want to delete "{selectedUser?.user_name}"?</p>
                                    {error && <Alert severity="error">{error}</Alert>}
                                    <div className="flex justify-end space-x-3">
                                        <button
                                            type="button"
                                            onClick={deleteUser}
                                            className="inline-flex justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                            disabled={loading}
                                        >
                                            {loading ? <CircularProgress size={24} color="inherit" /> : 'Delete'}
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => setIsModalOpen(false)}
                                            className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                            disabled={loading}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export default DeleteUserModal;
