import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Button, Alert } from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import theme from '../../theme';

const AccessModal = ({ open, handleClose, title }) => {
  const handleRequestAccess = () => {
    window.open(
      'https://aws.amazon.com/marketplace/pp/prodview-hh7p7n4i5yune?sr=0-2&ref_=beagle&applicationId=AWSMPContessa',
      '_blank'
    );
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleClose}>
        {/* Overlay */}
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        {/* Modal Content */}
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-y-full"
              enterTo="translate-y-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-y-0"
              leaveTo="translate-y-full"
            >
              <Dialog.Panel className="pointer-events-auto w-full max-w-3xl">
                <div
                  className="flex h-5/6 flex-col overflow-y-scroll bg-white shadow-xl rounded-lg"
                  style={{ marginTop: '80px', marginRight: '15px' }}
                >
                  {/* Header */}
                  <div className="px-4 pt-6 sm:px-6 bg-white rounded-t-lg">
                    <div className="flex items-start justify-between">
                      <Dialog.Title
                        className="text-lg font-medium"
                        style={{ color: theme.tmryk_background_color }}
                      >
                        {title}
                      </Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-white hover:bg-[#031A58] focus:outline-none focus:ring-2"
                          onClick={handleClose}
                        >
                          <span className="sr-only">Close panel</span>
                          <svg
                            className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Body */}
                  <div className="relative flex-1 px-4 py-6 sm:px-6">
                    <div className="space-y-6">
                      <Alert severity="warning">
                      Your account is currently inactive. 
                      To enable full access to all features, 
                      please request activation. 
                      For any further assistance, contact us at <a href="mailto:support@tumeryk.com">
                        support@tumeryk.com</a>.
                      </Alert>
                    </div>
                    {/* Buttons */}
                    <div className="mt-4 flex justify-end space-x-3">
                      {/* Close Button */}
                      <Button
                        variant="outlined"
                        onClick={handleClose}
                        style={{
                          borderColor: theme.tmryk_background_color,
                          color: theme.tmryk_background_color,
                          width: '150px',
                          height: '48px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textTransform: 'none',
                          fontWeight: 'bold',
                        }}
                      >
                        Close
                      </Button>
                      {/* Request Access Button */}
                      <Button
                        variant="contained"
                        onClick={handleRequestAccess}
                        startIcon={<LockOpenIcon />}
                        style={{
                          backgroundColor: theme.tmryk_background_color,
                          color: 'white',
                          width: '175px',
                          height: '48px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textTransform: 'none',
                          fontWeight: 'bold',
                          boxShadow: '0 3px 5px 2px rgba(84, 130, 78, .3)',
                        }}
                      >
                        Request Access
                      </Button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AccessModal;
