import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { PieChart, BarChart, LineChart } from '@mui/x-charts';
import client from '../../../client';

const ScanDashboardModal = ({ startDate, endDate, selectedModel }) => {
  const [owaspData, setOwaspData] = useState(null);
  const [customData, setCustomData] = useState(null);
  const [testSuiteData, setTestSuiteData] = useState(null);
  const [selectedProbe, setSelectedProbe] = useState('dan'); // Default to DAN
  const [barChartData, setBarChartData] = useState([]);
  const [lineChartData, setLineChartData] = useState({ custom_probes: [], my_test_suite: [], owasp: [] });

  const regularProbes = {
    dan: 'DAN',
    atkgen: 'Attack Generation',
    glitch: 'Glitch',
    knownbadsignatures: 'Known Bad Signatures',
    lmrc: 'LMRC',
    packagehallucination: 'Package Hallucination',
    promptinject: 'Prompt Inject',
    tap: 'TAP',
    xss: 'XSS',
    grandma: 'Grandma',
  };

  const owaspProbes = {
    'owasp:llm01': 'Prompt Injection (LLM01)',
    'owasp:llm02': 'Insecure Output Handling (LLM02)',
    'owasp:llm03': 'Training Data Poisoning (LLM03)',
    'owasp:llm04': 'Model Denial of Service (LLM04)',
    'owasp:llm05': 'Supply Chain Vulnerabilities (LLM05)',
    'owasp:llm06': 'Sensitive Information Disclosure (LLM06)',
    'owasp:llm07': 'Insecure Plugin Design (LLM07)',
    'owasp:llm08': 'Excessive Agency (LLM08)',
    'owasp:llm09': 'Overreliance (LLM09)',
    'owasp:llm10': 'Model Theft (LLM10)',
  };

  const customProbes = {
    'Custom': 'Custom Test Suite',
  };

  const allProbes = {
    ...regularProbes,
    ...owaspProbes,
    ...customProbes,
  };

  useEffect(() => {
    fetchAllData();
  }, [startDate, endDate, selectedProbe, selectedModel]);

  const fetchAllData = () => {
    fetchData('owasp', startDate, endDate, setOwaspData);
    fetchData('custom_probes', startDate, endDate, setCustomData);
    fetchData('my_test_suite', startDate, endDate, setTestSuiteData);
    fetchTrendData();
    if (selectedProbe && selectedProbe !== '') {
      fetchBarChartData(selectedProbe);
    } else {
      setBarChartData([]);
    }
  };

  const fetchData = (probeType, startDate, endDate, setData) => {
    client.get('/api/score_distribution', {
      params: {
        probe_type: probeType,
        start_date: startDate.toISOString().split('T')[0],
        end_date: endDate.toISOString().split('T')[0],
        model: selectedModel === 'All' ? undefined : selectedModel, // Include model filter
      }
    })
    .then(response => setData(response.data))
    .catch(error => {
      console.error('Error fetching data:', error);
      setData(null);
    });
  };

  const fetchBarChartData = (probe) => {
    client.get('/bar_data', {
      params: {
        probe_set: probe,
        start_date: startDate.toISOString().split('T')[0],
        end_date: endDate.toISOString().split('T')[0],
        model: selectedModel === 'All' ? undefined : selectedModel, // Include model filter
      }
    })
    .then(response => {
      const formattedData = response.data.map(item => ({
        x: item.model,
        y: item.average_score,
      }));
      setBarChartData(formattedData);
    })
    .catch(error => {
      console.error('Error fetching bar chart data:', error);
      setBarChartData([]);
    });
  };

  const fetchTrendData = () => {
    client.get('/trend_data', {
      params: {
        start_date: startDate.toISOString().split('T')[0],
        end_date: endDate.toISOString().split('T')[0],
        interval: 'week',  // Changed from 'weekly' to 'week'
        model: selectedModel === 'All' ? undefined : selectedModel, 
      }
    })
    .then(response => {
      setLineChartData(response.data);
    })
    .catch(error => {
      console.error('Error fetching trend data:', error);
      setLineChartData({ custom_probes: [], my_test_suite: [], owasp: [] });
    });
  };

  const handleProbeChange = (event) => {
    setSelectedProbe(event.target.value);
  };

  const renderPieChart = (data) => {
    if (data && data.average_score !== null) {
      const chartData = [
        { label: 'Excellent', value: data.excellent, color: '#448448' },
        { label: 'Good', value: data.good, color: '#50C878' },
        { label: 'Fair', value: data.fair, color: '#ffdd33' },
        { label: 'Poor', value: data.poor, color: '#FFBF00' },
        { label: 'Very Poor', value: data.very_poor, color: '#ff4637' },
      ];

      return (
        <>
          <PieChart
            series={[
              {
                data: chartData,
                innerRadius: 50,
                outerRadius: 90,
                label: () => '',
              },
            ]}
            slotProps={{
              legend: {
                direction: 'row',
                position: { vertical: 'bottom', horizontal: 'middle' },
                padding: -3,
                itemMarkWidth: 11,
                itemMarkHeight: 11,
              },
            }}
            margin={{ top: 70, bottom: 100, left: 100, right: 100 }}
          />
          <Typography
            variant="h6"
            align="center"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -90%)',
              pointerEvents: 'none',
            }}
          >
            {data.average_score.toFixed(1)}
          </Typography>
        </>
      );
    }
    return (
      <Typography
        variant="h6"
        align="center"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          pointerEvents: 'none',
        }}
      >
        No data to display
      </Typography>
    );
  };

  const renderBarChart = () => {
    if (selectedProbe === '') {
      return (
        <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="h6">No data available for the selected probe.</Typography>
        </Box>
      );
    }

    if (barChartData.length > 0) {
      return (
        <Box sx={{ width: '100%', height: '100%', flexGrow: 1 }}>
          <BarChart
            xAxis={[
                {
                scaleType: 'band',
                data: barChartData.map(item => item.x),  // X-axis data
                label: 'Model',
                tickLine: true,
                style: {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    maxWidth: '100%',
                },
                labelStyle: {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    maxWidth: '100%',
                },
                formatter: (label) => label.length > 15 ? `${label.slice(0, 12)}...` : label, // Apply ellipsis for long labels
                },
            ]}
            yAxis={[
                {
                scaleType: 'linear',
                min: 0,
                max: 100,  // Y-axis scale up to 100
                label: 'Average Score',
                ticks: [0, 25, 50, 75, 100],  // Specify ticks explicitly if needed
                },
            ]}
            series={[
                {
                data: barChartData.map(item => item.y),  // Y-axis data
                label: 'Average Score',
                barLabel: 'value',  // Display the score as the label on the bar
                colors: ['#4caf50'],  // Bar color
                },
            ]}
            slotProps={{
                legend: {
                itemMarkWidth: 11,
                itemMarkHeight: 11,
                },
            }}
            tooltip={{
                show: true,
            }}
            sx={{ width: '100%', height: '100%', paddingBottom: '25px' }}
          />
        </Box>
      );
    }
    return (
      <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="h6">No data available for the selected probe.</Typography>
      </Box>
    );
  };

  const renderLineChart = () => {
    if (lineChartData.custom_probes.length > 0 || lineChartData.my_test_suite.length > 0 || lineChartData.owasp.length > 0) {
      // Reformat the raw response data for the line chart
      const data = [
        { name: 'Custom Probes', data: lineChartData.custom_probes.map((item) => item.average_score), color: '#4caf50' }, // Green color
        { name: 'My Test Suite', data: lineChartData.my_test_suite.map((item) => item.average_score), color: '#2196f3' }, // Blue color
        { name: 'OWASP', data: lineChartData.owasp.map((item) => item.average_score), color: '#f44336' }, // Red color
      ];

      // Extract periods (dates) from the raw data for the X-axis
      const xData = lineChartData.custom_probes.map((item) => item.period);  // Assume periods are the same across all probes

      return (
        <Box sx={{ width: '100%', height: '100%', flexGrow: 1 }}>
          <LineChart
            xAxis={[{
              data: xData,
              label: 'Period',
              scaleType: 'point',
              style: {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                maxWidth: '100%',
              },
              formatter: (label) => label.length > 15 ? `${label.slice(0, 12)}...` : label,
            }]}
            series={data.map(series => ({
              data: series.data,
              label: series.name,
              color: series.color,
              strokeWidth: 2,
            }))}
            yAxis={[{ label: 'Score', min: 0, max: 100 }]}
            slotProps={{
              legend: {
                itemMarkWidth: 11,
                itemMarkHeight: 11,
              },
            }}
            tooltip={{
              show: true,
            }}
            sx={{ width: '100%', height: '100%', paddingBottom: '25px' }}
          />
        </Box>
      );
    }
    return (
      <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="h6">No data available for the selected probe.</Typography>
      </Box>
    );
  };

  return (
    <Box>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={4}>
          <Paper style={{ padding: 16, boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', borderRadius: 8 }}>
            <Typography variant="h6" align="center" gutterBottom>
              Scores for OWASP probes with breakdown
            </Typography>
            <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', height: 250 }}>
              {renderPieChart(owaspData)}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{ padding: 16, boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', borderRadius: 8 }}>
            <Typography variant="h6" align="center" gutterBottom>
              Scores for Custom probes with breakdown
            </Typography>
            <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', height: 250 }}>
              {renderPieChart(customData)}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{ padding: 16, boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', borderRadius: 8 }}>
            <Typography variant="h6" align="center" gutterBottom>
              Scores for My Test Suite with breakdown
            </Typography>
            <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', height: 250 }}>
              {renderPieChart(testSuiteData)}
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3} justifyContent="center" sx={{ marginTop: 3 }}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: 2, height: '400px', display: 'flex', flexDirection: 'column', minHeight: '300px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6" align="center" gutterBottom>
                Average Score per Model
              </Typography>
              <FormControl variant="outlined" sx={{ minWidth: 150 }} size="small">
                <InputLabel>Probe</InputLabel>
                <Select value={selectedProbe} onChange={handleProbeChange} label="Probe">
                  <MenuItem value="">
                    <em>Select a Probe</em>
                  </MenuItem>
                  <MenuItem disabled>Regular Probes</MenuItem>
                  {Object.entries(regularProbes).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                  <MenuItem disabled>OWASP Probes</MenuItem>
                  {Object.entries(owaspProbes).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                  <MenuItem disabled>Custom Probes</MenuItem>
                  {Object.entries(customProbes).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ flexGrow: 1, height: '100%' }}>
              {renderBarChart()}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: 2, height: '400px', display: 'flex', flexDirection: 'column', minHeight: '300px' }}>
            <Typography variant="h6" align="center" gutterBottom>
              Score Trend Over Time
            </Typography>
            <Box sx={{ flexGrow: 1, height: '100%' }}>
              {renderLineChart()}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ScanDashboardModal;
