import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
} from '@mui/material';
import theme from '../../../theme';
import client from '../../../client';
import { toast } from 'react-toastify';

function ImportPolicyModal({ isModalOpen, setIsModalOpen, onImportSuccess }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setIsModalOpen(false);
    setSelectedFile(null);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      toast.error('Please select a file to import');
      return;
    }

    setIsSubmitting(true);
    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await client.post('/import_policy', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      // Get the imported policy ID from the response
      const importedPolicyId = response.data?.policy_id;
      
      // Optionally, show a toast message here or let the parent component handle it
      // toast.success('Policy imported successfully!');
      
      if (onImportSuccess) {
        // Pass the imported policy ID to the callback
        await onImportSuccess(importedPolicyId);
      }
      handleClose();
    } catch (error) {
      console.error('Error importing policy:', error);
      toast.error(error.response?.data?.detail || 'Error importing policy');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog 
      open={isModalOpen} 
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ backgroundColor: theme.tmryk_background_color, color: 'white' }}>
        Import Policy
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Import a policy by selecting a policy file.
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box>
            <input
              accept="*/*"
              style={{ display: 'none' }}
              id="policy-file-upload"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="policy-file-upload">
              <Button
                variant="outlined"
                component="span"
                sx={{
                  borderColor: theme.tmryk_background_color,
                  color: theme.tmryk_background_color,
                  '&:hover': {
                    borderColor: theme.tmryk_background_color,
                  },
                }}
              >
                Select File
              </Button>
            </label>
            {selectedFile && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                Selected file: {selectedFile.name}
              </Typography>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          disabled={isSubmitting}
          sx={{
            backgroundColor: theme.tmryk_background_color,
            color: 'white',
            '&:hover': {
              backgroundColor: '#172E6C',
            },
          }}
        >
          Import
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ImportPolicyModal;
