import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Alert,
  RadioGroup,
  FormLabel,
  FormControl,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { METRICS, METRIC_CATEGORIES } from "../../constants/metricsConfig";
import UnifiedToggle from "./UnifiedToggle";
import theme from "../../theme";
import SelectFilesButtonComponent from "../modals/configs/SelectFilesButtonComponent";
import SelectFilesListComponent from "../modals/configs/SelectFilesListComponent";
import SensitiveDataConfigModal from "../modals/configs/SensitiveDataConfigModal";
import PropTypes from "prop-types";
import * as yamlParser from "js-yaml";
import {
  convertPdfToMarkdown,
  convertDocxToMarkdown,
  createFileFromText,
} from "../../utils/fileConverter";
import { toast } from "react-toastify";

const TrustMetricsPanel = ({
  thresholds,
  existingRagConfigType,
  parsedConfig,
  runDetails,
  selectedConfig,
  selectedFiles,
  setSelectedFiles,
  flowsPresent,
  handleToggle,
  handleThresholdChangeCommitted,
  addFilesToKnowledgeBase,
  addFilesToQdrantServer,
  onSaveSensitiveDataEntities,
  onConfigureTopicGuard,
  filesContent,
}) => {
  const formRef = useRef(null);
  const [sensitiveDataConfigOpen, setSensitiveDataConfigOpen] = useState(false);
  const [sensitiveDataConfigType, setSensitiveDataConfigType] = useState("");
  const [currentEntities, setCurrentEntities] = useState([]);
  const [isConverting, setIsConverting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [convertedFiles, setConvertedFiles] = useState([]);
  const [conversionError, setConversionError] = useState(null);
  const [ragType, setRagType] = useState(existingRagConfigType || "kb");
  const [topicGuardTopics, setTopicGuardTopics] = useState({
    relevant_topics: [],
    disallowed_topics: [],
    primary_topics: [],
    score_threshold: 500, // Default threshold
  });

  useEffect(() => {
    setRagType(existingRagConfigType || "kb");
  }, [existingRagConfigType]);

  // Process topic guard configuration from the consolidated file
  useEffect(() => {
    if (selectedConfig?.id && filesContent) {
      let relevantTopics = [];
      let disallowedTopics = [];
      let primaryTopics = [];
      let scoreThreshold = 500; // Default threshold

      // Load from topic_guard.yml file
      if (filesContent["topic_guard.yml"]) {
        try {
          const parsed = yamlParser.load(filesContent["topic_guard.yml"]);
          if (parsed) {
            // Get relevant topics
            if (Array.isArray(parsed.relevant_topics)) {
              relevantTopics = parsed.relevant_topics;
            }

            // Get disallowed topics
            if (Array.isArray(parsed.disallowed_topics)) {
              disallowedTopics = parsed.disallowed_topics;
            }

            // Get primary topics
            if (Array.isArray(parsed.primary_topics)) {
              primaryTopics = parsed.primary_topics;
            }

            // Get score threshold from topic_guard.yml
            if (parsed.score_threshold !== undefined) {
              scoreThreshold = parsed.score_threshold;
              console.log(
                "Loaded score threshold from topic_guard.yml:",
                scoreThreshold
              );
            }
          }
        } catch (e) {
          console.error("Error parsing topic_guard.yml:", e);
        }
      }

      // Check if we need to get the threshold from thresholds.yml
      if (filesContent["thresholds.yml"]) {
        try {
          const thresholdsData = yamlParser.load(
            filesContent["thresholds.yml"]
          );
          if (
            thresholdsData &&
            thresholdsData.topic_guard_threshold &&
            thresholdsData.topic_guard_threshold.threshold !== undefined
          ) {
            scoreThreshold = thresholdsData.topic_guard_threshold.threshold;
            console.log(
              "Loaded score threshold from thresholds.yml:",
              scoreThreshold
            );
          }
        } catch (e) {
          console.error("Error parsing thresholds.yml:", e);
        }
      }

      // Update state with the topics and threshold
      setTopicGuardTopics({
        relevant_topics: relevantTopics,
        disallowed_topics: disallowedTopics,
        primary_topics: primaryTopics,
        score_threshold: scoreThreshold,
      });
    }
  }, [selectedConfig, filesContent]);

  const handleConfigureSensitiveData = (type) => {
    // Extract current entities from the config
    let entities = [];
    if (
      parsedConfig?.rails?.config?.sensitive_data_detection?.[
        type.toLowerCase()
      ]?.entities
    ) {
      entities =
        parsedConfig.rails.config.sensitive_data_detection[type.toLowerCase()]
          .entities;
    }

    setSensitiveDataConfigType(type.toLowerCase());
    setCurrentEntities(entities);
    setSensitiveDataConfigOpen(true);
  };

  const handleSaveSensitiveDataConfig = async (entities) => {
    if (onSaveSensitiveDataEntities) {
      const success = await onSaveSensitiveDataEntities(
        sensitiveDataConfigType,
        entities
      );
      if (success) {
        setSensitiveDataConfigOpen(false);
      }
    }
  };

  // Function to handle file conversion
  const handleFilesForConversion = async (files) => {
    setIsConverting(true);
    setConversionError(null);
    const converted = [];
    const originalFiles = [];

    try {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (file.name.toLowerCase().endsWith(".pdf")) {
          // Convert PDF to Markdown
          const { text, filename } = await convertPdfToMarkdown(file);
          const mdFile = createFileFromText(text, filename);
          converted.push(mdFile);
        } else if (file.name.toLowerCase().endsWith(".docx")) {
          // Convert DOCX to Markdown
          const { text, filename } = await convertDocxToMarkdown(file);
          const mdFile = createFileFromText(text, filename);
          converted.push(mdFile);
        } else {
          // Keep original file (non-PDF, non-DOCX)
          originalFiles.push(file);
        }
      }

      // Update selected files with converted files + original non-PDF/DOCX files
      setConvertedFiles(converted);
      setSelectedFiles([...originalFiles, ...converted]);

      // Clear the original file input to prevent the original files from being uploaded
      if (formRef.current && formRef.current.elements.files) {
        formRef.current.elements.files.value = "";
      }
    } catch (error) {
      console.error("File conversion error:", error);
      setConversionError(
        "Failed to convert some files. Please try again or use Markdown/text files."
      );
    } finally {
      setIsConverting(false);
    }
  };

  const handleUpload = async () => {
    setIsUploading(true);
    if (selectedFiles.length === 0) {
      return;
    }

    // Create a new FormData object directly instead of relying on the form
    const formData = new FormData();
    let upload_status = false;

    // Add each selected file (original non-PDF/DOCX files + converted files)
    selectedFiles.forEach((file) => {
      formData.append("files", file);
    });

    // Replace the form's files with our curated selection
    if (formRef.current) {
      // Temporarily create a DataTransfer object
      const dataTransfer = new DataTransfer();
      selectedFiles.forEach((file) => dataTransfer.items.add(file));

      // Replace the files in the form input
      if (formRef.current.elements.files) {
        formRef.current.elements.files.files = dataTransfer.files;
      }

      // Now call the addFilesToKnowledgeBase function with the updated form
      if (ragType === "qdrant") {
        upload_status = await addFilesToQdrantServer(formRef);
      } else {
        upload_status = await addFilesToKnowledgeBase(formRef);
      }
    }
    setConvertedFiles([]);
    setIsUploading(false);

    // Enabling the  Topic Guard and opening the configure topic guard dialog for each file upload.
    if (upload_status) {
      // clear the selected files after successful uploading
      setSelectedFiles([]);
      formRef.current.elements.files.value = "";
      toast.info(
        "Enabling the Topic Guard for RAG based policy. Please configure Topics."
      );
      // Enable the Topic guard if RAG is configured
      const toggleTopic = await handleToggle(
        {
          label: "Topic Guard",
          style: "configKeyAndFlow",
          flows: { input: "topic guard check input" },
          configKey: "topic_guard",
        },
        true
      );
      if (toggleTopic) {
        // Opening the Topic Model for configure the topics
        onConfigureTopicGuard();
      }
    }
  };

  // Check if a metric is Topic Guard
  const isTopicGuard = (metricLabel) => {
    return metricLabel === "Topic Guard";
  };

  // Helper function to get the correct score for a metric
  const getMetricScore = (metric, runDetails) => {
    // Special case for Topic Guard to use topic_score instead of trust_score
    if (isTopicGuard(metric.label)) {
      // Check for topic_score first, fallback to trust_score for backward compatibility
      if (runDetails?.topic_score !== undefined) {
        console.log(
          "Using topic_score for Topic Guard:",
          runDetails.topic_score
        );
        return runDetails.topic_score;
      } else if (runDetails?.trust_score !== undefined) {
        console.log(
          "Fallback to trust_score for Topic Guard:",
          runDetails.trust_score
        );
        if (runDetails.trust_score > 500) {
          return 1000;
        } else {
          return 0;
        }
      }
    }

    // For other metrics with scoreKey
    if (metric.scoreKey) {
      let rawScore;
      switch (metric.scoreKey) {
        case "input_fairness_score":
          rawScore = runDetails?.bias_score?.input;
          break;
        case "output_fairness_score":
          rawScore = runDetails?.bias_score?.output;
          break;
        case "input_moderation_score":
          rawScore = runDetails?.moderation_score_input;
          break;
        case "output_moderation_score":
          rawScore = runDetails?.moderation_score_output;
          break;
        default:
          // Check primary scoreKey first, then altScoreKey if available
          rawScore = runDetails?.[metric.scoreKey];
          // If primary score key not found but altScoreKey exists, try using that
          if (
            (rawScore === undefined || rawScore === null) &&
            metric.altScoreKey
          ) {
            rawScore = runDetails?.[metric.altScoreKey];
          }
      }
      return rawScore === -1 || rawScore === undefined || rawScore === null
        ? "N/A"
        : rawScore;
    }

    return "N/A";
  };

  return (
    <Box
      paddingX={2}
      borderLeft="1px solid #ccc"
      sx={{ overflowY: "auto", backgroundColor: "#ffffff" }}
      width="400px"
    >
      <Box
        p={1}
        marginY={1}
        borderBottom="5px solid #ccc"
        bgcolor={theme.tmryk_background_color}
        color="white"
        borderRadius={1}
        flexShrink={0}
      >
        <Typography variant="h6" fontWeight="500" textAlign={"center"}>
          Trust Configuration
        </Typography>
      </Box>

      {/* Trust Score Section */}
      <Accordion sx={{ mt: 2, border: "1px solid #e0e0e0" }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon sx={{ color: theme.tmryk_background_color }} />
          }
          sx={{
            backgroundColor: "#f8f9fa",
            "&:hover": { backgroundColor: "#f1f3f5" },
          }}
        >
          <Typography
            sx={{ color: theme.tmryk_black_text_color, fontWeight: "500" }}
          >
            Trust Score
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {METRICS.filter((m) =>
            METRIC_CATEGORIES.scores.includes(m.label)
          ).map((m) => {
            const threshObj = thresholds[m.key] || {};
            const isSensitiveDataDetection = m.label.includes(
              "Sensitive Data Detection"
            );
            // Only pass threshold for non-sensitive data detection metrics
            const numericValue = isSensitiveDataDetection
              ? undefined
              : threshObj.threshold !== undefined
              ? threshObj.threshold
              : 500;
            let currentScore = "N/A";

            // Only process score if the metric has a scoreKey
            if (m.scoreKey) {
              currentScore = getMetricScore(m, runDetails);
            }

            let toggleable = true;
            let isOn =
              m.style === "trustActive"
                ? threshObj.active ?? false
                : m.style === "flowOnly"
                ? flowsPresent(m.flows)
                : m.style === "configKeyAndFlow"
                ? parsedConfig?.rails?.config?.[m.configKey] !== undefined &&
                  flowsPresent(m.flows)
                : m.style === "trustActiveAndFlow"
                ? (threshObj.active ?? false) && flowsPresent(m.flows)
                : true;

            // Get topic guard data if this is the Topic Guard toggle
            let topicGuardData = null;
            if (isTopicGuard(m.label)) {
              // Use our parsed topics from the YAML files
              topicGuardData = topicGuardTopics;
            }

            return (
              <UnifiedToggle
                key={m.key}
                label={m.label}
                toggleable={toggleable}
                isOn={isOn}
                onToggle={(newVal) => handleToggle(m, newVal)}
                threshold={numericValue}
                onThresholdChangeCommitted={
                  isTopicGuard(m.label) && isOn
                    ? (val) => handleThresholdChangeCommitted(m.key, val)
                    : (val) => handleThresholdChangeCommitted(m.key, val)
                }
                score={currentScore}
                onConfigure={
                  isSensitiveDataDetection
                    ? () => handleConfigureSensitiveData("Input")
                    : isTopicGuard(m.label) && isOn
                    ? onConfigureTopicGuard
                    : undefined
                }
                topicGuardData={topicGuardData}
                isLoading={!filesContent}
              />
            );
          })}
        </AccordionDetails>
      </Accordion>

      {/* Input Rails Section */}
      <Accordion sx={{ mt: 2, border: "1px solid #e0e0e0" }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon sx={{ color: theme.tmryk_background_color }} />
          }
          sx={{
            backgroundColor: "#f8f9fa",
            "&:hover": { backgroundColor: "#f1f3f5" },
          }}
        >
          <Typography
            sx={{ color: theme.tmryk_black_text_color, fontWeight: "500" }}
          >
            Input Rails
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {METRICS.filter(
            (m) =>
              m.key === "jailbreak_threshold" ||
              m.key === "input_fairness_threshold" ||
              m.key === "input_moderation_threshold" ||
              m.key === "input_sensitive_data_threshold" ||
              m.key === "topic_guard_threshold" ||
              m.key === "spell_check"
          ).map((m) => {
            const threshObj = thresholds[m.key] || {};
            const isSensitiveDataDetection = m.label.includes(
              "Sensitive Data Detection"
            );
            const isSpellCheck = m.key === "spell_check";
            // Don't pass threshold for sensitive data detection metrics or spell check
            const numericValue =
              isSensitiveDataDetection || isSpellCheck
                ? undefined
                : threshObj.threshold !== undefined
                ? threshObj.threshold
                : 500;
            let currentScore = "N/A";

            // Only process score if the metric has a scoreKey
            if (m.scoreKey) {
              currentScore = getMetricScore(m, runDetails);
            }

            let toggleable = true;
            let isOn =
              m.style === "trustActive"
                ? threshObj.active ?? false
                : m.style === "flowOnly"
                ? flowsPresent(m.flows)
                : m.style === "configKeyAndFlow"
                ? parsedConfig?.rails?.config?.[m.configKey] !== undefined &&
                  flowsPresent(m.flows)
                : m.style === "trustActiveAndFlow"
                ? (threshObj.active ?? false) && flowsPresent(m.flows)
                : true;

            // Get current entities for sensitive data detection
            let currentSensitiveEntities = [];
            if (
              isSensitiveDataDetection &&
              parsedConfig?.rails?.config?.sensitive_data_detection?.input
                ?.entities
            ) {
              currentSensitiveEntities =
                parsedConfig.rails.config.sensitive_data_detection.input
                  .entities;
            }

            // Get topic guard data if this is the Topic Guard toggle
            let topicGuardData = null;
            if (isTopicGuard(m.label)) {
              // Use our parsed topics from the YAML files
              topicGuardData = topicGuardTopics;
            }

            return (
              <UnifiedToggle
                key={m.key}
                label={m.label}
                toggleable={toggleable}
                isOn={isOn}
                onToggle={(newVal) => handleToggle(m, newVal)}
                threshold={numericValue}
                onThresholdChangeCommitted={
                  isTopicGuard(m.label) && isOn
                    ? (val) => handleThresholdChangeCommitted(m.key, val)
                    : (val) => handleThresholdChangeCommitted(m.key, val)
                }
                score={currentScore}
                onConfigure={
                  isSensitiveDataDetection
                    ? () => handleConfigureSensitiveData("Input")
                    : isTopicGuard(m.label) && isOn
                    ? onConfigureTopicGuard
                    : undefined
                }
                topicGuardData={topicGuardData}
                isLoading={!filesContent}
              />
            );
          })}
        </AccordionDetails>
      </Accordion>

      {/* Output Rails Section */}
      <Accordion sx={{ mt: 2, border: "1px solid #e0e0e0" }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon sx={{ color: theme.tmryk_background_color }} />
          }
          sx={{
            backgroundColor: "#f8f9fa",
            "&:hover": { backgroundColor: "#f1f3f5" },
          }}
        >
          <Typography
            sx={{ color: theme.tmryk_black_text_color, fontWeight: "500" }}
          >
            Output Rails
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {METRICS.filter((m) =>
            METRIC_CATEGORIES.outputRails.includes(m.label)
          ).map((m) => {
            const threshObj = thresholds[m.key] || {};
            const isSensitiveDataDetection = m.label.includes(
              "Sensitive Data Detection"
            );
            // Only pass threshold for non-sensitive data detection metrics
            const numericValue = isSensitiveDataDetection
              ? undefined
              : threshObj.threshold !== undefined
              ? threshObj.threshold
              : 500;
            let currentScore = "N/A";

            // Only process score if the metric has a scoreKey
            if (m.scoreKey) {
              currentScore = getMetricScore(m, runDetails);
            }

            let toggleable = true;
            let isOn =
              m.style === "trustActive"
                ? threshObj.active ?? false
                : m.style === "flowOnly"
                ? flowsPresent(m.flows)
                : m.style === "configKeyAndFlow"
                ? parsedConfig?.rails?.config?.[m.configKey] !== undefined &&
                  flowsPresent(m.flows)
                : m.style === "trustActiveAndFlow"
                ? (threshObj.active ?? false) && flowsPresent(m.flows)
                : true;

            // Get current entities for sensitive data detection
            let currentSensitiveEntities = [];
            if (
              isSensitiveDataDetection &&
              parsedConfig?.rails?.config?.sensitive_data_detection?.output
                ?.entities
            ) {
              currentSensitiveEntities =
                parsedConfig.rails.config.sensitive_data_detection.output
                  .entities;
            }

            // Get topic guard data if this is the Topic Guard toggle
            let topicGuardData = null;
            if (isTopicGuard(m.label)) {
              // Use our parsed topics from the YAML files
              topicGuardData = topicGuardTopics;
            }

            return (
              <UnifiedToggle
                key={m.key}
                label={m.label}
                toggleable={toggleable}
                isOn={isOn}
                onToggle={(newVal) => handleToggle(m, newVal)}
                threshold={numericValue}
                onThresholdChangeCommitted={
                  isTopicGuard(m.label) && isOn
                    ? (val) => handleThresholdChangeCommitted(m.key, val)
                    : (val) => handleThresholdChangeCommitted(m.key, val)
                }
                score={currentScore}
                onConfigure={
                  isSensitiveDataDetection
                    ? () => handleConfigureSensitiveData("Output")
                    : isTopicGuard(m.label) && isOn
                    ? onConfigureTopicGuard
                    : undefined
                }
                topicGuardData={topicGuardData}
                isLoading={!filesContent}
              />
            );
          })}
        </AccordionDetails>
      </Accordion>

      {/* Knowledge Base Section */}
      <Accordion defaultExpanded sx={{ mt: 2, border: "1px solid #e0e0e0" }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon sx={{ color: theme.tmryk_background_color }} />
          }
          sx={{
            backgroundColor: "#f8f9fa",
            "&:hover": { backgroundColor: "#f1f3f5" },
          }}
        >
          <Typography
            sx={{ color: theme.tmryk_black_text_color, fontWeight: "500" }}
          >
            Retrieval Augmented Generation
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* Add Sensitive Data Detection for Retrieval */}
          <UnifiedToggle
            label="Retrieval Sensitive Data Detection"
            toggleable={true}
            isOn={flowsPresent({
              retrieval: "mask sensitive data on retrieval",
            })}
            onToggle={(newVal) =>
              handleToggle(
                {
                  label: "Retrieval Sensitive Data Detection",
                  style: "flowOnly",
                  flows: { retrieval: "mask sensitive data on retrieval" },
                  configKey: "sensitive_data_detection",
                },
                newVal
              )
            }
            onConfigure={() => handleConfigureSensitiveData("Retrieval")}
          />

          {/* RAG Section - Contains RAG-specific toggles and configurations */}
          {/* Add Hallucination Score */}
          {METRICS.filter((m) => METRIC_CATEGORIES.rag.includes(m.label)).map((m) => {
            const threshObj = thresholds[m.key] || {};
            const numericValue = threshObj.threshold !== undefined ? threshObj.threshold : 500;
            let currentScore = "N/A";

            // Only process score if the metric has a scoreKey
            if (m.scoreKey) {
              currentScore = getMetricScore(m, runDetails);
            }

            let toggleable = true;
            let isOn =
              m.style === "trustActive"
                ? threshObj.active ?? false
                : m.style === "flowOnly"
                ? flowsPresent(m.flows)
                : m.style === "configKeyAndFlow"
                ? parsedConfig?.rails?.config?.[m.configKey] !== undefined &&
                  flowsPresent(m.flows)
                : m.style === "trustActiveAndFlow"
                ? (threshObj.active ?? false) && flowsPresent(m.flows)
                : true;

            return (
              <UnifiedToggle
                key={m.key}
                label={m.label}
                toggleable={toggleable}
                isOn={isOn}
                onToggle={(newVal) => handleToggle(m, newVal)}
                threshold={numericValue}
                onThresholdChangeCommitted={(val) => handleThresholdChangeCommitted(m.key, val)}
                score={currentScore}
              />
            );
          })}

          <FormControl>
            <FormLabel id="rag-group-label">RAG Type :</FormLabel>
            <RadioGroup
              row
              aria-labelledby="rag-label"
              name="rag-group"
              defaultValue={"kb"}
              value={ragType}
              onChange={(e) => setRagType(e.target.value)}
            >
              <FormControlLabel
                value="kb"
                disabled={existingRagConfigType === "qdrant"}
                control={<Radio />}
                label="Knowledge Base"
              />
              <FormControlLabel
                value="qdrant"
                disabled={existingRagConfigType === "kb"}
                control={<Radio />}
                label="Qdrant"
              />
            </RadioGroup>
          </FormControl>

          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ mb: 2, mt: 2 }}
          >
            Upload files in Markdown, text, PDF, or DOCX format. PDF and DOCX
            files will be automatically converted to Markdown.
            <br />
            Each file size should not exceed 5MB.
          </Typography>

          {conversionError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {conversionError}
            </Alert>
          )}

          {convertedFiles.length > 0 && (
            <Alert severity="info" sx={{ mb: 2 }}>
              {convertedFiles.length} file(s) have been converted to Markdown
            </Alert>
          )}

          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <SelectFilesButtonComponent
              selectedFiles={selectedFiles}
              setSelectedFiles={(files) => handleFilesForConversion(files)}
              acceptedFileTypes=".md,.txt,.pdf,.docx"
              ref={formRef}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpload}
              disabled={selectedFiles.length === 0 || isConverting}
              sx={{
                backgroundColor: theme.tmryk_background_color,
                "&:hover": { backgroundColor: "#172E6C" },
                color: "white",
              }}
            >
              {isConverting || isUploading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Upload"
              )}
            </Button>
          </Box>

          <SelectFilesListComponent selectedFiles={selectedFiles} />
        </AccordionDetails>
      </Accordion>

      {/* Detailed & General Stats Section */}
      <Box mt={2}>
        <Typography variant="h6" sx={{ color: theme.tmryk_black_text_color }}>
          Detailed & General Stats
        </Typography>
        {runDetails?.detailed_stats && (
          <Accordion sx={{ mt: 2, border: "1px solid #e0e0e0" }}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ color: theme.tmryk_background_color }} />
              }
              sx={{
                backgroundColor: "#f8f9fa",
                "&:hover": { backgroundColor: "#f1f3f5" },
              }}
            >
              <Typography
                sx={{ color: theme.tmryk_black_text_color, fontWeight: "500" }}
              >
                Detailed Stats
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ whiteSpace: "pre-wrap" }}>
              {runDetails.detailed_stats}
            </AccordionDetails>
          </Accordion>
        )}
        {runDetails?.general_stats && (
          <Accordion sx={{ mt: 2, border: "1px solid #e0e0e0" }}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ color: theme.tmryk_background_color }} />
              }
              sx={{
                backgroundColor: "#f8f9fa",
                "&:hover": { backgroundColor: "#f1f3f5" },
              }}
            >
              <Typography
                sx={{ color: theme.tmryk_black_text_color, fontWeight: "500" }}
              >
                General Stats
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ whiteSpace: "pre-wrap" }}>
              {runDetails.general_stats}
            </AccordionDetails>
          </Accordion>
        )}
        {!runDetails?.detailed_stats &&
          !runDetails?.general_stats &&
          !runDetails?.bot_message_log_summary && (
            <Typography
              variant="body2"
              sx={{
                marginLeft: 2,
                marginBottom: 2,
                whiteSpace: "pre-wrap",
                color: "text.secondary",
              }}
            >
              No run details available yet. Submit a prompt in the chat.
            </Typography>
          )}
      </Box>

      {/* Sensitive Data Configuration Modal */}
      {sensitiveDataConfigOpen && (
        <SensitiveDataConfigModal
          isOpen={sensitiveDataConfigOpen}
          onClose={() => setSensitiveDataConfigOpen(false)}
          configId={selectedConfig?.id}
          configType={sensitiveDataConfigType}
          currentEntities={currentEntities}
          onSave={handleSaveSensitiveDataConfig}
        />
      )}
    </Box>
  );
};

TrustMetricsPanel.propTypes = {
  thresholds: PropTypes.object.isRequired,
  existingRagConfigType: PropTypes.string,
  parsedConfig: PropTypes.object.isRequired,
  runDetails: PropTypes.object,
  selectedConfig: PropTypes.object,
  selectedFiles: PropTypes.array.isRequired,
  setSelectedFiles: PropTypes.func.isRequired,
  flowsPresent: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  handleThresholdChangeCommitted: PropTypes.func.isRequired,
  addFilesToKnowledgeBase: PropTypes.func.isRequired,
  addFilesToQdrantServer: PropTypes.func.isRequired,
  onSaveSensitiveDataEntities: PropTypes.func,
  onConfigureTopicGuard: PropTypes.func,
  filesContent: PropTypes.object,
};

export default TrustMetricsPanel;
