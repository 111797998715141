import React from 'react';
import { Link } from 'react-router-dom';
import { ConfigsIcon } from '../../assets/images/icons/ConfigsIcon/ConfigsIcon';
import { RoleConfigIcon } from '../../assets/images/icons/RoleConfigIcon/RoleConfigIcon';
import { UsersIcon } from '../../assets/images/icons/UsersIcon/UsersIcon';
import { LlmScanIcon } from '../../assets/images/icons/LlmScanIcon/LlmScanIcon';
import { DashboardIcon } from '../../assets/images/icons/DashboardIcon/DashboardIcon';

function ButtonComponent({ classNames, text, link_to, external }) {
    const classes = `h-12 w-48 mx-auto font-bold flex ${classNames}`;
    
    const renderIcon = () => {
        switch (link_to) {
            case '/dashboard':
                return <DashboardIcon/>
            case '/configs':
                return <ConfigsIcon />;
            case '/roles':
                return <RoleConfigIcon />;
            case '/users':
                return <UsersIcon />;
            case '/llm_scan':
                return <LlmScanIcon />;
            default:
                return null;
        }
    }

    if (external) {
        return (
            <a
                className={classes}
                href={link_to}
                target="_blank"
                rel="noopener noreferrer"
            >
                <div className="inline-flex items-center ml-6">
                    <div className='mr-2'>
                        {renderIcon()}
                    </div>
                    <p>{text}</p>
                </div>
            </a>
        );
    }

    return (
        <Link className={classes} to={link_to}>
            <div className="inline-flex items-center ml-6">
                <div className='mr-2'>
                    {renderIcon()}
                </div>
                <p>{text}</p>
            </div>
        </Link>
    );
}

export default ButtonComponent;
