// Component for managing LLM model configurations and providers in the admin interface

import React, { useState, useEffect } from 'react';
import client from '../client';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  CircularProgress,
  Snackbar,
  Alert,
  Grid,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Divider,
  Tooltip,
  Avatar,
  Fade,
  useTheme,
  alpha
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import LanguageIcon from '@mui/icons-material/Language';
import CodeIcon from '@mui/icons-material/Code';
import KeyIcon from '@mui/icons-material/Key';
import StorageIcon from '@mui/icons-material/Storage';
import RefreshIcon from '@mui/icons-material/Refresh';
import useSupportedModels from '../hooks/useSupportedModels';

const AdminModelManager = () => {
  const theme = useTheme();
  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openProviderDialog, setOpenProviderDialog] = useState(false);
  const [openModelDialog, setOpenModelDialog] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [newProvider, setNewProvider] = useState({
    provider_name: '',
    key_name: '',
    requires_api_key: true,
    requires_endpoint: false,
    requires_file: false,
    file_config: {
      fileType: 'application/json',
      fileName: 'credentials.json',
      label: 'Upload Credentials File'
    },
    visible: true
  });
  const [newModel, setNewModel] = useState({
    model_name: '',
    provider_id: null,
    visible: true
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [isSuperuser, setIsSuperuser] = useState(false);
  const [isTenantAdmin, setIsTenantAdmin] = useState(false);

  useEffect(() => {
    // Fetch user info to determine if user is a superuser or tenant admin
    client.get("/user_info")
      .then(response => {
        if (response.status === 200) {
          setIsSuperuser(response.data.is_superuser);
          setIsTenantAdmin(response.data.is_tenant_admin);
        }
      })
      .catch(error => console.error('Error fetching user info:', error));
  }, []);

  // Fetch providers and models from the API
  const fetchProviders = async () => {
    try {
      setLoading(true);
      const response = await client.get('/llm-models/supported-models');
      if (response.data && response.data.providers) {
        setProviders(response.data.providers);
      }
      setError(null);
    } catch (err) {
      console.error('Error fetching providers:', err);
      setError('Failed to fetch providers');
      // Initialize with empty array if fetch fails
      setProviders([]);
    } finally {
      setLoading(false);
    }
  };

  // Initialize data
  useEffect(() => {
    fetchProviders();
  }, []);

  // Handle provider dialog
  const handleOpenProviderDialog = (provider = null) => {
    if (provider) {
      setNewProvider({
        provider_name: provider.provider_name,
        key_name: provider.key_name,
        requires_api_key: provider.requires_api_key,
        requires_endpoint: provider.requires_endpoint,
        requires_file: provider.requires_file,
        file_config: provider.file_config || {
          fileType: 'application/json',
          fileName: 'credentials.json',
          label: 'Upload Credentials File'
        },
        visible: provider.visible !== false // default to true if not specified
      });
      setSelectedProvider(provider);
    } else {
      setNewProvider({
        provider_name: '',
        key_name: '',
        requires_api_key: true,
        requires_endpoint: false,
        requires_file: false,
        file_config: {
          fileType: 'application/json',
          fileName: 'credentials.json',
          label: 'Upload Credentials File'
        },
        visible: true
      });
      setSelectedProvider(null);
    }
    setOpenProviderDialog(true);
  };

  const handleCloseProviderDialog = () => {
    setOpenProviderDialog(false);
    setSelectedProvider(null);
  };

  // Handle model dialog
  const handleOpenModelDialog = (provider) => {
    setNewModel({
      model_name: '',
      provider_id: provider.id,
      visible: true
    });
    setSelectedProvider(provider);
    setOpenModelDialog(true);
  };

  const handleCloseModelDialog = () => {
    setOpenModelDialog(false);
    setSelectedProvider(null);
  };

  // Create or update provider
  const handleSaveProvider = async () => {
    try {
      if (selectedProvider) {
        // Update provider
        await client.put(`/llm-models/providers/${selectedProvider.id}`, newProvider);
        setSnackbar({
          open: true,
          message: 'Provider updated successfully',
          severity: 'success'
        });
      } else {
        // Create provider
        await client.post('/llm-models/providers', newProvider);
        setSnackbar({
          open: true,
          message: 'Provider created successfully',
          severity: 'success'
        });
      }
      handleCloseProviderDialog();
      fetchProviders();
    } catch (err) {
      console.error('Error saving provider:', err);
      setSnackbar({
        open: true,
        message: 'Failed to save provider',
        severity: 'error'
      });
    }
  };

  // Create model
  const handleSaveModel = async () => {
    try {
      await client.post('/llm-models/models', newModel);
      setSnackbar({
        open: true,
        message: 'Model created successfully',
        severity: 'success'
      });
      handleCloseModelDialog();
      fetchProviders();
    } catch (err) {
      console.error('Error saving model:', err);
      setSnackbar({
        open: true,
        message: 'Failed to save model',
        severity: 'error'
      });
    }
  };

  // Delete provider
  const handleDeleteProvider = async (providerId) => {
    try {
      await client.delete(`/llm-models/providers/${providerId}`);
      setSnackbar({
        open: true,
        message: 'Provider deleted successfully',
        severity: 'success'
      });
      fetchProviders();
    } catch (err) {
      console.error('Error deleting provider:', err);
      setSnackbar({
        open: true,
        message: 'Failed to delete provider',
        severity: 'error'
      });
    }
  };

  // Delete model
  const handleDeleteModel = async (modelName, providerId) => {
    try {
      // First get the model ID from the database
      const response = await client.get(`/llm-models/models?provider_id=${providerId}&model_name=${encodeURIComponent(modelName)}`);
      if (response.data && response.data.id) {
        await client.delete(`/llm-models/models/${response.data.id}`);
        setSnackbar({
          open: true,
          message: 'Model deleted successfully',
          severity: 'success'
        });
        fetchProviders();
      } else {
        setSnackbar({
          open: true,
          message: 'Model not found',
          severity: 'error'
        });
      }
    } catch (err) {
      console.error('Error deleting model:', err);
      setSnackbar({
        open: true,
        message: 'Failed to delete model',
        severity: 'error'
      });
    }
  };

  // Handle snackbar close
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  // Check if user has admin permissions
  if (!isSuperuser && !isTenantAdmin) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">
          You do not have permission to access this page. Only superusers and tenant admins can access the admin console.
        </Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, maxWidth: '1100px', mx: 'auto' }}>
      <Paper 
        elevation={1} 
        sx={{ 
          p: 3, 
          mb: 4, 
          borderRadius: '8px',
          background: theme.palette.background.paper,
          borderLeft: `4px solid rgb(3, 26, 88)`
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <StorageIcon 
              sx={{ fontSize: 28, mr: 2, color: 'rgb(3, 26, 88)' }} 
            />
            <Box>
              <Typography variant="h5" sx={{ fontWeight: 500 }}>
                LLM Model Manager
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Manage providers and their supported models
              </Typography>
            </Box>
          </Box>
          <Box>
            {isSuperuser && (
              <Button 
                variant="contained" 
                sx={{ 
                  mr: 2, 
                  borderRadius: '6px', 
                  bgcolor: 'rgb(3, 26, 88)',
                  '&:hover': {
                    bgcolor: 'rgb(5, 40, 130)'
                  }
                }}
                startIcon={<AddIcon />}
                onClick={() => handleOpenProviderDialog()}
              >
                Add Provider
              </Button>
            )}
          </Box>
        </Box>
      </Paper>

      {error && (
        <Alert severity="error" sx={{ mb: 3, borderRadius: '6px' }}>
          {error}
        </Alert>
      )}

      {providers.length === 0 && !loading && !error && (
        <Paper sx={{ p: 5, textAlign: 'center', borderRadius: '8px', mb: 3, backgroundColor: alpha(theme.palette.background.paper, 0.8) }}>
          <Typography variant="h6" color="text.secondary" gutterBottom>
            No providers configured
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            Add your first provider to get started
          </Typography>
          {isSuperuser && (
            <Button
              variant="contained"
              onClick={() => handleOpenProviderDialog()}
              sx={{ 
                borderRadius: '6px',
                bgcolor: 'rgb(3, 26, 88)',
                '&:hover': {
                  bgcolor: 'rgb(5, 40, 130)'
                }
              }}
            >
              Add Provider
            </Button>
          )}
        </Paper>
      )}

      {providers.map((provider) => (
        <Paper 
          key={provider.id} 
          sx={{ 
            p: 3, 
            mb: 3, 
            borderRadius: '8px',
            transition: 'box-shadow 0.2s',
            '&:hover': { boxShadow: 2 }
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Box display="flex" alignItems="center">
              <Avatar
                sx={{
                  bgcolor: 'rgba(3, 26, 88, 0.1)',
                  color: 'rgb(3, 26, 88)',
                  mr: 2
                }}
              >
                {provider.provider_name.charAt(0).toUpperCase()}
              </Avatar>
              <Typography variant="h6">{provider.provider_name}</Typography>
            </Box>
            {isSuperuser && (
              <Box>
                <IconButton 
                  sx={{ mr: 1, color: 'rgb(3, 26, 88)' }}
                  onClick={() => handleOpenProviderDialog(provider)}
                  size="small"
                >
                  <EditIcon />
                </IconButton>
                <IconButton 
                  color="error" 
                  onClick={() => handleDeleteProvider(provider.id)}
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            )}
          </Box>
          
          <Box mb={2} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Box display="flex" alignItems="center">
              <KeyIcon fontSize="small" sx={{ mr: 1, color: 'text.secondary' }} />
              <Typography variant="body2" color="text.secondary">
                Key Name: {provider.key_name || 'None'}
              </Typography>
            </Box>
            <Box display="flex" gap={1}>
              <Chip 
                size="small"
                label={provider.requires_api_key ? 'API Key Required' : 'No API Key Required'} 
                sx={{ 
                  borderRadius: '6px',
                  ...(provider.requires_api_key ? {
                    bgcolor: 'rgb(3, 26, 88)',
                    color: 'white'
                  } : {
                    color: 'text.secondary',
                    borderColor: 'rgba(0, 0, 0, 0.23)'
                  })
                }}
                variant={provider.requires_api_key ? "filled" : "outlined"}
              />
              <Chip 
                size="small" 
                label={provider.requires_endpoint ? 'Endpoint Required' : 'No Endpoint Required'} 
                sx={{ 
                  borderRadius: '6px',
                  ...(provider.requires_endpoint ? {
                    bgcolor: 'rgb(3, 26, 88)',
                    color: 'white'
                  } : {
                    color: 'text.secondary',
                    borderColor: 'rgba(0, 0, 0, 0.23)'
                  })
                }}
                variant={provider.requires_endpoint ? "filled" : "outlined"}
              />
              <Chip 
                size="small" 
                label={provider.requires_file ? 'File Upload Required' : 'No File Required'} 
                sx={{ 
                  borderRadius: '6px',
                  ...(provider.requires_file ? {
                    bgcolor: 'rgb(3, 26, 88)',
                    color: 'white'
                  } : {
                    color: 'text.secondary',
                    borderColor: 'rgba(0, 0, 0, 0.23)'
                  })
                }}
                variant={provider.requires_file ? "filled" : "outlined"}
              />
            </Box>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Box display="flex" alignItems="center">
              <CodeIcon fontSize="small" sx={{ mr: 1, color: 'rgb(3, 26, 88)' }} />
              <Typography variant="subtitle1">Models</Typography>
            </Box>
            {(isSuperuser || isTenantAdmin) && (
              <Button
                variant="contained"
                onClick={() => handleOpenModelDialog(provider)}
                startIcon={<AddIcon />}
                sx={{
                  mb: 2,
                  bgcolor: 'rgb(3, 26, 88)',
                  '&:hover': {
                    bgcolor: 'rgb(5, 40, 130)'
                  }
                }}
              >
                Add Model
              </Button>
            )}
          </Box>

          <TableContainer 
            component={Paper} 
            variant="outlined" 
            sx={{ 
              borderRadius: '6px',
              overflow: 'hidden'
            }}
          >
            <Table size="small">
              <TableHead>
                <TableRow sx={{ backgroundColor: 'rgba(3, 26, 88, 0.05)' }}>
                  <TableCell sx={{ fontWeight: 500 }}>Model Name</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {provider.models && provider.models.length > 0 ? (
                  provider.models.map((model) => {
                    // Convert string models to object format
                    const modelObj = typeof model === 'string' 
                      ? { name: model, visible: true } 
                      : model;
                    
                    return (
                    <TableRow 
                      key={modelObj.name || model}
                      sx={{ 
                        '&:hover': { 
                          backgroundColor: 'rgba(3, 26, 88, 0.02)' 
                        }
                      }}
                    >
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Typography>{modelObj.name || model}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        {isSuperuser && (
                          <IconButton 
                            color="error" 
                            size="small"
                            onClick={() => handleDeleteModel(modelObj.name || model, provider.id)}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={2} align="center" sx={{ py: 2 }}>
                      <Typography color="text.secondary">No models available</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ))}

      {/* Provider Dialog */}
      <Dialog 
        open={openProviderDialog} 
        onClose={handleCloseProviderDialog} 
        maxWidth="sm" 
        fullWidth
        PaperProps={{
          sx: { borderRadius: '8px' }
        }}
      >
        <DialogTitle>
          {selectedProvider ? 'Edit Provider' : 'Add Provider'}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box sx={{ pt: 1 }}>
            <TextField
              fullWidth
              label="Provider Name"
              value={newProvider.provider_name}
              onChange={(e) => setNewProvider({ ...newProvider, provider_name: e.target.value })}
              margin="normal"
              required
              InputProps={{
                startAdornment: <LanguageIcon sx={{ mr: 1, color: 'rgb(3, 26, 88)' }} />,
              }}
              sx={{
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgb(3, 26, 88)',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'rgb(3, 26, 88)',
                },
              }}
            />
            <TextField
              fullWidth
              label="Key Name"
              value={newProvider.key_name}
              onChange={(e) => setNewProvider({ ...newProvider, key_name: e.target.value })}
              margin="normal"
              helperText="Leave empty if no API key is required"
              InputProps={{
                startAdornment: <KeyIcon sx={{ mr: 1, color: 'rgb(3, 26, 88)' }} />,
              }}
              sx={{
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgb(3, 26, 88)',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'rgb(3, 26, 88)',
                },
              }}
            />
            <Box mt={2}>
              <FormControlLabel
                control={
                  <Switch
                    checked={newProvider.requires_api_key}
                    onChange={(e) => setNewProvider({ ...newProvider, requires_api_key: e.target.checked })}
                    sx={{
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        color: 'rgb(3, 26, 88)',
                      },
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: 'rgba(3, 26, 88, 0.5)',
                      },
                    }}
                  />
                }
                label="Requires API Key"
              />
            </Box>
            <Box mt={1}>
              <FormControlLabel
                control={
                  <Switch
                    checked={newProvider.requires_endpoint}
                    onChange={(e) => setNewProvider({ ...newProvider, requires_endpoint: e.target.checked })}
                    sx={{
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        color: 'rgb(3, 26, 88)',
                      },
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: 'rgba(3, 26, 88, 0.5)',
                      },
                    }}
                  />
                }
                label="Requires Endpoint"
              />
            </Box>
            <Box mt={1}>
              <FormControlLabel
                control={
                  <Switch
                    checked={newProvider.requires_file}
                    onChange={(e) => setNewProvider({ 
                      ...newProvider, 
                      requires_file: e.target.checked,
                      file_config: e.target.checked ? {
                        fileType: 'application/json',
                        fileName: 'credentials.json',
                        label: 'Upload Credentials File'
                      } : {}
                    })}
                    sx={{
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        color: 'rgb(3, 26, 88)',
                      },
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: 'rgba(3, 26, 88, 0.5)',
                      },
                    }}
                  />
                }
                label="Requires File Upload"
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button 
            onClick={handleCloseProviderDialog}
            variant="outlined"
            sx={{ 
              borderRadius: '6px',
              color: 'rgb(3, 26, 88)',
              borderColor: 'rgb(3, 26, 88)',
              '&:hover': {
                borderColor: 'rgb(5, 40, 130)',
                bgcolor: 'rgba(3, 26, 88, 0.04)'
              }
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSaveProvider} 
            variant="contained" 
            disabled={!newProvider.provider_name}
            sx={{ 
              borderRadius: '6px',
              bgcolor: 'rgb(3, 26, 88)',
              '&:hover': {
                bgcolor: 'rgb(5, 40, 130)'
              }
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Model Dialog */}
      <Dialog 
        open={openModelDialog} 
        onClose={handleCloseModelDialog} 
        maxWidth="sm" 
        fullWidth
        PaperProps={{
          sx: { borderRadius: '8px' }
        }}
      >
        <DialogTitle>
          Add Model to {selectedProvider?.provider_name}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box sx={{ pt: 1 }}>
            <TextField
              fullWidth
              label="Model Name"
              value={newModel.model_name}
              onChange={(e) => setNewModel({ ...newModel, model_name: e.target.value })}
              margin="normal"
              required
              InputProps={{
                startAdornment: <CodeIcon sx={{ mr: 1, color: 'rgb(3, 26, 88)' }} />,
              }}
              sx={{
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgb(3, 26, 88)',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'rgb(3, 26, 88)',
                },
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button 
            onClick={handleCloseModelDialog}
            variant="outlined"
            sx={{ 
              borderRadius: '6px',
              color: 'rgb(3, 26, 88)',
              borderColor: 'rgb(3, 26, 88)',
              '&:hover': {
                borderColor: 'rgb(5, 40, 130)',
                bgcolor: 'rgba(3, 26, 88, 0.04)'
              }
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSaveModel} 
            variant="contained" 
            disabled={!newModel.model_name}
            sx={{ 
              borderRadius: '6px',
              bgcolor: 'rgb(3, 26, 88)',
              '&:hover': {
                bgcolor: 'rgb(5, 40, 130)'
              }
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ 
            borderRadius: '6px',
            boxShadow: 2,
            width: '100%'
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AdminModelManager;