import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import theme from "../../theme";

const TestPolicyTab = ({
  showTestPolicyError,
  promptMessage,
  setPromptMessage,
  apiResponse,
  isSubmitting,
  isGenerating,
  generatedPrompts,
  selectedGeneratedPrompt,
  handleGenerateClick,
  handleGeneratedPromptClick,
  handleSubmit,
  handleClearResponse,
  setIsAddRecordModalOpen,
}) => {
  return (
    <Box flex={1} display="flex" flexDirection="column" sx={{ overflowY: "auto", p: 2 }}>
      {showTestPolicyError?.message && (
        <Box className="w-full mb-4 mt-2 border rounded-[4px]">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#5f2120" }} />}
              aria-controls="error-details-header"
              id="error-details-header"
              style={{
                backgroundColor: "#fdeded",
                color: "#5f2120",
                borderRadius: "4px",
              }}
            >
              <Typography>
                An error has occurred. Please expand to view additional details.
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{
                backgroundColor: "white",
                color: "#5f2120",
                borderRadius: "4px",
              }}
            >
              {showTestPolicyError?.error}
            </AccordionDetails>
          </Accordion>
        </Box>
      )}

      <Box className="w-full mb-2 border rounded-md bg-gray-50" sx={{ boxShadow: `0px 2px 4px ${theme.tmryk_box_shadow}` }}>
        <Box
          className="flex items-center justify-between px-3 py-2 border-b"
          sx={{
            backgroundColor: theme.tmryk_background_color,
            color: "white",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
          }}
        >
          <Box className="flex items-center">
            <Typography fontWeight="medium">Prompt</Typography>
          </Box>
          {generatedPrompts.length > 0 && (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              {generatedPrompts.map((_, index) => (
                <Button
                  key={index}
                  variant="contained"
                  sx={{
                    backgroundColor: selectedGeneratedPrompt === index ? theme.tmryk_background_color : "white",
                    color: selectedGeneratedPrompt === index ? "white" : theme.tmryk_background_color,
                    border: selectedGeneratedPrompt === index ? "1px solid white" : `1px solid ${theme.tmryk_background_color}`,
                    padding: "6px 16px",
                    minWidth: "100px",
                    boxShadow: selectedGeneratedPrompt === index ? "none" : `0px 1px 3px ${theme.tmryk_box_shadow}`,
                    transition: "all 0.2s ease",
                    "&:hover": {
                      backgroundColor: selectedGeneratedPrompt === index ? "#172E6C" : "rgba(3, 26, 88, 0.08)",
                      boxShadow: selectedGeneratedPrompt === index ? "none" : `0px 2px 5px ${theme.tmryk_box_shadow}`,
                      border: selectedGeneratedPrompt === index ? "1px solid white" : `1px solid ${theme.tmryk_background_color}`,
                    },
                  }}
                  onClick={() => handleGeneratedPromptClick(index)}
                >
                  Prompt {index + 1}
                </Button>
              ))}
            </Box>
          )}
        </Box>

        <Box className="px-4 py-2 bg-white rounded-b-md flex flex-col">
          <TextField
            multiline
            rows={4}
            variant="outlined"
            placeholder="Please enter your prompt here and click on submit."
            value={promptMessage}
            onChange={(e) => setPromptMessage(e.target.value)}
            fullWidth
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: theme.tmryk_background_color,
                },
              },
            }}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: promptMessage.trim() ? theme.tmryk_background_color : "rgba(0, 0, 0, 0.12)",
                color: promptMessage.trim() ? "white" : "rgba(0, 0, 0, 0.26)",
                fontWeight: "bold",
                marginRight: "10px",
                "&:hover": {
                  backgroundColor: promptMessage.trim() ? "#172E6C" : "rgba(0, 0, 0, 0.12)",
                },
              }}
              onClick={handleGenerateClick}
              disabled={!promptMessage.trim() || isGenerating}
            >
              {isGenerating ? <CircularProgress size={24} color="inherit" /> : "Generate"}
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: theme.tmryk_background_color,
                color: "white",
                "&:hover": { backgroundColor: "#172E6C" },
              }}
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
            </Button>
          </Box>
        </Box>
      </Box>

      <Box className="w-full mt-4 border rounded-md bg-gray-50" sx={{ boxShadow: `0px 2px 4px ${theme.tmryk_box_shadow}` }}>
        <Box
          className="flex items-center justify-between px-3 py-2 border-b"
          sx={{
            backgroundColor: theme.tmryk_background_color,
            color: "white",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
          }}
        >
          <Box className="flex items-center">
            <Typography fontWeight="medium">Response</Typography>
          </Box>
        </Box>
        <Box className="px-4 py-2 bg-white rounded-b-md">
          <TextField
            multiline
            rows={10}
            variant="outlined"
            placeholder="The generated response will appear here once you click submit"
            value={apiResponse}
            InputProps={{ readOnly: true }}
            fullWidth
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: theme.tmryk_background_color,
                },
              },
            }}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: theme.tmryk_background_color,
                color: "white",
                "&:hover": { backgroundColor: "#172E6C" },
              }}
              onClick={handleClearResponse}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: apiResponse ? theme.tmryk_background_color : "rgba(0, 0, 0, 0.12)",
                color: apiResponse ? "white" : "rgba(0, 0, 0, 0.26)",
                fontWeight: "bold",
                marginLeft: "10px",
                "&:hover": {
                  backgroundColor: apiResponse ? "#172E6C" : "rgba(0, 0, 0, 0.12)",
                },
              }}
              onClick={() => setIsAddRecordModalOpen(true)}
              disabled={!apiResponse}
            >
              Record
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TestPolicyTab; 