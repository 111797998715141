import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Typography, Box, Button, CircularProgress } from '@mui/material';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import theme from '../../theme';

export default function SaaSAgreementModal({ isModalOpen, setIsModalOpen, isLoading }) {
  const [loading, setLoading] = useState(true);

  const pdfUrl = '/tumeryk_docs/Tumeryk_SaaS_Evaluation_Agreement.pdf'; // Adjust the path as needed

  const onLoadSuccess = () => {
    setLoading(false);
  };

  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton } = zoomPluginInstance;

  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => setIsModalOpen(false)}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:h-auto">
                <div className="space-y-6 px-6 py-8 sm:p-10">
                  <Typography variant="h6" component="h3" style={{ color: theme.tmryk_background_color }}>
                    SaaS User Agreement
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Please read the following terms and conditions carefully.
                  </Typography>
                  <div style={{ height: '600px', overflow: 'auto', position: 'relative' }}>
                    {loading && <CircularProgress />}
                    <div style={{ width: '100%' }}>
                      <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`}>
                        <div style={{
                          position: 'sticky',
                          top: 0,
                          display: 'flex',
                          justifyContent: 'right',
                          backgroundColor: 'white',
                          zIndex: 1000,
                          padding: '10px 0'
                        }}>
                          <ZoomOutButton />
                          <ZoomInButton />
                        </div>
                        <Viewer
                          fileUrl={pdfUrl}
                          onDocumentLoad={onLoadSuccess}
                          plugins={[zoomPluginInstance]}
                          style={{ width: '100%' }}
                        />
                      </Worker>
                    </div>
                  </div>
                  <Box display="flex" justifyContent="flex-end" mt={3}>
                    <Button
                      variant="outlined"
                      onClick={() => setIsModalOpen(false)}
                      style={{
                        borderColor: theme.tmryk_background_color,
                        color: theme.tmryk_background_color,
                        backgroundColor: 'white'
                      }}
                      disabled={isLoading}
                    >
                      {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Close'}
                    </Button>
                  </Box>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
