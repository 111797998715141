import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import client from '../../../client';
import theme from '../../../theme';
import { toast } from 'react-toastify';
import {
  Button,
  TextField,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Box,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import useSupportedModels from '../../../hooks/useSupportedModels';
import { generateCustomConfig, manageApiKey, generateConfigPayload } from '../../../constants/llmConfigUtilities';
import FileUploadField from '../../common/FileUploadField';

// Helper components for form fields
const EngineSelect = ({ engine, setEngine, models }) => (
  <FormControl fullWidth>
    <InputLabel className="text-gray-700">Engine</InputLabel>
    <Select
      name="engine"
      label="Engine"
      value={engine || ''}
      onChange={(e) => setEngine(e.target.value)}
      className="w-full bg-white rounded text-gray-900"
    >
      {Object.keys(models).map((key) => (
        <MenuItem key={key} value={key}>
          {key}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

const ModelSelect = ({ engine, model, setModel, models }) => {
  const modelOptions = useMemo(() => {
    return engine && models[engine] ? models[engine].models.map((model) => (
      <MenuItem key={model} value={model}>
        {model}
      </MenuItem>
    )) : [];
  }, [engine, models]);

  return (
    <FormControl fullWidth>
      <InputLabel className="text-gray-700">Model</InputLabel>
      <Select
        name="model"
        label="Model"
        value={model || ''}
        onChange={(e) => setModel(e.target.value)}
        className="w-full bg-white rounded text-gray-900"
      >
        {modelOptions}
      </Select>
    </FormControl>
  );
};

const APIKeyField = ({ engine, apiKey, setApiKey, models }) => {
  const requiresKey = models[engine]?.requiredToAddKey;
  if (!requiresKey) return null;

  return (
    <TextField
      label="API Key"
      value={apiKey}
      onChange={(e) => setApiKey(e.target.value)}
      fullWidth
      className="w-full bg-white border-2 rounded p-3 text-gray-900"
    />
  );
};

const AzureFields = ({
  azureEndpoint,
  setAzureEndpoint,
  azureApiVersion,
  setAzureApiVersion,
  azureDeploymentName,
  setAzureDeploymentName,
}) => {
  const handleEndpointChange = (e) => {
    const value = e.target.value;
    setAzureEndpoint(value);

    // Try to extract Azure details from full URL if pasted
    try {
      if (value.includes('/openai/deployments/')) {
        const url = new URL(value);
        // Extract base endpoint
        setAzureEndpoint(`${url.protocol}//${url.hostname}`);
        
        // Extract deployment name from path
        const pathParts = url.pathname.split('/');
        const deploymentIndex = pathParts.indexOf('deployments');
        if (deploymentIndex !== -1 && deploymentIndex + 1 < pathParts.length) {
          setAzureDeploymentName(pathParts[deploymentIndex + 1]);
        }

        // Extract API version from query parameters
        const apiVersion = url.searchParams.get('api-version');
        if (apiVersion) {
          setAzureApiVersion(apiVersion);
        }
      }
    } catch (error) {
    // If URL parsing fails, just keep the value as is
      console.error('Failed to parse Azure URL:', error);
    }
  };

  return (
    <>
      <TextField
        label="Azure Endpoint"
        name="azureEndpoint"
        value={azureEndpoint}
        onChange={handleEndpointChange}
        fullWidth
        className="w-full bg-white border-2 rounded p-3 text-gray-900"
        helperText="You can paste a full Azure OpenAI URL to auto-fill all fields"
      />
      <TextField
        label="API Version"
        name="azureApiVersion"
        value={azureApiVersion}
        onChange={(e) => setAzureApiVersion(e.target.value)}
        fullWidth
        className="w-full bg-white border-2 rounded p-3 text-gray-900"
      />
      <TextField
        label="Deployment Name"
        name="azureDeploymentName"
        value={azureDeploymentName}
        onChange={(e) => setAzureDeploymentName(e.target.value)}
        fullWidth
        className="w-full bg-white border-2 rounded p-3 text-gray-900"
      />
    </>
  );
};

export default function UpdatePolicyModal({
  isModalOpen,
  setIsModalOpen,
  onConfigUpdated,
  configs,
  selectedConfig,
}) {
  const { models, loading: modelsLoading, error: modelsError } = useSupportedModels();
  const [selectedConfigId, setSelectedConfigId] = useState('');
  const [engine, setEngine] = useState('');
  const [model, setModel] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [credentialsFile, setCredentialsFile] = useState(null);
  const [newConfigName, setNewConfigName] = useState('');
  const [validationError, setValidationError] = useState('');
  const [azureEndpoint, setAzureEndpoint] = useState('');
  const [azureApiVersion, setAzureApiVersion] = useState('');
  const [azureDeploymentName, setAzureDeploymentName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (selectedConfig) {
      setSelectedConfigId(selectedConfig.id);
      setEngine(selectedConfig.engine || '');
      setModel(selectedConfig.model || '');
      setApiKey(selectedConfig.apiKey || '');
      if (selectedConfig.engine === 'azure') {
        setAzureEndpoint(selectedConfig.azureEndpoint || '');
        setAzureApiVersion(selectedConfig.azureApiVersion || '');
        setAzureDeploymentName(selectedConfig.azureDeploymentName || '');
      }
    }
  }, [selectedConfig]);

  useEffect(() => {
    if (selectedConfigId) {
      const fetchModelEngine = async () => {
        try {
          const response = await client.get(`/read_model_engine?config_name=${selectedConfigId}`);
          setEngine(response.data.engine);
          setModel(response.data.model);
          if (response.data.engine === 'azure') {
            setAzureEndpoint(response.data.azure_endpoint || '');
            setAzureApiVersion(response.data.api_version || '');
            setAzureDeploymentName(response.data.deployment_name || '');
          }
        } catch (error) {
          toast.error("Failed to load engine and model for selected Policy.");
        }
      };
      fetchModelEngine();
    }
  }, [selectedConfigId]);

  const validateForm = () => {
    if (!selectedConfigId) return 'Please select a policy to update.';
    if (!engine) return 'Engine is required.';
    if (!model) return 'Model is required.';
    if (models[engine]?.requiredToAddKey && !apiKey.trim()) return 'API key is required.';
    if (engine === 'azure') {
      if (!azureEndpoint.trim()) return 'Azure endpoint is required.';
      if (!azureApiVersion.trim()) return 'Azure API version is required.';
      if (!azureDeploymentName.trim()) return 'Azure deployment name is required.';
    }
    if (engine === 'vertexai' && !credentialsFile) return 'Credentials file is required for Vertex AI engine.';
    return '';
  };

  const handleUpdatePolicy = useCallback(async () => {
    const validationMessage = validateForm();
    if (validationMessage) {
      setValidationError(validationMessage);
      return;
    }
    setIsSubmitting(true);
    setValidationError('');

    const customConfig = generateCustomConfig(engine, model);
    
    const azureParams = engine === 'azure' ? {
      azure_endpoint: azureEndpoint.trim(),
      api_version: azureApiVersion.trim(), 
      deployment_name: azureDeploymentName.trim()
    } : null;

    const configPayload = generateConfigPayload(
      engine,
      model,
      { jailbreakDetection: false, moderation: false, hallucination: false },
      azureParams
    );

    try {
      const params = { config_name: selectedConfigId, file_name: 'models.yml' }

      if (newConfigName) {
        params['new_config_name'] = newConfigName;
      }

      await client.post("/edit_config", configPayload, { params: params });
      await manageApiKey(selectedConfigId, apiKey, engine, true, newConfigName);

      const updatedConfigId = newConfigName || selectedConfigId;
      setSelectedConfigId(updatedConfigId);

      if (customConfig) {
        const formData = new FormData();
        formData.append('files', new Blob([customConfig['config.py']], { type: 'text/plain' }), 'config.py');
        await client.post(`/add_files_to_config?config_name=${updatedConfigId}`, formData);
      } else {
        const existingFiles = await client.get(`/read_config?config_name=${updatedConfigId}`);
        if ('config.py' in existingFiles.data) {
          await client.post(`/delete_config_file?config_name=${updatedConfigId}&file_name=config.py`);
        }
      }
      if (engine === 'vertexai' && credentialsFile) {
        const formData = new FormData();
        formData.append('files', credentialsFile, 'google-creds.json');
        await client.post(`/add_files_to_config?config_name=${updatedConfigId}`, formData);
      }

      onConfigUpdated({ id: updatedConfigId, engine, model, apiKey, azureEndpoint, azureApiVersion, azureDeploymentName });
      toast.success('Policy updated successfully!');
      setIsModalOpen(false);
    } catch (error) {
      toast.error(error.message || 'Failed to update policy.');
    } finally {
      setIsSubmitting(false);
    }
  }, [engine, model, apiKey, newConfigName, selectedConfigId, onConfigUpdated, credentialsFile, azureEndpoint, azureApiVersion, azureDeploymentName]);

  return (
    <Transition.Root show={isModalOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setIsModalOpen}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <Dialog.Title className="text-lg font-medium text-gray-900" style={{ color: theme.tmryk_background_color }}>
                    Update Policy
                  </Dialog.Title>

                  <div className="mt-8 space-y-6">
                    {validationError && <Alert severity="error">{validationError}</Alert>}

                    <FormControl fullWidth>
                      <InputLabel>Select Policy to Update</InputLabel>
                      <Select
                        name="selectedConfigToUpdate"
                        label="Select Policy to Update"
                        value={selectedConfigId}
                        onChange={(e) => setSelectedConfigId(e.target.value)}
                      >
                        {configs.map((config) => (
                          <MenuItem key={config.id} value={config.id}>
                            {config.id}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <TextField
                      label="New Config Name (optional)"
                      value={newConfigName}
                      onChange={(e) => setNewConfigName(e.target.value)}
                      fullWidth
                      className="w-full bg-white border-2 rounded p-3 text-gray-900"
                    />

                    <EngineSelect engine={engine} setEngine={setEngine} models={models} />
                    <ModelSelect engine={engine} model={model} setModel={setModel} models={models} />

                    <APIKeyField engine={engine} apiKey={apiKey} setApiKey={setApiKey} models={models} />

                    {engine === 'azure' && (
                      <AzureFields
                        azureEndpoint={azureEndpoint}
                        setAzureEndpoint={setAzureEndpoint}
                        azureApiVersion={azureApiVersion}
                        setAzureApiVersion={setAzureApiVersion}
                        azureDeploymentName={azureDeploymentName}
                        setAzureDeploymentName={setAzureDeploymentName}
                      />
                    )}

                    {engine && (
                      <FileUploadField
                        modelConfig={models[engine]}
                        file={credentialsFile}
                        setFile={setCredentialsFile}
                      />
                    )}
                  </div>
                </div>

                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <Button
                    variant="contained"
                    onClick={handleUpdatePolicy}
                    style={{ backgroundColor: theme.tmryk_background_color, color: 'white', marginLeft: 10 }}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Update Policy'}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => setIsModalOpen(false)}
                    style={{ borderColor: theme.tmryk_background_color, color: theme.tmryk_background_color }}
                  >
                    Cancel
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}


