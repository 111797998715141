import React, { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Button, IconButton, CircularProgress, Typography } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { toast } from "react-toastify";
import client from "../../../client";
import theme from "../../../theme";

export default function DeleteCustomListModal({
  isOpen,
  setIsOpen,
  listName,
  onDeleteSuccess,
  setParentIsOpen,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDelete = async () => {
    setIsSubmitting(true);
    try {
      const response = await client.delete(`/custom_suite_list/${listName}`);
      if (response.status === 200) {
        onDeleteSuccess();
      } else {
        toast.error("Failed to delete the list.");
      }
      setIsOpen(false);
      setParentIsOpen(true);
    } catch (error) {
      console.error("Error deleting record:", error);
      toast.error("Error deleting the list.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setParentIsOpen(true);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleClose}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden flex justify-center items-center">
            <Transition.Child
              as="div"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-y-[-100%]"
              enterTo="translate-y-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-y-0"
              leaveTo="translate-y-[-100%]"
            >
              <Dialog.Panel className="pointer-events-auto w-screen max-w-lg h-full max-h-[90vh] flex flex-col bg-white shadow-xl rounded-lg overflow-y-auto">
                <div className="px-4 pt-6 sm:px-6 bg-white rounded-t-lg">
                  <div className="flex items-start justify-between">
                    <Dialog.Title className="text-lg font-medium" style={{ color: theme.tmryk_background_color }}>
                      Delete List
                    </Dialog.Title>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-white hover:bg-[#031A58] focus:outline-none focus:ring-2"
                          onClick={handleClose}
                        >
                          <span className="sr-only">Close panel</span>
                          <CloseIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                  </div>
                </div>
                <div className="relative flex-1 px-4 py-6 sm:px-6 overflow-y-auto">
                  <Typography>
                    Are you sure you want to delete the list <strong>{listName}</strong>? This action cannot be undone.
                  </Typography>
                  <div className="flex justify-end mt-4">
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "white",
                        color: theme.tmryk_background_color,
                        borderColor: theme.tmryk_background_color,
                        borderWidth: "1px",
                        marginRight: "10px",
                      }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "red",
                        color: "white",
                      }}
                      onClick={handleDelete}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <CircularProgress size={24} /> : "Delete"}
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
