/**
 * Common Variable used across the apps
 */

// Model's Performance Metrics with their description
export const MODELS_TRUST_METRICS = {
  "Prompt Injection": "Prompt injection exploits LLMs' reliance on user input as instructions. By crafting malicious prompts disguised as regular text, attackers can hijack the LLM's behavior, forcing it to ignore prior instructions, reveal confidential data, or perform unintended actions.",
  "Security": "LLM security encompasses protecting against prompt injection, data poisoning, model stealing, and unintended information disclosure. It also involves ensuring responsible use, preventing misuse for malicious purposes, and mitigating biases embedded within the model.",
  "Sensitive Information Disclosure": "Sensitive information disclosure in LLMs occurs when models inadvertently reveal private data from training or user prompts. This can include personal details, confidential business information, or copyrighted material, posing privacy and security risks.",
  "Insecure Output Handling": "Insecure output handling in LLMs arises when applications directly use model outputs without validation. This can lead to vulnerabilities like prompt injection, where malicious inputs manipulate the model's response, or disclosure of sensitive data unintentionally included in the output.",
  "Supply Chain Vulnerabilities": "LLM supply chains are vulnerable to data poisoning, model theft, reliance on biased training data, and compromised infrastructure. These can lead to inaccurate outputs, intellectual property loss, and perpetuation of harmful biases, impacting downstream applications.",
  "Hallucination": "LLM hallucinations are outputs that are factually incorrect, nonsensical, or unfaithful to the provided context. They can manifest as fabricated information, incorrect reasoning, or irrelevant responses, stemming from the model's learned patterns rather than actual knowledge.",
  "Psychological Safety": "Psychological safety in LLM interaction means users feel comfortable experimenting, making mistakes, and providing feedback without fear of negative consequences like judgment or technical errors. This fosters trust and encourages exploration of the LLM's capabilities.",
  "Fairness": "LLM fairness means mitigating biases present in training data that lead to discriminatory outputs. It ensures equitable performance across different demographics (e.g., race, gender) in tasks like text generation, avoiding harmful stereotypes and promoting inclusive language.",
  "Toxicity": "LLM toxicity refers to the generation of harmful, biased, or offensive outputs, including hate speech, discriminatory language, and personally identifiable information. This arises from biased training data and can perpetuate societal harms if unchecked.",
};

// Default value for Category setting for the different range with colors
export const DEFAULT_CATEGORIES_SETTING = [
  {"name": "Excellent","min_score": 800,"max_score": 1000,"color": "#448448"},
  {"name": "Good","min_score": 780,"max_score": 800,"color": "#ADC850"},
  {"name": "Fair","min_score": 740,"max_score": 780,"color": "#FFB333"},
  {"name": "Poor","min_score": 680,"max_score": 740,"color": "#FF7D00"},
  {"name": "Very Poor","min_score": 0,"max_score": 680,"color": "#FA6369"},
]

// Default value for Performance Weights
export const DEFAULT_TRUST_METRICS_WEIGHTS = [
  { name: "Prompt Injection", weight: 0.12 },
  { name: "Security", weight: 0.11 },
  { name: "Sensitive Information Disclosure", weight: 0.11 },
  { name: "Insecure Output Handling", weight: 0.11 },
  { name: "Supply Chain Vulnerabilities", weight: 0.11 },
  { name: "Hallucination", weight: 0.11 },
  { name: "Psychological Safety", weight: 0.11 },
  { name: "Fairness", weight: 0.11 },
  { name: "Toxicity", weight: 0.11 }
]

// Different Price Details with the mapped to database column
export const MODEL_TOKEN_PRICE_TYPES = {
  "Blended Token": 'blended_token_1m_price',
  "Input Token": 'input_token_1m_price',
  "Output Token": 'output_token_1m_price'
}

// Mapping detection values to their respective labels
export const DETECTION_LABELS = {
  "align_score": 'Align Score',
  "jailbreak_score": 'Jailbreak Score',
  "toxicity_input_category": 'Toxicity Input Category',
  "toxicity_input_score": 'Toxicity Input Score',
  "toxicity_category": 'Toxicity Output Category',
  "toxicity_output_score": 'Toxicity Output Score',
  "bias_input_score": 'Bias Input Score',
  "bias_score": 'Bias Output Score',
  "violation_flag": 'Topic Violation',
}

// Toxicity Category Mapping for proper label
export const MODERATION_MAP = {
  0: 'Safe',
  1: 'Violence',
  2: 'Sexual',
  3: 'Criminal Planning/Confessions',
  4: 'Guns and Illegal Weapons',
  5: 'Controlled/Regulated Substances',
  6: 'Suicide and Self Harm',
  7: 'Sexual (minor)',
  8: 'Hate/Identity Hate',
  9: 'PII/Privacy',
  10: 'Harassment',
  11: 'Threat',
  12: 'Profanity',
  13: 'Needs Caution',
  14: 'Other',
  15: 'Manipulation',
  16: 'Fraud/Deception',
  17: 'Malware',
  18: 'High Risk Gov Decision Making',
  19: 'Political/Misinformation/Conspiracy',
  20: 'Copyright/Trademark/Plagiarism',
  21: 'Unauthorized Advice',
  22: 'Illegal Activity',
  23: 'Immoral/Unethical',
}


// Menu Props for the Dropdown List
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left", // Align the dropdown to the left
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left", // Make sure the dropdown opens left-aligned
  },
};