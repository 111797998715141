import * as pdfjs from 'pdfjs-dist';
import mammoth from 'mammoth';

// Set the worker source for PDF.js
const pdfjsWorker = await import('pdfjs-dist/build/pdf.worker.entry');
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

/**
 * Converts PDF file to markdown text
 * @param {File} file - The PDF file to convert
 * @returns {Promise<{text: string, filename: string}>} The extracted text and new filename
 */
export const convertPdfToMarkdown = async (file) => {
  try {
    // Read the file as ArrayBuffer
    const arrayBuffer = await file.arrayBuffer();
    
    // Load the PDF document
    const pdf = await pdfjs.getDocument({ data: arrayBuffer }).promise;
    let text = '';
    
    // Extract text from each page
    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const content = await page.getTextContent();
      const pageText = content.items.map(item => item.str).join(' ');
      text += `## Page ${i}\n\n${pageText}\n\n`;
    }
    
    // Create a new filename with .md extension
    const filename = file.name.replace(/\.pdf$/i, '.md');
    
    return { text, filename };
  } catch (error) {
    console.error('Error converting PDF to Markdown:', error);
    throw error;
  }
};

/**
 * Converts DOCX file to markdown text
 * @param {File} file - The DOCX file to convert
 * @returns {Promise<{text: string, filename: string}>} The converted markdown and new filename
 */
export const convertDocxToMarkdown = async (file) => {
  try {
    // Read the file as ArrayBuffer
    const arrayBuffer = await file.arrayBuffer();
    
    // Convert DOCX to HTML
    const result = await mammoth.convertToMarkdown({ arrayBuffer });
    const text = result.value;
    
    // Create a new filename with .md extension
    const filename = file.name.replace(/\.docx$/i, '.md');
    
    return { text, filename };
  } catch (error) {
    console.error('Error converting DOCX to Markdown:', error);
    throw error;
  }
};

/**
 * Creates a new File object from text content
 * @param {string} content - The text content
 * @param {string} filename - The filename for the new file
 * @returns {File} A new File object
 */
export const createFileFromText = (content, filename) => {
  return new File([content], filename, { type: 'text/markdown' });
}; 