import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Box, Tabs, Tab, TextField, IconButton, Popover, MenuItem, Select, FormControl, InputLabel, ListSubheader } from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import theme from '../theme';
import ScanDashboardModal from '../components/modals/dashboard/ScanDashboardModal';
import ViolationsDashboardModal from '../components/modals/dashboard/ViolationsDashboardModal';
import UsageDashboardModal from '../components/modals/dashboard/UsageDashboardModal';
import client from "../client"; // Replace with your actual client import

const LLM_TYPES = {
  openai: {
    keyName: "OPENAI_API_KEY",
    models: ["gpt-4", "gpt-3.5-turbo", "gpt-4-turbo-preview"],
  },
  octo: {
    keyName: "OCTO_API_TOKEN",
    models: [
      "meta-llama-3-8b-instruct",
      "meta-llama-3-70b-instruct",
      "mistral-7b-instruct-v0.3",
      "mixtral-8x7b-instruct",
    ],
  },
  replicate: {
    keyName: 'REPLICATE_API_TOKEN',
    models: ['meta/meta-llama-3-70b-instruct', 'meta/llama-2-70b-chat', 'mistralai/mistral-7b-instruct-v0.2'],
  },
  bedrock_claude: {
    keyName: '',
    models: ['anthropic.claude-3-5-sonnet'],
  },
};

const DashboardPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);
    return thirtyDaysAgo;
  });
  const [endDate, setEndDate] = useState(new Date());
  
  const [anchorEl, setAnchorEl] = useState(null); // State for the filter popover
  const [configs, setConfigs] = useState([]); // Store AI policies
  const [loadingConfigs, setLoadingConfigs] = useState(true); // Add a loading state for configs
  const [selectedModel, setSelectedModel] = useState("All"); // State for selected model

  // Fetch AI policies from Configs API endpoint
  useEffect(() => {
    const fetchConfigs = async () => {
      try {
        const response = await client.get("/v1/rails/configs");
        setConfigs(response.data); // Set the data
        setLoadingConfigs(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error("Error fetching configs:", error);
        setLoadingConfigs(false); // In case of error, stop loading
      }
    };

    fetchConfigs();
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleModelsChange = (event) => {
    setSelectedModel(event.target.value); // Update selected model
  };

  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <AppBar position="static" style={{ backgroundColor: theme.tmryk_background_color }}>
          <Toolbar>
            <Typography variant="h6" style={{ color: 'white', marginRight: '20px' }}>
              Dashboard
            </Typography>

            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              textColor="inherit"
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{
                style: {
                  backgroundColor: 'white', // Ensure the indicator (underline) is white
                },
              }}
              sx={{
                '& .MuiTab-root': {
                  color: 'white',
                },
                '& .Mui-selected': {
                  borderBottom: '2px solid white', // White border for the selected tab
                },
              }}
            >
              <Tab label="Scores" />
              <Tab label="Violations" />
              <Tab label="Usage Metrics" />
            </Tabs>

            <Box sx={{ flexGrow: 1 }} />
            
            {/* Conditionally render Models Dropdown only when "Scans" tab is selected */}
            {selectedTab === 0 && (
              <FormControl
                variant="outlined"
                sx={{
                  minWidth: 200,
                  marginRight: 2,
                  marginTop: 1,
                  marginBottom: 1,
                  '& .MuiOutlinedInput-root': {
                    color: 'white',
                    '& fieldset': {
                      borderColor: 'white',
                    },
                    '&:hover fieldset': {
                      borderColor: 'white',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'white',
                    },
                    '& .MuiSvgIcon-root': {
                      color: 'white',
                    },
                  },
                }}
              >
                <InputLabel
                  sx={{
                    color: 'white',
                    '&.Mui-focused': {
                      color: 'white', // Keep the label white even when focused
                    },
                  }}
                >
                  Models
                </InputLabel>
                <Select
                  label="Models"
                  value={selectedModel}
                  onChange={handleModelsChange}
                  sx={{
                    color: 'black',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'white',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'white',
                    },
                    '& .MuiSvgIcon-root': {
                      color: 'white',
                    },
                  }}
                >
                  {/* Add "All" option */}
                  <MenuItem value="All">
                    <em>All Models</em>
                  </MenuItem>

                  {/* Main Categories with custom styles */}
                  <ListSubheader sx={{ fontSize: '1.2rem', fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>LLM Models</ListSubheader>
                  {Object.entries(LLM_TYPES).map(([key, type]) => (
                    [
                      <ListSubheader key={key} sx={{ fontSize: '1rem', fontWeight: 'bold', color: '#888' }}>{key.toUpperCase()}</ListSubheader>,
                      ...type.models.map((model) => (
                        <MenuItem key={model} value={model}>
                          {model}
                        </MenuItem>
                      ))
                    ]
                  ))}

                  {/* AI Policies Group */}
                  <ListSubheader sx={{ fontSize: '1.2rem', fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>AI Policies</ListSubheader>
                  {loadingConfigs ? (
                    <MenuItem disabled>Loading...</MenuItem>
                  ) : (
                    configs.map((config) => (
                      <MenuItem key={config.id} value={config.id}>
                        {config.id}
                      </MenuItem>
                    ))
                  )}

                  {/* Private Endpoint Group */}
                  <ListSubheader sx={{ fontSize: '1.2rem', fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>Private Endpoint</ListSubheader>
                  <MenuItem value="private-endpoint">Private-Endpoint</MenuItem>
                </Select>
              </FormControl>
            )}

            {/* Filter icon */}
            <IconButton
              color="inherit"
              aria-describedby={id}
              onClick={handleFilterClick}
              sx={{ color: 'white' }}
            >
              <FilterList />
            </IconButton>

            {/* Popover for date filtering */}
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleFilterClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
                {/* Start Date */}
                <DesktopDatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  maxDate={endDate}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      color: 'black',
                      '& fieldset': {
                        borderColor: 'black',
                      },
                      '&:hover fieldset': {
                        borderColor: 'black',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'black',
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth />
                  )}
                />

                {/* End Date with minDate validation to prevent end date being before start date */}
                <DesktopDatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  minDate={startDate} 
                  maxDate={new Date()}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      color: 'black',
                      '& fieldset': {
                        borderColor: 'black',
                      },
                      '&:hover fieldset': {
                        borderColor: 'black',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'black',
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth />
                  )}
                />
              </Box>
            </Popover>
          </Toolbar>
        </AppBar>

        <Box sx={{ padding: 2, backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
          {selectedTab === 0 && (
            <ScanDashboardModal startDate={startDate} endDate={endDate} selectedModel={selectedModel} />
          )}
          {selectedTab === 1 && (
            <ViolationsDashboardModal startDate={startDate} endDate={endDate} />
          )}
          {selectedTab === 2 && (
            <UsageDashboardModal startDate={startDate} endDate={endDate} />
          )}
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default DashboardPage;
