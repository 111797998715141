import React, { Fragment, useState, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  Typography,
  Box,
  Button,
  Chip,
  CircularProgress,
  TextField,
  Checkbox,
  FormControlLabel,
  IconButton,
  Paper,
  Alert,
} from "@mui/material";
import {
  Close as CloseIcon,
  CheckBox,
  CheckBoxOutlineBlank,
  Cancel as CancelIcon,
  Delete as DeleteIcon,
  Visibility as ViewIcon,
  Edit as EditIcon,
  ArrowBack,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import client from "../../../client";
import theme from "../../../theme";
import ViewListModal from "../../common/ViewListModal";
import DeleteCustomListModal from "./DeleteCustomListModal";
import EditCustomListModal from "./EditCustomListModal";

export default function AddRecordModal({
  isModalOpen,
  setIsModalOpen,
  promptMessage,
  apiResponse,
  openViewModal,
  openDeleteModal,
  openEditModal,
}) {
  const [isCreatingNewList, setIsCreatingNewList] = useState(false);
  const [newListName, setNewListName] = useState("");
  const [substring, setSubstring] = useState("");
  const [selectedSuites, setSelectedSuites] = useState([]);
  const [substrings, setSubstrings] = useState([]);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedList, setSelectedList] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [customSuites, setCustomSuites] = useState([]);
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    const fetchCustomSuites = async () => {
      setIsLoading(true);
      try {
        const response = await client.get("/custom_suite_list");
        setCustomSuites(response.data);
        if (response.data.length === 0) {
          setIsCreatingNewList(true);
        }
      } catch (error) {
        toast.error("Error fetching custom suites");
      } finally {
        setIsLoading(false);
      }
    };

    if (isModalOpen) {
      fetchCustomSuites();
    }
  }, [isModalOpen]);

  const handleCreateNewList = () => {
    setIsCreatingNewList(true);
  };

  const handleAddSubstring = () => {
    if (substring.trim()) {
      setSubstrings([...substrings, substring.trim()]);
      setSubstring("");
    }
  };

  const handleRemoveSubstring = (index) => {
    const updatedSubstrings = substrings.filter((_, i) => i !== index);
    setSubstrings(updatedSubstrings);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleAddSubstring();
      event.preventDefault();
    }
  };

  const handleSelectSuite = (listName) => {
    if (selectedSuites.includes(listName)) {
      setSelectedSuites(selectedSuites.filter((suite) => suite !== listName));
    } else {
      setSelectedSuites([...selectedSuites, listName]);
    }
  };

  const openViewModalHandler = (listName) => {
    openViewModal(listName);
    setIsModalOpen(false);
  };

  const openDeleteModalHandler = (listName) => {
    openDeleteModal(listName);
    setIsModalOpen(false);
  };

  const openEditModalHandler = (listName) => {
    setSelectedList(listName);
    openEditModal(listName);
    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setError("");
  };

  const handleRecord = async () => {
    setIsSubmitting(true);
    try {
      const listsToAdd = isCreatingNewList ? [newListName] : selectedSuites;

      if (isCreatingNewList) {
        if (!newListName.trim()) {
          setError("New list name is required");
          setIsSubmitting(false);
          return;
        }

        if (substrings.length === 0) {
          setError("At least one substring is required");
          setIsSubmitting(false);
          return;
        }

        await client.post("/custom_suite_list", {
          list_name: newListName,
          created_by: "current_user",
          prompts_responses: {
            [promptMessage]: apiResponse,
          },
          substrings: substrings,
        });
      } else {
        if (selectedSuites.length === 0) {
          setError("Please select a custom suite");
          setIsSubmitting(false);
          return;
        }
        await Promise.all(
          selectedSuites.map(async (listName) => {
            const promptsResponsesUpdate = { prompt: promptMessage, response: apiResponse };
            await client.put(`/custom_suite_list/${listName}/add_prompt`, promptsResponsesUpdate);
          })
        );
      }

      toast.success("Record added successfully");
      setIsCreatingNewList(false);
      setNewListName("");
      setSelectedSuites([]);
      setSubstrings([]);
      const response = await client.get("/custom_suite_list");
      setCustomSuites(response.data);
    } catch (error) {
      console.error("Error adding record:", error);
      setError(error.response?.data?.detail || "Error adding record");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBack = () => {
    setIsCreatingNewList(false);
    setError("");
  };

  const handleDeleteSuccess = () => {
    setSelectedSuites(selectedSuites.filter((suite) => suite !== selectedList));
  };

  return (
    <>
      <Transition.Root show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={handleCloseModal}>
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden flex justify-center items-center">
              <Transition.Child
                as="div"
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-y-[-100%]"
                enterTo="translate-y-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-y-0"
                leaveTo="translate-y-[-100%]"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-6xl h-full max-h-[90vh] flex flex-col bg-white shadow-xl rounded-lg overflow-y-auto">
                  <div className="px-4 pt-6 sm:px-6 bg-white rounded-t-lg">
                    <div className="flex items-start justify-between">
                      <div className="flex items-center">
                        {isCreatingNewList && customSuites.length > 0 && (
                          <IconButton
                            onClick={handleBack}
                            style={{ color: theme.tmryk_background_color }}
                          >
                            <ArrowBack />
                          </IconButton>
                        )}
                        <Dialog.Title
                          className="text-lg font-medium"
                          style={{ color: theme.tmryk_background_color }}
                        >
                          Add Record to Custom Suite
                        </Dialog.Title>
                      </div>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-white hover:bg-[#031A58] focus:outline-none focus:ring-2"
                          onClick={handleCloseModal}
                        >
                          <span className="sr-only">Close panel</span>
                          <CloseIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  {isLoading ? (
                    <div className="flex justify-center items-center min-h-[200px] w-full">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="flex-1 px-4 py-6 sm:px-6 overflow-y-auto">
                      {error && (
                        <Alert
                          severity="error"
                          onClose={() => setError("")}
                          style={{ marginBottom: "20px" }}
                        >
                          {error}
                        </Alert>
                      )}
                      <Paper
                        elevation={3}
                        style={{ padding: "20px", marginBottom: "20px" }}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{
                            color: theme.tmryk_background_color,
                            marginBottom: "10px",
                          }}
                        >
                          Prompt:
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ marginBottom: "20px" }}
                        >
                          {promptMessage}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          style={{
                            color: theme.tmryk_background_color,
                            marginBottom: "10px",
                          }}
                        >
                          Response:
                        </Typography>
                        <Typography variant="body1">{apiResponse}</Typography>
                      </Paper>
                      {isCreatingNewList ? (
                        <>
                          <div>
                            <Typography
                              variant="subtitle1"
                              style={{
                                marginBottom: "10px",
                                color: theme.tmryk_background_color,
                              }}
                            >
                              Custom Suite Name
                            </Typography>
                            <TextField
                              label="Enter your custom suite name here"
                              value={newListName}
                              onChange={(e) => setNewListName(e.target.value)}
                              variant="outlined"
                              fullWidth
                              style={{ marginBottom: "20px" }}
                            />
                          </div>
                          <div>
                            <Typography
                              variant="subtitle1"
                              style={{
                                marginBottom: "10px",
                                color: theme.tmryk_background_color,
                              }}
                            >
                              Substrings
                            </Typography>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                              }}
                            >
                              <TextField
                                label="Add Substring"
                                value={substring}
                                onChange={(e) => setSubstring(e.target.value)}
                                onKeyPress={handleKeyPress}
                                variant="outlined"
                                fullWidth
                                style={{ marginRight: "10px" }}
                              />
                              <Button
                                variant="contained"
                                style={{
                                  backgroundColor: theme.tmryk_background_color,
                                  color: "white",
                                }}
                                onClick={handleAddSubstring}
                              >
                                Add
                              </Button>
                            </div>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {substrings.map((substr, index) => (
                                <Chip
                                  key={index}
                                  label={substr}
                                  onDelete={() => handleRemoveSubstring(index)}
                                  style={{
                                    margin: "5px",
                                    backgroundColor:
                                      theme.tmryk_background_color,
                                    color: "white",
                                  }}
                                  deleteIcon={
                                    <CancelIcon style={{ color: "white" }} />
                                  }
                                />
                              ))}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="h6"
                              component="h3"
                              style={{ color: theme.tmryk_background_color }}
                            >
                              Select Custom Suite
                            </Typography>
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: theme.tmryk_background_color,
                                color: "white",
                              }}
                              onClick={handleCreateNewList}
                            >
                               + Create New Suite
                            </Button>
                          </div>
                          <div
                            style={{
                              marginTop: "20px",
                              maxHeight: "40vh",
                              overflowY: "auto",
                            }}
                          >
                            {customSuites.map((suite, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "10px",
                                  padding: "10px",
                                  border: "1px solid #ddd",
                                  borderRadius: "8px",
                                  backgroundColor: selectedSuites.includes(
                                    suite.list_name
                                  )
                                    ? theme.tmryk_background_color
                                    : "white",
                                }}
                                onClick={() =>
                                  handleSelectSuite(suite.list_name)
                                }
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      icon={
                                        <CheckBoxOutlineBlank
                                          style={{ fontSize: 28 }}
                                        />
                                      }
                                      checkedIcon={
                                        <CheckBox
                                          style={{
                                            fontSize: 28,
                                            color: "white",
                                          }}
                                        />
                                      }
                                      checked={selectedSuites.includes(
                                        suite.list_name
                                      )}
                                      onChange={() =>
                                        handleSelectSuite(suite.list_name)
                                      }
                                      color="primary"
                                    />
                                  }
                                  label={
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <Typography
                                        variant="body1"
                                        style={{
                                          color: selectedSuites.includes(
                                            suite.list_name
                                          )
                                            ? "white"
                                            : "black",
                                        }}
                                      >
                                        {suite.list_name}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        style={{
                                          color: selectedSuites.includes(
                                            suite.list_name
                                          )
                                            ? "white"
                                            : "gray",
                                        }}
                                      >
                                        Created by: {suite.created_by} | Date:{" "}
                                        {suite.date}
                                      </Typography>
                                    </div>
                                  }
                                  style={{ margin: 0 }}
                                />
                                <div
                                  style={{
                                    marginLeft: "auto",
                                    display: "flex",
                                  }}
                                >
                                  <IconButton
                                    onClick={() =>
                                      openViewModalHandler(suite.list_name)
                                    }
                                    style={{
                                      color: selectedSuites.includes(
                                        suite.list_name
                                      )
                                        ? "white"
                                        : theme.tmryk_background_color,
                                    }}
                                  >
                                    <ViewIcon />
                                  </IconButton>
                                  <IconButton
                                    onClick={() =>
                                      openEditModalHandler(suite.list_name)
                                    }
                                    style={{
                                      color: selectedSuites.includes(
                                        suite.list_name
                                      )
                                        ? "white"
                                        : theme.tmryk_background_color,
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton
                                    onClick={() =>
                                      openDeleteModalHandler(suite.list_name)
                                    }
                                    style={{
                                      color: selectedSuites.includes(
                                        suite.list_name
                                      )
                                        ? "white"
                                        : theme.tmryk_background_color,
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                      <Box display="flex" justifyContent="flex-end" mt={3}>
                        <Button
                          variant="outlined"
                          onClick={handleCloseModal}
                          ref={cancelButtonRef}
                          style={{
                            borderColor: theme.tmryk_background_color,
                            color: theme.tmryk_background_color,
                            backgroundColor: "white",
                            marginRight: "10px",
                          }}
                        >
                          Close
                        </Button>
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: theme.tmryk_background_color,
                            color: "white",
                          }}
                          onClick={handleRecord}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <CircularProgress size={24} />
                          ) : (
                            "Record"
                          )}
                        </Button>
                      </Box>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ViewListModal
        isOpen={viewModalOpen}
        setIsOpen={setViewModalOpen}
        listName={selectedList}
        setIsModalOpen={setIsModalOpen}
      />
      <DeleteCustomListModal
        isOpen={deleteModalOpen}
        setIsOpen={setDeleteModalOpen}
        listName={selectedList}
        onDeleteSuccess={handleDeleteSuccess}
        setParentIsOpen={setIsModalOpen}
      />
      <EditCustomListModal
        isOpen={editModalOpen}
        setIsOpen={setEditModalOpen}
        listName={selectedList}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
}
